import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.customerEventSummary || initialState;

export const selectCustomersEventSummary = createSelector(
  [selectDomain],
  customersEventSummaryState => customersEventSummaryState.customerEvents,
);

export const selectLoadingEventSummary = createSelector(
  [selectDomain],
  customersEventSummaryState => customersEventSummaryState.loading,
);

export const selectError = createSelector(
  [selectDomain],
  customersEventSummaryState => customersEventSummaryState.error,
);

export const selectErrorMsg = createSelector(
  [selectDomain],
  customersEventSummaryState => customersEventSummaryState.errorMsg,
);
