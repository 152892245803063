import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.usersList || initialState;

export const selectAllUsers = createSelector(
  [selectDomain],
  usersListState => usersListState.users,
);

export const selectUsersTotal = createSelector(
  [selectDomain],
  usersListState => usersListState.usersTotal,
);

export const selectLoading = createSelector(
  [selectDomain],
  usersListState => usersListState.loading,
);

export const selectError = createSelector([selectDomain], usersListState => usersListState.error);

export const selectErrorMsg = createSelector(
  [selectDomain],
  usersListState => usersListState.errorMsg,
);
