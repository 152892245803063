import { useEffect, useState } from 'react';
import { ReactComponent as FilterDropdown } from '../../../../assets/crm/filterDropdown.svg';
import { FilterBox } from './FilterBox';

export function WebFilters(props) {
  const [searchValue, setSearchValue] = useState([
    {
      label: 'Name',
    },
    {
      label: 'Project Name',
    },
  ]);

  const [search, setSearch] = useState('');

  const [showSearchValue, setShowSearchValue] = useState(false);

  const [selectedSearchValue, setSelectedSearchValue] = useState('Name');

  const handleSearchValue = () => {
    setShowSearchValue(!showSearchValue);
  };

  const handleSearch = e => {
    const lowerCase = e.target.value.toLowerCase();
    setSearch(lowerCase);
    props.setCustomerSearch([
      {
        type: selectedSearchValue,
        value: lowerCase,
      },
    ]);
  };

  return (
    <div className="md:flex hidden justify-between border rounded-sm bg-white py-5 px-6 mb-6 -mt-12">
      <div>
        <FilterBox
          status={props.status}
          setAppliedCustomerFilters={props.setAppliedCustomerFilters}
          applyFilters={props.applyFilters}
        />
      </div>
      <div className="xl:w-96 lg:w-[540px] w-72">
        <div className="flex items-center border bg-gray-200 rounded-sm">
          <button
            onClick={handleSearchValue}
            className="2xl:w-40 xl:w-44 w-48 flex items-center justify-between bg-gray-200 p-2.5"
          >
            <h6 className="lg:text-xs text-[10px] text-black font-normal mr-2">
              {selectedSearchValue}
            </h6>

            <FilterDropdown className={showSearchValue ? 'rotate-180' : ''} />
          </button>
          {showSearchValue ? (
            <div className="absolute z-30 top-[140px] w-24 bg-gray-200 rounded-b-lg shadow-sm">
              {searchValue.map((item, index) => {
                return (
                  <>
                    <button
                      key={`searchOptions-${index}`}
                      onClick={() => {
                        setSelectedSearchValue(item?.label);
                        handleSearchValue();
                      }}
                      className="w-full flex items-center justify-between border bg-gray-200 py-3 px-2"
                    >
                      <h5 className="lg:text-xs text-[10px] text-black font-normal">
                        {item?.label}
                      </h5>
                    </button>
                    {searchValue?.length === index + 1 ? <></> : <hr className="border-white" />}
                  </>
                );
              })}
            </div>
          ) : (
            <></>
          )}
          <div className="h-9 border border-gray-300" />
          <input
            type="search"
            placeholder={`Search Customers by ${selectedSearchValue}`}
            onChange={handleSearch}
            value={search}
            className="lg:w-full w-52 text-slate-200 font-normal border-0 bg-gray-200 outline-none text-[10px] py-1.5 px-2.5 lg:text-xs lg:py-2.5 lg:px-5"
          />
        </div>
      </div>
    </div>
  );
}
