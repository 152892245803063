import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { usersListResponse } from 'types/authSchemaService';
import { getRequest } from 'utils/request';
import { usersListActions as actions } from '.';

/**
 * CustomersloadCustomers request/response handler
 */
export function* getAllUsersList(filter_obj) {
  yield delay(500);

  var params = Object.keys(filter_obj.payload)
    .filter(function (key) {
      return filter_obj.payload[key].length ? true : false;
    })
    .map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(filter_obj.payload[key]);
    })
    .join('&');
  const requestURL = `${process.env.REACT_APP_AUTH_API_URL}/user/?${params}`;

  try {
    const { data } = yield call(getRequest, requestURL);
    const users: usersListResponse = data?.response;
    if (users?.items?.length > 0) {
      yield put(
        actions.usersLoaded({
          items: users?.items,
          total: users?.total,
        }),
      );
    } else {
      yield put(actions.usersError('Something went wrong while fetching users.'));
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.usersError('Customersload Customers not found.'));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.usersError('Failed to fetch users.'));
    } else {
      yield put(actions.usersError('Something went wrong while fetching users.'));
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* usersListSaga() {
  // Watches for loadRepos actions and calls getAllUsersList when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.loadusers.type, getAllUsersList);
}
