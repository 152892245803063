import { LoadingIndicator } from 'app/components/LoadingIndicator';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import { A11y, Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getFacing, getStatus, getUnitFurnished } from 'utils/helper';
import { ReactComponent as Arrow } from '../../assets/arrowDown.svg';
import { ReactComponent as Cross } from '../../assets/close.svg';
import { ReactComponent as Copy } from '../../assets/copy.svg';
import FloorPlan from '../../assets/floorplan.png';
import { ReactComponent as Mail } from '../../assets/gmail.svg';
import { ReactComponent as ShareBlack } from '../../assets/shareBlack.svg';
import { ReactComponent as WhatsApp } from '../../assets/whatsapp.svg';
import { selectProjectDetails } from '../ProjectDetailsPage/ProjectDetails/slice/selectors';
import { useUnitDetailsSlice } from '../ProjectDetailsPage/components/DetailsPageMobile/slice/index';
import {
  selectUnitDetails,
  selectUnitDetailsLoading,
} from '../ProjectDetailsPage/components/DetailsPageMobile/slice/selectors';

const useEffectOnMount = (effect: React.EffectCallback) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
};
export function UnitDetailsPage(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openProjectShare, setOpenProjectShare] = useState(false);
  const [unitImages, setUnitImages] = useState<any[]>([]);
  const [view, setView] = useState<String>();
  const [parking, setParking] = useState<String>();
  const [phase, setPhase] = useState<String>();

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + '"></span>';
    },
  };

  const { actions } = useUnitDetailsSlice();

  const unitDetails = useSelector(selectUnitDetails);
  const unitDetailsLoading = useSelector(selectUnitDetailsLoading);
  const projectDetails = useSelector(selectProjectDetails);

  useEffectOnMount(() => {
    dispatch(actions.loadUnitDetails(id));
  });

  useEffect(() => {
    let unitImagesArr: any[] = [];
    let unitViewsArr: any[] = [];
    let unitParkingArr: any[] = [];
    if (
      unitDetails?.unit_group_image?.map(image => {
        if (!unitImagesArr.includes(image?.file)) {
          unitImagesArr.push(image?.file);
        }
      })
    )
      setUnitImages(unitImagesArr);
    if (
      unitDetails?.unit_group_view?.map(_view => {
        if (!unitViewsArr.includes(_view?.view?.name)) {
          unitViewsArr.push(_view?.view?.name);
        }
      })
    )
      setView(unitViewsArr.join());

    if (
      unitDetails?.unit_group_parking_type?.map(_type => {
        if (!unitParkingArr.includes(_type?.parking_type?.name)) {
          unitParkingArr.push(_type?.parking_type?.name);
        }
      })
    )
      setParking(unitParkingArr.join());

    if (projectDetails) {
      projectDetails?.project_phase?.map(phase => {
        if (unitDetails?.project_phase_id === phase.id) {
          let unitOptionObj = phase?.name;
          setPhase(unitOptionObj);
        }
      });
    }
  }, [unitDetails]);

  return (
    <>
      {unitDetailsLoading ? <LoadingIndicator /> : <></>}
      {unitDetails?.id ? (
        <section className="py-16">
          <div className="flex justify-between shadow-sm py-3 px-4 mb-4">
            <a
              onClick={() => {
                navigate(-1);
              }}
            >
              <Arrow />
            </a>
            <button
              onClick={() => {
                setOpenProjectShare(true);
              }}
            >
              <ShareBlack />
            </button>
            <BottomSheet open={openProjectShare}>
              <div className="h-1/2 py-6 px-8">
                <div className="flex justify-between">
                  <h4 className="text-base text-black font-semibold">Share this Project</h4>
                  <button
                    onClick={() => {
                      setOpenProjectShare(false);
                    }}
                  >
                    <Cross />
                  </button>
                </div>
                <hr className="border border-slate-500 my-4" />
                <h4 className="text-base text-black/80 text-center font-normal">
                  Share Project details via
                </h4>
                <div>
                  <div className="flex justify-between items-center border-2 border-slate-500 rounded-xl py-1.5 pr-1.5 pl-4 my-4">
                    <h6 className="text-sm text-black/80 font-medium">Whatsapp</h6>
                    <button className="border border-green-100 bg-green-100 rounded-lg p-2">
                      <WhatsApp className="w-4 h-4" />
                    </button>
                  </div>
                  <div className="flex justify-between items-center border-2 border-slate-500 rounded-xl py-1.5 pr-1.5 pl-4 my-4">
                    <h6 className="text-sm text-black/80 font-medium">Gmail</h6>
                    <button
                      onClick={() =>
                        window.open(
                          `https://mail.google.com/mail/?view=cm&fs=1&to=user@example.com&su=Check out this Project!&body=${window.location.href}`,
                          '_blank',
                        )
                      }
                      className="border border-gray-200 bg-gray-200 rounded-lg p-2"
                    >
                      <Mail className="w-4 h-4" />
                    </button>
                  </div>
                </div>
                <h4 className="text-base text-black text-center font-semibold mb-4">Or</h4>
                <h4 className="text-base text-black/80 text-center font-normal mb-4">
                  Copy below link to your clipboard and send it to people!
                </h4>
                <div className="flex justify-between items-center border-2 border-slate-500 rounded-xl py-1.5 pr-1.5 pl-4 my-4">
                  <h6 className="text-sm text-black/80 font-medium">copy link</h6>
                  <button className="p-1.5">
                    <Copy className="w-6 h-6" />
                  </button>
                </div>
              </div>
            </BottomSheet>
          </div>
          <div className="mx-4">
            {unitImages?.length ? (
              <div>
                <Swiper
                  modules={[Autoplay, Pagination, A11y]}
                  spaceBetween={10}
                  slidesPerView={1}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  grabCursor={true}
                  pagination={pagination}
                >
                  {unitImages?.map(image => (
                    <SwiperSlide className="mb-10">
                      <div className="w-full">
                        <div
                          style={{ backgroundImage: `url(${image})` }}
                          className="w-full h-96 rounded-lg bg-cover "
                        ></div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            ) : (
              <img alt="" src={FloorPlan} className="w-full h-48 rounded-lg bg-cover"></img>
            )}
          </div>

          <div className="mx-2.5">
            <div className="border-2 border-gray-300 rounded-lg shadow-sm py-4 px-4 my-3">
              <div className="flex flex-1">
                <div className="flex-1">
                  <h6 className="text-sm text-gray-600 font-medium">Configuration</h6>
                  <p className="text-base text-black font-medium">
                    {unitDetails?.unit_configuration?.name
                      ? unitDetails?.unit_configuration?.name
                      : '--'}
                  </p>
                </div>
                <div className="flex-1">
                  <h6 className="text-sm text-gray-600 font-medium">Pricing</h6>
                  <p className="text-base text-black font-medium">
                    {unitDetails?.starting_price + ' - ' + unitDetails?.ending_price}
                  </p>
                </div>
              </div>
              <hr className="border border-gray-300 my-4" />
              <div className="flex flex-1">
                <div className="flex-1">
                  <h6 className="text-sm text-gray-600 font-medium">Carpet Area</h6>
                  <p className="text-base text-black font-medium">
                    {unitDetails?.carpet_area} Sq. Ft.
                  </p>
                </div>
                <div className="flex-1">
                  <h6 className="text-sm text-gray-600 font-medium">Builtup Area</h6>
                  <p className="text-base text-black font-medium">
                    {unitDetails?.built_up_area} Sq. Ft.
                  </p>
                </div>
              </div>
              <hr className="border border-gray-300 my-4" />
              <div className="flex flex-1">
                <div className="flex-1">
                  <h6 className="text-sm text-gray-600 font-medium">Parking</h6>
                  <p className="text-base text-black font-medium">{parking ? parking : '--'}</p>
                </div>
                <div className="flex-1">
                  <h6 className="text-sm text-gray-600 font-medium">Phase</h6>
                  <p className="text-base text-black font-medium">{phase ? phase : '--'}</p>
                </div>
              </div>
              <hr className="border border-gray-300 my-4" />
              <div className="flex flex-1">
                <div className="flex-1">
                  <h6 className="text-sm text-gray-600 font-medium">Facing</h6>
                  <p className="text-base text-black font-medium">
                    {getFacing(unitDetails?.facing_directions)}
                  </p>
                </div>
                <div className="flex-1">
                  <h6 className="text-sm text-gray-600 font-medium">View</h6>
                  <p className="text-base text-black font-medium">{view}</p>
                </div>
              </div>
              <hr className="border border-gray-300 my-4" />
              <div className="flex flex-1">
                <div className="flex-1">
                  <h6 className="text-sm text-gray-600 font-medium">Furnished</h6>
                  <p className="text-base text-black font-medium">
                    {getUnitFurnished(unitDetails?.furnished)}
                  </p>
                </div>
                <div className="flex-1">
                  <h6 className="text-sm text-gray-600 font-medium">Status</h6>
                  <p className="text-base text-black font-medium">
                    {getStatus(unitDetails?.status)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
    </>
  );
}
