import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { developersListResponse } from 'types/projectServiceSchemas';
import { request } from 'utils/request';
import { developerListActions as actions } from '.';

/**
 * Developers request/response handler
 */
export function* getDeveloperList() {
  yield delay(500);

  const requestURL = `${process.env.REACT_APP_PROJECT_API_URL}/developer/`;
  try {
    const developers: developersListResponse = yield call(request, requestURL);

    if (developers?.items?.length > 0) {
      yield put(
        actions.developersLoaded({
          items: developers?.items,
          total: developers?.total,
        }),
      );
    } else {
      yield put(actions.developerError('Something went wrong while fetching developers.'));
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.developerError('Developers not found.'));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.developerError('Failed to fetch developers.'));
    } else {
      yield put(actions.developerError('Something went wrong while fetching developers.'));
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* developersListSaga() {
  // Watches for loadRepos actions and calls getDeveloperList when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.loadDevelopers.type, getDeveloperList);
}
