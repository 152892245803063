import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.unitType || initialState;

export const selectUnitTypes = createSelector(
  [selectDomain],
  unitTypesListState => unitTypesListState.unitTypes,
);

export const selectUnitTypesTotal = createSelector(
  [selectDomain],
  unitTypesListState => unitTypesListState.unitTypesTotal,
);

export const selectUnitTypesLoading = createSelector(
  [selectDomain],
  unitTypesListState => unitTypesListState.loading,
);

export const selectError = createSelector(
  [selectDomain],
  unitTypesListState => unitTypesListState.error,
);

export const selectErrorMsg = createSelector(
  [selectDomain],
  unitTypesListState => unitTypesListState.errorMsg,
);
