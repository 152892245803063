import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { crmServiceSchemas } from 'types/crmServiceSchemas';
import { postRequest } from 'utils/request';
import { callCenterInfoActions as actions } from '.';
/**
 * CustomersloadCustomers request/response handler
 */
export function* getCallCenterInfo() {
  yield delay(500);

  const requestURL = `${process.env.REACT_APP_DDP_CRM_API_URL}/call_center/info/`;

  try {
    const { data } = yield call(postRequest, requestURL);
    const callCenterInfo: crmServiceSchemas['CallCenterInfo'] = data.response;
    if (callCenterInfo) {
      yield put(actions.callCenterInfoLoaded(callCenterInfo));
    } else {
      yield put(actions.callCenterInfoError('Something went wrong while fetching callCenterInfo.'));
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.callCenterInfoError('Customersload Customers not found.'));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.callCenterInfoError('Failed to fetch callCenterInfo.'));
    } else {
      yield put(actions.callCenterInfoError('Something went wrong while fetching callCenterInfo.'));
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* callCenterInfoSaga() {
  // Watches for loadRepos actions and calls getCallCenterInfo when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.loadCallCenterInfo.type, getCallCenterInfo);
}
