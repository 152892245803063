import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state

const selectSearchDomain = (state: RootState) => state.filterSearchProjectList || initialState;

export const selectFilerSearchProjects = createSelector(
  [selectSearchDomain],
  filterSearchProjectListState => filterSearchProjectListState.projects,
);

export const selectFilerSearchProjectsTotal = createSelector(
  [selectSearchDomain],
  filterSearchProjectListState => filterSearchProjectListState.projectsTotal,
);

export const selectFilerSearchLoading = createSelector(
  [selectSearchDomain],
  filterSearchProjectListState => filterSearchProjectListState.loading,
);

export const selectFilerSearchError = createSelector(
  [selectSearchDomain],
  filterSearchProjectListState => filterSearchProjectListState.error,
);

export const selectFilerSearchErrorMsg = createSelector(
  [selectSearchDomain],
  filterSearchProjectListState => filterSearchProjectListState.errorMsg,
);
