import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.cities || initialState;

export const selectCities = createSelector(
  [selectDomain],
  citiesListState => citiesListState.cities,
);

export const selectCitiesLoading = createSelector(
  [selectDomain],
  citiesListState => citiesListState.loading,
);

export const selectError = createSelector([selectDomain], citiesListState => citiesListState.error);

export const selectErrorMsg = createSelector(
  [selectDomain],
  citiesListState => citiesListState.errorMsg,
);
