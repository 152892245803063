import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { crmServiceSchemas } from 'types/crmServiceSchemas';
import { getRequest } from 'utils/request';
import { teamEventSummaryActions as actions } from '.';
/**
 * TeamloadTeam request/response handler
 */
export function* getTeamEventSummary() {
  yield delay(500);

  const requestURL = `${process.env.REACT_APP_DDP_CRM_API_URL}/team_summary`;

  try {
    const { data } = yield call(getRequest, requestURL);
    const teamEventSummary: crmServiceSchemas['TeamSummaryResponse'] = data?.response;
    if (teamEventSummary) {
      yield put(
        actions.teamLoadedEventSummary({
          items: teamEventSummary,
        }),
      );
    } else {
      yield put(actions.teamError('Something went wrong while fetching teamEventSummary.'));
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.teamError('Teamload Team not found.'));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.teamError('Failed to fetch teamEventSummary.'));
    } else {
      yield put(actions.teamError('Something went wrong while fetching teamEventSummary.'));
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* teamEventSummarySaga() {
  // Watches for loadRepos actions and calls getTeamEventSummary when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.loadTeamEventSummary.type, getTeamEventSummary);
}
