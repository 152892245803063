import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { customersLeadBookingResponse } from 'types/crmServiceSchemas';
import { getRequest } from 'utils/request';
import { customerLeadListActions as actions } from '.';

/**
 * CustomerLeadBookingloadCustomerLeadBooking request/response handler
 */
export function* getCustomerLeadBooking(filter_obj) {
  yield delay(500);

  var params = Object.keys(filter_obj.payload)
    .filter(function (key) {
      return filter_obj.payload[key].length ? true : !!filter_obj.payload[key];
    })
    .map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(filter_obj.payload[key]);
    })
    .join('&');

  const requestURL = `${process.env.REACT_APP_DDP_CRM_API_URL}/customer_lead_booking/?${params}`;
  try {
    const { data } = yield call(getRequest, requestURL);
    const customerLeadBooking: customersLeadBookingResponse = data?.response;
    if (customerLeadBooking?.items.length > 0) {
      yield put(
        actions.customerLeadLoadedBooking({
          items: customerLeadBooking?.items,
          total: customerLeadBooking?.total,
          page: customerLeadBooking?.page,
          size: customerLeadBooking?.size,
        }),
      );
    } else {
      yield put(
        actions.customerLeadBookingError(
          'Something went wrong while fetching customerLeadBooking.',
        ),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(
        actions.customerLeadBookingError('CustomerLeadBookingload CustomerLeadBooking not found.'),
      );
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.customerLeadBookingError('Failed to fetch customerLeadBooking.'));
    } else {
      yield put(
        actions.customerLeadBookingError(
          'Something went wrong while fetching customerLeadBooking.',
        ),
      );
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* customerLeadBookingListSaga() {
  // Watches for loadRepos actions and calls getCustomerLeadBooking when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.loadCustomerLeadBooking.type, getCustomerLeadBooking);
}
