import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.customerSummary || initialState;

export const selectCustomersCount = createSelector(
  [selectDomain],
  customersCountState => customersCountState.count,
);

export const selectLoadingCount = createSelector(
  [selectDomain],
  customersCountState => customersCountState.loading,
);

export const selectError = createSelector(
  [selectDomain],
  customersCountState => customersCountState.error,
);

export const selectErrorMsg = createSelector(
  [selectDomain],
  customersCountState => customersCountState.errorMsg,
);
