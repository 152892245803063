import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.amenities || initialState;

export const selectAmenities = createSelector(
  [selectDomain],
  amenitiesListState => amenitiesListState.amenities,
);

export const selectAmenitiesTotal = createSelector(
  [selectDomain],
  amenitiesListState => amenitiesListState.amenitiesTotal,
);

export const selectAmenitiesLoading = createSelector(
  [selectDomain],
  amenitiesListState => amenitiesListState.loading,
);

export const selectError = createSelector(
  [selectDomain],
  amenitiesListState => amenitiesListState.error,
);

export const selectErrorMsg = createSelector(
  [selectDomain],
  amenitiesListState => amenitiesListState.errorMsg,
);
