import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.channelPartnerList || initialState;

export const selectChannelPartners = createSelector(
  [selectDomain],
  channelPartnerListState => channelPartnerListState.channelPartners,
);

export const selectChannelPartnersTotal = createSelector(
  [selectDomain],
  channelPartnerListState => channelPartnerListState.channelPartnersTotal,
);

export const selectLoading = createSelector(
  [selectDomain],
  channelPartnerListState => channelPartnerListState.loading,
);

export const selectError = createSelector(
  [selectDomain],
  channelPartnerListState => channelPartnerListState.error,
);

export const selectErrorMsg = createSelector(
  [selectDomain],
  channelPartnerListState => channelPartnerListState.errorMsg,
);
