import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state

const selectFilterDomain = (state: RootState) => state.projectFilter || initialState;

export const selectFilterObj = createSelector(
  [selectFilterDomain],
  projectFilterState => projectFilterState.filterobj,
);

export const selectFilterObjCount = createSelector(
  [selectFilterDomain],
  projectFilterState => projectFilterState.filterobjCount,
);
