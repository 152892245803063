import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { authSaga } from './saga';
import { AuthState } from './types';

export const initialState: AuthState = {
  loading: false,
  error: false,
  otpSent: false,
  errorMsg: '',
  access_token: '',
  user_id: '',
  usd_code: '',
  phone_number: '',
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    requestAuth(state, action: PayloadAction<{ usdCode: string; phoneNumber: string; otpViaCall?: boolean }>) {
      state.loading = true;
      state.error = false;
      state.otpSent = false;
      state.access_token = '';
      state.user_id = '';
      state.errorMsg = '';
    },
    otpSent(state, action) {
      state.otpSent = action.payload;
      state.error = false;
      state.loading = false;
    },
    authenticate(
      state,
      action: PayloadAction<{ usdCode: string; phoneNumber: string; otp: string }>,
    ) {
      state.loading = true;
      state.error = false;
      state.access_token = '';
      state.user_id = '';
      state.errorMsg = '';
    },
    authenticated(state, action: PayloadAction<{ access_token: string; user_id: string }>) {
      state.access_token = action.payload.access_token;
      state.user_id = action.payload.user_id;
      state.loading = false;
      localStorage.setItem('userToken', action.payload.access_token);
      localStorage.setItem('userID', action.payload.user_id);
    },
    authenticationError(state, action: PayloadAction<string>) {
      state.error = true;
      state.errorMsg = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: authActions, reducer } = slice;

export const useAuthSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: authSaga });
  return { actions: slice.actions };
};
