import { Dialog, Option, Select } from '@material-tailwind/react';
import { Card } from 'app/components/Card';
import { BlackLoadingIndicator } from 'app/components/LoadingIndicator';
import { PageWrapper } from 'app/components/PageWrapper';
import RangeSlider from 'app/components/RangeSlider/RangeSlider';
import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { useDispatch, useSelector } from 'react-redux';
import { BottomSheet } from 'react-spring-bottom-sheet';
import DefaultItemRenderer from 'styles/theme/customMultiSelect';
import { PRICES, getStageName } from 'utils/helper';
import { useAmenitiesListSlice } from '../ProjectListPage/Projects/ProjectList/slices/amenitiesSlice';
import { selectAmenities } from '../ProjectListPage/Projects/ProjectList/slices/amenitiesSlice/selectors';
import { useCitiesListSlice } from '../ProjectListPage/Projects/ProjectList/slices/citySlice';
import { selectCities } from '../ProjectListPage/Projects/ProjectList/slices/citySlice/selectors';
import { useDevelopersListSlice } from '../ProjectListPage/Projects/ProjectList/slices/developerSlice';
import { selectDevelopers } from '../ProjectListPage/Projects/ProjectList/slices/developerSlice/selectors';
import {
  selectFilerSearchLoading,
  selectFilerSearchProjects,
  selectFilerSearchProjectsTotal,
} from '../ProjectListPage/Projects/ProjectList/slices/filterSearchSlice/selectors';
import { useProjectFilterSlice } from '../ProjectListPage/Projects/ProjectList/slices/filterSlice';
import {
  selectFilterObj,
  selectFilterObjCount,
} from '../ProjectListPage/Projects/ProjectList/slices/filterSlice/selectors';
import { useProjectSearchSlice } from '../ProjectListPage/Projects/ProjectList/slices/searchSlice';
import { useUnitConfigurationListSlice } from '../ProjectListPage/Projects/ProjectList/slices/untiConfigurationSlice';
import { selectUnitConfigurations } from '../ProjectListPage/Projects/ProjectList/slices/untiConfigurationSlice/selectors';
import { useUnitTypeListSlice } from '../ProjectListPage/Projects/ProjectList/slices/untiTypeSlice';
import { selectUnitTypes } from '../ProjectListPage/Projects/ProjectList/slices/untiTypeSlice/selectors';
import { ReactComponent as ArrowWhite } from './../../assets/arrow-rightwhite.svg';
import { ReactComponent as Arrow } from './../../assets/arrowDown.svg';
import { ReactComponent as Cross } from './../../assets/close.svg';
import { ReactComponent as CrossWhite } from './../../assets/closewhite.svg';
import { ReactComponent as FilterIcon } from './../../assets/filter.svg';
import { ReactComponent as SearchIcon } from './../../assets/search.svg';
import { ReactComponent as NoResults } from './../../assets/zeroProjects.svg';

import { useProjectFilterSearchSlice } from '../ProjectListPage/Projects/ProjectList/slices/filterSearchSlice';
export function SearchResultPage() {
  const useEffectOnMount = (effect: React.EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, []);
  };

  useEffectOnMount(() => {
    dispatch(developeractions.loadDevelopers());
    dispatch(unitconfigurationsactions.loadUnitConfiguration());
    dispatch(unittypeactions.loadUnitType());
    dispatch(amenitiesactions.loadAmenities());
    dispatch(citiesactions.loadCities());
    setSelectedAmenities(filterobj?.amenities);
    setSelectedCities(filterobj?.city);
    setSelectedDevelopers(filterobj?.developer_id);
    setSelectedUnitConf(filterobj?.unit_group_configuration);
    setSelectedUnitOption(filterobj?.unit_group_type);
    setselectedPriceRange(filterobj?.price_range);
    setSelectedProjectrIds(filterobj?.project_ids);
    setselectedStageOfDevelopment(filterobj?.stage_name);
    setInputText(filterobj?.project_and_developer_name);
  });

  const [openFilterModal, setOpenFilterModal] = React.useState(false);

  const [selectedDeveloperIds, setSelectedDeveloperIds] = useState<
    string[] | undefined[] | undefined | any
  >([]);

  const [selectedProjectrIds, setSelectedProjectrIds] = useState<
    string[] | undefined[] | undefined | any
  >([]);

  const [selectedUnitOption, setSelectedUnitOption] = useState<
    string[] | undefined[] | undefined | any
  >([]);

  const [selectedUnitConf, setSelectedUnitConf] = useState<
    string[] | undefined[] | undefined | any
  >([]);

  const [selectedStageOfDevelopment, setselectedStageOfDevelopment] = useState<
    string | undefined | any
  >();

  const [selectedAmenities, setSelectedAmenities] = useState<
    string[] | undefined[] | undefined | any
  >([]);

  const [selectedCities, setSelectedCities] = useState<string[] | undefined[] | undefined | any>(
    [],
  );

  const [selectedDevelopers, setSelectedDevelopers] = useState<
    string[] | undefined[] | undefined | any
  >([]);

  const [optedCities, setOptedCities] = useState<any>([]);

  const [optedDevelopers, setOptedDevelopers] = useState<any>([]);

  const [optedUnitConf, setOptedUnitConf] = useState('');

  const [optedUnitTypes, setOptedUnitTypes] = useState('');

  const [optedAmenities, setOptedAmenities] = useState('');

  const [optedStageOfDevelopment, setOptedStageOfDevelopment] = useState('');

  const [minPrice, setMinPrice] = useState(0);

  const [maxPrice, setMaxPrice] = useState(54);

  const [selectedPriceRange, setselectedPriceRange] = useState<string | undefined>();

  const handleFilterModal = () => setOpenFilterModal(!openFilterModal);

  const { actions } = useProjectSearchSlice();

  const { actions: developeractions } = useDevelopersListSlice();

  const { actions: unitconfigurationsactions } = useUnitConfigurationListSlice();

  const { actions: unittypeactions } = useUnitTypeListSlice();

  const { actions: amenitiesactions } = useAmenitiesListSlice();

  const { actions: citiesactions } = useCitiesListSlice();

  const { actions: filteractions } = useProjectFilterSlice();

  const { actions: filtersearchactions } = useProjectFilterSearchSlice();

  const developers = useSelector(selectDevelopers);
  const unitConfigurations = useSelector(selectUnitConfigurations);
  const unitTypes = useSelector(selectUnitTypes);
  const stageOfDevelopment = ['1', '2', '3'];
  const amenities = useSelector(selectAmenities);
  const cities = useSelector(selectCities);
  const filterobj = useSelector(selectFilterObj);
  const filterCount = useSelector(selectFilterObjCount);
  const loading = useSelector(selectFilerSearchLoading);

  useEffect(() => {
    let filterSearchObj = { ...filterobj };

    if (filterSearchObj?.price_range) {
      const tempMinPrice = filterSearchObj?.price_range
        ?.split(',')[0]
        .substring(1, filterSearchObj?.price_range?.split(',')[0].length);

      const tempMaxPrice = filterSearchObj?.price_range
        ?.split(',')[1]
        .substring(1, filterSearchObj?.price_range?.split(',')[1].length - 1);
      setMinPrice(PRICES.findIndex(item => item.value === tempMinPrice));
      setMaxPrice(PRICES.findIndex(item => item.value === tempMaxPrice));
      if (
        PRICES.findIndex(item => item.value === tempMinPrice) === 0 &&
        PRICES.findIndex(item => item.value === tempMaxPrice) === 54
      ) {
        filterSearchObj['price_range'] = '';
      }
    }
    dispatch(filtersearchactions.loadProjectsFilterSearch(filterSearchObj));
  }, [filterobj]);

  const [inputText, setInputText] = useState('');

  const dispatch = useDispatch();

  let inputHandler = e => {
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  const resetFilters = () => {
    setSelectedUnitOption([]);
    setSelectedUnitConf([]);
    setselectedStageOfDevelopment('');
    setSelectedAmenities([]);
    setSelectedCities([]);
    setSelectedDeveloperIds([]);
    setSelectedDevelopers([]);
    setOptedStageOfDevelopment('');
    setOptedCities([]);
    setOptedDevelopers([]);
    setselectedPriceRange('');
    setMinPrice(0);
    setMaxPrice(54);
  };

  const filterfetchQuery = () => {
    handleFilterModal();
  };

  const [citiesOptions, setCitiesOptions] = useState<any>([]);

  useEffect(() => {
    setCitiesOptions(
      cities?.map(city => ({
        label: city?.name,
        value: city?.id,
      })),
    );
  }, [cities]);
  const [developersOptions, setDevelopersOption] = useState<any>([]);

  useEffect(() => {
    setDevelopersOption(
      developers.map(developer => ({
        label: developer?.name,
        value: developer?.id,
      })),
    );
  }, [developers]);

  useEffect(() => {
    if (developersOptions && selectedDevelopers) {
      let arr: any = [];
      selectedDevelopers.map(id => {
        let developersObj = developersOptions.filter(data => data?.value == id);
        if (developersObj.length > 0) {
          arr.push(developersObj[0]);
        }
      });
      setOptedDevelopers(arr);
    }
  }, [selectedDevelopers]);

  useEffect(() => {
    if (citiesOptions && selectedCities) {
      let arr: any = [];
      selectedCities.map(id => {
        let cityObj = citiesOptions.filter(data => data?.value == id);
        if (cityObj.length > 0) {
          arr.push(cityObj[0]);
        }
      });
      setOptedCities(arr);
    }
  }, [selectedCities]);

  const setSelectedUnitOptionTemp = unitOptionId => {
    let data: string[] | undefined[] | undefined | any = [...selectedUnitOption];
    if (data?.includes(unitOptionId)) {
      var index = data.indexOf(unitOptionId);
      if (index > -1) {
        data.splice(index, 1);
      }
      setSelectedUnitOption(data);
    } else {
      data?.push(unitOptionId);
      setSelectedUnitOption(data);
    }
  };

  const setSelectedDevelopersTemp = optionSelected => {
    let developerIdsArr: any = [];
    optionSelected.map(item => {
      if (!developerIdsArr.includes(item?.value)) {
        developerIdsArr.push(item?.value);
      }
    });
    setSelectedDevelopers(developerIdsArr);
  };

  const setSelectedCitiesTemp = optionSelected => {
    let citiesIdsArr: any = [];
    optionSelected.map(item => {
      if (!citiesIdsArr.includes(item?.value)) {
        citiesIdsArr.push(item?.value);
      }
    });
    setSelectedCities(citiesIdsArr);
  };

  const setSelectedUnitConfTemp = unitConfId => {
    let data: string[] | undefined[] | undefined | any = [...selectedUnitConf];

    if (data?.includes(unitConfId)) {
      var index = data.indexOf(unitConfId);
      if (index > -1) {
        data.splice(index, 1);
      }
      setSelectedUnitConf(data);
    } else {
      data?.push(unitConfId);
      setSelectedUnitConf(data);
    }
  };

  const setSelectedAmenitiesTemp = amenitiesId => {
    let data: string[] | undefined[] | undefined | any = [...selectedAmenities];
    if (data?.includes(amenitiesId)) {
      var index = data.indexOf(amenitiesId);
      if (index > -1) {
        data.splice(index, 1);
      }
      setSelectedAmenities(data);
    } else {
      data?.push(amenitiesId);
      setSelectedAmenities(data);
    }
  };

  const handleMinPriceChange = e => {
    if (e) {
      setMinPrice(e);
      if (Number(e) > Number(maxPrice)) {
        setMaxPrice(e);
      }
    }
  };

  const handleMaxPriceChange = e => {
    if (e) {
      setMaxPrice(e);
      if (Number(e) < Number(minPrice)) {
        setMinPrice(e);
      }
    }
  };

  const showFilterDeveloper = () => {
    let arr: any = [];
    selectedDevelopers.forEach(id => {
      let developersObj = developers.filter(data => data?.id == id);
      if (developersObj.length > 0) {
        arr.push(developersObj[0]);
      }
    });
    return arr.map(item => item?.name).join(', ');
  };

  const showFiltercities = () => {
    let arr: any = [];
    selectedCities.forEach(id => {
      let citiessObj = cities.filter(data => data?.id == id);
      if (citiessObj.length > 0) {
        arr.push(citiessObj[0]);
      }
    });
    return arr.map(item => item?.name).join(', ');
  };

  useEffect(() => {
    let arr: any = [];
    selectedUnitOption.forEach(id => {
      let unitOptionObj = unitTypes.filter(data => data?.id == id);
      if (unitOptionObj.length > 0) {
        arr.push(unitOptionObj[0]);
      }
    });
    setOptedUnitTypes(arr.map(item => item?.name).join(', '));
  }, [selectedUnitOption, unitTypes]);

  useEffect(() => {
    let arr: any = [];
    selectedUnitConf.forEach(id => {
      let unitConfObj = unitConfigurations.filter(data => data?.id == id);
      if (unitConfObj.length > 0) {
        arr.push(unitConfObj[0]);
      }
    });
    setOptedUnitConf(arr.map(item => item?.name).join(', '));
  }, [selectedUnitConf, unitConfigurations]);

  useEffect(() => {
    let arr: any = [];
    selectedAmenities.forEach(id => {
      let amenitiesObj = amenities.filter(data => data?.id == id);
      if (amenitiesObj.length > 0) {
        arr.push(amenitiesObj[0]);
      }
    });
    setOptedAmenities(arr.map(item => item?.name).join(', '));
  }, [selectedAmenities, amenities]);

  useEffect(() => {
    let filterObj = {
      city: [...selectedCities],
      developer_id: [...selectedDevelopers],
      unit_group_configuration: [...selectedUnitConf],
      unit_group_type: [...selectedUnitOption],
      amenities: [...selectedAmenities],
      project_and_developer_name: inputText,
      project_ids: [...selectedProjectrIds],
      stage_name: selectedStageOfDevelopment,
      price_range: '',
    };
    if (selectedDeveloperIds) {
      filterObj.developer_id = [...filterObj.developer_id, ...selectedDeveloperIds];
    }
    if (selectedProjectrIds) {
      filterObj.project_ids = [...filterObj.project_ids, ...selectedProjectrIds];
    }

    console.log(minPrice, maxPrice, 'minPrice, maxPrice');
    if (minPrice !== undefined || maxPrice !== undefined) {
      if (minPrice === 0 && maxPrice === 54) {
        filterObj['price_range'] = '';
      } else {
        filterObj['price_range'] = `[${PRICES[minPrice].value}, ${PRICES[maxPrice].value}]`;
      }
    }
    dispatch(filteractions.loadProjectsFilter(filterObj));
  }, [
    selectedCities,
    selectedDevelopers,
    selectedUnitConf,
    selectedUnitOption,
    selectedAmenities,
    inputText,
    selectedProjectrIds,
    selectedStageOfDevelopment,
    minPrice,
    maxPrice,
    selectedDeveloperIds,
    selectedProjectrIds,
  ]);

  const fetchQuery = () => {
    dispatch(filteractions.loadProjectsFilter(filterobj));
  };
  const filterProjects = () => {
    dispatch(filteractions.loadProjectsFilter(filterobj));
    handleFilterModal();
  };

  const searchedAndFilteredProjects = useSelector(selectFilerSearchProjects);
  const searchedAndFilteredProjectsTotal = useSelector(selectFilerSearchProjectsTotal);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 540);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 540);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  function check() {
    const elements = document.getElementsByClassName('dropdown-container');
    const elements2 = document.getElementsByClassName('dropdown-heading');
    for (var i = 0; i < elements.length; i++) {
      elements[i].setAttribute('style', 'position:unset');
    }
    for (var i = 0; i < elements2.length; i++) {
      elements2[i].setAttribute('style', 'position:unset');
    }
  }

  return (
    <PageWrapper>
      <div className="flex items-center justify-center mb-10 gap-x-2 ">
        <div className="w-full border-2 border-white bg-white shadow-sm rounded-lg p-0.5 md:p-1">
          <label className="flex items-center ml-0.5 md:ml-5">
            <SearchIcon className="w-4 h-4 md:w-6 md:h-6" />
            <input
              className="text-black flex-grow outline-none ml-0.5 text-[10px] sm:text-xs md:text-sm md:ml-4"
              type="search"
              placeholder="Search by Developer or Project Name"
              onChange={inputHandler}
              value={inputText}
            />
            <button
              onClick={fetchQuery}
              className="border-2 border-purple-800 bg-primary rounded-lg py-3 px-4 md:py-5 md:px-10"
            >
              <p className="text-[10px] sm:text-xs md:text-sm text-white font-medium">Search</p>
            </button>
          </label>
        </div>
        <div>
          <button
            className="flex justify-center w-[40px] md:w-[80px] border-2 border-white bg-white rounded-lg shadow-sm py-[10px] px-2 md:py-[11px] md:px-2.5"
            onClick={filterfetchQuery}
          >
            <FilterIcon className="md:w-12 md:h-12 w-6 h-6" />
          </button>
          {filterCount > 0 ? (
            <div className="absolute border border-white bg-white rounded-full p-[2px] top-0 h-7 w-7 mt-28 ml-5 md:h-8 md:w-8 md:ml-14">
              <div className="border border-primary bg-primary rounded-full p-[3px]">
                <p className="md:text-sm text-[10px] text-white font-medium text-center">
                  {filterCount}
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        {isDesktop ? (
          <Dialog open={openFilterModal} handler={handleFilterModal} className="rounded-3xl">
            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto inset-0 z-50 outline-none focus:outline-none relative">
              {/*content*/}
              <div className="border-0 rounded-3xl shadow-sm relative flex flex-col w-full bg-white">
                {/*header*/}
                <div className="flex items-start justify-between border-b border-solid border-slate-200 bg-primary rounded-t-3xl pt-9 pb-7 px-8">
                  <h3 className="text-xl text-white font-semibold">Filters</h3>
                  <button
                    className="bg-primary border-0 text-white float-right text-3xl leading-none font-semibold outline-none focus:outline-none ml-auto"
                    onClick={handleFilterModal}
                  >
                    <span className="bg-primary text-white h-8 w-8 text-2xl block outline-none focus:outline-none">
                      <CrossWhite />
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="overflow-y-auto h-96 relative p-6 flex-auto">
                  <h2 className="text-xl text-black font-semibold">Price Range</h2>
                  <div className="flex my-8">
                    <RangeSlider
                      className="w-full h-3"
                      min={0}
                      max={54}
                      value={[minPrice, maxPrice]}
                      defaultValue={[minPrice, maxPrice]}
                      onChange={val => {
                        setMinPrice(val[0]);
                        setMaxPrice(val[1]);
                      }}
                    />
                  </div>
                  <div className="flex items-center gap-x-4 mt-12">
                    <div className="w-96">
                      <Select
                        size="md"
                        color="purple"
                        label="Min Price"
                        onChange={handleMinPriceChange}
                        onClick={check}
                        value={`${minPrice}`}
                      >
                        {PRICES.map((price, index) => (
                          <Option key={`${index}`} value={`${index}`} className="">
                            {price?.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <hr className="border border-black w-3" />
                    <div className="w-96">
                      <Select
                        size="md"
                        color="purple"
                        label="Max Price"
                        onChange={handleMaxPriceChange}
                        onClick={check}
                        value={`${maxPrice}`}
                      >
                        {PRICES.map((price, index) => (
                          <Option key={`${index}`} value={`${index}`} className="">
                            {price?.name}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  <hr className="border border-grey-400 my-12" />
                  <div className="flex gap-x-12">
                    <div className="w-96">
                      <h2 className="text-xl text-black font-semibold mb-6">City</h2>
                      <MultiSelect
                        options={citiesOptions}
                        value={optedCities}
                        onChange={setSelectedCitiesTemp}
                        labelledBy="Cities"
                        ItemRenderer={DefaultItemRenderer}
                      />
                    </div>
                    <div className="w-96">
                      <h2 className="text-xl text-black font-semibold mb-6">Developer</h2>
                      <MultiSelect
                        options={developersOptions}
                        value={optedDevelopers}
                        onChange={setSelectedDevelopersTemp}
                        labelledBy="Developers"
                        ItemRenderer={DefaultItemRenderer}
                      />
                    </div>
                  </div>
                  <hr className="border border-grey-400 my-12" />
                  <div className="w-full">
                    <h2 className="text-xl text-black font-semibold mb-6">Unit Type</h2>
                    <div className="grid gap-3 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 grid-cols-2">
                      {unitTypes.map((unit_type, index) => (
                        <button
                          key={unit_type?.id}
                          className={
                            selectedUnitOption.includes(unit_type.id)
                              ? 'border border-purple-100 bg-purple-50 rounded-lg mt-2 py-2 px-3 xl:py-3 xl:px-4'
                              : 'border border-gray-50 bg-gray-50 rounded-lg mt-2 py-2 px-3 xl:py-3 xl:px-4'
                          }
                          onClick={() => {
                            setSelectedUnitOptionTemp(unit_type?.id);
                          }}
                        >
                          <span className="text-base text-black font-normal">{unit_type.name}</span>
                        </button>
                      ))}
                    </div>
                  </div>
                  <hr className="border border-grey-400 my-12" />
                  <div className="w-full">
                    <h2 className="text-xl text-black font-semibold mb-6">Unit Configuration</h2>
                    <div className="grid gap-3 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 grid-cols-2">
                      {unitConfigurations.map((unit_configuration, index) => (
                        <button
                          key={unit_configuration?.id}
                          className={
                            selectedUnitConf.includes(unit_configuration?.id)
                              ? 'border border-purple-100 bg-purple-50 py-3 px-4 rounded-lg mt-2'
                              : 'border border-gray-50 bg-gray-50 py-3 px-4 rounded-lg mt-2'
                          }
                          onClick={() => {
                            setSelectedUnitConfTemp(unit_configuration?.id);
                          }}
                        >
                          <span className="text-base text-black font-normal">
                            {unit_configuration.name}
                          </span>
                        </button>
                      ))}
                    </div>
                  </div>
                  <hr className="border border-grey-400 my-12" />
                  <div className="w-full">
                    <h2 className="text-xl text-black font-semibold mb-6">Development Stage</h2>
                    <div className="grid gap-2 2xl:grid-cols-3 lg:grid-cols-2 grid-cols-1">
                      {stageOfDevelopment.map((item, index) => (
                        <button
                          key={item}
                          className={
                            optedStageOfDevelopment === `${index + 1}`
                              ? 'border border-purple-100 bg-purple-50 py-2 px-4 rounded-lg mt-2'
                              : 'border border-gray-50 bg-gray-50 py-2 px-4 rounded-lg mt-2'
                          }
                          onClick={() => {
                            setselectedStageOfDevelopment(item);
                            setOptedStageOfDevelopment(`${index + 1}`);
                          }}
                        >
                          <span className="text-base text-black font-normal">
                            {getStageName(item)}
                          </span>
                        </button>
                      ))}
                    </div>
                  </div>
                  <hr className="border border-grey-400 my-12" />
                  <div className="w-full">
                    <h2 className="text-xl text-black font-semibold mb-6">Amenities</h2>
                    <div className="grid gap-2 2xl:grid-cols-3 grid-cols-2">
                      {amenities.map((amenity, index) => (
                        <button
                          key={amenity?.id}
                          className={
                            selectedAmenities.includes(amenity?.id)
                              ? 'border border-purple-100 bg-purple-50 py-2 px-4 rounded-lg mt-2'
                              : 'border border-gray-50 bg-gray-50 py-2 px-4 rounded-lg mt-2'
                          }
                          onClick={() => {
                            setSelectedAmenitiesTemp(amenity?.id);
                          }}
                        >
                          <span className="text-base text-black font-normal">{amenity?.name}</span>
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-between p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-base font-semibold text-primary"
                    type="button"
                    onClick={resetFilters}
                  >
                    Clear Filters
                  </button>
                  <button
                    className={
                      searchedAndFilteredProjectsTotal
                        ? 'text-base font-medium bg-primary text-white px-6 py-3 rounded-lg shadow-sm hover:shadow-md outline-none'
                        : 'text-base font-medium bg-primary text-white px-14 py-1 rounded-lg shadow-sm hover:shadow-md outline-none'
                    }
                    type="button"
                    onClick={filterProjects}
                  >
                    {searchedAndFilteredProjectsTotal ? (
                      `Show ${searchedAndFilteredProjectsTotal} results`
                    ) : (
                      <BlackLoadingIndicator className="h-10 w-10 animate-spin text-white" />
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
          </Dialog>
        ) : (
          <BottomSheet open={openFilterModal}>
            <div className="pb-20 px-4 overflow-y-auto mt-6">
              <div className="flex justify-between shadow-sm py-3 px-4 mb-4 mt-6">
                <div className="flex items-center">
                  <button onClick={filterfetchQuery}>
                    <Arrow />
                  </button>
                  <p className="text-lg font-medium text-black ml-2">Filters</p>
                </div>
                <button onClick={resetFilters} className="text-sm text-primary font-medium">
                  Reset Filters
                </button>
              </div>
              <div className="m-4">
                <h5 className="text-base text-black font-medium">Budget</h5>
                <RangeSlider
                  className="w-full h-3 my-5"
                  min={0}
                  max={54}
                  value={[minPrice, maxPrice]}
                  defaultValue={[minPrice, maxPrice]}
                  onChange={val => {
                    setMinPrice(val[0]);
                    setMaxPrice(val[1]);
                  }}
                />
                <div className="sm:grid sm:grid-cols-2 sm:gap-x-32 mt-12">
                  <div className="sm:flex items-center xs:w-full sm:w-1/2">
                    <Select
                      color="purple"
                      label="Min Price"
                      onChange={handleMinPriceChange}
                      value={`${minPrice}`}
                    >
                      {PRICES.map((price, index) => (
                        <Option key={`${index}`} value={`${index}`} className="">
                          {price?.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  <div className=" xs:w-full xs:mt-4 sm:w-1/2 sm:mt-0">
                    <Select
                      color="purple"
                      label="Max Price"
                      onChange={handleMaxPriceChange}
                      value={`${maxPrice}`}
                    >
                      {PRICES.map((price, index) => (
                        <Option key={`${index}`} value={`${index}`} className="">
                          {price?.name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                </div>
                <div className="mt-4">
                  <div className="w-full">
                    <h6 className="text-sm text-black/90 font-semibold mb-4">Select City</h6>
                    <MultiSelect
                      options={citiesOptions}
                      value={optedCities}
                      onChange={setSelectedCitiesTemp}
                      labelledBy="Cities"
                      ItemRenderer={DefaultItemRenderer}
                    />
                  </div>
                  <div className="w-full mt-7">
                    <h6 className="text-sm text-black/90 font-semibold mb-4">Select Developer</h6>
                    <MultiSelect
                      options={developersOptions}
                      value={optedDevelopers}
                      onChange={setSelectedDevelopersTemp}
                      labelledBy="Developers"
                      ItemRenderer={DefaultItemRenderer}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <h6 className="text-base text-black/90 font-semibold mb-4">Unit Type</h6>
                  <div className="grid gap-3  grid-cols-2 sm:grid-cols-3">
                    {unitTypes.map((unit_type, index) => (
                      <button
                        key={unit_type?.id}
                        className={
                          selectedUnitOption?.includes(unit_type?.id)
                            ? 'border border-purple-100 bg-purple-100 py-2 px-3 rounded-lg mt-2'
                            : 'border border-gray-50 bg-gray-50 py-2 px-3 rounded-lg mt-2'
                        }
                        onClick={() => {
                          setSelectedUnitOptionTemp(unit_type?.id);
                        }}
                      >
                        <span className="text-base text-black font-normal">{unit_type.name}</span>
                      </button>
                    ))}
                  </div>
                  <h6 className="text-base text-black/90 font-semibold mt-7 mb-4">
                    Development Stage
                  </h6>
                  <div className="grid gap-2 grid-cols-1 sm:grid-cols-2">
                    {stageOfDevelopment.map((item, index) => (
                      <button
                        key={item}
                        className={
                          optedStageOfDevelopment === `${index + 1}`
                            ? 'border border-purple-100 bg-purple-100 py-2 px-3 rounded-lg mt-2'
                            : 'border border-gray-50 bg-gray-50 py-2 px-3 rounded-lg mt-2'
                        }
                        onClick={() => {
                          setOptedStageOfDevelopment(`${index + 1}`);
                          setselectedStageOfDevelopment(item);
                        }}
                      >
                        <span className="text-base text-black font-normal">
                          {getStageName(item)}
                        </span>
                      </button>
                    ))}
                  </div>
                  <h6 className="text-base text-black/90 font-semibold mt-7 mb-4">
                    Unit Configuration or Bedrooms
                  </h6>
                  <div className="grid gap-3 grid-cols-2 sm:grid-cols-3">
                    {unitConfigurations.map((unit_configuration, index) => (
                      <button
                        key={unit_configuration?.id}
                        className={
                          selectedUnitConf.includes(unit_configuration?.id)
                            ? 'border border-purple-100 bg-purple-100 py-2 px-3 rounded-lg mt-2'
                            : 'border border-gray-50 bg-gray-50 py-2 px-3 rounded-lg mt-2'
                        }
                        onClick={() => {
                          setSelectedUnitConfTemp(unit_configuration?.id);
                        }}
                      >
                        <span className="text-base text-black font-normal">
                          {unit_configuration.name}
                        </span>
                      </button>
                    ))}
                  </div>
                  <h6 className="text-base text-black/90 font-semibold mt-7 mb-4">Amenities</h6>
                  <div className="grid gap-3 grid-cols-2 sm:grid-cols-3">
                    {amenities.map((amenity, index) => (
                      <button
                        key={amenity?.id}
                        className={
                          selectedAmenities.includes(amenity?.id)
                            ? 'border border-purple-100 bg-purple-100 py-2 px-3 rounded-lg mt-2'
                            : 'border border-gray-50 bg-gray-50 py-2 px-3 rounded-lg mt-2'
                        }
                        onClick={() => {
                          setSelectedAmenitiesTemp(amenity?.id);
                        }}
                      >
                        <span className="text-base text-black font-normal">{amenity?.name}</span>
                      </button>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="fixed bottom-0 w-full flex justify-between bg-white shadow-sm border-2 rounded-t-lg py-2 px-4 mt-4">
              <div className="flex items-center"></div>
              <button
                className={
                  searchedAndFilteredProjectsTotal
                    ? 'flex items-center border bg-primary rounded-lg py-3 px-6 text-sm text-white font-medium'
                    : 'flex items-center border bg-primary rounded-lg py-0.5 px-16 text-sm text-white font-medium'
                }
                type="button"
                onClick={filterProjects}
              >
                {searchedAndFilteredProjectsTotal ? (
                  <>
                    Show {searchedAndFilteredProjectsTotal} results
                    <span>
                      <ArrowWhite className="ml-4" />
                    </span>
                  </>
                ) : (
                  <BlackLoadingIndicator className="h-10 w-10 animate-spin text-white" />
                )}
              </button>
            </div>
          </BottomSheet>
        )}
      </div>
      <div className="flex flex-wrap gap-x-6 gap-y-4 w-5/6 mt-6 mb-10">
        {selectedDevelopers.length ? (
          <div className="flex items-center border-2 border-purple-200 bg-purple-50 rounded-md py-1.5 px-3">
            <p className="text-sm text-black/100 font-medium items-center">
              Developer:
              <span className="font-normal ml-2.5">{showFilterDeveloper()}</span>
            </p>
            <button
              onClick={() => {
                setSelectedDeveloperIds([]);
                setSelectedDevelopers([]);
                let toBeUpdatedFilters = { ...filterobj };
                dispatch(
                  filteractions.loadProjectsFilter({
                    ...toBeUpdatedFilters,
                    developer_id: [],
                  }),
                );
              }}
            >
              <Cross className="w-5 h-5 text-primary ml-3" />
            </button>
          </div>
        ) : (
          <></>
        )}
        {selectedPriceRange?.length && !(minPrice === 0 && maxPrice === 54) ? (
          <div className="flex items-center border-2 border-purple-200 bg-purple-50 rounded-md py-1.5 px-3">
            <p className="text-sm text-black/100 font-medium items-center">
              Price Range:
              <span className="font-normal ml-2.5">
                {PRICES[minPrice].name} - {PRICES[maxPrice].name}
              </span>
            </p>
            <button
              onClick={() => {
                setselectedPriceRange('');
                let toBeUpdatedFilters = { ...filterobj };
                dispatch(
                  filteractions.loadProjectsFilter({
                    ...toBeUpdatedFilters,
                    price_range: '',
                  }),
                );
              }}
            >
              <Cross className="w-5 h-5 text-primary ml-3" />
            </button>
          </div>
        ) : (
          <></>
        )}
        {selectedUnitOption.length ? (
          <div className="flex items-center border-2 border-purple-200 bg-purple-50 rounded-md py-1.5 px-3">
            <p className="text-sm text-black/100 font-medium items-center">
              Type:
              <span className="font-normal ml-2.5">{optedUnitTypes}</span>
            </p>
            <button
              onClick={() => {
                setSelectedUnitOption([]);
                let toBeUpdatedFilters = { ...filterobj };
                dispatch(
                  filteractions.loadProjectsFilter({
                    ...toBeUpdatedFilters,
                    unit_group_type: [],
                  }),
                );
              }}
            >
              <Cross className="w-5 h-5 text-primary ml-3" />
            </button>
          </div>
        ) : (
          <></>
        )}
        {selectedCities.length ? (
          <div className="flex items-center border-2 border-purple-200 bg-purple-50 rounded-md py-1.5 px-3">
            <p className="text-sm text-black/100 font-medium items-center">
              City:
              <span className="font-normal ml-2.5">{showFiltercities()}</span>
            </p>
            <button
              onClick={() => {
                setOptedCities([]);
                setSelectedCities([]);
                let toBeUpdatedFilters = { ...filterobj };
                dispatch(
                  filteractions.loadProjectsFilter({
                    ...toBeUpdatedFilters,
                    city: [],
                  }),
                );
              }}
            >
              <Cross className="w-5 h-5 text-primary ml-3" />
            </button>
          </div>
        ) : (
          <></>
        )}

        {selectedUnitConf.length ? (
          <div className="flex items-center border-2 border-purple-200 bg-purple-50 rounded-md py-1.5 px-3">
            <p className="text-sm text-black/100 font-medium items-center">
              Configuration:
              <span className="font-normal ml-2.5">{optedUnitConf}</span>
            </p>
            <button
              onClick={() => {
                setSelectedUnitConf([]);
                let toBeUpdatedFilters = { ...filterobj };
                dispatch(
                  filteractions.loadProjectsFilter({
                    ...toBeUpdatedFilters,
                    unit_group_configuration: [],
                  }),
                );
              }}
            >
              <Cross className="w-5 h-5 text-primary ml-3" />
            </button>
          </div>
        ) : (
          <></>
        )}

        {optedStageOfDevelopment.length ? (
          <div className="flex items-center border-2 border-purple-200 bg-purple-50 rounded-md py-1.5 px-3">
            <p className="text-sm text-black/100 font-medium items-center">
              Stage:
              <span className="font-normal ml-2.5">{getStageName(optedStageOfDevelopment)}</span>
            </p>
            <button
              onClick={() => {
                setOptedStageOfDevelopment('');
                setselectedStageOfDevelopment('');
                let toBeUpdatedFilters = { ...filterobj };
                dispatch(
                  filteractions.loadProjectsFilter({ ...toBeUpdatedFilters, stage_name: '' }),
                );
              }}
            >
              <Cross className="w-5 h-5 text-primary ml-3" />
            </button>
          </div>
        ) : (
          <></>
        )}

        {selectedAmenities.length ? (
          <div className="flex items-center border-2 border-purple-200 bg-purple-50 rounded-md py-1.5 px-3">
            <p className="text-sm text-black/100 font-medium items-center">
              Amenities:
              <span className="font-normal ml-2.5">{optedAmenities}</span>
            </p>
            <button
              onClick={() => {
                setSelectedAmenities([]);
                let toBeUpdatedFilters = { ...filterobj };
                dispatch(
                  filteractions.loadProjectsFilter({ ...toBeUpdatedFilters, amenities: [] }),
                );
              }}
            >
              <Cross className="w-5 h-5 text-primary ml-3" />
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
      {loading ? (
        <BlackLoadingIndicator />
      ) : (
        <>
          <div>
            <h1 className="text-[28px] text-black font-semibold mb-4">
              Showing {searchedAndFilteredProjectsTotal} results
            </h1>
            {searchedAndFilteredProjectsTotal === 0 ? (
              <div className="bg-white rounded-lg text-center py-14">
                <div className="flex justify-center mb-[48px]">
                  <NoResults className="w-40 h-40 md:w-80 md:h-80" />
                </div>
                <h1 className="md:text-[40px] text-lg text-black font-bold mb-4">
                  No Result Found
                </h1>
                <p className="md:text-[20px] text-xs text-gray-500 font-normal mb-2">
                  We couldn't find what you searched for.
                </p>
                <p className="md:text-[20px] text-xs text-gray-500 font-normal">
                  Try searching again
                </p>
              </div>
            ) : (
              <div className="grid lg:grid-cols-3 xs:grid-cols-1 xs:gap-x-4 md:grid-cols-2 gap-x-8 lg:gap-y-14 md:gap-y-3">
                {searchedAndFilteredProjects.map(project => (
                  <Card key={project.id} project={project} to={`/project/${project.id}`} />
                ))}
              </div>
            )}
          </div>
        </>
      )}
    </PageWrapper>
  );
}
