import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { unitConfigurationListResponse } from 'types/projectServiceSchemas';
import { request } from 'utils/request';
import { unitConfigurationListActions as actions } from '.';

/**
 * unitConfigurations request/response handler
 */
export function* getunitConfigurationList() {
  yield delay(500);

  const requestURL = `${process.env.REACT_APP_PROJECT_API_URL}/unit_configuration/`;
  try {
    const unitConfigurations: unitConfigurationListResponse = yield call(request, requestURL);

    if (unitConfigurations?.items?.length > 0) {
      yield put(
        actions.unitConfigurationsLoaded({
          items: unitConfigurations?.items,
          total: unitConfigurations?.total,
        }),
      );
    } else {
      yield put(
        actions.unitConfigurationError('Something went wrong while fetching unitConfigurations.'),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.unitConfigurationError('unitConfigurations not found.'));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.unitConfigurationError('Failed to fetch unitConfigurations.'));
    } else {
      yield put(
        actions.unitConfigurationError('Something went wrong while fetching unitConfigurations.'),
      );
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* unitConfigurationsListSaga() {
  // Watches for loadRepos actions and calls getunitConfigurationList when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.loadUnitConfiguration.type, getunitConfigurationList);
}
