import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.customerHistory || initialState;

export const selectcustomerHistory = createSelector(
  [selectDomain],
  customerHistoryState => customerHistoryState.customerHistory,
);

export const selectcustomerHistoryLoading = createSelector(
  [selectDomain],
  customerHistoryState => customerHistoryState.loading,
);

export const selectcustomerHistoryError = createSelector(
  [selectDomain],
  customerHistoryState => customerHistoryState.error,
);

export const selectcustomerHistoryErrorMsg = createSelector(
  [selectDomain],
  customerHistoryState => customerHistoryState.errorMsg,
);
