import { PayloadAction } from '@reduxjs/toolkit';
import { crmServiceSchemas } from 'types/crmServiceSchemas';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { customersCountSaga } from './saga';
import { CustomersCountState } from './types';

export const initialState: CustomersCountState = {
  loading: true,
  error: false,
  errorMsg: '',
  count: {},
  filter_request: {},
};

const slice = createSlice({
  name: 'customerSummary',
  initialState,
  reducers: {
    loadCustomersCount(state, action) {
      state.loading = true;
      state.error = false;
      state.count = {};
      const filter_obj = action?.payload;
      state.filter_request = filter_obj;
    },
    customersLoadedCount(
      state,
      action: PayloadAction<{
        items: crmServiceSchemas['CustomerSummaryResponse'];
      }>,
    ) {
      const customers = action.payload;
      state.count = customers?.items;
      state.loading = false;
    },
    customersError(state, action: PayloadAction<string>) {
      state.error = true;
      state.errorMsg = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: customersCountActions, reducer } = slice;

export const useCustomersCountSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: customersCountSaga });
  return { actions: slice.actions };
};
