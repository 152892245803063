import { delay, takeLatest } from 'redux-saga/effects';
import { projectFilterActions as actions } from '.';

export function* setProjectFilter(filterInput) {
  yield delay(500);
}

export function* projectFilterSaga() {
  yield takeLatest(actions.loadProjectsFilter.type, setProjectFilter);
}
