import { Dialog } from '@material-tailwind/react';
import { selectUnitConfigurations } from 'app/pages/ProjectListPage/Projects/ProjectList/slices/untiConfigurationSlice/selectors';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as CheckboxFilled } from '../../../../../assets/crm/checkboxfill.svg';
import { ReactComponent as CheckboxUnFilled } from '../../../../../assets/crm/chek unfill box.svg';
import { ReactComponent as CrossPurple } from '../../../../../assets/crm/closePurple.svg';
import { ReactComponent as DotBlue } from '../../../../../assets/crm/dotBlue.svg';
import { ReactComponent as FilterDropdown } from '../../../../../assets/crm/filterDropdown.svg';
import { allFilters } from '../../../filters';

export function FilterBox(props) {
  const [moreFilterModal, setMoreFilterModal] = useState(false);

  const [filters, setFilters] = useState<any[]>(allFilters);

  const [active, setActive] = useState(0);

  const [show, setShow] = useState(0);

  const unitConfigurations = useSelector(selectUnitConfigurations);
  const [unitConfigLoaded, setUnitConfigLoaded] = useState(false);

  const moreFiltersBreakPoint = 5;

  useEffect(() => {
    props.setAppliedCustomerFilters(filters);
  }, [filters]);

  useEffect(() => {
    if(!unitConfigLoaded && unitConfigurations.length){
      const bookingFilters = [...allFilters];
      const bookingFiltersWithUnitConfigurations = bookingFilters.map(item => {
        if (item.label === 'Unit Configuration') {
          return {
            ...item,
            option: unitConfigurations.map(confItem => {
              return { subLabel: confItem.name, selected: false, id: confItem.id };
            }),
          };
        } else {
          return { ...item };
        }
      });
      setUnitConfigLoaded(true);
      setFilters(bookingFiltersWithUnitConfigurations);
    }
  }, [unitConfigurations]);

  const toggleActive = value => {
    if (active !== value) {
      setActive(value);
    } else {
      setActive(0);
    }
  };
  const toggleShow = value => {
    if (active !== value) {
      setShow(value);
    } else {
      setShow(0);
    }
  };
  const toggleMoreFilters = () => {
    setMoreFilterModal(!moreFilterModal);
  };

  const updateFilterSelections = (item, label) => {
    let toBeUpdatedFilter = [...filters];
    let mainFilterIndex = toBeUpdatedFilter.findIndex(filterItem => filterItem.value === item);
    let subFilterIndex = toBeUpdatedFilter[mainFilterIndex]?.option.findIndex(
      subFilterItem => subFilterItem.subLabel === label,
    );

    let toBeUpdatedFilterOptions = toBeUpdatedFilter[mainFilterIndex]?.option;
    const isFilterMultiSelect = toBeUpdatedFilter[mainFilterIndex]?.multiselect;

    const updatedFilterOptions = toBeUpdatedFilterOptions.map((toBeUpdatedFilterOption, index) => {
      if (!isFilterMultiSelect) {
        if (index === subFilterIndex) {
          return { ...toBeUpdatedFilterOption, selected: true };
        }
        return { ...toBeUpdatedFilterOption, selected: false };
      } else {
        if (index === subFilterIndex) {
          return {
            ...toBeUpdatedFilterOption,
            selected: !toBeUpdatedFilterOption.selected,
          };
        }
        return {
          ...toBeUpdatedFilterOption,
          selected: toBeUpdatedFilterOption.selected,
        };
      }
    });

    toBeUpdatedFilter[mainFilterIndex] = {
      ...toBeUpdatedFilter[mainFilterIndex],
      option: updatedFilterOptions,
    };
    setFilters(toBeUpdatedFilter);
  };

  return (
    <>
      <div className="flex flex-wrap gap-4">
        {filters.map((item, index) => {
          return item?.value <= moreFiltersBreakPoint + 1 ? (
            <div key={`${item.value}`}>
              <button
                key={`filters-${index}`}
                onClick={() => {
                  item?.value > moreFiltersBreakPoint
                    ? toggleMoreFilters()
                    : toggleActive(item?.value);
                  toggleShow(item?.value);
                }}
                className={
                  item?.value > moreFiltersBreakPoint
                    ? 'flex items-center border border-purple-700 bg-white rounded-sm py-2.5 px-5'
                    : item?.value === active
                    ? 'flex items-center border border-gray-300 bg-gray-200 rounded-sm py-2.5 px-5'
                    : 'flex items-center border border-gray-300 bg-white rounded-sm py-2.5 px-5'
                }
              >
                <h6
                  className={
                    item?.value > moreFiltersBreakPoint
                      ? 'lg:text-sm text-xs text-primary font-normal'
                      : 'lg:text-sm text-xs text-black font-normal mr-4'
                  }
                >
                  {item?.value <= moreFiltersBreakPoint ? item?.label : 'More Filters'}
                </h6>
                {item?.value <= moreFiltersBreakPoint ? <FilterDropdown /> : <></>}
              </button>
              {item.value <= moreFiltersBreakPoint ? (
                item?.value === show ? (
                  item?.option?.length ? (
                    <div className="absolute z-30 w-72 bg-white rounded-sm shadow-lg pt-3 pb-4">
                      {item?.option?.map((sub_item, sub_index) => {
                        return (
                          <button
                            key={`${sub_item.subLabel}`}
                            className={
                              sub_item?.selected
                                ? 'w-full flex items-center justify-between border bg-purple-50 py-3 px-4'
                                : 'w-full flex items-center justify-between border bg-white py-3 px-4'
                            }
                            onClick={() => {
                              updateFilterSelections(item.value, sub_item.subLabel);
                            }}
                          >
                            <h5 className="lg:text-sm text-xs text-black font-medium">
                              {sub_item?.subLabel}
                            </h5>
                            {sub_item?.selected ? (
                              <CheckboxFilled className="w-6 h-6" />
                            ) : (
                              <CheckboxUnFilled className="w-6 h-6" />
                            )}
                          </button>
                        );
                      })}
                      <div className="grid grid-cols-2 gap-x-8 mt-5 mx-5">
                        <button
                          className="border bg-gray-200 rounded-sm p-3"
                          onClick={() => {
                            let toBeUpdatedFilters = [...filters];
                            toBeUpdatedFilters[item?.value - 1] = {
                              ...allFilters[item?.value - 1],
                            };
                            setFilters(toBeUpdatedFilters);
                            props.applyFilters(toBeUpdatedFilters);
                            toggleActive(item?.value);
                            toggleShow(item?.value);
                          }}
                        >
                          <h6 className="text-xs text-black font-normal">Clear</h6>
                        </button>
                        <button
                          onClick={() => {
                            toggleActive(item?.value);
                            toggleShow(item?.value);
                            props.applyFilters(filters);
                          }}
                          className="border bg-primary rounded-sm p-3"
                        >
                          <h6 className="text-xs text-white font-normal">Apply</h6>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          );
        })}
      </div>

      {filters?.length > moreFiltersBreakPoint ? (
        <Dialog
          open={moreFilterModal}
          handler={toggleMoreFilters}
          animate={{
            mount: { scale: 1, y: 1 },
            unmount: { scale: 0.9, y: -100 },
          }}
          className="absolute rounded-xs m-0 right-0 w-[28%] max-w-96 min-w-44 h-full overflow-y-auto flex-1"
          style={{ backgroundColor: '#F1F1F1' }}
        >
          <div className="bg-white">
            <h3 className="text-lg text-black font-medium p-7">Filters</h3>
          </div>
          <div className="grid gap-y-4 p-7">
            {filters.map((item, index) =>
              item?.value > moreFiltersBreakPoint ? (
                <div className="border border-gray-300 bg-white rounded-md shadow-sm py-5 px-4">
                  <div className="w-full flex items-center justify-between">
                    <div className="flex items-center">
                      <DotBlue />
                      <h5 className="text-sm text-black font-medium ml-1.5">{item.label}</h5>
                    </div>
                  </div>
                  {item?.option?.map((sub_item, sub_index) => (
                    <div
                      key={`customers-have-open-book`}
                      className="flex items-center justify-between mt-4 ml-3"
                    >
                      <h5 className="text-xs text-black font-medium">{sub_item?.subLabel}</h5>
                      <button
                        onClick={() => {
                          updateFilterSelections(item.value, sub_item.subLabel);
                        }}
                      >
                        {sub_item?.selected ? (
                          <CheckboxFilled className="w-5 h-5" />
                        ) : (
                          <CheckboxUnFilled className="w-5 h-5" />
                        )}
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              ),
            )}
          </div>
          <div className="flex items-center gap-x-5 bg-white p-7">
            <button
              className="w-1/3 border bg-gray-200 rounded-md p-3"
              onClick={() => {
                setFilters(allFilters);
                props.applyFilters(allFilters);
                toggleMoreFilters();
              }}
            >
              <h5 className="text-sm text-black font-medium">Clear All</h5>
            </button>
            <button
              onClick={() => {
                props.applyFilters(filters);
                toggleMoreFilters();
              }}
              className="w-2/3 border bg-primary rounded-md p-3"
            >
              <h5 className="text-sm text-white font-medium">View Customers</h5>
            </button>
          </div>
        </Dialog>
      ) : (
        <></>
      )}

      {filters.filter(item => item?.option?.filter(option => option.selected === true).length > 0)
        .length ? (
        <div className="flex flex-wrap gap-4 mt-7">
          {filters
            .filter(item => item.option.filter(option => option.selected === true).length > 0)
            .map(item => (
              <div className="flex items-center border border-purple-100 bg-purple-50 rounded-lg py-1.5 px-3">
                <h6 className="text-xs text-black font-semibold">
                  {item.label}:
                  <span className="font-normal mx-2.5">
                    {item.option
                      .filter(subItem => subItem.selected === true)
                      .map(filterItem => filterItem.subLabel)
                      .join(', ')}
                  </span>
                </h6>
                <button
                  onClick={() => {
                    let toBeUpdatedFilters = [...filters];
                    toBeUpdatedFilters[item?.value - 1] = {
                      ...allFilters[item?.value - 1],
                    };
                    setFilters(toBeUpdatedFilters);
                    props.applyFilters(toBeUpdatedFilters);
                  }}
                >
                  <CrossPurple className="w-5 h-5 text-primary ml-3" />
                </button>
              </div>
            ))}
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
