import { PayloadAction } from '@reduxjs/toolkit';
import { crmServiceSchemas } from 'types/crmServiceSchemas';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { customerDetailsSaga } from './saga';
import { CustomersDetailsState } from './types';

export const initialState: CustomersDetailsState = {
  loading: true,
  error: false,
  errorMsg: '',
  customerDetails: {},
};

const slice = createSlice({
  name: 'customerDetails',
  initialState,
  reducers: {
    loadCustomerDetails(state, id) {
      state.loading = true;
      state.error = false;
      state.customerDetails = {};
    },
    customerDetailsLoaded(
      state,
      action: PayloadAction<crmServiceSchemas['CustomerComplexResponse']>,
    ) {
      const customerDetails = action.payload;
      state.customerDetails = customerDetails;
      state.loading = false;
    },
    customerDetailsError(state, action: PayloadAction<string>) {
      state.error = true;
      state.errorMsg = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: customerListActions, reducer } = slice;

export const useCustomerDetailSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: customerDetailsSaga });
  return { actions: slice.actions };
};
