interface Props {
  className?: string;
}

export const LoadingIndicator = (props: Props) => (
  <div className="text-center font-bold text-white">
    <svg
      fill="none"
      className={`${props.className} w-10 h-10 animate-spin text-white`}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </div>
);

export const BlackLoadingIndicator = (props: Props) => (
  <div className="flex flex-row justify-center text-center font-bold text-black">
    <svg
      fill="none"
      className={props.className ? `${props.className}` : 'w-24 h-24 animate-spin text-black'}
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  </div>
);
