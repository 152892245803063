import { Checkbox } from '@material-tailwind/react';
import { Option } from 'react-multi-select-component';

interface IDefaultItemRendererProps {
  checked: boolean;
  option: Option;
  disabled?: boolean;
  onClick;
}

const DefaultItemRenderer = ({ checked, option, onClick, disabled }: IDefaultItemRendererProps) => (
  <div
    className={` transition duration-75 dark:text-gray-400 hover:text-primary${
      disabled ? 'disabled' : ''
    }`}
  >
    <Checkbox
      className="rounded-full text-center p-2"
      color="deep-purple"
      onChange={onClick}
      checked={checked}
      tabIndex={-1}
      ripple={false}
      disabled={disabled} 
      crossOrigin={undefined}    
    />

    <span className="text-black font-normal">{option.label}</span>
  </div>
);

export default DefaultItemRenderer;
