import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { customersListResponse } from 'types/crmServiceSchemas';
import { getRequest } from 'utils/request';
import { customersListActions as actions } from '.';

/**
 * CustomersloadCustomers request/response handler
 */
export function* getCustomersList(filter_obj) {
  yield delay(500);

  var params = Object.keys(filter_obj.payload)
    .filter(function (key) {
      return filter_obj.payload[key].length ? true : !!filter_obj.payload[key];
    })
    .map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(filter_obj.payload[key]);
    })
    .join('&');
  const requestURL = `${process.env.REACT_APP_DDP_CRM_API_URL}/customer/?${params}`;
  try {
    const { data } = yield call(getRequest, requestURL);
    const customers: customersListResponse = data?.response;
    if (customers?.items?.length >= 0) {
      yield put(
        actions.customersLoaded({
          items: customers?.items,
          total: customers?.total,
          page: customers?.page,
          size: customers?.size,
        }),
      );
    } else {
      yield put(actions.customersError('Something went wrong while fetching customers.'));
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.customersError('Customersload Customers not found.'));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.customersError('Failed to fetch customers.'));
    } else {
      yield put(actions.customersError('Something went wrong while fetching customers.'));
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* customersListSaga() {
  // Watches for loadRepos actions and calls getCustomersList when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.loadCustomers.type, getCustomersList);
}
