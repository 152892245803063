import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.customerLeadBooking || initialState;

export const selectCustomerLeadBooking = createSelector(
  [selectDomain],
  customerLeadBookingState => customerLeadBookingState.customerLead,
);

export const selectCustomerLeadBookingTotal = createSelector(
  [selectDomain],
  customerLeadBookingState => customerLeadBookingState.customerLeadTotal,
);

export const selectCustomerLeadBookingPage = createSelector(
  [selectDomain],
  customerLeadBookingState => customerLeadBookingState.customerLeadPage,
);

export const selectCustomerLeadBookingSize = createSelector(
  [selectDomain],
  customerLeadBookingState => customerLeadBookingState.customerLeadSize,
);

export const selectLoading = createSelector(
  [selectDomain],
  customerLeadBookingState => customerLeadBookingState.loading,
);

export const selectError = createSelector(
  [selectDomain],
  customerLeadBookingState => customerLeadBookingState.error,
);

export const selectErrorMsg = createSelector(
  [selectDomain],
  customerLeadBookingState => customerLeadBookingState.errorMsg,
);
