export const PRICES = [
  { name: '₹ 5 lac', value: '500000' },
  { name: '₹ 10 lac', value: '1000000' },
  { name: '₹ 15 lac', value: '1500000' },
  { name: '₹ 20 lac', value: '2000000' },
  { name: '₹ 25 lac', value: '2500000' },
  { name: '₹ 30 lac', value: '3000000' },
  { name: '₹ 35 lac', value: '3500000' },
  { name: '₹ 40 lac', value: '4000000' },
  { name: '₹ 45 lac', value: '4500000' },
  { name: '₹ 50 lac', value: '5000000' },
  { name: '₹ 60 lac', value: '6000000' },
  { name: '₹ 70 lac', value: '7000000' },
  { name: '₹ 80 lac', value: '8000000' },
  { name: '₹ 90 lac', value: '9000000' },
  { name: '₹ 1 Cr', value: '10000000' },
  { name: '₹ 1.1 Cr', value: '11000000' },
  { name: '₹ 1.2 Cr', value: '12000000' },
  { name: '₹ 1.3 Cr', value: '13000000' },
  { name: '₹ 1.4 Cr', value: '14000000' },
  { name: '₹ 1.5 Cr', value: '15000000' },
  { name: '₹ 1.6 Cr', value: '16000000' },
  { name: '₹ 1.7 Cr', value: '17000000' },
  { name: '₹ 1.8 Cr', value: '18000000' },
  { name: '₹ 1.9 Cr', value: '19000000' },
  { name: '₹ 2 Cr', value: '20000000' },
  { name: '₹ 2.1 Cr', value: '21000000' },
  { name: '₹ 2.2 Cr', value: '22000000' },
  { name: '₹ 2.3 Cr', value: '23000000' },
  { name: '₹ 2.4 Cr', value: '24000000' },
  { name: '₹ 2.5 Cr', value: '25000000' },
  { name: '₹ 2.6 Cr', value: '26000000' },
  { name: '₹ 2.7 Cr', value: '27000000' },
  { name: '₹ 2.8 Cr', value: '28000000' },
  { name: '₹ 2.9 Cr', value: '29000000' },
  { name: '₹ 3 Cr', value: '30000000' },
  { name: '₹ 3.1 Cr', value: '31000000' },
  { name: '₹ 3.2 Cr', value: '32000000' },
  { name: '₹ 3.3 Cr', value: '33000000' },
  { name: '₹ 3.4 Cr', value: '34000000' },
  { name: '₹ 3.5 Cr', value: '35000000' },
  { name: '₹ 3.6 Cr', value: '36000000' },
  { name: '₹ 3.7 Cr', value: '37000000' },
  { name: '₹ 3.8 Cr', value: '38000000' },
  { name: '₹ 3.9 Cr', value: '39000000' },
  { name: '₹ 4 Cr', value: '40000000' },
  { name: '₹ 4.1 Cr', value: '41000000' },
  { name: '₹ 4.2 Cr', value: '42000000' },
  { name: '₹ 4.3 Cr', value: '43000000' },
  { name: '₹ 4.4 Cr', value: '44000000' },
  { name: '₹ 4.5 Cr', value: '45000000' },
  { name: '₹ 4.6 Cr', value: '46000000' },
  { name: '₹ 4.7 Cr', value: '47000000' },
  { name: '₹ 4.8 Cr', value: '48000000' },
  { name: '₹ 4.9 Cr', value: '49000000' },
  { name: '₹ 5 Cr', value: '50000000' },
];

export const getStageName = stageNumber => {
  switch (stageNumber) {
    case '1':
    case 1:
      return 'Prelaunch';
    case '2':
    case 2:
      return 'Under Construction';
    case '3':
    case 3:
      return 'Ready to Move';
    default:
      return null;
  }
};
export const getFacing = (status: any) => {
  switch (status) {
    case '4':
    case 4:
      return 'East';
    case '3':
    case 3:
      return 'West';
    case '1':
    case 1:
      return 'North';
    case '2':
    case 2:
      return 'South';
    case '6':
    case 6:
      return 'North-East';
    case '5':
    case 5:
      return 'North-West';
    case '8':
    case 8:
      return 'South-East';
    case '7':
    case 7:
      return 'South-West';
  }
};
export const getUnitFurnished = (status: any) => {
  switch (status) {
    case '1':
    case 1:
      return 'No';
    case '2':
    case 2:
      return 'Semi';
    case '3':
    case 3:
      return 'Fully';
  }
};

export const getRegulatoryStatus = (status: any) => {
  switch (status) {
    case '1':
    case 1:
      return 'OC';
    case '2':
    case 2:
      return 'NOC';
  }
};

export const getStatus = (status: any) => {
  switch (status) {
    case '1':
    case 1:
      return 'Active';
    case '2':
    case 2:
      return 'Inactive';
  }
};

export const checkFieldIsIncluded = (arr: string | any[], val: any) => {
  if (arr.length > 0) {
    if (arr.includes(val)) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const REGEX = {
  ZEROSONLY: /^0*$/,
  PINCODE: /^([1-9]{1})([0-9]{5})$/,
  LANDLINE: /^[1-9]{1}[0-9]{7}$/,
  NUMBER: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  WEBSITE: /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/,
  EMAIL: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
  // LANDLINE: /^\(?([0-9]{5})\)?[-]?([0-9]{5})$/,
  PAN: /^[A-Za-z]{3}[CPHFATBLJGcphfatbljg]{1}[A-Za-z]{1}\d{4}[A-Za-z]{1}$/,
  GSTIN:
    /^\d{2}[A-Za-z]{3}[CPHFATBLJGcphfatbljg]{1}[A-Za-z]{1}\d{4}[A-Za-z]{1}\d{1}[Zz]{1}[0-9A-Za-z]{1}$/,
  IFSC: /^[A-Za-z]{4}0[A-Za-z0-9]{6}$/,
};

export const convertBudget = str => {
  if (typeof str != 'string') {
    return '';
  }
  let budgetStr = str.split(/[[),]/);
  return convertToString(parseInt(budgetStr[1])) + ' to ' + convertToString(parseInt(budgetStr[2]));
};

export const convertToString = val => {
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + ' Cr';
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(0) + ' Lac';
  }
  return val;
};

export const convertTotalPrice = val => {
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + ' Cr';
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + ' Lac';
  }
  return val;
};

export const getDateAgo = date => {
  const todaysDate = new Date();
  let createdOnDate = new Date(date);

  let diffrenceInSec = todaysDate.getTime() - createdOnDate.getTime();
  var diffrenceInDays = diffrenceInSec / (1000 * 3600 * 24);
  return Math.round(diffrenceInDays);
};

export const clipText = (str: string, cliplength = 15) => {
  if (str?.length > cliplength) {
    return `${str.substring(0, cliplength)}...`;
  }
  return str;
};

export const withoutProperty = (obj, property) => {
  const { [property]: unused, ...rest } = obj;
  return rest;
};

export function getNearbyLocations(latitude, longitude, callback) {
  let locality_mapper: any = {};
  try {
    const atm = fetch(
      `/maps/api/place/nearbysearch/json?location=${latitude}%2C${longitude}&radius=500&key=${process.env.REACT_APP_LOCALITY_API_KEY}&type=atm`,
    )
      .then(data => {
        return data.json();
      })
      .then(atmJsonData => {
        locality_mapper['ATMs'] = atmJsonData.results.length;
      });

    const hospital = fetch(
      `/maps/api/place/nearbysearch/json?location=${latitude}%2C${longitude}&radius=500&key=${process.env.REACT_APP_LOCALITY_API_KEY}&type=hospital`,
    )
      .then(data => {
        return data.json();
      })
      .then(hospitalJsonData => {
        locality_mapper['Hospitals'] = hospitalJsonData.results.length;
      });

    const school = fetch(
      `/maps/api/place/nearbysearch/json?location=${latitude}%2C${longitude}&radius=500&key=${process.env.REACT_APP_LOCALITY_API_KEY}&type=school`,
    )
      .then(data => {
        return data.json();
      })
      .then(schoolJsonData => {
        locality_mapper['Schools'] = schoolJsonData.results.length;
      });

    Promise.all([atm, hospital, school]).then(() => {
      return callback(locality_mapper);
    });
  } catch (err) {}
}

// Sorting function
export function sortByFirstValue(a, b) {
  var firstValueA = parseInt(a.split(':')[0]);
  var firstValueB = parseInt(b.split(':')[0]);
  return firstValueA - firstValueB;
}
