import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { projectFilterSaga } from './saga';
import { ProjectFilterState } from './types';

export const initialState: ProjectFilterState = {
  filterobj: {
    project_and_developer_name: '',
    city: [],
    developer_id: [],
    unit_group_type: [],
    unit_group_configuration: [],
    developmentStageIds: [],
    amenities: [],
    price_range: '',
    project_ids: [],
    stage_name: '',
  },
  filterobjCount: 0,
};

const slice = createSlice({
  name: 'projectFilter',
  initialState,
  reducers: {
    loadProjectsFilter(state, action) {
      state.filterobj = action.payload;
      state.filterobjCount = Object.keys(action.payload).filter(
        key =>
          key !== 'project_and_developer_name' &&
          action.payload[key] !== null &&
          action.payload[key] !== '' &&
          action.payload[key] !== undefined &&
          !(Array.isArray(action.payload[key]) && action.payload[key].length === 0),
      ).length;
    },
  },
});

export const { actions: projectFilterActions, reducer } = slice;

export const useProjectFilterSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: projectFilterSaga });
  return { actions: slice.actions };
};
