import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { crmServiceSchemas } from 'types/crmServiceSchemas';
import { getRequest } from 'utils/request';
import { customerListActions as actions } from '.';

/**
 * Projects request/response handler
 */
export function* getCustomerDetails(id) {
  yield delay(500);

  const requestURL = `${process.env.REACT_APP_DDP_CRM_API_URL}/customer/${id?.payload}/`;
  try {
    const { data } = yield call(getRequest, requestURL);
    const customerDetails: crmServiceSchemas['CustomerComplexResponse'] = data?.response;

    if (customerDetails?.id) {
      yield put(actions.customerDetailsLoaded(customerDetails));
    } else {
      yield put(
        actions.customerDetailsError('Something went wrong while fetching project details.'),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.customerDetailsError('Project not found.'));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.customerDetailsError('Failed to fetch project.'));
    } else {
      yield put(
        actions.customerDetailsError('Something went wrong while fetching project details.'),
      );
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* customerDetailsSaga() {
  // Watches for loadRepos actions and calls getCustomerDetails when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.loadCustomerDetails.type, getCustomerDetails);
}
