import { PayloadAction } from '@reduxjs/toolkit';
import { projectServiceSchemas } from 'types/projectServiceSchemas';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { projectSearchSaga } from './saga';
import { ProjectSearchState } from './types';

export const initialState: ProjectSearchState = {
  loading: true,
  error: false,
  errorMsg: '',
  projects: [],
  projectsTotal: 0,
  searchInput: '',
};

const slice = createSlice({
  name: 'projectSearch',
  initialState,
  reducers: {
    loadProjectsSearch(state, action) {
      state.loading = true;
      state.error = false;
      state.projects = [];

      const searchInput = action.payload;
      state.searchInput = searchInput;
    },
    projectsSearchLoaded(
      state,
      action: PayloadAction<{
        items: projectServiceSchemas['SearchResults'][];
        total: number;
      }>,
    ) {
      const projects = action.payload;
      state.projects = projects?.items;
      state.projectsTotal = projects?.total;
      state.loading = false;
    },
    projectError(state, action: PayloadAction<string>) {
      state.error = true;
      state.errorMsg = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: projectSearchActions, reducer } = slice;

export const useProjectSearchSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: projectSearchSaga });
  return { actions: slice.actions };
};
