import { Dialog, Input } from '@material-tailwind/react';
import { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { ToastContainer, toast } from 'react-toastify';
import 'react-spring-bottom-sheet/dist/style.css';
import { useAuthSlice } from '../../pages/LoginPage/slice';
import {
  selectAuthLoading,
  selectOtpSent,
  selectToken,
  selectAuthErrorMsg
} from '../../pages/LoginPage/slice/selectors';
import { LoadingIndicator } from '../LoadingIndicator';
import { Sidebar } from '../SideBar';
import { ReactComponent as ArrowRight } from './../../assets/arrow-right.svg';
import { ReactComponent as Cross } from './../../assets/close.svg';
import { ReactComponent as SideIcon } from './../../assets/sidebarIcon.svg';
import { useSearchParams } from 'react-router-dom';
export function NavBar() {
  const dispatch = useDispatch();
  const { actions } = useAuthSlice();
  // const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const closeSidebar = () => {
    setIsOpen(false);
  };

  const RESEND_OTP_TIME_LIMIT = 30;

  const loading = useSelector(selectAuthLoading);
  const otpSent = useSelector(selectOtpSent);
  const token = useSelector(selectToken);
  const authErrMsg = useSelector(selectAuthErrorMsg);

  const [resendButtonDisabledTime, setResendButtonDisabledTime] = useState(RESEND_OTP_TIME_LIMIT);

  const [usdCode, setUsdCode] = useState('+91');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [isShown, setIsShown] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  let resendOtpTimerInterval;

  //start timer on screen on launch
  useEffect(() => {
    startResendOtpTimer();
    return () => {
      if (resendOtpTimerInterval) {
        clearInterval(resendOtpTimerInterval);
      }
    };
  }, [resendButtonDisabledTime]);

  const startResendOtpTimer = () => {
    if (resendOtpTimerInterval) {
      clearInterval(resendOtpTimerInterval);
    }
    resendOtpTimerInterval = setInterval(() => {
      if (+resendButtonDisabledTime <= 0) {
        clearInterval(resendOtpTimerInterval);
      } else {
        setResendButtonDisabledTime(resendButtonDisabledTime - 1);
      }
    }, 1000);
  };

  const onResendOtpButtonPress = () => {
    //clear input field
    setOtp('');
    setResendButtonDisabledTime(RESEND_OTP_TIME_LIMIT);
    startResendOtpTimer();

    dispatch(actions.requestAuth({ usdCode, phoneNumber, otpViaCall:true }));
  };

  const [currentUrl, setCurrentUrl] = useState('/projects');
  const selectedUrl = url => {
    setCurrentUrl(url);
  };

  if (token) {
    window.location.replace(currentUrl);
  }

  const [isDesktop, setDesktop] = useState(window.innerWidth > 540);

  const [openLoginModal, setOpenLoginModal] = useState(isDesktop? !!searchParams.get("show_login"): false);

  const [openLogin, setOpenLogin] = useState(isDesktop? false: !!searchParams.get("show_login"));

  const handleLoginModal = () => {
    setIsOpen(!isOpen);
    isDesktop ? setOpenLoginModal(!openLoginModal) : setOpenLogin(!openLogin);
    setPhoneErr('');
    setPhoneNumber('');
    setOtp('');
    dispatch(actions.otpSent(false));
  };

  // const [otpSent, setIsLogin] = useState(false);

  const [phoneErr, setPhoneErr] = useState('');

  let pattern = /^[3-9]\d{9}$/gi;

  const handleisLogin = () => {
    if (!pattern.test(phoneNumber)) {
      setPhoneErr('Please enter valid phone number');
    } else {
      dispatch(actions.requestAuth({ usdCode, phoneNumber }));
      setResendButtonDisabledTime(RESEND_OTP_TIME_LIMIT);
      startResendOtpTimer();
    }
  };

  const handleChange = () => {
    dispatch(actions.otpSent(false));
    setOtp('');
  };

  const updateMedia = () => {
    setDesktop(window.innerWidth > 540);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  useEffect(() => {
    authErrMsg && toast.error(authErrMsg, {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  },[authErrMsg]);

  return (
    <nav className="w-full shadow fixed z-50 bg-primary">
      <div className="justify-between px-2 w-full lg:max-w-11/12 items-center flex">
        <div className="flex items-center">
          <button onClick={toggleSidebar}>
            <SideIcon />
          </button>
          <Sidebar
            isOpen={isOpen}
            onClose={closeSidebar}
            openLogin={handleLoginModal}
            selectedUrl={selectedUrl}
          />
          <div className="flex items-center justify-between py-3 md:py-5 md:block">
            <a href="/projects">
              <h2 className="lg:text-2xl md:text-xl font-semibold text-white ml-2">Anarock CP</h2>
            </a>
          </div>
        </div>

        {localStorage.getItem('userToken') ? (
          <div className=" font-medium space-x-2 md:inline-block">
            <a
              href="/projects"
              className="px-4 py-2 text-gray-800 bg-white rounded-md shadow hover:bg-gray-100"
              onClick={() => {
                localStorage.setItem('userToken', '');
              }}
            >
              Logout
            </a>
          </div>
        ) : (
          <>
            <div className="font-medium space-x-2 md:inline-block">
              <button
                onClick={() => {
                  isDesktop ? setOpenLoginModal(!openLoginModal) : setOpenLogin(true);
                }}
                className="px-4 py-2 text-gray-800 bg-white rounded-md shadow hover:bg-gray-100"
              >
                Login
              </button>
            </div>

            {/* web login view starts */}

            <Dialog
              size="sm"
              open={openLoginModal}
              handler={handleLoginModal}
              className="rounded-2xl md:w-full lg:max-w-[33.33%] xl:p-8 lg:p-5 md:p-4 sm:p-3 max-w-[55.33%]"
            >
              <div className="overflow-x-hidden overflow-y-auto inset-0 z-50 outline-none focus:outline-none">
                {/* log in view starts */}

                <div className="flex items-center justify-between">
                  <h1 className="xl:text-2xl lg:text-xl sm:text-lg font-semibold text-black">
                    {!otpSent && !loading ? 'Login' : ' Confirm OTP'}
                  </h1>
                  <button onClick={handleLoginModal}>
                    <Cross className="text-gray-600 lg:w-6 h-6 sm:w-5 sm:h-5" />
                  </button>
                </div>
                {!otpSent && !loading ? (
                  <div>
                    <h5 className="lg:text-base sm:text-sm font-normal mt-2">
                      Login to your account
                    </h5>
                    <div className="my-8">
                      <div className="flex items-center space-x-2">
                        <div className="flex justify-center w-20 border border-gray-400 rounded-lg px-2 py-2 h-11">
                          <p className="text-slate-600 font-medium">+91</p>
                          {/* <ArrowDown className="-rotate-90 w-6 h-6 slate-600" /> */}
                        </div>
                        <div className="w-full min-w-0">
                          <label className="min-w-0">
                            <Input
                              value={phoneNumber}
                              type="text"
                              label="Phone Number"
                              color="purple"
                              size="lg"
                              maxLength={10}
                              id="phoneInput"
                              onChange={e => {
                                setPhoneNumber(e.target.value);
                              }}
                              crossOrigin={undefined}
                            />
                          </label>
                        </div>
                      </div>
                      {phoneErr ? (
                        <p className="lg:text-sm sm:text-xs text-red-600 mt-2">{phoneErr}</p>
                      ) : null}
                    </div>
                    <button
                      disabled={phoneNumber.length < 10}
                      onClick={handleisLogin}
                      className={
                        phoneNumber.length < 10
                          ? 'w-full flex justify-center border border-purple-200 bg-purple-200 rounded-lg lg:p-3 sm:p-2'
                          : 'w-full flex justify-center border border-purple-700 bg-primary rounded-lg lg:p-3 sm:p-2'
                      }
                      value={phoneNumber}
                    >
                      <h5 className="lg:text-base sm:text-sm text-white text-center flex-1 font-medium">
                        Next
                      </h5>
                      <ArrowRight className="" />
                    </button>
                    <p className="lg:text-sm sm:text-xs text-black font-normal mt-6">
                      By proceeding, you agree to the
                      <a className="text-primary font-medium mx-1" href="https://anarockcp.com/privacy-policy/" target="_blank">Privacy Policy</a> and
                      <a className="text-primary font-medium mx-1" href="https://anarockcp.com/terms-of-use/" target="_blank">Terms & Conditions.</a>
                    </p>
                  </div>
                ) : (
                  <div>
                    {/* verify view starts */}
                    <div className="flex items-center justify-between my-8">
                      <p className="lg:text-base sm:text-sm text-slate-700 font-normal">
                        Enter OTP sent to
                        <span className="text-black font-medium ml-1">+91 {phoneNumber}</span>
                      </p>
                      <button onClick={handleChange}>
                        <p className="lg:text-base sm:text-sm text-primary font-semibold">Change</p>
                      </button>
                    </div>
                    <div>
                      <div className="my-2">
                        <OtpInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={4}
                          separator={<span className="xl:w-6 lg:w-4 sm:w-1"></span>}
                          isInputNum={true}
                          shouldAutoFocus={true}
                          inputStyle={{
                            border: '1px solid grey',
                            borderRadius: '8px',
                            width: '48px',
                            height: '54px',
                            fontSize: '16px',
                            color: '#000',
                            fontWeight: '400',
                          }}
                          focusStyle={{
                            border: '1px solid #700CB3',
                            outline: '0px',
                          }}
                        />
                      </div>

                      {resendButtonDisabledTime > 0 ? (
                        <button className="border border-gray-600 rounded-lg py-2 px-3 my-8">
                          <p className="lg:text-base sm:text-sm text-primary font-normal">
                            Resend OTP
                            <span className="text-gray-600 ml-1">in </span>
                            <span className="text-primary">
                              00:{resendButtonDisabledTime.toString().length === 1
                                ? '0' + resendButtonDisabledTime
                                : resendButtonDisabledTime}
                            </span>
                          </p>
                        </button>
                      ) : (
                        <button
                          onClick={onResendOtpButtonPress}
                          className="border border-gray-600 rounded-lg py-2 px-3 my-8"
                        >
                          <p className="lg:text-base sm:text-sm text-primary font-normal">
                            Get OTP via Call
                          </p>
                        </button>
                      )}
                    </div>
                    <button
                      disabled={otp.length < 4}
                      onClick={() => {
                        dispatch(actions.authenticate({ usdCode, phoneNumber, otp }));
                      }}
                      className={
                        otp.length < 4
                          ? 'w-full flex justify-center border border-purple-100 bg-purple-100 rounded-lg lg:py-3 sm:p-2'
                          : 'w-full flex justify-center border border-purple-700 bg-primary rounded-lg lg:py-3 sm:p-2'
                      }
                    >
                      <h5 className="lg:text-base sm:text-sm text-white font-medium">
                        {loading ? <LoadingIndicator /> : <span>Login</span>}
                      </h5>
                    </button>
                  </div>
                )}
                <ToastContainer
                  position="bottom-right"
                  autoClose={50000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="dark"
                />
              </div>
            </Dialog>

            {/* web login view ends */}

            {/* mobile login view starts */}

            <BottomSheet open={openLogin}>
              <div className="h-full p-6">
                <div className="flex justify-between">
                  <h4 className="text-base text-black font-semibold">
                    {!otpSent && !loading ? 'Login' : 'Confirm OTP'}
                  </h4>
                  <button
                    onClick={() => {
                      setOpenLogin(false);
                    }}
                  >
                    <Cross />
                  </button>
                </div>
                <hr className="my-4 border border-slate-200" />
                {!otpSent && !loading ? (
                  <div>
                    <h4 className="text-base text-gray-600 font-normal">Login to your account</h4>
                    <div className="flex items-center space-x-2 my-5">
                      <div className="flex justify-center w-28 border border-gray-400 rounded-lg px-2 py-2 h-11">
                        <p className="text-gray-600 font-noraml mt-0.5">+ 91</p>
                      </div>
                      <div className="w-full min-w-0">
                        <label className="min-w-0">
                          <Input
                            value={phoneNumber}
                            type="text"
                            label="Phone Number"
                            color="purple"
                            size="lg"
                            maxLength={10}
                            id="phoneInput"
                            onChange={e => {
                              setPhoneNumber(e.target.value);
                            }}
                            crossOrigin={undefined}
                          />
                        </label>
                      </div>
                    </div>
                    <button
                      disabled={phoneNumber.length < 10}
                      onClick={handleisLogin}
                      className={
                        phoneNumber.length < 10
                          ? 'w-full flex justify-center border border-purple-200 bg-purple-200 rounded-lg p-3'
                          : 'w-full flex justify-center border border-purple-700 bg-primary rounded-lg p-3'
                      }
                    >
                      <h5 className="text-base text-white text-center flex-1 font-medium">Next</h5>
                      <ArrowRight className="" />
                    </button>
                    <p className="text-sm text-black font-normal mt-6">
                      By proceeding, you agree to the
                      <span className="text-primary font-medium mx-1">Privacy Policy</span> and
                      <span className="text-primary font-medium mx-1">Terms & Conditions.</span>
                    </p>
                  </div>
                ) : (
                  <div>
                    <div className="flex items-center justify-between space-x-2 my-8">
                      <p className="sm:text-sm text-slate-700 font-normal">
                        Enter OTP sent to
                        <span className="text-black font-medium ml-1">+91 {phoneNumber}</span>
                      </p>
                      <button onClick={handleChange}>
                        <p className="text-sm text-primary font-semibold">Change</p>
                      </button>
                    </div>
                    <div>
                      <div className="flex justify-center my-2">
                        <OtpInput
                          value={otp}
                          onChange={setOtp}
                          numInputs={4}
                          separator={<span className="w-6"></span>}
                          isInputNum={true}
                          shouldAutoFocus={true}
                          inputStyle={{
                            border: '1px solid grey',
                            borderRadius: '8px',
                            width: '54px',
                            height: '54px',
                            fontSize: '16px',
                            color: '#000',
                            fontWeight: '400',
                          }}
                          focusStyle={{
                            border: '1px solid #700CB3',
                            outline: '0px',
                          }}
                        />
                      </div>
                      <div className="flex justify-center">
                        {resendButtonDisabledTime > 0 ? (
                          <button className="border border-gray-600 rounded-lg py-2 px-3 my-8">
                            <p className="lg:text-base sm:text-sm sx:text-sm text-primary font-normal">
                              Resend OTP
                              <span className="text-gray-600 ml-1">in </span>
                              <span className="text-primary">
                                {resendButtonDisabledTime.toString().length === 1
                                  ? '0' + resendButtonDisabledTime
                                  : resendButtonDisabledTime}
                              </span>
                            </p>
                          </button>
                        ) : (
                          <button
                            onClick={onResendOtpButtonPress}
                            className="border border-gray-600 rounded-lg py-2 px-3 my-8"
                          >
                            <p className="lg:text-base sm:text-sm text-primary font-normal">
                              Resend OTP
                            </p>
                          </button>
                        )}
                      </div>
                    </div>
                    <button
                      disabled={otp.length < 4}
                      onClick={() => {
                        dispatch(actions.authenticate({ usdCode, phoneNumber, otp }));
                      }}
                      className={
                        otp.length < 4
                          ? 'w-full flex justify-center border border-purple-100 bg-purple-100 rounded-lg p-3'
                          : 'w-full flex justify-center border border-purple-700 bg-primary rounded-lg p-3'
                      }
                    >
                      <h5 className="text-base text-white font-medium">
                        {loading ? <LoadingIndicator /> : <span>Login</span>}
                      </h5>
                    </button>
                  </div>
                )}
              </div>
            </BottomSheet>

            {/* mobile login view ends */}
          </>
        )}
      </div>
    </nav>
  );
}
