import { Dialog } from '@material-tailwind/react';
import { selectChannelPartners } from 'app/pages/MyCustomers/slices/allCpCurrentOrgslice/selectors';
import { useAllUsersListSlice } from 'app/pages/MyCustomers/slices/allUsersslice';
import { selectAllUsers } from 'app/pages/MyCustomers/slices/allUsersslice/selectors';
import { useCustomerDetailSlice } from 'app/pages/MyCustomers/slices/customerDetailsSlice';
import { selectcustomerDetails } from 'app/pages/MyCustomers/slices/customerDetailsSlice/selectors';
import { useCustomerHistorylice } from 'app/pages/MyCustomers/slices/customerHistorySlice';
import { selectcustomerHistory } from 'app/pages/MyCustomers/slices/customerHistorySlice/selectors';
import { useUnitConfigurationListSlice } from 'app/pages/ProjectListPage/Projects/ProjectList/slices/untiConfigurationSlice';
import { selectUnitConfigurations } from 'app/pages/ProjectListPage/Projects/ProjectList/slices/untiConfigurationSlice/selectors';
import { useUnitTypeListSlice } from 'app/pages/ProjectListPage/Projects/ProjectList/slices/untiTypeSlice';
import { selectUnitTypes } from 'app/pages/ProjectListPage/Projects/ProjectList/slices/untiTypeSlice/selectors';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { convertBudget, convertTotalPrice } from 'utils/helper';
import { getRequest, postRequest } from 'utils/request';
import { ReactComponent as Cross } from './../../assets/close.svg';
import { ReactComponent as Copy } from './../../assets/copy.svg';
import { ReactComponent as Added } from './../../assets/crm/Added.svg';
import { ReactComponent as CategoryUnfilled } from './../../assets/crm/Category unfill.svg';
import { ReactComponent as FailedBy } from './../../assets/crm/Failed by.svg';
import { ReactComponent as FollowUp } from './../../assets/crm/Follow Up.svg';
import { ReactComponent as Meeting } from './../../assets/crm/Meeting.svg';
import { ReactComponent as ExclamationMark } from './../../assets/crm/No event scheduled.svg';
import { ReactComponent as SiteVisit } from './../../assets/crm/Site Visit.svg';
import { ReactComponent as TotalPrice } from './../../assets/crm/Total Price.svg';
import { ReactComponent as TransferTeammate } from './../../assets/crm/Transfer to Teammate.svg';
import { ReactComponent as ViewNotes } from './../../assets/crm/View All Notes.svg';
import { ReactComponent as History } from './../../assets/crm/View complete history.svg';
import { ReactComponent as ArrowDown } from './../../assets/crm/arrow_forward.svg';
import { ReactComponent as BookingRequestDate } from './../../assets/crm/booking request date.svg';
import { ReactComponent as Configuration } from './../../assets/crm/cONFIGURATION.svg';
import {
  ReactComponent as Category,
  ReactComponent as CategoryFilled,
} from './../../assets/crm/categiry fill.svg';
import {
  ReactComponent as CheckCircle,
  ReactComponent as Tick,
} from './../../assets/crm/checkboxfill.svg';
import { ReactComponent as UncheckCircle } from './../../assets/crm/chek unfill box.svg';
import { ReactComponent as Dot } from './../../assets/crm/dot.svg';
import { ReactComponent as DotGreen } from './../../assets/crm/dotGreen.svg';
import { ReactComponent as SendCallCenter } from './../../assets/crm/inCallCenter.svg';
import { ReactComponent as MarkedIntersted } from './../../assets/crm/mARKED inTERESTED.svg';
import { ReactComponent as UnCheckRadio } from './../../assets/crm/radio_button_unchecked.svg';
import { ReactComponent as CheckRadio } from './../../assets/crm/radio_check_button.svg';
import { ReactComponent as Share } from './../../assets/crm/shareBlack.svg';
import { ReactComponent as ArrowDownPurple } from './../../assets/crm/viewprojectdropedown.svg';
import { ReactComponent as Mail } from './../../assets/gmail.svg';
import { ReactComponent as AgentAssigned } from './../../assets/history icon/Agent Assigned.svg';
import { ReactComponent as BookingVerified } from './../../assets/history icon/Booking verified.svg';
import { ReactComponent as CustomerCreated } from './../../assets/history icon/Customer Created.svg';
import { ReactComponent as CustomerDetails } from './../../assets/history icon/Customer Details Updated.svg';
import { ReactComponent as Failed } from './../../assets/history icon/Failed.svg';
import { ReactComponent as MovedtoBooking } from './../../assets/history icon/Moved to Bookings.svg';
import { ReactComponent as MovedtoInterested } from './../../assets/history icon/Moved to Interested.svg';
import { ReactComponent as NotesAdded } from './../../assets/history icon/Notes added to customer.svg';
import { ReactComponent as ProjectsTagged } from './../../assets/history icon/Projects Tagged.svg';
import { ReactComponent as Recovery } from './../../assets/history icon/Recovery.svg';
import { ReactComponent as Sent } from './../../assets/history icon/Sent.svg';
import { ReactComponent as BookingRequestCreated } from './../../assets/history icon/booking request created.svg';
import { ReactComponent as Search } from './../../assets/search.svg';
import { ReactComponent as WhatsApp } from './../../assets/whatsapp.svg';

const useEffectOnMount = (effect: React.EffectCallback) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
};
export function CustomerDetailsCard(props) {
  const customerDetails = props?.data;
  const customerBookingDetail = props?.bookingData;
  const activeTab = props?.active_tab;
  const selectedBar = props?.selectedBar;
  const onSelect = props?.onSelect;
  const type = props?.type;
  const dispatch = useDispatch();

  useEffectOnMount(() => {
    dispatch(unitconfigurationsactions.loadUnitConfiguration());
    dispatch(unittypeactions.loadUnitType());
  });

  const { actions: unitconfigurationsactions } = useUnitConfigurationListSlice();

  const { actions: unittypeactions } = useUnitTypeListSlice();

  const { actions: customerdetailsactions } = useCustomerDetailSlice();

  // const { actions: channelpartnersactions } = useChannelPartnersListSlice();

  const { actions: usersactions } = useAllUsersListSlice();

  const { actions: customerhistoryactions } = useCustomerHistorylice();

  const unitConfigurations = useSelector(selectUnitConfigurations);

  const unitTypes = useSelector(selectUnitTypes);

  const customerDetail = useSelector(selectcustomerDetails);

  const channelPartnerList = useSelector(selectChannelPartners);

  const allUserList = useSelector(selectAllUsers);

  const customerHistory = useSelector(selectcustomerHistory);

  const [customerUnitConfPrefference, setcustomerUnitConfPrefference] = useState('');
  const [customerUnitTypePrefference, setcustomerUnitTypePrefference] = useState('');
  const [customerLocalityPrefference, setcustomerLocalityPrefference] = useState('');
  const [customerLeadProjectsIds, setcustomerLeadProjectsIds] = useState('');
  const [customerBookingLeadProjectsIds, setcustomerBookingLeadProjectsIds] = useState('');
  const [shareProjectId, setshareProjectId] = useState('');
  const [projectShareUrl, setprojectShareUrl] = useState('');
  const [customerDetail_CC, setcustomerDetail_CC] = useState<any>({});
  const [customerLeadProjects, setcustomerLeadProjects] = useState<any>([]);
  const [customerBookingLeadProjects, setcustomerBookingLeadProjects] = useState<any>([]);
  const [customerLeads, setcustomerLeads] = useState<any>([]);
  const [customerEvent, setCustomerEvent] = useState<any>([]);
  const [historyData, setHistoryData] = useState({
    FOLLOW_UP: 0,
    MEETING: 0,
    SITE_VISIT: 0,
  });

  const [cardEventType, setCardEventType] = useState<any>([]);
  const [historyCompletedata, setHistoryCompletedata] = useState<any>([]);

  useEffect(() => {
    setprojectShareUrl(`${process.env.REACT_APP_PROJECT_API_URL}/cp/project/${shareProjectId}`);
  }, [shareProjectId]);

  useEffect(() => {
    let arr: any = [];
    (customerDetails || customerDetail)?.customer_unit_configuration_preference?.map(id => {
      let unit_configuraion_prefference = unitConfigurations.filter(data => data?.id === id);
      if (unit_configuraion_prefference.length > 0) {
        arr.push(unit_configuraion_prefference[0]);
      }
    });
    setcustomerUnitConfPrefference(arr.map(item => item?.name).join(', '));
  }, [unitConfigurations, customerDetails, customerDetail]);

  useEffect(() => {
    let arr: any = [];
    (customerDetails || customerDetail)?.customer_unit_type_preference?.map(id => {
      let unit_type_prefference = unitTypes.filter(data => data?.id === id);
      if (unit_type_prefference.length > 0) {
        arr.push(unit_type_prefference[0]);
      }
    });
    setcustomerUnitTypePrefference(arr.map(item => item?.name).join(', '));
  }, [unitTypes, customerDetails, customerDetail]);

  useEffect(() => {
    let arr: any = [];
    let projectArr: any = [];
    let customerLeadArr: any = [];
    (customerDetails || customerDetail)?.customer_locality_preference?.map(item => {
      arr.push(item?.google_places_response?.results[0]?.formatted_address);
    });
    setcustomerLocalityPrefference(arr.join(', '));

    (customerDetails || customerDetail)?.customer_lead?.map(lead => {
      customerLeadArr.push(lead);
      projectArr.push(lead?.project_id);
    });

    if (customerDetails?.customer_event?.length) {
      const cardEventArr: any = customerDetails?.customer_event.filter(
        event => new Date(event.scheduled_time).getDate() >= new Date().getDate(),
      );
      if (cardEventArr.length) {
        setCardEventType(
          Array.from(cardEventArr).sort(
            (a: any, b: any) =>
              moment(a.scheduled_time).valueOf() - moment(b.scheduled_time).valueOf(),
          ),
        );
      }
    }

    if (customerDetails?.status === 'IN_CC' && activeTab === 3) {
      const requestURL = `${process.env.REACT_APP_DDP_CRM_API_URL}/customer/${customerDetails?.id}/get_details/`;
      if (requestURL && customerDetails?.id) {
        getRequest(requestURL).then(res => {
          setcustomerDetail_CC(res?.data?.response);
        });
      }
    }

    setcustomerLeadProjectsIds(projectArr?.join(','));
    setcustomerLeads(customerLeadArr);
  }, [customerDetails, customerDetail]);

  useEffect(() => {
    if (customerBookingDetail?.customer_lead?.project_id) {
      setcustomerBookingLeadProjectsIds(customerBookingDetail?.customer_lead?.project_id);
    }
  }, [customerBookingDetail]);

  useEffect(() => {
    if (customerLeadProjectsIds) {
      const requestURL = `${process.env.REACT_APP_PROJECT_API_URL}/cp/project?project_ids=${customerLeadProjectsIds}`;
      if (requestURL && customerLeadProjectsIds) {
        getRequest(requestURL).then(res => {
          setcustomerLeadProjects(res?.data?.items);
        });
      }
    }
    if (customerBookingLeadProjectsIds) {
      const requestURL = `${process.env.REACT_APP_PROJECT_API_URL}/cp/project?project_ids=${customerBookingLeadProjectsIds}`;
      if (requestURL && customerBookingLeadProjectsIds) {
        getRequest(requestURL).then(res => {
          setcustomerBookingLeadProjects(res?.data?.items);
        });
      }
    }
  }, [customerLeadProjectsIds, customerBookingLeadProjectsIds]);

  const [customerNotes, setCustomerNotes] = useState<any[]>([]);

  useEffect(() => {
    let customerEventArr: any = [];
    customerDetail?.customer_event
      ?.filter(item => item?.status === 'SCHEDULED')
      ?.map((event, index) => {
        customerEventArr.push(event);
      });
    setCustomerEvent(customerEventArr);

    if (customerDetail?.customer_notes?.length) {
      const customerNotesTemp = [...customerDetail?.customer_notes];
      const updatedCustomerNotesWithDate = customerNotesTemp.map(item => {
        return {
          ...item,
          date: moment.utc(item.created_at).utcOffset('+05:30').format('YYYY-MM-DD'),
          time: moment.utc(item.created_at).utcOffset('+05:30').format('HH:mm:ss'),
        };
      });
      updatedCustomerNotesWithDate.sort(compare);
      const sortedCustomerNotesWithDate = [...checkDate([...updatedCustomerNotesWithDate])];
      setCustomerNotes(sortedCustomerNotesWithDate);
    }
  }, [customerDetail]);

  useEffect(() => {
    let userIdsArr: any = [];
    channelPartnerList.map(cp => {
      userIdsArr.push(cp?.user_id);
    });
    let filter_obj: any = {
      user_ids: userIdsArr.join(','),
    };

    if (filter_obj) {
      dispatch(usersactions.loadusers(filter_obj));
    }
  }, [channelPartnerList]);

  const filterProjectData = id => {
    let selectedPrData = {};
    selectedPrData = [...customerLeadProjects]?.filter(data => data.id === id);
    return selectedPrData[0];
  };

  const teamMateTransfer = () => {
    let data = {
      transferee_cp_id: teammateSelected,
      tranferror_cp_id: localStorage.getItem('userID'),
    };
    const requestURL = `${process.env.REACT_APP_DDP_CRM_API_URL}/${customerDetail?.id}/transfer/`;
    if (requestURL && teammateSelected && customerDetail.id) {
      postRequest(requestURL, data).then(res => {
        if (res.status === 200) {
          handleOpenClose();
          toast.success('Customer Transferred Successfully!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        } else if (res.status !== 200) {
          handleOpenClose();
          toast.error('Customer Transferred Failed!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
        return res;
      });
    }
    setTeammateSelected('');
    handleTransfeTeammateModal();
  };
  const preferredPropertyStatus = {
    READY_TO_MOVE: 'Ready To Move',
    UNDER_CONSTRUCTION: 'Under Construction',
    PRE_LAUNCH: 'Pre-Launch',
  };

  const statusList = {
    NEW: 'New',
    INTERESTED: 'Interested',
    IN_CC: 'In Call Center',
    FAILED: 'Failed',
    BOOKING_DONE: 'Booking',
    NOT_INTERESTED: 'Not Interested',
  };

  const eventType = {
    FOLLOW_UP: 'Follow Up',
    MEETING: 'Meeting',
    SITE_VISIT: 'Site Visit',
  };

  const [open, setOpen] = useState(false);

  const [transferTeammate, setTransferTeammate] = useState(false);

  const [sendCallCanter, setSendCallCanter] = useState(false);

  const [search, setSearch] = useState('');

  const handleOpen = id => {
    dispatch(customerdetailsactions.loadCustomerDetails(id));
    dispatch(customerhistoryactions.loadCustomerHistory(id));
    setOpen(!open);
  };

  const handleOpenClose = () => {
    setOpen(!open);
  };

  const handleTransfeTeammateModal = () => {
    setTransferTeammate(!transferTeammate);
  };

  const handleSendCallCanterModal = () => {
    const requestUrl = `${process.env.REACT_APP_PROJECT_API_URL}/cp/project?is_top_project=true&page=1&size=10`;
    getRequest(requestUrl).then(res => {
      setRecommendedProjects(res?.data?.items);
    });
    setSendCallCanter(!sendCallCanter);
  };

  const [openAllProjects, setOpenAllProjects] = useState(false);

  const handleAllProjects = () => {
    setOpenAllProjects(!openAllProjects);
  };

  const [notes, setNotes] = useState<string>('');

  const handleChange = event => {
    setNotes(event.target.value);
  };

  const addNotes = () => {
    const requestURL = `${process.env.REACT_APP_DDP_CRM_API_URL}/customer_note/`;
    let data = { customer_id: customerDetail?.id, note: notes };
    if (requestURL && notes) {
      postRequest(requestURL, data).then(res => {
        if (res.status === 200) {
          setNotes('');
          handleOpenClose();
          toast.success('Note Added Successfully!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        } else if (res.status !== 200) {
          handleOpenClose();
          toast.error('Note Added Failed!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
        return res;
      });
    }
  };

  const sendCallCanterApiCall = () => {
    const requestURL = `${process.env.REACT_APP_DDP_CRM_API_URL}/customer/${customerDetail?.id}/send_to_call_center/`;
    let data = {
      call_center_project_ids: selectedProjects,
    };
    if (requestURL && data) {
      postRequest(requestURL, data).then(res => {
        const msg = res?.response?.data?.response?.message;
        if (res.status === 200) {
          handleOpenClose();
          toast.success('Customer Send To Call Center!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        } else if (res.status !== 200) {
          handleOpenClose();
          toast.error(msg, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
        return res;
      });
    }
    setSelectedProjects([]);
    setTick([]);
    setSendCallCanter(!sendCallCanter);
  };

  const [teammateSelected, setTeammateSelected] = useState<string | undefined>('');

  const [maxProjectmsg, setMaxProjectmsg] = useState(false);

  const [selectedProjects, setSelectedProjects] = useState<any>([]);

  const [recommendedProjects, setRecommendedProjects] = useState<any>([]);

  const [searchedProject, setSearchedProject] = useState<any>([]);
  const [controller, setController] = useState<any>();

  useEffect(() => {
    if (selectedProjects.length >= 2) {
      setMaxProjectmsg(true);
    } else {
      setMaxProjectmsg(false);
    }
  }, [selectedProjects]);

  const handleSearch = e => {
    if (controller) {
      controller.abort();
    }
    const localController = new AbortController();
    setController(localController);

    const lowerCase = e.target.value.toLowerCase();
    setSearch(lowerCase);
    if (!lowerCase) {
      setSearchedProject([]);
    } else {
      let requestUrl = `${process.env.REACT_APP_PROJECT_API_URL}/cp/project?project_name=${lowerCase}`;
      getRequest(requestUrl, localController.signal).then(res => {
        setSearchedProject([]);
        setSearchedProject(res?.data?.items);
      });
    }
  };

  const selectProject = id => {
    if (selectedProjects.includes(id)) {
      var selectproj = [...selectedProjects];
      var index = selectproj.indexOf(id);
      if (index > -1) {
        selectproj.splice(index, 1);
      }
      setSelectedProjects(selectproj);
    } else {
      var projects = [...recommendedProjects];
      var selectproj = [...selectedProjects];
      var elementPos = projects
        .map(function (x) {
          return x?.id;
        })
        .indexOf(id);
      selectproj.push(projects[elementPos].id);
      setSelectedProjects([...selectproj]);
    }
  };

  const [tick, setTick] = useState<any>([]);

  const tickProjects = id => {
    let tickListTemp = [...tick];
    if (tickListTemp.includes(id)) {
      setTick([...tickListTemp.filter(ele => id !== ele)]);
    } else {
      setTick([...tick, id]);
    }
  };

  const dummyProjectImg = 'https://images.indianexpress.com/2016/02/green-building759.jpg';
  const eventIcon = {
    'FollowUp Scheduled': {
      icon: (
        <div className="border border-blue-50 bg-blue-50 rounded-full w-9 h-9 p-2">
          <FollowUp className="-ml-0.5" />
        </div>
      ),
    },
    'FollowUp Rescheduled': {
      icon: (
        <div className="border border-blue-50 bg-blue-50 rounded-full w-9 h-9 p-2">
          <FollowUp className="-ml-0.5" />
        </div>
      ),
    },
    'FollowUp Done': {
      icon: (
        <div className="border border-blue-50 bg-blue-50 rounded-full w-9 h-9 p-2">
          <FollowUp className="-ml-0.5" />
        </div>
      ),
    },
    'FollowUp Cancelled': {
      icon: (
        <div className="border border-blue-50 bg-blue-50 rounded-full w-9 h-9 p-2">
          <FollowUp className="-ml-0.5" />
        </div>
      ),
    },
    'FollowUp Missed': {
      icon: (
        <div className="border border-blue-50 bg-blue-50 rounded-full w-9 h-9 p-2">
          <FollowUp className="-ml-0.5" />
        </div>
      ),
    },
    'Meeting Scheduled': {
      icon: (
        <div className="border border-green-50 bg-green-50 rounded-full w-9 h-9 p-2">
          <Meeting />
        </div>
      ),
    },
    'Meeting Cancelled': {
      icon: (
        <div className="border border-green-50 bg-green-50 rounded-full w-9 h-9 p-2">
          <Meeting />
        </div>
      ),
    },
    'Meeting Done': {
      icon: (
        <div className="border border-green-50 bg-green-50 rounded-full w-9 h-9 p-2">
          <Meeting />
        </div>
      ),
    },
    'Meeting Missed': {
      icon: (
        <div className="border border-green-50 bg-green-50 rounded-full w-9 h-9 p-2">
          <Meeting />
        </div>
      ),
    },
    'Site Visit Scheduled': {
      icon: (
        <div className="border border-pink-50 bg-pink-50 rounded-full w-9 h-9 p-2">
          <SiteVisit />
        </div>
      ),
    },
    'Site Visit Rescheduled': {
      icon: (
        <div className="border border-pink-50 bg-pink-50 rounded-full w-9 h-9 p-2">
          <SiteVisit />
        </div>
      ),
    },
    'Site Visit Done': {
      icon: (
        <div className="border border-pink-50 bg-pink-50 rounded-full w-9 h-9 p-2">
          <SiteVisit />
        </div>
      ),
    },
    'Site Visit Cancelled': {
      icon: (
        <div className="border border-pink-50 bg-pink-50 rounded-full w-9 h-9 p-2">
          <SiteVisit />
        </div>
      ),
    },
    'Site Visit Missed': {
      icon: (
        <div className="border border-pink-50 bg-pink-50 rounded-full w-9 h-9 p-2">
          <SiteVisit />
        </div>
      ),
    },
    'Agent Assigned': {
      icon: (
        <div className="border border-green-50 bg-green-50 rounded-full w-9 h-9 p-2">
          <AgentAssigned />
        </div>
      ),
    },
    'Booking Request Created': {
      icon: (
        <div className="border border-blue-50 bg-blue-50 rounded-full w-9 h-9 p-2">
          <BookingRequestCreated />
        </div>
      ),
    },

    'Booking Verified': {
      icon: (
        <div className="border border-green-50 bg-green-50 rounded-full w-9 h-9 p-2">
          <BookingVerified />
        </div>
      ),
    },
    'Customer Details Updated': {
      icon: (
        <div className="border border-blue-50 bg-blue-50 rounded-full w-9 h-9 p-2">
          <CustomerDetails />
        </div>
      ),
    },
    'Customer Created': {
      icon: (
        <div className="border border-green-50 bg-green-50 rounded-full w-9 h-9 p-2">
          <CustomerCreated />
        </div>
      ),
    },
    Failed: {
      icon: (
        <div className="border border-red-50 bg-red-50 rounded-full w-9 h-9 p-2">
          <Failed />
        </div>
      ),
    },
    'Moved to Failed': {
      icon: (
        <div className="border border-red-50 bg-red-50 rounded-full w-9 h-9 p-2">
          <Failed />
        </div>
      ),
    },
    'Customer Marked as Failed': {
      icon: (
        <div className="border border-red-50 bg-red-50 rounded-full w-9 h-9 p-2">
          <Failed />
        </div>
      ),
    },
    'Moved to Booking Done': {
      icon: (
        <div className="border border-purple-50 bg-purple-50 rounded-full w-9 h-9 p-2">
          <MovedtoBooking />
        </div>
      ),
    },
    'Moved to Interested': {
      icon: (
        <div className="border border-purple-50 bg-purple-50 rounded-full w-9 h-9 p-2">
          <MovedtoInterested />
        </div>
      ),
    },
    'New Notes Added': {
      icon: (
        <div className="border border-purple-50 bg-purple-50 rounded-full w-9 h-9 p-2">
          <NotesAdded />
        </div>
      ),
    },
    'Moved to New': {
      icon: (
        <div className="border border-orange-50 bg-orange-50 rounded-full w-9 h-9 p-2">
          <Recovery />
        </div>
      ),
    },
    'Customer Recovered Successfully': {
      icon: (
        <div className="border border-orange-50 bg-orange-50 rounded-full w-9 h-9 p-2">
          <Recovery />
        </div>
      ),
    },
    'Sent to Call Centre': {
      icon: (
        <div className="border border-purple-50 bg-purple-50 rounded-full w-9 h-9 p-2">
          <Sent />
        </div>
      ),
    },
    Transfer: {
      icon: (
        <div className="border border-purple-50 bg-purple-50 rounded-full w-9 h-9 p-2">
          <Sent />
        </div>
      ),
    },
    'Sent to ACP Call Centre': {
      icon: (
        <div className="border border-purple-50 bg-purple-50 rounded-full w-9 h-9 p-2">
          <Sent />
        </div>
      ),
    },
    'New Project Tagged': {
      icon: (
        <div className="border border-green-50 bg-green-50 rounded-full w-9 h-9 p-2">
          <ProjectsTagged />
        </div>
      ),
    },
  };

  const [showHistory, setShowHistory] = useState(false);

  const handleShowHistory = () => {
    setShowHistory(!showHistory);
  };

  const [showNotes, setShowNotes] = useState(false);

  const handleShowNotes = () => {
    setShowNotes(!showNotes);
  };

  const getEventCount = eventArray => {
    if (eventArray && eventArray.length) {
      let counts = eventArray.reduce(
        (c, { scheduled: key }) => ((c[key] = (c[key] || 0) + 1), c),
        {},
      );
      return counts;
    }
    return {};
  };
  useEffect(() => {
    setHistoryData(getEventCount(customerHistory));
    let data = [...customerHistory];
    data.sort(compare);
    let updatedData: any = [...checkDate([...data])];
    setHistoryCompletedata([...updatedData]);
  }, [customerHistory]);

  const compare = (a, b) => {
    if (a.date > b.date) {
      return -1;
    }
    if (a.date < b.date) {
      return 1;
    }
    return 0;
  };

  const checkDate = data => {
    var dateIndetifier = '';
    let tempData: any = [];
    data.forEach(history => {
      let tempHistory: any = { ...history };
      if (!dateIndetifier || tempHistory.date < dateIndetifier) {
        tempHistory.renderDate = true;
        dateIndetifier = tempHistory.date;
      } else {
        tempHistory.renderDate = false;
      }
      tempData.push(tempHistory);
    });
    return tempData;
  };

  const [followUpDate, setFollowUpDate] = useState<any>({});
  const [meetingDate, setMeetingDate] = useState<any>({});
  useEffect(() => {
    setFollowUpDate(cardEventType.find(event => event.event_type === 'FOLLOW_UP'));
    setMeetingDate(cardEventType.find(event => event.event_type === 'MEETING'));
  }, [cardEventType]);

  const [openProjectShare, setOpenProjectShare] = useState(false);
  const handleProjectShare = id => {
    setshareProjectId(id);
    setOpenProjectShare(!openProjectShare);
  };

  const setSelectedCustomer = data => {
    if (onSelect) {
      onSelect(data);
    }
  };

  return (
    <>
      <div className="lg:grid lg:grid-cols-5 md:flex md:flex-nowrap justify-between items-centerw-full border bg-white rounded-lg shadow-sm py-5 mb-2 xl:px-[30px] lg:px-4 md:px-3">
        <div className="flex flex-1 items-center">
          <button
            onClick={
              selectedBar
                ? () => {
                    setSelectedCustomer(customerDetails?.id);
                  }
                : undefined
            }
            disabled={props?.phoneNotComplete && selectedBar === 2}
          >
            {((selectedBar && props?.isSelected) || type === 'select_all') &&
            !(props?.phoneNotComplete && selectedBar === 2) ? (
              <span className="text-sm font-medium text-white">
                <Tick />
              </span>
            ) : selectedBar ? (
              <span className="text-sm font-medium text-white">
                <UncheckCircle />
              </span>
            ) : (
              <div className="flex items-center border bg-gray-600 rounded-full w-8 h-8 p-2.5">
                <span className="text-sm font-medium text-white">
                  {(
                    customerDetails?.customer_name ||
                    customerBookingDetail?.customer_lead?.customer?.customer_name
                  ).charAt(0)}
                </span>
              </div>
            )}
          </button>
          <button
            onClick={() =>
              handleOpen(customerDetails?.id || customerBookingDetail?.customer_lead?.customer_id)
            }
          >
            <div className="flex flex-auto items-center">
              <div className="flex  flex-initial w-40 xl:w-64 flex-wrap ml-3">
                <div className="flex flex-initial items-center">
                  <h4 className="flex items-center text-black font-medium text-sm mr-2">
                    {customerDetails?.customer_name ||
                      customerBookingDetail?.customer_lead?.customer?.customer_name}
                  </h4>
                  <div className="flex">
                    {customerDetails?.category === 'HOT' ||
                    customerBookingDetail?.customer_lead?.customer?.category === 'HOT' ? (
                      <>
                        <CategoryFilled className="w-6 h-6" />
                        <CategoryFilled className="w-6 h-6" />
                        <CategoryFilled className="w-6 h-6" />
                      </>
                    ) : customerDetails?.category === 'WARM' ||
                      customerBookingDetail?.customer_lead?.customer?.category === 'WARM' ? (
                      <>
                        <CategoryFilled className="w-6 h-6" />
                        <CategoryFilled className="w-6 h-6" />
                        <CategoryUnfilled className="w-6 h-6" />
                      </>
                    ) : customerDetails?.category === 'COLD' ||
                      customerBookingDetail?.customer_lead?.customer?.category === 'COLD' ? (
                      <>
                        <CategoryFilled className="w-6 h-6" />
                        <CategoryUnfilled className="w-6 h-6" />
                        <CategoryUnfilled className="w-6 h-6" />
                      </>
                    ) : (
                      <>
                        <CategoryUnfilled className="w-6 h-6" />
                        <CategoryUnfilled className="w-6 h-6" />
                        <CategoryUnfilled className="w-6 h-6" />
                      </>
                    )}
                  </div>
                </div>
                <div className="flex flex-initial w-40 md:w-48 lg:w-48 xl:w-54 items-center">
                  {customerDetails?.budget ? (
                    <h6 className="text-xs text-left  flex-1 text-gray-500 font-medium truncate w-32">
                      {convertBudget(customerDetails?.budget) || ''}
                    </h6>
                  ) : (
                    <></>
                  )}
                  {customerBookingDetail ? (
                    <></>
                  ) : (
                    <>
                      {customerUnitConfPrefference ? (
                        <>
                          <Dot className="mx-1" />
                          <h6 className="text-xs text-left flex-1 grow text-gray-500 font-medium truncate w-32">
                            {customerUnitConfPrefference || ''}
                          </h6>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}

                  {customerLocalityPrefference ? (
                    <>
                      <Dot className="mx-1" />
                      <h6 className="text-xs text-left flex-1 text-gray-500 font-medium truncate w-32">
                        {customerLocalityPrefference}
                      </h6>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </button>
        </div>

        {customerDetail_CC?.status === 'IN_CC' && activeTab === 3 ? (
          <div
            style={{ backgroundColor: 'rgba(255, 225, 119, 0.3)' }}
            className="flex flex-initial w-32 h-9 items-center rounded-sm justify-center"
          >
            <h6
              style={{ color: 'rgba(254, 167, 19, 1)' }}
              className="text-xs font-medium lg:text-justify"
            >
              {customerDetail_CC?.disposition?.created_at
                ? moment
                    .utc(customerDetail_CC?.disposition?.created_at)
                    .utcOffset('+05:30')
                    .format('Do MMM YYYY, hh:mm A')
                : 'Under Progress'}
            </h6>
          </div>
        ) : (
          <></>
        )}
        {customerBookingDetail ? (
          <div className="flex flex-1 ml-8">
            <div className="grid grid-flow-row">
              <div className="flex text-left">
                <img
                  src={customerBookingLeadProjects[0]?.thumbnail_image || dummyProjectImg}
                  className="xl:w-10 xl:h-10 xl:rounded-lg lg:w-8 lg:h-8 lg:rounded-lg w-7 h-7 rounded-md"
                  alt=""
                />
                <h5 className="text-sm text-black font-medium m-auto ml-4">
                  {customerBookingLeadProjects[0]?.name}
                </h5>
              </div>
            </div>
          </div>
        ) : customerLeadProjects[0] ? (
          <div className="flex flex-1 ml-8">
            <div className="grid grid-flow-row">
              <div className="flex text-left">
                <img
                  src={customerLeadProjects[0]?.thumbnail_image || dummyProjectImg}
                  className="xl:w-10 xl:h-10 xl:rounded-lg lg:w-8 lg:h-8 lg:rounded-lg w-7 h-7 rounded-md"
                  alt=""
                />
                <h5 className="text-sm text-black font-medium m-auto ml-4">
                  {customerLeadProjects[0]?.name}
                </h5>
              </div>
              {customerLeadProjects[1]?.name ? (
                <div className="flex text-left mt-2">
                  <img
                    src={customerLeadProjects[1]?.thumbnail_image || dummyProjectImg}
                    className="xl:w-10 xl:h-10 xl:rounded-lg lg:w-8 lg:h-8 lg:rounded-lg w-7 h-7 rounded-md"
                    alt=""
                  />
                  <h5 className="text-sm text-black font-medium m-auto ml-4">
                    {customerLeadProjects[1]?.name}
                  </h5>
                </div>
              ) : (
                <></>
              )}

              {customerLeadProjects?.length > 2 ? (
                <button onClick={handleAllProjects} className="flex items-center mt-3">
                  <h6 className="text-xs text-primary font-medium mr-2">View all Projects</h6>
                  <ArrowDownPurple />
                </button>
              ) : (
                <></>
              )}
              {customerDetails?.remarks && activeTab === 6 ? (
                <div className="flex mt-2">
                  <h5 className="text-gray-700 font-medium">Reason: </h5>
                  <span>&nbsp;{customerDetails?.remarks?.remark}</span>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <div className=" flex flex-1 ml-8">
            {customerDetails?.remarks && activeTab === 6 ? (
              <div className="flex mt-2">
                <h5 className="text-gray-700 font-medium">Reason: </h5>
                <span>&nbsp;{customerDetails?.remarks?.remark}</span>
              </div>
            ) : (
              <></>
            )}
          </div>
        )}

        <Dialog
          open={openAllProjects}
          handler={handleAllProjects}
          className="fixed top-0 py-5 px-6 w-[28%] max-w-96 min-w-44 overflow-y-auto"
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <h4 className="text-base text-black font-medium">
                {customerDetails?.customer_name || '--'}
              </h4>
              <div className="flex">
                {customerDetails?.category === 'HOT' ? (
                  <>
                    <CategoryFilled className="w-6 h-6" />
                    <CategoryFilled className="w-6 h-6" />
                    <CategoryFilled className="w-6 h-6" />
                  </>
                ) : customerDetails?.category === 'WARM' ? (
                  <>
                    <CategoryFilled className="w-6 h-6" />
                    <CategoryFilled className="w-6 h-6" />
                    <CategoryUnfilled className="w-6 h-6" />
                  </>
                ) : customerDetails?.category === 'COLD' ? (
                  <>
                    <CategoryFilled className="w-6 h-6" />
                    <CategoryUnfilled className="w-6 h-6" />
                    <CategoryUnfilled className="w-6 h-6" />
                  </>
                ) : (
                  <>
                    <CategoryUnfilled className="w-6 h-6" />
                    <CategoryUnfilled className="w-6 h-6" />
                    <CategoryUnfilled className="w-6 h-6" />
                  </>
                )}
              </div>
            </div>
            <button onClick={handleAllProjects}>
              <Cross />
            </button>
          </div>
          <div className="flex items-center">
            <h6 className="text-xs text-gray-700 font-medium">
              {convertBudget(customerDetails?.budget) || '--'}
            </h6>
            <Dot className="mx-1" />
            <h6 className="text-xs text-gray-700 font-medium">
              {customerUnitConfPrefference || '--'}
            </h6>
            <Dot className="mx-1" />
            <h6 className="text-xs text-gray-700 font-medium truncate w-32">
              {customerLocalityPrefference || '--'}
            </h6>
          </div>
          <hr className="border border-gray-200 mt-2 mb-4" />
          <h6 className="text-xs text-gray-700 font-medium">Projects Interested in:</h6>
          <div>
            {customerLeadProjects.map((item, index) => (
              <h5 className="text-sm text-black/80 font-normal my-3">{item?.name}</h5>
            ))}
          </div>
        </Dialog>

        {/* <div className="flex flex-1 items-center justify-center">
          <div className="flex items-center">
            <div
              className={
                customerDetails?.subDetails === 'ADDED'
                  ? 'border border-green-50 bg-green-50 rounded-full xl:p-2 lg:p-1.5 p-1'
                  : customerDetails?.subDetails === 'FOLLOW UP DONE'
                  ? 'border border-indigo-50 bg-indigo-50 rounded-full xl:p-2 lg:p-1.5 p-1'
                  : customerDetails?.subDetails === 'MEETING PLANNED'
                  ? 'border border-green-50 bg-green-50 rounded-full xl:p-2 lg:p-1.5 p-1'
                  : customerDetails?.subDetails === 'CONFIGURATION'
                  ? 'border border-orange-50 bg-orange-50 rounded-full xl:p-2 lg:p-1.5 p-1'
                  : customerDetails?.subDetails === 'TOTAL PRICE'
                  ? 'border border-green-50 bg-green-50 rounded-full xl:p-2 lg:p-1.5 p-1'
                  : customerDetails?.subDetails === 'FAILED BY'
                  ? 'border border-red-100 bg-red-100 rounded-full xl:p-2 lg:p-1.5 p-1'
                  : 'border border-purple-50 bg-purple-50 rounded-full xl:p-2 lg:p-1.5 p-1'
              }
            >
              {customerDetails?.subDetails === 'ADDED' ? (
                <Added />
              ) : customerDetails?.subDetails === 'SENT TO CALL CENTER' ? (
                <Sent />
              ) : customerDetails?.subDetails === 'TOTAL PRICE' ? (
                <TotalPrice />
              ) : customerDetails?.subDetails === 'FOLLOW UP DONE' ? (
                <FollowUp />
              ) : customerDetails?.subDetails === 'MARKED INTERESTED' ? (
                <MarkedIntersted />
              ) : customerDetails?.subDetails === 'MEETING PLANNED' ? (
                <Meeting className="w-4 h-4" />
              ) : customerDetails?.subDetails === 'CONFIGURATION' ? (
                <Configuration />
              ) : customerDetails?.subDetails === 'FAILED BY' ? (
                <Failed />
              ) : customerDetails?.subDetails === 'FAILED DATE' ? (
                <MovedtoBooking />
              ) : customerDetails?.subDetails === 'No event scheduled' ? (
                <ExclamationMark />
              ) : (
                <BookingRequestDate />
              )}
            </div>
            <div className="xl:ml-2 ml-1.5">
              <h5 className="text-sm text-black font-medium lg:text-justify">
                {customerDetails?.subDetailsValue}
              </h5>
              <p className="text-[10px] text-slate-600 text-justify font-normal">
                {customerDetails?.subDetails}
              </p>
            </div>
          </div>
        </div> */}
        {customerBookingDetail?.booking_date ? (
          <div className="flex flex-1 items-center justify-center">
            <div className="flex items-center">
              <div className="border border-pink-50 bg-pink-50 rounded-full xl:p-2 lg:p-1.5 p-1">
                <BookingRequestDate />
              </div>
              <div className="xl:ml-2 ml-1.5">
                <h5 className="text-sm text-black font-medium lg:text-justify">
                  {moment(customerBookingDetail?.booking_date)
                    .utcOffset('+05:30')
                    .format('Do MMM, YYYY')}
                </h5>
                <p className="text-[10px] text-slate-600 text-justify font-normal">
                  BOOKING REQUEST DATE
                </p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {customerBookingDetail?.agreement_value ? (
          <div className="flex flex-1 items-center justify-center">
            <div className="flex items-center">
              <div className="border border-green-50 bg-green-50 rounded-full xl:p-2 lg:p-1.5 p-1">
                <TotalPrice />
              </div>
              <div className="xl:ml-2 ml-1.5">
                <h5 className="text-sm text-black font-medium lg:text-justify">
                  {convertTotalPrice(customerBookingDetail?.agreement_value)}
                </h5>
                <p className="text-[10px] text-slate-600 text-justify font-normal">TOTAL PRICE</p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {customerBookingDetail?.unit_number ? (
          <div className="flex flex-1 items-center justify-center">
            <div className="flex items-center">
              <div className="border border-orange-50 bg-orange-50 rounded-full xl:p-2 lg:p-1.5 p-1">
                <Configuration />
              </div>
              <div className="xl:ml-2 ml-1.5">
                <h5 className="text-sm text-black font-medium lg:text-justify">
                  {customerBookingDetail?.unit_number}
                </h5>
                <p className="text-[10px] text-slate-600 text-justify font-normal">UNIT</p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {customerDetails?.created_at && (activeTab === 1 || activeTab === 2) ? (
          <div className="flex flex-1 items-center justify-center">
            <div className="flex items-center">
              <div className="border border-green-50 bg-green-50 rounded-full xl:p-2 lg:p-1.5 p-1">
                <Added />
              </div>
              <div className="xl:ml-2 ml-1.5">
                <h5 className="text-sm text-black font-medium lg:text-justify">
                  {moment(customerDetails.created_at).diff(new Date(), 'days') < 0
                    ? `${Math.abs(
                        moment(customerDetails.created_at).diff(new Date(), 'days'),
                      )} Days Ago`
                    : 'Today'}
                </h5>
                <p className="text-[10px] text-slate-600 text-justify font-normal">ADDED</p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {activeTab === 4 ? (
          <div className="flex flex-1 items-center justify-center">
            <div className="flex items-center">
              <div className="border border-green-50 bg-purple-50 rounded-full xl:p-2 lg:p-1.5 p-1">
                <MarkedIntersted />
              </div>
              <div className="xl:ml-2 ml-1.5">
                <h5 className="text-sm text-black font-medium lg:text-justify">
                  {customerDetails?.status === 'INTERESTED'
                    ? moment(
                        customerDetails?.status_updated_at
                          ? customerDetails.status_updated_at
                          : customerDetails.updated_at,
                      ).diff(new Date(), 'days') < 0
                      ? `${Math.abs(
                          moment(
                            customerDetails?.status_updated_at
                              ? customerDetails.status_updated_at
                              : customerDetails.updated_at,
                          ).diff(new Date(), 'days'),
                        )} Days Ago`
                      : 'Today'
                    : ''}
                </h5>
                <p className="text-[10px] text-slate-600 text-justify font-normal">
                  MARKED INTERESTED
                </p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {activeTab === 3 ? (
          <div className="flex flex-1 items-center justify-center">
            <div className="flex items-center">
              <div className="border border-green-50 bg-purple-50 rounded-full xl:p-2 lg:p-1.5 p-1">
                <Sent />
              </div>
              <div className="xl:ml-2 ml-1.5">
                <h5 className="text-sm text-black font-medium lg:text-justify">
                  {customerDetails?.status === 'IN_CC'
                    ? moment(
                        customerDetails?.status_updated_at
                          ? customerDetails.status_updated_at
                          : customerDetails.updated_at,
                      ).diff(new Date(), 'days') < 0
                      ? `${Math.abs(
                          moment(
                            customerDetails?.status_updated_at
                              ? customerDetails.status_updated_at
                              : customerDetails.updated_at,
                          ).diff(new Date(), 'days'),
                        )} Days Ago`
                      : 'Today'
                    : ''}
                </h5>
                <p className="text-[10px] text-slate-600 text-justify font-normal">
                  SEND TO CALL CENTER
                </p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {followUpDate?.scheduled_time && activeTab !== 6 ? (
          <div className="flex flex-1 items-center justify-center">
            <div className="flex items-center">
              <div className="border border-green-50 bg-green-50 rounded-full xl:p-2 lg:p-1.5 p-1">
                <FollowUp />
              </div>
              <div className="xl:ml-2 ml-1.5">
                <h5 className="text-sm text-black font-medium lg:text-justify">
                  {moment(followUpDate?.scheduled_time).utcOffset('+05:30').format('Do MMM, YYYY')}
                </h5>
                <p className="text-[10px] text-slate-600 text-justify font-normal">
                  FOLLOW UP {followUpDate?.status}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {meetingDate?.scheduled_time && activeTab !== 6 ? (
          <div className="flex flex-1 items-center justify-center">
            <div className="flex items-center">
              <div className="border border-green-50 bg-green-50 rounded-full xl:p-2 lg:p-1.5 p-1">
                <Meeting className="w-4 h-4" />
              </div>
              <div className="xl:ml-2 ml-1.5">
                <h5 className="text-sm text-black font-medium lg:text-justify">
                  {moment(meetingDate?.scheduled_time).utcOffset('+05:30').format('Do MMM, YYYY')}
                </h5>
                <p className="text-[10px] text-slate-600 text-justify font-normal">
                  MEETING {meetingDate?.status}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {customerDetails?.status === 'NEW' &&
        customerDetails?.customer_event?.length < 1 &&
        activeTab === 2 ? (
          <div className="flex flex-1 items-center justify-center">
            <div className="flex items-center">
              <div className="border border-green-50 bg-red-50 rounded-full xl:p-2 lg:p-1.5 p-1">
                <ExclamationMark />
              </div>
              <div className="xl:ml-2 ml-1.5">
                <h5 style={{ color: '#B61D1D' }} className="text-sm font-medium lg:text-justify">
                  No Event Scheduled
                </h5>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}

        {activeTab === 6 ? (
          customerDetails?.status === 'FAILED' &&
          customerDetails?.status_updated_by === 'CHANNEL_PARTNER' ? (
            <div className="flex flex-1 items-center justify-center">
              <div className="flex items-center">
                <div className="border border-red-50 bg-red-100 rounded-full xl:p-2 lg:p-1.5 p-1">
                  <FailedBy />
                </div>
                <div className="xl:ml-2 ml-1.5">
                  <h5 className="text-sm text-black font-medium lg:text-justify">YOU</h5>
                  <p className="text-[10px] text-slate-600 text-justify font-normal">FAILED BY</p>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}

        {activeTab === 6 ? (
          <div className="flex flex-1 items-center justify-center">
            <div className="flex items-center">
              <div className="border border-green-50 bg-purple-50 rounded-full xl:p-2 lg:p-1.5 p-1">
                <MovedtoBooking />
              </div>
              <div className="xl:ml-2 ml-1.5">
                <h5 className="text-sm text-black font-medium lg:text-justify">
                  {customerDetails?.status === 'FAILED'
                    ? moment(
                        customerDetails?.status_updated_at
                          ? customerDetails.status_updated_at
                          : customerDetails.updated_at,
                      ).diff(new Date(), 'days') < 0
                      ? `${Math.abs(
                          moment(
                            customerDetails?.status_updated_at
                              ? customerDetails.status_updated_at
                              : customerDetails.updated_at,
                          ).diff(new Date(), 'days'),
                        )} Days Ago`
                      : 'Today'
                    : ''}
                </h5>
                <p className="text-[10px] text-slate-600 text-justify font-normal">FAILED DATE</p>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {customerDetails?.subDetails === 'FAILED DATE' ? (
        <h5 className="text-sm text-gray-500 font-medium mt-2">
          Reason:
          <span className="text-black"> Customer not interested in the property anymore</span>
        </h5>
      ) : (
        <></>
      )}
      <div className="h-full">
        <Dialog
          open={open}
          handler={handleOpenClose}
          animate={{
            mount: { scale: 1, y: 1 },
            unmount: { scale: 0.9, y: -100 },
          }}
          className="absolute rounded-xs m-0 pb-16 right-0 lg:max-w-[36%] xl:max-w-[30%] min-w-[20%] h-full overflow-y-auto"
          style={{ backgroundColor: '#F1F1F1' }}
        >
          <div className=" absolute">
            <button className="bg-white" onClick={handleOpenClose}>
              <Cross />
            </button>
          </div>
          <div className="bg-white">
            <CustomerDetailHeader
              openClose={handleOpenClose}
              text={customerDetail?.customer_name?.charAt(0) || ''}
            />
          </div>
          <div className="bg-white py-5 px-8">
            <div className="flex items-center space-x-2">
              <h3 className="text-lg text-black font-medium">
                {customerDetail?.customer_name || '--'}
              </h3>
              <Dot />
              <h3 className="text-lg text-black font-medium">
                {customerDetail?.phone_no || '--'}
              </h3>
            </div>
            <div className="flex items-center space-x-2">
              <p className="text-sm text-gray-700 font-medium">{customerDetail?.status || '--'}</p>
              <Dot />
              <p className="text-sm text-gray-700 font-medium">
                {moment(
                  customerDetail?.updated_at
                    ? customerDetail.updated_at
                    : customerDetail.created_at,
                ).diff(new Date(), 'days') < 0
                  ? `${Math.abs(
                      moment(
                        customerDetail?.updated_at
                          ? customerDetail.updated_at
                          : customerDetail.created_at,
                      ).diff(new Date(), 'days'),
                    )} Days Ago`
                  : 'Today'}
              </p>
            </div>
            <div className="grid grid-cols-2 gap-4 mt-3.5">
              <div>
                <p className="text-sm text-gray-700 font-medium">Budget</p>
                <p className="text-base text-black font-medium">
                  {convertBudget(customerDetail?.budget) || '--'}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-700 font-medium">Unit Configuration</p>
                <p className="text-base text-black font-medium">
                  {customerUnitConfPrefference || '--'}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-700 font-medium">Property Status</p>
                <p className="text-base text-black font-medium">
                  {preferredPropertyStatus[customerDetail?.preferred_property_status] || '--'}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-700 font-medium">Localities</p>
                <p className="text-base text-black font-medium ">
                  {customerLocalityPrefference || '--'}
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-700 font-medium">Unit Type</p>
                <p className="text-base text-black font-medium">
                  {customerUnitTypePrefference || '--'}
                </p>
              </div>
            </div>
          </div>
          <div className="grid gap-y-4 mt-4 mx-8">
            <div className="border border-gray-300 bg-white rounded-md shadow-sm p-3.5">
              <h5 className="text-sm text-black font-semibold">Category</h5>
              <div className="flex mt-2">
                {customerDetail?.category === 'HOT' ? (
                  <>
                    <Category className="w-6 h-6" />
                    <Category className="w-6 h-6" />
                    <Category className="w-6 h-6" />
                  </>
                ) : customerDetail?.category === 'WARM' ? (
                  <>
                    <Category className="w-6 h-6" />
                    <Category className="w-6 h-6" />
                    <CategoryUnfilled className="w-6 h-6" />
                  </>
                ) : customerDetail?.category === 'COLD' ? (
                  <>
                    <Category className="w-6 h-6" />
                    <CategoryUnfilled className="w-6 h-6" />
                    <CategoryUnfilled className="w-6 h-6" />
                  </>
                ) : (
                  <>
                    <CategoryUnfilled className="w-6 h-6" />
                    <CategoryUnfilled className="w-6 h-6" />
                    <CategoryUnfilled className="w-6 h-6" />
                  </>
                )}
              </div>
            </div>
            <div className="border border-gray-300 bg-white rounded-md shadow-sm p-3.5">
              <h5 className="text-sm text-black font-semibold">Status</h5>
              <div className="text-base text-black font-medium mt-2">
                {statusList[customerDetail?.status] || '--'}
              </div>
            </div>
            <div className="border border-gray-300 bg-white rounded-md shadow-sm p-3.5">
              <h5 className="text-sm text-black font-semibold">Upcoming Events / Past Events</h5>
              {customerEvent.map((item, index) => {
                return (
                  <div key={`${customerDetail?.id}-${item?.id}`}>
                    <div className="flex mt-6">
                      <div
                        className={
                          item?.event_type === 'FOLLOW_UP'
                            ? 'border border-blue-50 bg-blue-50 rounded-full p-3'
                            : item?.event_type === 'SITE_VISIT'
                            ? 'border border-pink-50 bg-pink-50 rounded-full p-3'
                            : 'border border-green-50 bg-green-50 rounded-full p-3'
                        }
                      >
                        {item?.event_type === 'FOLLOW_UP' ? (
                          <FollowUp />
                        ) : item?.event_type === 'SITE_VISIT' ? (
                          <SiteVisit />
                        ) : (
                          <Meeting />
                        )}
                      </div>
                      <div className="ml-3.5">
                        <h5 className="text-sm text-gray-700 font-medium">
                          {eventType[item?.event_type]}

                          {filterProjectData(item?.project_id) ? (
                            <span className="ml-1">
                              - {filterProjectData(item?.project_id)?.project_name}
                            </span>
                          ) : (
                            <></>
                          )}
                        </h5>
                        <div className="flex items-center mt-1.5">
                          <DotGreen />
                          <h5 className="text-sm text-black font-medium ml-1.5">
                            {moment
                              .utc(item.scheduled_time)
                              .utcOffset('+05:30')
                              .format('Do MMM YYYY, hh:mm A')}
                          </h5>
                        </div>
                      </div>
                    </div>
                    {customerEvent.length === index + 1 ? <></> : <hr className="my-5" />}
                  </div>
                );
              })}
            </div>
            <div className="border border-gray-300 bg-white rounded-md shadow-sm p-3.5">
              <h5 className="text-sm text-black font-semibold">Preferred Projects</h5>
              {customerLeadProjects.map((item, index) => {
                return (
                  <div key={`${customerDetail?.id}-${item?.id}`}>
                    <div className="flex items-center justify-between mt-6">
                      <div className="flex">
                        <img
                          src={item?.thumbnail_image || dummyProjectImg}
                          className="w-24 h-24 rounded-lg"
                          alt="project_img"
                        />
                        <div className="ml-3.5">
                          <h3 className="text-lg text-black font-medium">{item?.name}</h3>
                          <h4 className="text-sm text-gray-700 font-medium">{item?.address}</h4>
                          <div className="flex items-center mt-2.5">
                            <DotGreen />
                            <h5 className="text-sm text-black font-medium ml-1.5">
                              {customerLeads.map(leads => {
                                if (leads?.project_id === item?.id) {
                                  return statusList[leads?.status];
                                }
                              })}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <button onClick={() => handleProjectShare(item?.id)}>
                        <Share className="w-8 h-8" />
                      </button>
                    </div>
                    {customerLeadProjects.length === index + 1 ? <></> : <hr className="my-5" />}
                  </div>
                );
              })}
              {/* share modal starts */}
              <Dialog
                open={openProjectShare}
                handler={handleProjectShare}
                className="rounded-2xl p-8"
              >
                <div className="flex justify-between">
                  <div className="text-base text-black font-semibold">Share this Project</div>
                  <button onClick={handleProjectShare}>
                    <Cross />
                  </button>
                </div>
                <hr className="border border-slate-500 my-6" />
                <h4 className="text-base text-black/80 text-center font-normal">
                  Share Project details via
                </h4>
                <div className="flex justify-center gap-x-8 my-6">
                  <a
                    href={`whatsapp://send?text=${projectShareUrl}`}
                    data-action="share/whatsapp/share"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="text-center">
                      <button className="border-2 border-green-100 bg-green-100 rounded-lg p-2">
                        <WhatsApp className="w-10 h-10" />
                      </button>
                      <p className="text-sm text-black/90 font-medium mt-4">Whatsapp</p>
                    </div>
                  </a>
                  <div className="text-center">
                    <button
                      className="border-2 border-gray-200 bg-gray-200 rounded-lg p-2"
                      onClick={() =>
                        window.open(
                          `https://mail.google.com/mail/?view=cm&fs=1&to=user@example.com&su=Check out this Project!&body=${projectShareUrl}`,
                          '_blank',
                        )
                      }
                    >
                      <Mail className="w-10 h-10" />
                    </button>
                    <p className="text-sm text-black/90 font-medium mt-4">Gmail</p>
                  </div>
                </div>
                <h4 className="text-base text-black text-center font-semibold mb-4">Or</h4>
                <h4 className="text-base text-black/80 text-center font-normal mb-4">
                  Copy below link to your clipboard and send it to people!
                </h4>
                <div className="flex justify-between items-center border-2 border-slate-500 rounded-xl py-1.5 pr-1.5 pl-4 mt-4">
                  <h6 className="text-sm text-black/80 font-medium">{`${projectShareUrl}`}</h6>
                  <button
                    className="flex border-2 border-slate-500 rounded-xl p-1.5"
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${process.env.REACT_APP_PROJECT_API_URL}/cp/project/${shareProjectId}`,
                      );
                    }}
                  >
                    <Copy className="w-6 h-6" />
                    <p className="text-base text-black font-medium ml-2">Copy</p>
                  </button>
                </div>
              </Dialog>
              {/* share modal ends */}
            </div>
            <div className="border border-gray-300 bg-white rounded-md shadow-sm p-3.5">
              <h5 className="text-sm text-black font-semibold">Transfer Customer</h5>
              <div className="flex justify-center mt-6">
                <button
                  onClick={handleTransfeTeammateModal}
                  disabled={
                    (customerDetail?.status === 'NEW' || customerDetail?.status === 'INTERESTED') &&
                    props?.cpBusiness === 'FIRM'
                      ? false
                      : true
                  }
                  className={
                    (customerDetail?.status === 'NEW' || customerDetail?.status === 'INTERESTED') &&
                    props?.cpBusiness === 'FIRM'
                      ? 'px-4 mr-3 items-center'
                      : 'px-4 mr-3 items-center opacity-50'
                  }
                >
                  <TransferTeammate className="ml-10" />
                  <h5 className="text-sm text-black font-medium mt-3.5">Transfer to Teammate</h5>
                </button>
                {/* Transfer to Teammate Modal Starts */}
                <Dialog
                  open={transferTeammate}
                  handler={handleTransfeTeammateModal}
                  className="w-96 max-w-96 min-w-44 py-5 px-6 rounded-sm"
                >
                  <div>
                    <div className="flex justify-between">
                      <h4 className="text-base text-black font-medium">Transfer to teammate</h4>
                      <button
                        onClick={() => {
                          handleTransfeTeammateModal();
                          setTeammateSelected('');
                        }}
                      >
                        <Cross />
                      </button>
                    </div>
                    <p className="text-xs text-gray-600 font-normal">
                      Select Teammate to transfer customers:
                    </p>
                    <h6 className="text-xs text-gray-600 font-semibold mt-6 mb-4">
                      Choose teammate
                      <span className="text-red-700 font-medium">*</span>
                    </h6>
                    {allUserList.map((item, index) => {
                      return (
                        <div
                          key={`${customerDetail?.id}-${item?.id}`}
                          className="flex items-center mb-6"
                        >
                          <button
                            onClick={() => {
                              setTeammateSelected(item?.id);
                            }}
                          >
                            {teammateSelected === item?.id ? <CheckRadio /> : <UnCheckRadio />}
                          </button>
                          <h5 className="text-sm text-black/80 font-normal ml-2.5">
                            {item?.name || '--'}
                          </h5>
                        </div>
                      );
                    })}

                    <button
                      // onClick={handleTransfeTeammateModal}
                      onClick={teamMateTransfer}
                      className="w-full border border-purple-700 bg-primary rounded-lg p-4"
                    >
                      <h4 className="text-sm text-white font-medium">Transfer to Teammate</h4>
                    </button>
                  </div>
                </Dialog>
                {/* Transfer to Teammate Modal Ends */}
                <hr className="rotate-180 border border-gray-200 h-20 mx-4" />
                <button
                  onClick={handleSendCallCanterModal}
                  disabled={customerDetail?.status !== 'NEW' ? true : false}
                  className={
                    customerDetail?.status === 'NEW'
                      ? 'px-4 mr-3 items-center'
                      : 'px-4 mr-3 items-center opacity-50'
                  }
                >
                  <SendCallCenter className="w-11 h-11 ml-6" />
                  <h5 className="text-sm text-black font-medium mt-3.5">Send to Call Centre</h5>
                </button>
                {/* Send to Call Center Starts */}
                <Dialog
                  open={sendCallCanter}
                  handler={handleSendCallCanterModal}
                  className="w-96 max-w-96 min-w-44 py-5 px-6 rounded-sm"
                >
                  <div>
                    <div className="flex flex-1 justify-between">
                      <h4 className="text-base text-black font-medium">Send to Call Center</h4>
                      <button
                        onClick={() => {
                          setSendCallCanter(!sendCallCanter);
                          setSelectedProjects([]);
                          setTick([]);
                        }}
                      >
                        <Cross />
                      </button>
                    </div>
                    <p className="text-xs text-gray-600 font-normal">
                      Select Projects (Max 2) to send to Call Centre:
                    </p>
                    <h6 className="text-xs text-gray-600 font-semibold mt-6 mb-4">
                      Choose Project
                      <span className="text-red-700 font-medium">*</span>
                    </h6>
                    <div className="flex items-center border bg-gray-200 rounded-md py-3 px-5">
                      <span>
                        <Search className="w-4 h-4" />
                      </span>
                      <input
                        placeholder="Search project"
                        className="w-full text-xs text-black font-normal border-gray-200 bg-gray-200 ml-2.5"
                        onChange={handleSearch}
                        value={search}
                      />
                    </div>
                    {maxProjectmsg ? (
                      <div className="flex items-center mt-3">
                        <ExclamationMark />
                        <h6 className="text-xs text-black font-medium ml-2">
                          You have selected the maximum number of projects
                        </h6>
                      </div>
                    ) : (
                      <></>
                    )}
                    <h6 className="text-xs text-gray-600 font-medium mt-3 mb-4">
                      Recommended Projects:
                    </h6>
                    <div className="h-72 overflow-y-auto">
                      {searchedProject?.length
                        ? searchedProject?.map((sub_item, sub_index) => {
                            return (
                              <div key={`teammate-${sub_index}`} className="flex items-center mb-6">
                                <button
                                  onClick={() => {
                                    selectProject(sub_item?.id);
                                    tickProjects(sub_item?.id);
                                  }}
                                >
                                  {tick.includes(sub_item?.id) ? (
                                    <CheckCircle className="w-6 h-6" />
                                  ) : (
                                    <UncheckCircle className="w-6 h-6" />
                                  )}
                                </button>
                                <h5 className="text-sm text-black/80 font-normal ml-2.5">
                                  {sub_item?.name}
                                </h5>
                              </div>
                            );
                          })
                        : recommendedProjects?.map((sub_item, sub_index) => {
                            return (
                              <div key={`teammate-${sub_index}`} className="flex items-center mb-6">
                                <button
                                  onClick={() => {
                                    selectProject(sub_item?.id);
                                    tickProjects(sub_item?.id);
                                  }}
                                >
                                  {tick.includes(sub_item?.id) ? (
                                    <CheckCircle className="w-6 h-6" />
                                  ) : (
                                    <UncheckCircle className="w-6 h-6" />
                                  )}
                                </button>
                                <h5 className="text-sm text-black/80 font-normal ml-2.5">
                                  {sub_item?.name}
                                </h5>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                  <button
                    onClick={sendCallCanterApiCall}
                    className="w-full border border-purple-700 bg-primary rounded-lg p-4"
                  >
                    <h4 className="text-sm text-white font-medium">Send to Call Center</h4>
                  </button>
                </Dialog>
                {/* Send to Call Center Modal Ends */}
              </div>
            </div>
            <div className="border border-gray-300 bg-white rounded-md shadow-sm p-3.5">
              <h5 className="text-sm text-black font-semibold">History</h5>
              <div className="grid grid-cols-3 my-6">
                <div className="flex">
                  <div className="border border-blue-50 bg-blue-50 rounded-full w-9 h-9 p-1.5">
                    <FollowUp className="w-4 h-4 mt-1 ml-0.5" />
                  </div>
                  <div className="ml-2">
                    <h5 className="text-xs text-black font-medium">Follow Up</h5>
                    <h3 className="text-lg text-black font-medium">
                      {historyData?.FOLLOW_UP ? historyData?.FOLLOW_UP : 0}
                    </h3>
                  </div>
                  <hr className="rotate-180 border border-gray-200 h-12 mx-2" />
                </div>
                <div className="flex">
                  <div className="border border-green-50 bg-green-50 rounded-full w-9 h-9 p-1.5">
                    <Meeting className="w-4 h-4 mt-1 ml-1" />
                  </div>
                  <div className="ml-2">
                    <h5 className="text-xs text-black font-medium">Meeting</h5>
                    <h3 className="text-lg text-black font-medium">
                      {historyData?.MEETING ? historyData?.MEETING : 0}
                    </h3>
                  </div>
                  <hr className="rotate-180 border border-gray-200 h-12 mx-2" />
                </div>
                <div className="flex">
                  <div className="border border-pink-50 bg-pink-50 rounded-full w-9 h-9 p-1.5">
                    <SiteVisit className="w-4 h-4 mt-0.5 ml-1" />
                  </div>
                  <div className="ml-2">
                    <h5 className="text-xs text-black font-medium">Site Visit</h5>
                    <h3 className="text-lg text-black font-medium">
                      {historyData?.SITE_VISIT ? historyData?.SITE_VISIT : 0}
                    </h3>
                  </div>
                </div>
              </div>
              <hr className="border border-gray-200" />
              <div className="flex items-center mt-3.5">
                <button onClick={handleShowHistory} className="flex flex-1 items-center">
                  <History />
                  <h5 className="text-sm text-primary font-medium ml-2">View complete history</h5>
                </button>
                <ArrowDown className={showHistory ? '-rotate-90' : 'rotate-90'} />
              </div>
              {showHistory ? (
                <div className="mt-6">
                  {historyCompletedata.map((item, index) => {
                    return (
                      <div key={`${customerDetail?.id}-history-${index}`}>
                        {item?.renderDate ? (
                          <>
                            <h4 className="text-base text-black font-semibold">
                              {moment(item?.date).utcOffset('+05:30').format('Do MMM, YYYY')}
                            </h4>
                            <hr className="mt-2 mb-5" />
                          </>
                        ) : (
                          <></>
                        )}
                        <div className="flex">
                          {item?.label ? (
                            eventIcon[item?.label] ? (
                              eventIcon[item?.label].icon
                            ) : (
                              <></>
                            )
                          ) : (
                            <></>
                          )}
                          {/* <hr className="rotate-180 bg-gray-200 mt-4 h-7 w-0.5" /> */}
                          <div className="ml-2.5">
                            <h6 className="text-xs text-black font-medium">{item?.label}</h6>
                            <p className="text-[10px] text-gray-700 font-normal">
                              {moment(item?.time, 'hh:mm:ss').utcOffset('+05:30').format('hh:mm A')}
                            </p>
                            <p className="text-[10px] text-gray-700 font-normal mt-2.5 mb-5">
                              {item?.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="border border-gray-300 bg-white rounded-md shadow-sm p-3.5">
              <div className="flex items-center justify-between">
                <h5 className="text-sm text-black font-semibold">Add Note</h5>
                <button onClick={addNotes} className="border bg-purple-50 rounded-lg py-2 px-6">
                  <span className="text-sm text-purple-700 font-medium">Add Note</span>
                </button>
              </div>
              <textarea
                onChange={handleChange}
                value={notes}
                rows={4}
                className="w-full border border-purple-50 rounded-lg text-sm text-black font-medium my-2"
              />
              <hr className="border border-gray-200" />
              <div className="flex items-center mt-3.5">
                <button onClick={handleShowNotes} className="flex flex-1 items-center">
                  <ViewNotes />
                  <h5 className="text-sm text-primary font-medium ml-2">View All Notes</h5>
                </button>
                <ArrowDown className={showNotes ? '-rotate-90' : 'rotate-90'} />
              </div>
              {showNotes && customerDetail?.customer_notes?.length ? (
                <div className="mt-5">
                  <h5 className="text-sm text-primary font-medium">All Notes</h5>
                  {customerNotes?.map(item => (
                    <div>
                      {item?.renderDate ? (
                        <>
                          <h4 className="mt-6 text-sm text-black font-semibold">
                            {moment.utc(item?.date).utcOffset('+05:30').format('Do MMM, YYYY')}
                          </h4>
                          <hr className="mt-2 mb-5" />
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="flex mt-6">
                        <div className="flex border border-purple-50 bg-purple-50 rounded-full w-9 h-9 p-2">
                          <NotesAdded />
                        </div>
                        <div className="ml-2.5">
                          <h6 className="text-xs text-black font-medium">Notes Added</h6>
                          <p className="text-[10px] text-gray-700 font-normal">
                            {moment
                              .utc(item?.created_at)
                              .utcOffset('+05:30')
                              .format('Do MMM YYYY, hh:mm A')}
                          </p>
                          <p className="border bg-gray-100 rounded-md p-3 text-[10px] text-gray-700 font-normal mt-2.5 mb-5">
                            {item?.note}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="absolute bottom-0 right-0">
            <ToastContainer
              autoClose={50000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
          </div>
        </Dialog>
      </div>
    </>
  );
}

function CustomerDetailHeader(props) {
  return (
    <svg
      className="w-full h-full"
      width="472"
      height="108"
      viewBox="0 0 472 108"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1163_11717)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 0H472V94L0 71.6371L0 0Z"
          fill="#700CB3"
        />
        <mask id="mask0_1163_11717" maskUnits="userSpaceOnUse" x="0" y="0" width="472" height="94">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 0H472V94L0 71.6371L0 0Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_1163_11717)">
          <ellipse cx="85.7008" cy="142.1" rx="252.791" ry="136.1" fill="#8D36C8" />
          <ellipse cx="530.326" cy="193.1" rx="252.791" ry="136.1" fill="#8D36C8" />
        </g>
      </g>
      <g clip-path="url(#clip1_1163_11717)">
        <rect
          x="33.0986"
          y="62.0986"
          width="44.8028"
          height="44.8028"
          rx="22.4014"
          fill="#700CB3"
          stroke="white"
          stroke-width="2.19722"
        />
        <foreignObject x="33.0986" y="60.0986" width="44.8028" height="44.8028">
          <h4 className="mt-2 lg:text-lg 4xl:text-2xl md:text-base text-center text-white font-medium">
            {props?.text}
          </h4>
        </foreignObject>
        <mask id="mask1_1163_11717" maskUnits="userSpaceOnUse" x="32" y="61" width="47" height="47">
          <rect
            x="33.0986"
            y="62.0986"
            width="44.8028"
            height="44.8028"
            rx="22.4014"
            fill="white"
            stroke="white"
            stroke-width="2.19722"
          />
        </mask>
        <g mask="url(#mask1_1163_11717)"></g>
      </g>
      <defs>
        <clipPath id="clip0_1163_11717">
          <rect width="472" height="94" fill="white" />
        </clipPath>
        <clipPath id="clip1_1163_11717">
          <rect width="47" height="47" fill="white" transform="translate(32 61)" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default CustomerDetailHeader;
