export const allFilters = [
  {
    label: 'Event Type',
    value: 1,
    multiselect: false,
    option: [
      {
        subLabel: 'Follow Up',
        selected: false,
      },
      {
        subLabel: 'Meeting',
        selected: false,
      },
      {
        subLabel: 'Site Visit',
        selected: false,
      },
    ],
  },
  {
    label: 'Event Status',
    value: 2,
    multiselect: false,
    option: [
      {
        subLabel: 'Missed',
        selected: false,
      },
      {
        subLabel: 'Planned',
        selected: false,
      },
      {
        subLabel: 'Completed',
        selected: false,
      },
    ],
  },
  {
    label: 'Event Date',
    value: 3,
    multiselect: false,
    option: [
      {
        subLabel: 'Today',
        selected: false,
      },
      {
        subLabel: 'This Week',
        selected: false,
      },
      {
        subLabel: 'This Month',
        selected: false,
      },
      {
        subLabel: 'No Events',
        selected: false,
      },
    ],
  },
  {
    label: 'Category',
    value: 4,
    multiselect: true,
    option: [
      {
        subLabel: 'Hot',
        selected: false,
      },
      {
        subLabel: 'Warm',
        selected: false,
      },
      {
        subLabel: 'Cold',
        selected: false,
      },
    ],
  },
  {
    label: 'Customer Created',
    value: 5,
    multiselect: false,
    option: [
      {
        subLabel: 'Today',
        selected: false,
      },
      {
        subLabel: 'Last 7 Days',
        selected: false,
      },
      {
        subLabel: 'Last 30 Days',
        selected: false,
      },
      {
        subLabel: 'All Time',
        selected: false,
      },
    ],
  },
  {
    label: 'CC - Call Status',
    value: 6,
    multiselect: false,
    option: [
      {
        subLabel: 'Not Yet Called',
        selected: false,
      },
      {
        subLabel: 'Not Interested',
        selected: false,
      },
      {
        subLabel: 'Call Attempted Unsuccessfully',
        selected: false,
      },
    ],
  },
  {
    label: 'Unit Configuration',
    value: 7,
    multiselect: true,
    option: [],
  },
  {
    label: 'Booking Done on',
    value: 8,
    option: [
      {
        subLabel: 'Today',
        selected: false,
      },
      {
        subLabel: 'Yesterday',
        selected: false,
      },
      {
        subLabel: 'Last 7 Days',
        selected: false,
      },
      {
        subLabel: 'Last 30 Days',
        selected: false,
      },
    ],
  },
  
  {
    label: 'Marked Failed on',
    value: 9,
    option: [
      {
        subLabel: 'Today',
        selected: false,
      },
      {
        subLabel: 'Last 7 Days',
        selected: false,
      },
      {
        subLabel: 'Last 30 Days',
        selected: false,
      },
      {
        subLabel: 'All Time',
        selected: false,
      },
    ],
  },
];