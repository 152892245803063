import { PayloadAction } from '@reduxjs/toolkit';
import { crmServiceSchemas } from 'types/crmServiceSchemas';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { callCenterInfoSaga } from './saga';
import { CallCenterInfoState } from './types';

export const initialState: CallCenterInfoState = {
  loading: true,
  error: false,
  errorMsg: '',
  callCenterInfo: {},
};

const slice = createSlice({
  name: 'callCenterInfo',
  initialState,
  reducers: {
    loadCallCenterInfo(state) {
      state.loading = true;
      state.error = false;
      state.callCenterInfo = {};
    },
    callCenterInfoLoaded(state, action: PayloadAction<crmServiceSchemas['CallCenterInfo']>) {
      state.callCenterInfo = action.payload;
      state.loading = false;
    },
    callCenterInfoError(state, action: PayloadAction<string>) {
      state.error = true;
      state.errorMsg = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: callCenterInfoActions, reducer } = slice;

export const useCallCenterInfoSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: callCenterInfoSaga });
  return { actions: slice.actions };
};
