import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { customersListResponse } from 'types/crmServiceSchemas';
import { getRequest } from 'utils/request';
import { channelPartnersListActions as actions } from '.';

/**
 * CustomersloadCustomers request/response handler
 */
export function* getChannelPartnersList(filter_obj) {
  yield delay(500);

  const requestURL = `${process.env.REACT_APP_CP_API_URL}/channel_partner/`;

  try {
    const { data } = yield call(getRequest, requestURL);
    const customers: customersListResponse = data?.response;
    if (customers?.items?.length > 0) {
      yield put(
        actions.channelPartnersLoaded({
          items: customers?.items,
          total: customers?.total,
        }),
      );
    } else {
      yield put(actions.channelPartnersError('Something went wrong while fetching customers.'));
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.channelPartnersError('Customersload Customers not found.'));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.channelPartnersError('Failed to fetch customers.'));
    } else {
      yield put(actions.channelPartnersError('Something went wrong while fetching customers.'));
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* channelPartnersListSaga() {
  // Watches for loadRepos actions and calls getChannelPartnersList when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.loadchannelPartners.type, getChannelPartnersList);
}
