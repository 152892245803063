import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { GlobalStyle } from 'styles/global-styles';
import { CrmDashboard } from './pages/CrmDashboard';
import { CustomerDetails } from './pages/CustomerDetailsMobile';
import { CustomerHistory } from './pages/CustomerHistory';
import { MyCustomers } from './pages/MyCustomers';
import { NotFoundPage } from './pages/NotFoundPage/Loadable';
import { ProjectDetailsPage } from './pages/ProjectDetailsPage/Loadable';
import { ProjectListPage } from './pages/ProjectListPage/Loadable';
import { SearchResultPage } from './pages/SearchResultPage';
import { SendCallCenter } from './pages/SendCallCenter';
import { TransferTeammate } from './pages/TransferTeammate';
import { UnitDetailsPage } from './pages/UnitDetailsPage';
import { Layout } from './components/Layout';

export function App() {
  const { i18n } = useTranslation();

  return (
    <BrowserRouter>
      <Helmet
        titleTemplate="%s - ANAROCK Channel Partner"
        defaultTitle="ANAROCK Channel Partner"
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="ANAROCK Channel Partner" />
      </Helmet>

      <Routes>
        <Route path="/" element={<Navigate to="/projects" replace />} />
        {/* <Route path="/login" element={<LoginPage />} /> */}
        <Route path="/projects" element={<Layout><ProjectListPage /></Layout>} />
        {/* <Route
          path="/projects"
          element={
            <AuthenticatedRoute>
              <ProjectListPage />
            </AuthenticatedRoute>
          }
        /> */}
        <Route path="/project/:id" element={<Layout><ProjectDetailsPage /></Layout>} />
        <Route path="/project/unit_group/:id" element={<Layout><UnitDetailsPage /></Layout>} />
        <Route path="/project/searchResults" element={<Layout><SearchResultPage /></Layout>} />
        <Route path="/crm/dashboard" element={<Layout><CrmDashboard /></Layout>} />
        <Route path="/crm/myCustomers" element={<Layout><MyCustomers /></Layout>} />
        <Route path="/crm/transfer_teammate" element={<Layout><TransferTeammate /></Layout>} />
        <Route path="/crm/send_to_call_center" element={<Layout><SendCallCenter /></Layout>} />
        <Route path="/crm/customer_details/:id" element={<Layout><CustomerDetails /></Layout>} />
        <Route path="/crm/customer_history/:id" element={<Layout><CustomerHistory /></Layout>} />
        <Route path="*" element={<Layout><NotFoundPage /></Layout>} />
      </Routes>
      <GlobalStyle />
    </BrowserRouter>
  );
}
