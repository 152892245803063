import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { projectsListResponse } from 'types/projectServiceSchemas';
import { request } from 'utils/request';
import { projectFilterSearchActions as actions } from '.';

/**
 * Projects request/response handler
 */
export function* getProjectFilterSearch(searchFilterInput) {
  yield delay(500);
  var params = Object.keys(searchFilterInput.payload)
    .filter(function (key) {
      return searchFilterInput.payload[key].length ? true : false;
    })
    .map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(searchFilterInput.payload[key]);
    })
    .join('&');

  const requestURL = `${process.env.REACT_APP_PROJECT_API_URL}/cp/project?${params}`;

  try {
    const projects: projectsListResponse = yield call(request, requestURL);

    if (projects?.items?.length > 0) {
      yield put(
        actions.projectsFilterSearchLoaded({
          items: projects?.items,
          total: projects?.total,
        }),
      );
    } else {
      yield put(actions.projectError('Something went wrong while fetching projects.'));
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.projectError('Projects not found.'));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.projectError('Failed to fetch projects.'));
    } else {
      yield put(actions.projectError('Something went wrong while fetching projects.'));
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* projectFilterSearchSaga() {
  // Watches for loadRepos actions and calls getProjectFilterSearch when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.loadProjectsFilterSearch.type, getProjectFilterSearch);
}
