import { CustomerDetailsCardMobile } from 'app/components/CustomerDetailsCardMobile';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import { ToastContainer, toast } from 'react-toastify';
import { getRequest, postRequest } from 'utils/request';
import { ReactComponent as Cross } from '../../assets/close.svg';
import { ReactComponent as ExclamationMark } from '../../assets/crm/No event scheduled.svg';
import { ReactComponent as Arrow } from '../../assets/crm/arrow_forward.svg';
import { ReactComponent as CheckCircle } from '../../assets/crm/checkboxfill.svg';
import { ReactComponent as SearchIconPurple } from '../../assets/crm/searchpurple.svg';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { useCustomersListSlice } from '../MyCustomers/slices/customerListslice';
import { selectCustomers } from '../MyCustomers/slices/customerListslice/selectors';
import { ReactComponent as UncheckCircle } from './../../assets/crm/chek unfill box.svg';

const useEffectOnMount = (effect: React.EffectCallback) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
};
export function SendCallCenter(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { actions } = useCustomersListSlice();

  const customerList = useSelector(selectCustomers);

  const [search, setSearch] = useState('');

  const [sendCallCanter, setSendCallCanter] = useState(false);

  const [searchedProject, setSearchedProject] = useState<any>([]);
  const [transferCusList, setTransferCusList] = useState<any>([]);
  const [inputText, setInputText] = useState(0);

  const [controller, setController] = useState<any>();

  const handleSendCallCenter = () => {
    setSendCallCanter(!sendCallCanter);
  };

  const [selectedProjects, setSelectedProjects] = useState<any>([]);
  const [recommendedProjects, setRecommendedProjects] = useState<any>([]);
  const [maxProjectmsg, setMaxProjectmsg] = useState(false);

  const selectProject = id => {
    if (selectedProjects.includes(id)) {
      var selectproj = [...selectedProjects];
      var index = selectproj.indexOf(id);
      if (index > -1) {
        selectproj.splice(index, 1);
      }
      setSelectedProjects(selectproj);
    } else {
      var projects = [...recommendedProjects];
      var selectproj = [...selectedProjects];
      var elementPos = projects
        .map(function (x) {
          return x?.id;
        })
        .indexOf(id);
      selectproj.push(projects[elementPos].id);
      setSelectedProjects([...selectproj]);
    }
  };

  const [optionSelected, setOptionSelected] = useState('');

  useEffect(() => {
    if (selectedProjects.length >= 2) {
      setMaxProjectmsg(true);
    } else {
      setMaxProjectmsg(false);
    }
  }, [selectedProjects]);

  useEffect(() => {
    let customerIdsArr = [...transferCusList];
    if (optionSelected === 'select_all') {
      customerList.map((item, index) => {
        if (item?.id && item?.phone_no?.length === 10) {
          if (!customerIdsArr.includes(item?.id)) {
            customerIdsArr.push(item?.id);
          }
        }
      });
      setTransferCusList(customerIdsArr);
      setInputText(customerIdsArr?.length);
    }
  }, [optionSelected]);

  const selectedCustomer = id => {
    let transferListTemp = [...transferCusList];
    if (transferListTemp.includes(id)) {
      setTransferCusList([...transferListTemp.filter(ele => id != ele)]);
      setInputText(transferCusList.length - 1);
    } else {
      transferListTemp.push(id);
      setInputText(transferCusList.length + 1);
      setTransferCusList([...transferListTemp]);
    }
  };

  const handleSearch = e => {
    if (controller) {
      controller.abort();
    }
    const localController = new AbortController();
    setController(localController);

    const lowerCase = e.target.value.toLowerCase();
    setSearch(lowerCase);
    if (!lowerCase) {
      setSearchedProject([]);
    } else {
      let requestUrl = `${process.env.REACT_APP_PROJECT_API_URL}/cp/project?project_name=${lowerCase}`;
      getRequest(requestUrl, localController.signal).then(res => {
        setSearchedProject([]);
        setSearchedProject(res?.data?.items);
      });
    }
  };

  useEffectOnMount(() => {
    if (customerList.length === 0) {
      dispatch(actions.loadCustomers({ status: 'NEW' }));
    }
    const requestUrl = `${process.env.REACT_APP_PROJECT_API_URL}/cp/project?is_top_project=true&page=1&size=10`;
    getRequest(requestUrl).then(res => {
      setRecommendedProjects(res?.data?.items);
    });
  });

  const [tick, setTick] = useState<any>([]);
  const tickProjects = id => {
    let tickListTemp = [...tick];
    if (tickListTemp.includes(id)) {
      setTick([...tickListTemp.filter(ele => id != ele)]);
    } else {
      setTick([...tick, id]);
    }
  };

  const bulkSendCallCanterApiCall = () => {
    const requestURL = `${process.env.REACT_APP_DDP_CRM_API_URL}/customer/send_to_call_center/`;
    let data = {
      customer_ids: transferCusList,
      call_center_project_ids: selectedProjects,
    };
    if (requestURL && data) {
      postRequest(requestURL, data).then(res => {
        const msg = res?.response?.data?.response?.message;
        if (res.status === 200) {
          toast.success('Customer Send To Call Center!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        } else if (res.status !== 200) {
          toast.error(msg, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
        return res;
      });
    }
    handleSendCallCenter();
    navigate(-1);
  };

  const clearValues = () => {
    setTransferCusList([]);
    setSelectedProjects([]);
    setTick([]);
    setInputText(0);
    navigate(-1);
  };

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={50000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="py-14">
        <div className="w-full fixed top-14 flex items-center bg-white shadow-sm py-4 px-5">
          <a onClick={clearValues}>
            <Arrow className="w-7 h-7 rotate-180" />
          </a>
          <h3 className="text-lg text-black font-medium ml-4">Select customers to transfer</h3>
        </div>
        <div className="my-20 m-4">
          <div
            className={
              search.length > 0
                ? 'w-full border border-purple-700 bg-white rounded-lg p-3'
                : 'w-full border border-gray-400 bg-white rounded-lg p-3'
            }
          >
            <label className="flex items-center">
              {search.length > 0 ? <SearchIconPurple /> : <SearchIcon className="w-5 h-5" />}
              <input
                className="w-full outline-none text-sm text-black bg-white ml-2"
                type="search"
                placeholder="Search by Customer Name"
                onChange={handleSearch}
              />
            </label>
          </div>
          <div className="flex items-center justify-between my-6">
            <h5 className="text-sm text-black font-medium">{inputText} Customer Selected</h5>
            <button
              onClick={() => setOptionSelected('select_all')}
              className="border-2 border-purple-50 bg-white rounded-md py-2.5 px-5"
            >
              <h6 className="text-xs text-black font-medium">Select All</h6>
            </button>
          </div>
          {customerList
            ?.filter(item =>
              item?.customer_name
                ? item?.customer_name.toLowerCase().includes(search.toLowerCase())
                : '',
            )
            .map((sub_item, sub_index) => (
              <CustomerDetailsCardMobile
                data={sub_item}
                multiselect={true}
                selectedBar={2}
                onSelect={selectedCustomer}
                phoneNotComplete={sub_item?.phone_no?.length !== 10}
                isSelected={
                  transferCusList.filter(data => data === sub_item.id).length > 0 ? true : false
                }
                type={optionSelected}
              />
            ))}
        </div>
      </div>
      <div className="w-full fixed bottom-0 flex items-center justify-between bg-white rounded-lg shadow-xl pt-4 pb-6 px-4">
        <button onClick={clearValues} className="w-24 border border-red-700 rounded-md p-2.5">
          <h5 className="text-sm text-red-700 font-normal">Cancel</h5>
        </button>
        <button
          onClick={handleSendCallCenter}
          className="w-44 border border-purple-700 bg-primary rounded-md p-2.5"
        >
          <h5 className="text-sm text-white font-normal">Send to Call Center</h5>
        </button>
        <BottomSheet open={sendCallCanter}>
          <div className="p-4">
            <div>
              <div className="flex flex-1 justify-between">
                <h4 className="text-base text-black font-medium">Send to Call Center</h4>
                <button onClick={handleSendCallCenter}>
                  <Cross />
                </button>
              </div>
              <p className="text-xs text-gray-600 font-normal">
                Select Projects (Max 2) to send to Call Centre:
              </p>
              <h6 className="text-xs text-gray-600 font-semibold mt-6 mb-4">
                Choose Project
                <span className="text-red-700 font-medium">*</span>
              </h6>
              <div className="flex items-center border bg-gray-200 rounded-md py-3 px-5">
                <span>
                  <SearchIcon className="w-4 h-4" />
                </span>
                <input
                  placeholder="Search project"
                  className="w-full outline-none text-xs text-black font-normal border-gray-200 bg-gray-200 ml-2.5"
                  onChange={handleSearch}
                />
              </div>
              {maxProjectmsg ? (
                <div className="flex items-center mt-3">
                  <ExclamationMark />
                  <h6 className="text-xs text-black font-medium ml-2">
                    You have selected the maximum number of projects
                  </h6>
                </div>
              ) : (
                <></>
              )}
              <h6 className="text-xs text-gray-600 font-medium mt-3 mb-4">Recommended Projects:</h6>
              <div className="h-72 overflow-y-auto">
                {searchedProject?.length
                  ? searchedProject?.map((sub_item, sub_index) => {
                      return (
                        <div key={`teammate-${sub_index}`} className="flex items-center mb-6">
                          <button
                            onClick={() => {
                              selectProject(sub_item?.id);
                              tickProjects(sub_item?.id);
                            }}
                          >
                            {tick.includes(sub_item?.id) ? (
                              <CheckCircle className="w-6 h-6" />
                            ) : (
                              <UncheckCircle className="w-6 h-6" />
                            )}
                          </button>
                          <h5 className="text-sm text-black/80 font-normal ml-2.5">
                            {sub_item?.name}
                          </h5>
                        </div>
                      );
                    })
                  : recommendedProjects?.map((sub_item, sub_index) => {
                      return (
                        <div key={`teammate-${sub_index}`} className="flex items-center mb-6">
                          <button
                            onClick={() => {
                              selectProject(sub_item?.id);
                              tickProjects(sub_item?.id);
                            }}
                          >
                            {tick.includes(sub_item?.id) ? (
                              <CheckCircle className="w-6 h-6" />
                            ) : (
                              <UncheckCircle className="w-6 h-6" />
                            )}
                          </button>
                          <h5 className="text-sm text-black/80 font-normal ml-2.5">
                            {sub_item?.name}
                          </h5>
                        </div>
                      );
                    })}
              </div>
            </div>
            <button
              onClick={bulkSendCallCanterApiCall}
              className="w-full border border-purple-700 bg-primary rounded-lg p-4"
            >
              <h4 className="text-sm text-white font-medium">Send to Call Centre</h4>
            </button>
          </div>
        </BottomSheet>
      </div>
    </div>
  );
}
