import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { masterServiceSchemas } from 'types/mastersServiceSchemas';
import { request } from 'utils/request';
import { citiesListActions as actions } from '.';

/**
 * cities request/response handler
 */
export function* getcitiesList() {
  yield delay(500);

  const requestURL = `${process.env.REACT_APP_MASTER_API_URL}/city/`;
  try {
    const cities: masterServiceSchemas['CityResponse'][] = yield call(request, requestURL);

    if (cities) {
      yield put(actions.citiesLoaded(cities));
    } else {
      yield put(actions.citiesError('Something went wrong while fetching cities.'));
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.citiesError('cities not found.'));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.citiesError('Failed to fetch cities.'));
    } else {
      yield put(actions.citiesError('Something went wrong while fetching cities.'));
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* citiesListSaga() {
  // Watches for loadRepos actions and calls getcitiesList when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.loadCities.type, getcitiesList);
}
