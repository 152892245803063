import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import 'react-spring-bottom-sheet/dist/style.css';
import { ReactComponent as Arrow } from '../../assets/crm/arrow_forward.svg';
import { useCustomerHistorylice } from '../MyCustomers/slices/customerHistorySlice';
import { selectcustomerHistory } from '../MyCustomers/slices/customerHistorySlice/selectors';
import { ReactComponent as FollowUp } from './../../assets/crm/Follow Up.svg';
import { ReactComponent as Meeting } from './../../assets/crm/Meeting.svg';
import { ReactComponent as SiteVisit } from './../../assets/crm/Site Visit.svg';
import { ReactComponent as AgentAssigned } from './../../assets/history icon/Agent Assigned.svg';
import { ReactComponent as BookingVerified } from './../../assets/history icon/Booking verified.svg';
import { ReactComponent as CustomerCreated } from './../../assets/history icon/Customer Created.svg';
import { ReactComponent as CustomerDetails } from './../../assets/history icon/Customer Details Updated.svg';
import { ReactComponent as Failed } from './../../assets/history icon/Failed.svg';
import { ReactComponent as MovedtoBooking } from './../../assets/history icon/Moved to Bookings.svg';
import { ReactComponent as MovedtoInterested } from './../../assets/history icon/Moved to Interested.svg';
import { ReactComponent as NotesAdded } from './../../assets/history icon/Notes added to customer.svg';
import { ReactComponent as ProjectsTagged } from './../../assets/history icon/Projects Tagged.svg';
import { ReactComponent as Recovery } from './../../assets/history icon/Recovery.svg';
import { ReactComponent as Sent } from './../../assets/history icon/Sent.svg';
import { ReactComponent as BookingRequestCreated } from './../../assets/history icon/booking request created.svg';

const useEffectOnMount = (effect: React.EffectCallback) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
};
export function CustomerHistory() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { actions: customerhistoryactions } = useCustomerHistorylice();

  useEffectOnMount(() => {
    dispatch(customerhistoryactions.loadCustomerHistory(id));
  });

  const customerHistory = useSelector(selectcustomerHistory);

  const checkDate = data => {
    var dateIndetifier = '';
    let tempData: any = [];
    data.forEach(history => {
      let tempHistory: any = { ...history };
      if (!dateIndetifier || tempHistory.date < dateIndetifier) {
        tempHistory.renderDate = true;
        dateIndetifier = tempHistory.date;
      } else {
        tempHistory.renderDate = false;
      }
      tempData.push(tempHistory);
    });
    return tempData;
  };

  const compare = (a, b) => {
    if (a.date > b.date) {
      return -1;
    }
    if (a.date < b.date) {
      return 1;
    }
    return 0;
  };

  useEffect(() => {
    let data = [...customerHistory];
    data.sort(compare);
    let updatedData: any = [...checkDate([...data])];
    setHistoryCompletedata([...updatedData]);
  }, [customerHistory]);

  const [historyCompletedata, setHistoryCompletedata] = useState<any>([]);

  const eventIcon = {
    'FollowUp Scheduled': {
      icon: (
        <div className="border border-blue-50 bg-blue-50 rounded-full w-9 h-9 p-2">
          <FollowUp className="-ml-0.5" />
        </div>
      ),
    },
    'FollowUp Rescheduled': {
      icon: (
        <div className="border border-blue-50 bg-blue-50 rounded-full w-9 h-9 p-2">
          <FollowUp className="-ml-0.5" />
        </div>
      ),
    },
    'FollowUp Done': {
      icon: (
        <div className="border border-blue-50 bg-blue-50 rounded-full w-9 h-9 p-2">
          <FollowUp className="-ml-0.5" />
        </div>
      ),
    },
    'FollowUp Cancelled': {
      icon: (
        <div className="border border-blue-50 bg-blue-50 rounded-full w-9 h-9 p-2">
          <FollowUp className="-ml-0.5" />
        </div>
      ),
    },
    'FollowUp Missed': {
      icon: (
        <div className="border border-blue-50 bg-blue-50 rounded-full w-9 h-9 p-2">
          <FollowUp className="-ml-0.5" />
        </div>
      ),
    },
    'Meeting Scheduled': {
      icon: (
        <div className="border border-green-50 bg-green-50 rounded-full w-9 h-9 p-2">
          <Meeting />
        </div>
      ),
    },
    'Meeting Cancelled': {
      icon: (
        <div className="border border-green-50 bg-green-50 rounded-full w-9 h-9 p-2">
          <Meeting />
        </div>
      ),
    },
    'Meeting Done': {
      icon: (
        <div className="border border-green-50 bg-green-50 rounded-full w-9 h-9 p-2">
          <Meeting />
        </div>
      ),
    },
    'Meeting Missed': {
      icon: (
        <div className="border border-green-50 bg-green-50 rounded-full w-9 h-9 p-2">
          <Meeting />
        </div>
      ),
    },
    'Site Visit Scheduled': {
      icon: (
        <div className="border border-pink-50 bg-pink-50 rounded-full w-9 h-9 p-2">
          <SiteVisit />
        </div>
      ),
    },
    'Site Visit Rescheduled': {
      icon: (
        <div className="border border-pink-50 bg-pink-50 rounded-full w-9 h-9 p-2">
          <SiteVisit />
        </div>
      ),
    },
    'Site Visit Done': {
      icon: (
        <div className="border border-pink-50 bg-pink-50 rounded-full w-9 h-9 p-2">
          <SiteVisit />
        </div>
      ),
    },
    'Site Visit Cancelled': {
      icon: (
        <div className="border border-pink-50 bg-pink-50 rounded-full w-9 h-9 p-2">
          <SiteVisit />
        </div>
      ),
    },
    'Site Visit Missed': {
      icon: (
        <div className="border border-pink-50 bg-pink-50 rounded-full w-9 h-9 p-2">
          <SiteVisit />
        </div>
      ),
    },
    'Agent Assigned': {
      icon: (
        <div className="border border-green-50 bg-green-50 rounded-full w-9 h-9 p-2">
          <AgentAssigned />
        </div>
      ),
    },
    'Booking Verified': {
      icon: (
        <div className="border border-green-50 bg-green-50 rounded-full w-9 h-9 p-2">
          <BookingVerified />
        </div>
      ),
    },
    'Booking Request Created': {
      icon: (
        <div className="border border-blue-50 bg-blue-50 rounded-full w-9 h-9 p-2">
          <BookingRequestCreated />
        </div>
      ),
    },
    'Customer Details Updated': {
      icon: (
        <div className="border border-blue-50 bg-blue-50 rounded-full w-9 h-9 p-2">
          <CustomerDetails />
        </div>
      ),
    },
    'Customer Created': {
      icon: (
        <div className="border border-green-50 bg-green-50 rounded-full w-9 h-9 p-2">
          <CustomerCreated />
        </div>
      ),
    },
    Failed: {
      icon: (
        <div className="border border-red-50 bg-red-50 rounded-full w-9 h-9 p-2">
          <Failed />
        </div>
      ),
    },
    'Moved to Failed': {
      icon: (
        <div className="border border-red-50 bg-red-50 rounded-full w-9 h-9 p-2">
          <Failed />
        </div>
      ),
    },
    'Customer Marked as Failed': {
      icon: (
        <div className="border border-red-50 bg-red-50 rounded-full w-9 h-9 p-2">
          <Failed />
        </div>
      ),
    },
    'Moved to Booking Done': {
      icon: (
        <div className="border border-purple-50 bg-purple-50 rounded-full w-9 h-9 p-2">
          <MovedtoBooking />
        </div>
      ),
    },
    'Moved to Interested': {
      icon: (
        <div className="border border-purple-50 bg-purple-50 rounded-full w-9 h-9 p-2">
          <MovedtoInterested />
        </div>
      ),
    },
    'New Notes Added': {
      icon: (
        <div className="border border-purple-50 bg-purple-50 rounded-full w-9 h-9 p-2">
          <NotesAdded />
        </div>
      ),
    },
    'Moved to New': {
      icon: (
        <div className="border border-orange-50 bg-orange-50 rounded-full w-9 h-9 p-2">
          <Recovery />
        </div>
      ),
    },
    'Customer Recovered Successfully': {
      icon: (
        <div className="border border-orange-50 bg-orange-50 rounded-full w-9 h-9 p-2">
          <Recovery />
        </div>
      ),
    },
    'Sent to Call Centre': {
      icon: (
        <div className="border border-purple-50 bg-purple-50 rounded-full w-9 h-9 p-2">
          <Sent />
        </div>
      ),
    },
    Transfer: {
      icon: (
        <div className="border border-purple-50 bg-purple-50 rounded-full w-9 h-9 p-2">
          <Sent />
        </div>
      ),
    },
    'Sent to ACP Call Centre': {
      icon: (
        <div className="border border-purple-50 bg-purple-50 rounded-full w-9 h-9 p-2">
          <Sent />
        </div>
      ),
    },
    'New Project Tagged': {
      icon: (
        <div className="border border-green-50 bg-green-50 rounded-full w-9 h-9 p-2">
          <ProjectsTagged />
        </div>
      ),
    },
  };

  return (
    <div>
      <div className="py-14">
        <div className="w-full fixed top-14 flex items-center bg-white shadow-sm py-4 px-5">
          <button onClick={() => navigate(`/crm/customer_details/${id}`)}>
            <Arrow className="w-7 h-7 rotate-180" />
          </button>
          <h3 className="text-lg text-black font-medium ml-4">Customer History</h3>
        </div>
        <div className="mt-20 mx-4">
          <div className="mt-6">
            {historyCompletedata.map((item, index) => {
              return (
                <div key={`history-${index}`}>
                  {item?.renderDate ? (
                    <>
                      <h4 className="text-base text-black font-semibold">
                        {moment(item?.date).utcOffset('+05:30').format('Do MMM YYYY')}
                      </h4>
                      <hr className="border border-gray-300 mt-2 mb-5" />
                    </>
                  ) : (
                    <></>
                  )}
                  <div className="flex">
                    {item?.label ? (
                      eventIcon[item?.label] ? (
                        eventIcon[item?.label].icon
                      ) : (
                        <></>
                      )
                    ) : (
                      <></>
                    )}
                    {/* <hr className="rotate-180 bg-gray-200 mt-4 h-7 w-0.5" /> */}
                    <div className="ml-2.5">
                      <h6 className="text-xs text-black font-medium">{item?.label}</h6>
                      <p className="text-[10px] text-gray-700 font-normal">
                        {moment(item?.time, 'hh:mm:ss').utcOffset('+05:30').format('hh:mm A')}
                      </p>
                      <p className="text-[10px] text-gray-700 font-normal mt-2.5 mb-5">
                        {item?.description}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
