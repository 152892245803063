import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.projectDetails || initialState;

export const selectProjectDetails = createSelector(
  [selectDomain],
  projectsDetailsState => projectsDetailsState.projectDetails,
);

export const selectProjectDetailsLoading = createSelector(
  [selectDomain],
  projectsDetailsState => projectsDetailsState.loading,
);

export const selectProjectDetailsError = createSelector(
  [selectDomain],
  projectsDetailsState => projectsDetailsState.error,
);

export const selectProjectDetailsErrorMsg = createSelector(
  [selectDomain],
  projectsDetailsState => projectsDetailsState.errorMsg,
);
