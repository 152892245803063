import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.customersList || initialState;

export const selectCustomers = createSelector(
  [selectDomain],
  customersListState => customersListState.customers,
);

export const selectCustomersTotal = createSelector(
  [selectDomain],
  customersListState => customersListState.customersTotal,
);

export const selectCustomersPage = createSelector(
  [selectDomain],
  customersListState => customersListState.customersPage,
);

export const selectCustomersSize = createSelector(
  [selectDomain],
  customersListState => customersListState.customersSize,
);

export const selectLoading = createSelector(
  [selectDomain],
  customersListState => customersListState.loading,
);

export const selectError = createSelector(
  [selectDomain],
  customersListState => customersListState.error,
);

export const selectErrorMsg = createSelector(
  [selectDomain],
  customersListState => customersListState.errorMsg,
);
