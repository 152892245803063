import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectDomain = (state: RootState) => state.auth || initialState;

export const selectToken = createSelector([selectDomain], authState => authState.access_token);

export const selectUserId = createSelector([selectDomain], authState => authState.user_id);

export const selectAuthLoading = createSelector([selectDomain], authState => authState.loading);

export const selectAuthError = createSelector([selectDomain], authState => authState.error);

export const selectAuthErrorMsg = createSelector([selectDomain], authState => authState.errorMsg);

export const selectOtpSent = createSelector([selectDomain], authState => authState.otpSent);
