import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { crmServiceSchemas } from 'types/crmServiceSchemas';
import { getRequest } from 'utils/request';
import { customerListActions as actions } from '.';

/**
 * Projects request/response handler
 */
export function* getCustomerHistory(id) {
  yield delay(500);
  const requestURL = `${process.env.REACT_APP_DDP_CRM_API_URL}/customer/${id?.payload}/history/`;
  try {
    const { data } = yield call(getRequest, requestURL);
    const customerHistory: crmServiceSchemas['CustomerHistoryResponse'][] = data.response;
    if (customerHistory) {
      yield put(actions.customerHistoryLoaded(customerHistory));
    } else {
      yield put(
        actions.customerHistoryError('Something went wrong while fetching project History.'),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.customerHistoryError('Project not found.'));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.customerHistoryError('Failed to fetch project.'));
    } else {
      yield put(
        actions.customerHistoryError('Something went wrong while fetching project History.'),
      );
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* customerHistorySaga() {
  // Watches for loadRepos actions and calls getCustomerHistory when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.loadCustomerHistory.type, getCustomerHistory);
}
