import { PayloadAction } from '@reduxjs/toolkit';
import { projectServiceSchemas } from 'types/projectServiceSchemas';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { unitDetailsSaga } from './saga';
import { UnitDetailsState } from './types';

export const initialState: UnitDetailsState = {
  loading: true,
  error: false,
  errorMsg: '',
  unitDetails: {},
};

const slice = createSlice({
  name: 'unitDetails',
  initialState,
  reducers: {
    loadUnitDetails(state, id) {
      state.loading = true;
      state.error = false;
      state.unitDetails = {};
    },
    unitDetailsLoaded(
      state,
      action: PayloadAction<projectServiceSchemas['UnitGroupComplexResponse']>,
    ) {
      const unitDetails = action.payload;
      state.unitDetails = unitDetails;
      state.loading = false;
    },
    unitDetailsError(state, action: PayloadAction<string>) {
      state.error = true;
      state.errorMsg = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: unitListActions, reducer } = slice;

export const useUnitDetailsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: unitDetailsSaga });
  return { actions: slice.actions };
};
