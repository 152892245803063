import { PayloadAction } from '@reduxjs/toolkit';
import { crmServiceSchemas } from 'types/crmServiceSchemas';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { customersEventSummarySaga } from './saga';
import { CustomersEventSummaryState } from './types';

export const initialState: CustomersEventSummaryState = {
  loading: true,
  error: false,
  errorMsg: '',
  customerEvents: {},
  filter_request: {},
};

const slice = createSlice({
  name: 'customerEventSummary',
  initialState,
  reducers: {
    loadCustomersEventSummary(state, action) {
      state.loading = true;
      state.error = false;
      state.customerEvents = {};
      const filter_obj = action?.payload;
      state.filter_request = filter_obj;
    },
    customersLoadedEventSummary(
      state,
      action: PayloadAction<{
        items: crmServiceSchemas['EventSummaryResponse'];
      }>,
    ) {
      const customers = action.payload;
      state.customerEvents = customers?.items;
      state.loading = false;
    },
    customersError(state, action: PayloadAction<string>) {
      state.error = true;
      state.errorMsg = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: customersEventSummaryActions, reducer } = slice;

export const useCustomersEventSummarySlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: customersEventSummarySaga });
  return { actions: slice.actions };
};
