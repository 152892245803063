import { PayloadAction } from '@reduxjs/toolkit';
import { projectServiceSchemas } from 'types/projectServiceSchemas';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { unitTypesListSaga } from './saga';
import { UnitTypeListState } from './types';

export const initialState: UnitTypeListState = {
  loading: true,
  error: false,
  errorMsg: '',
  unitTypes: [],
  unitTypesTotal: 0,
};

const slice = createSlice({
  name: 'unitType',
  initialState,
  reducers: {
    loadUnitType(state) {
      state.loading = true;
      state.error = false;
      state.unitTypes = [];
    },
    unitTypesLoaded(
      state,
      action: PayloadAction<{
        items: projectServiceSchemas['UnitTypeResponse'][];
        total: number;
      }>,
    ) {
      const unitTypes = action.payload;
      state.unitTypes = unitTypes?.items;
      state.unitTypesTotal = unitTypes?.total;
      state.loading = false;
    },
    unitTypeError(state, action: PayloadAction<string>) {
      state.error = true;
      state.errorMsg = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: unitTypeListActions, reducer } = slice;

export const useUnitTypeListSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: unitTypesListSaga });
  return { actions: slice.actions };
};
