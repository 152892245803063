import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.unitConfiguration || initialState;

export const selectUnitConfigurations = createSelector(
  [selectDomain],
  unitConfigurationsListState => unitConfigurationsListState.unitConfigurations,
);

export const selectUnitConfigurationsTotal = createSelector(
  [selectDomain],
  unitConfigurationsListState => unitConfigurationsListState.unitConfigurationsTotal,
);

export const selectUnitConfigurationsLoading = createSelector(
  [selectDomain],
  unitConfigurationsListState => unitConfigurationsListState.loading,
);

export const selectError = createSelector(
  [selectDomain],
  unitConfigurationsListState => unitConfigurationsListState.error,
);

export const selectErrorMsg = createSelector(
  [selectDomain],
  unitConfigurationsListState => unitConfigurationsListState.errorMsg,
);
