import { PayloadAction } from '@reduxjs/toolkit';
import { cpServiceSchemas } from 'types/cpSchemaService';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { channelPartnersListSaga } from './saga';
import { channelPartnersListState } from './types';

export const initialState: channelPartnersListState = {
  loading: true,
  error: false,
  errorMsg: '',
  channelPartners: [],
  channelPartnersTotal: 0,
  filter_obj: '',
};

const slice = createSlice({
  name: 'channelPartnerList',
  initialState,
  reducers: {
    loadchannelPartners(state, action) {
      state.loading = true;
      state.error = false;
      state.channelPartners = [];
      const filter_obj = action?.payload;
      state.filter_obj = filter_obj;
    },
    channelPartnersLoaded(
      state,
      action: PayloadAction<{
        items: cpServiceSchemas['CpResponse'][];
        total: number;
      }>,
    ) {
      const channelPartners = action.payload;
      state.channelPartners = channelPartners?.items;
      state.channelPartnersTotal = channelPartners?.total;
      state.loading = false;
    },
    channelPartnersError(state, action: PayloadAction<string>) {
      state.error = true;
      state.errorMsg = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: channelPartnersListActions, reducer } = slice;

export const useChannelPartnersListSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: channelPartnersListSaga });
  return { actions: slice.actions };
};
