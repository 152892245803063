import { PayloadAction } from '@reduxjs/toolkit';
import { crmServiceSchemas } from 'types/crmServiceSchemas';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { customerLeadBookingListSaga } from './saga';
import { CustomerLeadBookingState } from './types';

export const initialState: CustomerLeadBookingState = {
  loading: true,
  error: false,
  errorMsg: '',
  customerLead: [],
  customerLeadTotal: 0,
  filter_obj: {},
  customerLeadPage: 0,
  customerLeadSize: 50,
};

const slice = createSlice({
  name: 'customerLeadBooking',
  initialState,
  reducers: {
    loadCustomerLeadBooking(state, action) {
      state.error = false;
      state.customerLead = [];
      const filter_object = action?.payload;
      state.filter_obj = filter_object;

      state.loading = true;
    },
    customerLeadLoadedBooking(
      state,
      action: PayloadAction<{
        items: crmServiceSchemas['CustomerLeadBookingResponse'][];
        total: number;
        page: number;
        size: number;
      }>,
    ) {
      const customerLead = action.payload;

      state.customerLead = customerLead?.items;
      state.loading = false;

      state.customerLeadTotal = customerLead?.total;
      state.customerLeadPage = customerLead?.page;
      state.customerLeadSize = customerLead?.size;
    },
    customerLeadBookingError(state, action: PayloadAction<string>) {
      state.error = true;
      state.errorMsg = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: customerLeadListActions, reducer } = slice;

export const useCustomerLeadBookingSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: customerLeadBookingListSaga });
  return { actions: slice.actions };
};
