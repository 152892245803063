import { PayloadAction } from '@reduxjs/toolkit';
import { crmServiceSchemas } from 'types/crmServiceSchemas';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { customerHistorySaga } from './saga';
import { CustomerHistoryState } from './types';

export const initialState: CustomerHistoryState = {
  loading: true,
  error: false,
  errorMsg: '',
  customerHistory: [],
};

const slice = createSlice({
  name: 'customerHistory',
  initialState,
  reducers: {
    loadCustomerHistory(state, id) {
      state.loading = true;
      state.error = false;
      state.customerHistory = [];
    },

    customerHistoryLoaded(
      state,
      action: PayloadAction<crmServiceSchemas['CustomerHistoryResponse'][]>,
    ) {
      const customerHistory = action.payload;
      state.customerHistory = customerHistory;
      state.loading = false;
    },
    customerHistoryError(state, action: PayloadAction<string>) {
      state.error = true;
      state.errorMsg = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: customerListActions, reducer } = slice;

export const useCustomerHistorylice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: customerHistorySaga });
  return { actions: slice.actions };
};
