import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.customerDetails || initialState;

export const selectcustomerDetails = createSelector(
  [selectDomain],
  customerDetailsState => customerDetailsState.customerDetails,
);

export const selectcustomerDetailsLoading = createSelector(
  [selectDomain],
  customerDetailsState => customerDetailsState.loading,
);

export const selectcustomerDetailsError = createSelector(
  [selectDomain],
  customerDetailsState => customerDetailsState.error,
);

export const selectcustomerDetailsErrorMsg = createSelector(
  [selectDomain],
  customerDetailsState => customerDetailsState.errorMsg,
);
