import { useCustomersEventSlice } from 'app/pages/CrmDashboard/slices/customersEventslice';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { useDispatch } from 'react-redux';
import { getRequest } from 'utils/request';
import {
  ReactComponent as CalenderDot,
  ReactComponent as DotBlue,
} from './../../assets/crm/dotBlue.svg';
import { ReactComponent as DotGreen } from './../../assets/crm/dotGreen.svg';
import { ReactComponent as DotOrange } from './../../assets/crm/dotOrange.svg';
import './styles.css';
// import 'react-calendar/dist/Calendar.css';

export function CrmCalendar() {
  const [value, setValue] = useState(new Date());
  const [calenderEvents, setCalenderEvents] = useState<any>([]);
  const [calenderDates, setCalenderDates] = useState<any>([]);

  const selectedDate = moment(value).format('YYYY-MM-DD');
  const nexDate = moment(value).add(1, 'days').format('YYYY-MM-DD');

  const dispatch = useDispatch();
  const { actions: customerseventactions } = useCustomersEventSlice();

  function onChange(nextValue) {
    setValue(nextValue);
  }
  useEffect(() => {
    const requestURL = `${
      process.env.REACT_APP_DDP_CRM_API_URL
    }/customer_event/?start_date=${moment(value)
      .add(-30, 'days')
      .format('YYYY-MM-DD')}&end_date=${moment(value)
      .add(30, 'days')
      .format('YYYY-MM-DD')}&size=100`;
    getRequest(requestURL).then(res => {
      const Events = res?.data?.response?.items?.filter(item => item?.status != 'CANCELLED');
      if (Events) {
        setCalenderEvents([...calenderEvents, ...Events]);
      }
    });

    dispatch(
      customerseventactions.loadCustomersEvent({
        start_date: selectedDate,
        end_date: nexDate,
        timedelta_seconds: 19800,
      }),
    );
  }, [value]);

  useEffect(() => {
    let datesarr: any = [];
    if (calenderEvents.length) {
      calenderEvents?.map(item => {
        if (item?.scheduled_time) {
          datesarr.push({
            date: moment.utc(item?.scheduled_time).utcOffset('+5:30').toDate(),
            event_type: item?.event_type,
          });
        }
      });
    }
    setCalenderDates(datesarr);
  }, [calenderEvents]);

  interface Event {
    date: string;
    event_type: string;
  }

  const tileContent = ({ date }: { date: Date }): JSX.Element | null => {
    const events: Event[] = calenderDates.filter(
      event =>
        event.date.getDate() === date.getDate() &&
        event.date.getMonth() === date.getMonth() &&
        event.date.getFullYear() === date.getFullYear(),
    );
    if (events.length > 0) {
      const result: Event[] = Array.from(
        new Set(
          events
            .map(event => new Date(event.date).getDate() + '==' + event.event_type)
            .filter((event, index, array) => array.indexOf(event) === index),
        ),
      ).map(combinedEvent => {
        const [date, event_type] = combinedEvent.split('==');
        return { date, event_type };
      });

      return (
        <div className="flex justify-center space-x-1">
          {result.map(item =>
            item?.event_type === 'FOLLOW_UP' ? (
              <DotGreen />
            ) : item?.event_type === 'SITE_VISIT' ? (
              <CalenderDot />
            ) : item?.event_type === 'MEETING' ? (
              <DotOrange />
            ) : (
              ''
            ),
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      <Calendar
        onChange={onChange}
        value={value}
        calendarType={'Hebrew'}
        showNeighboringMonth={false}
        tileContent={tileContent}
        prevLabel=""
        prev2Label=""
        next2Label=""
        nextLabel=""
        className="xl:px-8 md:px-4 px-2"
      />
      <div className="xl:w-72 lg:w-64 w-72 relative grid grid-cols-3 items-center -mt-[30px] 2xl:ml-20 xl:ml-14 md:ml-8 sm:ml-10 ml-10">
        <div className="flex items-center">
          <DotBlue />
          <p className="text-xs text-gray-500 font-normal ml-1">Site Visits</p>
        </div>
        <div className="flex items-center">
          <DotGreen />
          <p className="text-xs text-gray-500 font-normal ml-1">Follow ups</p>
        </div>
        <div className="flex items-center">
          <DotOrange />
          <p className="text-xs text-gray-500 font-normal ml-1">Meeting</p>
        </div>
      </div>
    </div>
  );
}
