import { PayloadAction } from '@reduxjs/toolkit';
import { projectServiceSchemas } from 'types/projectServiceSchemas';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { amenitiesListSaga } from './saga';
import { AmenitiesListState } from './types';

export const initialState: AmenitiesListState = {
  loading: true,
  error: false,
  errorMsg: '',
  amenities: [],
  amenitiesTotal: 0,
};

const slice = createSlice({
  name: 'amenities',
  initialState,
  reducers: {
    loadAmenities(state) {
      state.loading = true;
      state.error = false;
      state.amenities = [];
    },
    amenitiesLoaded(
      state,
      action: PayloadAction<{
        items: projectServiceSchemas['AmenitiesMasterResponse'][];
        total: number;
      }>,
    ) {
      const amenities = action.payload;
      state.amenities = amenities?.items;
      state.amenitiesTotal = amenities?.total;
      state.loading = false;
    },
    amenitiesError(state, action: PayloadAction<string>) {
      state.error = true;
      state.errorMsg = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: amenitiesListActions, reducer } = slice;

export const useAmenitiesListSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: amenitiesListSaga });
  return { actions: slice.actions };
};
