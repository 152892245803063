import { PayloadAction } from '@reduxjs/toolkit';
import { projectServiceSchemas } from 'types/projectServiceSchemas';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { unitConfigurationsListSaga } from './saga';
import { UnitConfigurationListState } from './types';

export const initialState: UnitConfigurationListState = {
  loading: true,
  error: false,
  errorMsg: '',
  unitConfigurations: [],
  unitConfigurationsTotal: 0,
};

const slice = createSlice({
  name: 'unitConfiguration',
  initialState,
  reducers: {
    loadUnitConfiguration(state) {
      state.loading = true;
      state.error = false;
      state.unitConfigurations = [];
    },
    unitConfigurationsLoaded(
      state,
      action: PayloadAction<{
        items: projectServiceSchemas['UnitConfigurationResponse'][];
        total: number;
      }>,
    ) {
      const unitConfigurations = action.payload;
      state.unitConfigurations = unitConfigurations?.items;
      state.unitConfigurationsTotal = unitConfigurations?.total;
      state.loading = false;
    },
    unitConfigurationError(state, action: PayloadAction<string>) {
      state.error = true;
      state.errorMsg = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: unitConfigurationListActions, reducer } = slice;

export const useUnitConfigurationListSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: unitConfigurationsListSaga });
  return { actions: slice.actions };
};
