import { selectUnitConfigurations } from 'app/pages/ProjectListPage/Projects/ProjectList/slices/untiConfigurationSlice/selectors';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import { toast } from 'react-toastify';
import { getRequest, postRequest } from 'utils/request';
import { ReactComponent as Cross } from '../../../../assets/close.svg';
import { ReactComponent as TransferCallCenter } from '../../../../assets/crm/Send to Call Centre.svg';
import { ReactComponent as TransferTeamMate } from '../../../../assets/crm/Transfer to Teammate.svg';
import { ReactComponent as Upload } from '../../../../assets/crm/bulk uplod bottam shheet icon.svg';
import { ReactComponent as BulkUpload } from '../../../../assets/crm/bulkuploadforresponsive.svg';
import { ReactComponent as FilterBlack } from '../../../../assets/crm/filter_alt.svg';
import { ReactComponent as FilterIcon } from '../../../../assets/filter.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/search.svg';
import { allFilters } from '../../filters';

const useEffectOnMount = (effect: React.EffectCallback) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
};

export function MobileFilters(props) {
  const navigate = useNavigate();
  const [filters, setFilters] = useState<any[]>(allFilters);
  const [cpBusinessProfile, setCpBusinessProfile] = useState<any>();

  useEffect(() => {
    props.setAppliedCustomerFilters(filters);
  }, [filters]);

  useEffectOnMount(() => {
    const fetchCPBusinessURL = `${process.env.REACT_APP_CP_API_URL}/cp/business/profile/`;
    getRequest(fetchCPBusinessURL).then(res => {
      setCpBusinessProfile(res?.data?.response);
    });
  });

  const unitConfigurations = useSelector(selectUnitConfigurations);

  useEffect(() => {
    const bookingFilters = [...allFilters];
    const bookingFiltersWithUnitConfigurations = bookingFilters.map(item => {
      if (item.label === 'Unit Configuration') {
        return {
          ...item,
          option: unitConfigurations.map(confItem => {
            return { subLabel: confItem.name, selected: false, id: confItem.id };
          }),
        };
      } else {
        return { ...item };
      }
    });
    setFilters(bookingFiltersWithUnitConfigurations);
  }, [unitConfigurations]);

  const updateFilterSelections = (item, label) => {
    let toBeUpdatedFilter = [...filters];
    let mainFilterIndex = toBeUpdatedFilter.findIndex(filterItem => filterItem.value === item);
    let subFilterIndex = toBeUpdatedFilter[mainFilterIndex]?.option.findIndex(
      subFilterItem => subFilterItem.subLabel === label,
    );
    let toBeUpdatedFilterOptions = toBeUpdatedFilter[mainFilterIndex]?.option;
    const isFilterMultiSelect = toBeUpdatedFilter[mainFilterIndex]?.multiselect;

    const updatedFilterOptions = toBeUpdatedFilterOptions.map((toBeUpdatedFilterOption, index) => {
      if (!isFilterMultiSelect) {
        if (index === subFilterIndex) {
          if (toBeUpdatedFilterOption?.selected) {
            return { ...toBeUpdatedFilterOption, selected: false };
          }
          return { ...toBeUpdatedFilterOption, selected: true };
        }
        return { ...toBeUpdatedFilterOption, selected: false };
      } else {
        if (index === subFilterIndex) {
          return {
            ...toBeUpdatedFilterOption,
            selected: !toBeUpdatedFilterOption.selected,
          };
        }
        return {
          ...toBeUpdatedFilterOption,
          selected: toBeUpdatedFilterOption.selected,
        };
      }
    });

    toBeUpdatedFilter[mainFilterIndex] = {
      ...toBeUpdatedFilter[mainFilterIndex],
      option: updatedFilterOptions,
    };

    setFilters(toBeUpdatedFilter);
  };

  const [openFilterSheet, setOpenFilterSheet] = useState(false);

  const [openFiltersOptions, setOpenFiltersOptions] = useState(false);

  const [bulkUploadSheet, setBulkUploadSheet] = useState(false);

  const [file, setFile] = useState<File>();

  const handleFileChange = e => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const handleFiltersOptions = () => {
    setOpenFiltersOptions(!openFiltersOptions);
  };

  const handleBulkUploadSheet = () => {
    setBulkUploadSheet(!bulkUploadSheet);
  };

  const handleFiltersSheet = () => {
    setOpenFilterSheet(!openFilterSheet);
    setOpenFiltersOptions(!openFiltersOptions);
  };

  const TransferTeammate = () => {
    navigate(`/crm/transfer_teammate`);
  };

  const SendCallCenter = () => {
    navigate(`/crm/send_to_call_center`);
  };

  const [search, setSearch] = useState('');

  const handleSearch = e => {
    const lowerCase = e.target.value.toLowerCase();
    setSearch(lowerCase);
    props.setCustomerSearch([
      {
        type: 'Name',
        value: lowerCase,
      },
      {
        type: 'Project Name',
        value: lowerCase,
      },
    ]);
  };

  const bulkUploadApiCall = () => {
    var formData = new FormData();
    if (file) {
      formData.append('uploaded_file', file);
    }
    const cpUserId = localStorage.getItem('userID');
    const requestURL = `${process.env.REACT_APP_DDP_CRM_API_URL}/customer/bulk_upload/${cpUserId}`;
    if (requestURL && cpUserId) {
      postRequest(requestURL, formData).then(res => {
        if (res.status === 200) {
          toast.success('Customers Uploaded Successfully!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        } else if (res.status !== 200) {
          toast.error('Customers Uploaded Failed!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
        return res;
      });
    }
    handleBulkUploadSheet();
    setFile(undefined);
  };

  return (
    <div className="w-full flex items-center justify-between -mt-12">
      <div className="w-full border border-gray-400 bg-white rounded-lg p-3">
        <label className="flex items-center">
          <SearchIcon className="w-5 h-5" />
          <input
            className="w-full outline-none text-sm text-black bg-white ml-2"
            type="search"
            placeholder="Search by Name or Project Name"
            aria-placeholder="text-gray-300"
            onChange={handleSearch}
          />
        </label>
      </div>
      <button
        onClick={handleFiltersOptions}
        className="border border-gray-400 bg-white rounded-lg p-3 ml-2"
      >
        <FilterIcon className="w-5 h-5" />
      </button>
      <BottomSheet open={openFiltersOptions}>
        <div className="py-5 px-4">
          <div className="flex items-center justify-between">
            <h6 className="text-sm text-black font-medium">Options</h6>
            <button onClick={handleFiltersOptions}>
              <Cross />
            </button>
          </div>
          <hr className="border-purple-50 my-3" />
          <div className="grid gap-y-4">
            <button
              onClick={handleFiltersSheet}
              className="w-full border-2 border-purple-50 rounded-md py-3 px-4"
            >
              <div className="flex items-center">
                <FilterBlack className="w-4 h-4" />
                <h6 className="text-xs text-black font-medium ml-3">Filters</h6>
              </div>
            </button>
            {(props?.status === 'NEW' || props?.status === 'INTERESTED') &&
            cpBusinessProfile?.business_type === 'FIRM' ? (
              <button
                onClick={TransferTeammate}
                className="w-full border-2 border-purple-50 rounded-md py-3 px-4"
              >
                <div className="flex items-center">
                  <TransferTeamMate className="w-4 h-4" />
                  <h6 className="text-xs text-black font-medium ml-3">Transfer to Teammate</h6>
                </div>
              </button>
            ) : (
              <></>
            )}
            {props?.status === 'NEW' ? (
              <button
                onClick={() => {
                  handleFiltersOptions();
                  handleBulkUploadSheet();
                }}
                className="w-full border-2 border-purple-50 rounded-md py-3 px-4"
              >
                <div className="flex items-center">
                  <BulkUpload className="w-4 h-4" />
                  <h6 className="text-xs text-black font-medium ml-3">Bulk upload customers</h6>
                </div>
              </button>
            ) : (
              <></>
            )}
            {props?.status === 'NEW' ? (
              <button
                onClick={SendCallCenter}
                className="w-full border-2 border-purple-50 rounded-md py-3 px-4"
              >
                <div className="flex items-center">
                  <TransferCallCenter className="w-4 h-4" />
                  <h6 className="text-xs text-black font-medium ml-3">Send to Call Centre</h6>
                </div>
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </BottomSheet>

      <BottomSheet open={openFilterSheet}>
        <div className="pb-20 px-4 overflow-y-auto mt-2">
          <div className="flex items-center justify-between">
            <h6 className="text-sm text-black font-medium">Filters</h6>
            <button
              onClick={() => {
                setOpenFilterSheet(false);
              }}
            >
              <Cross />
            </button>
          </div>
          <hr className="border-purple-50 my-3" />
          {filters.map((item, index) => {
            return (
              <div key={`${item.value}`} className="grid mb-7">
                <h6 className="text-sm text-black font-medium mb-3">{item?.label}</h6>
                {item?.option?.length ? (
                  <div
                    className={
                      item?.option?.length === 2
                        ? 'grid grid-cols-2 gap-y-2 gap-x-4'
                        : 'grid grid-cols-3 gap-y-2 gap-x-4'
                    }
                  >
                    {item?.option?.map((sub_item, sub_index) => {
                      return (
                        <button
                          key={`${sub_item.subLabel}`}
                          onClick={() => {
                            updateFilterSelections(item.value, sub_item.subLabel);
                          }}
                          className={
                            sub_item?.selected
                              ? 'bg-purple-50 rounded-md p-2.5'
                              : 'bg-gray-100 rounded-md p-2.5'
                          }
                        >
                          <h6
                            className={
                              sub_item?.selected
                                ? 'text-xs text-primary font-noraml'
                                : 'text-xs text-black font-noraml'
                            }
                          >
                            {sub_item?.subLabel}
                          </h6>
                        </button>
                      );
                    })}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            );
          })}
        </div>

        <div className="w-full fixed bottom-0 flex items-center justify-between bg-white border rounded-t-lg shadow-xl pt-4 pb-6 px-4">
          <button
            onClick={() => {
              setFilters(allFilters);
              props.applyFilters(allFilters);
            }}
          >
            <h5 className="text-sm text-primary font-medium">Reset Filters</h5>
          </button>
          <button
            onClick={() => {
              props.applyFilters(filters);
              setOpenFilterSheet(false);
            }}
            className="w-1/2 flex justify-center bg-primary rounded-md py-2.5"
          >
            <h4 className="text-base text-white font-medium">Apply</h4>
          </button>
        </div>
      </BottomSheet>

      <BottomSheet open={bulkUploadSheet}>
        <div className="p-4">
          <div className="flex flex-1 justify-between">
            <h4 className="text-base text-black font-medium">Bulk Upload Customers</h4>
            <button onClick={handleBulkUploadSheet}>
              <Cross />
            </button>
          </div>
          <div className="flex flex-col items-center mt-10">
            <Upload />
            <label className="flex flex-col items-center cursor-pointer">
              {!file ? (
                <>
                  <h5 className="text-sm text-black font-normal mt-5">
                    Drag & drop file here to upload
                  </h5>
                  <p className="text-sm text-gray-500 font-normal my-3">or</p>
                </>
              ) : (
                <div className="w-80 flex items-center justify-between border border-gray-200 bg-gray-200 text-xs text-black font-medium py-2 px-3 my-6">
                  {file && `${file.name} - ${file.type}`}
                  {/* <button>
              <Cross />
            </button> */}
                </div>
              )}
              <input
                type="file"
                size={300}
                accept=".pdf, .xls, .xlsx, .csv"
                onChange={handleFileChange}
                className="hidden cursor-pointer"
              />
              {!file ? (
                <div className="flex gap-x-2">
                  <div className="border border-purple-700 bg-primary rounded-md py-2.5 px-4">
                    <h4 className="text-sm text-white font-medium">Browse File</h4>
                  </div>
                  <a
                    href={require('../../../../assets/SampleFile.csv')}
                    download="SampleBulkUpload"
                    className="border border-purple-700 bg-primary rounded-md py-2.5 px-4"
                  >
                    <h4 className="text-sm text-white font-medium">Sample File</h4>
                  </a>
                </div>
              ) : (
                <></>
              )}
              {file ? (
                <button
                  onClick={bulkUploadApiCall}
                  className="border border-purple-700 bg-primary rounded-md py-2.5 px-4"
                >
                  <h4 className="text-sm text-white font-medium">Upload File</h4>
                </button>
              ) : (
                <></>
              )}
            </label>
          </div>
        </div>
      </BottomSheet>
    </div>
  );
}
