import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { A11y, Autoplay, FreeMode, Mousewheel, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import { projectServiceSchemas } from 'types/projectServiceSchemas';
import '../../../styles/StyleConstants';
import { ReactComponent as Unitype } from '../../assets/apartment.svg';
import { ReactComponent as StartingPrice } from '../../assets/payments.svg';
import { ReactComponent as UniConfiguration } from '../../assets/settings_overscan.svg';
import { ReactComponent as PosessionDate } from '../../assets/vpn_key.svg';
import { ReactComponent as Exclusive } from './../../assets/exclusive.svg';
import { ReactComponent as HotSelling } from './../../assets/hotSelling.svg';
import { ReactComponent as TrustTag } from './../../assets/tag.svg';

export function Card(props) {
  const project: projectServiceSchemas['CpProjectResponse'] = props.project;
  const [unitTypes, setUnitTypes] = useState('');
  const [isHotSelling, setisHotSelling] = useState(false);
  const [isExclusive, setisExclusive] = useState(false);
  const [isTrusted, setisTrusted] = useState(false);

  const [unitConfiguration, setUnitConfiguration] = useState('');
  const navigate = useNavigate();

  const navigateToDetails = () => {
    navigate(props?.to);
  };
  const useEffectOnMount = (effect: React.EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, []);
  };

  useEffectOnMount(() => {
    if (project?.is_hot_selling_project) {
      setisHotSelling(true);
    }

    if (project?.is_exclusive) {
      setisExclusive(true);
    }

    if (project?.is_seal_of_trust_project) {
      setisTrusted(true);
    }

    if (project?.project_phase?.length) {
      let tempArr: any[] = [];
      let configurationtempArr: any[] = [];
      project?.project_phase.forEach(phase => {
        if (phase?.unit_group?.length) {
          phase?.unit_group.forEach(unit => {
            if (!tempArr.includes(unit?.unit_type?.name)) {
              tempArr.push(unit?.unit_type?.name);
            }
            if (!configurationtempArr.includes(unit?.unit_configuration?.name)) {
              configurationtempArr.push(unit?.unit_configuration?.name);
            }
          });
        }
      });
      setUnitConfiguration(configurationtempArr.join(', '));
      setUnitTypes(tempArr.join(', '));
    }
  });
  const getPossessionDate = phases => {
    if (phases?.length) {
      let pDate: any = undefined;
      for (let i = 0; i < phases?.length; i++) {
        const plannedDateOfPossession = phases[i]?.planned_date_of_possession;
        if (plannedDateOfPossession) {
          pDate = pDate
            ? pDate.isBefore(moment(plannedDateOfPossession))
              ? pDate
              : moment(plannedDateOfPossession)
            : moment(plannedDateOfPossession);
        }
      }
      return pDate ? pDate.format(' MMM, YYYY') : '--';
    }
  };

  return (
    <div>
      <div
        onClick={() => navigateToDetails()}
        className="2xl:w-full xl:w-80 border-0 border-white rounded-2xl bg-white px-3 pt-0.5 pb-5 mt-5 shadow-md hover:shadow-md"
      >
        <div
          className="w-full 2xl:h-72 xl:h-64 lg:h-60 md:h-52 xs:h-48 rounded-xl bg-cover"
          style={{ backgroundImage: `url(${project?.thumbnail_image})` }}
        >
          <div className="flex justify-between items-center mx-3 mt-3">
            <div className="flex">
              {isHotSelling ? <HotSelling className="w-16 h-16" /> : ''}
              {isExclusive ? <Exclusive className="w-14 h-16 ml-1.5" /> : ''}
            </div>
            <div className="mt-4">{isTrusted ? <TrustTag className="w-9 h-9" /> : ''}</div>
          </div>
          {project?.project_image?.length === 0 ? (
            <></>
          ) : (
            <div
              className={
                isHotSelling || isExclusive
                  ? 'flex justify-between 2xl:mt-44 xl:mt-36 lg:mt-32 md:mt-24 xs:mt-20'
                  : isTrusted
                  ? 'flex justify-between 2xl:mt-48 xl:mt-40 lg:mt-36 md:mt-28 mt-24'
                  : 'flex justify-between 2xl:mt-56 xl:mt-48 lg:mt-44 md:mt-36 mt-32'
              }
            >
              <div></div>
              <div className="bg-black/50 backdrop-blur-sm rounded-md mb-4 mr-4 py-1 px-2.5">
                <h5 className="text-sm text-white font-medium">
                  +
                  {project?.cover_image && project?.project_image
                    ? project?.project_image?.length + 1 + ' '
                    : project?.project_image?.length + ' '}
                  more
                </h5>
              </div>
            </div>
          )}
        </div>
        <p className="2xl:text-2xl xl:text-xl md:text-lg text-base text-slate-900 font-semibold truncate 2xl:w-80 lg:w-60 md:w-72 2xl:mt-4 xl:mt-3 md:mt-2 mt-1">
          {project?.name}
        </p>
        <p className="xl:text-base text-sm text-gray-500 font-normal truncate 2xl:w-80 xl:w-72 lg:w-60 w-72">
          {project?.google_place_response?.results[0]?.formatted_address
            ? project?.google_place_response?.results[0]?.formatted_address
            : '--'}
        </p>
        <hr className="text-gray-500 my-4" />
        <div className="flex flex-1">
          <div className="flex-1">
            <div className="flex items-center">
              <StartingPrice />
              <p className="xl:text-sm text-[11px] text-black/80 font-medium ml-2">
                {project?.starting_price ? project?.starting_price : '--'}
              </p>
            </div>
            <div className="flex items-center mt-4">
              <Unitype />
              <p className="xl:text-sm text-[11px] text-black/80 font-medium truncate xl:w-24 lg:w-32 w-24 ml-2">
                {unitTypes ? unitTypes : '--'}
              </p>
            </div>
          </div>
          <div className="flex-1">
            <div className="flex items-center">
              <UniConfiguration />
              <p className="xl:text-sm text-[11px] text-black/80 font-medium truncate xl:w-32 lg:w-16 w-28 ml-2">
                {unitConfiguration ? unitConfiguration : '--'}
              </p>
            </div>
            <div className="flex items-center mt-4">
              <PosessionDate />
              <div className="xl:text-sm text-[11px] text-black/80 font-medium ml-2">
                {getPossessionDate(project?.project_phase) || '--'}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function TopProjects(props) {
  const projects = props.projects;
  const navigate = useNavigate();

  const navigateToDetails = id => {
    navigate(`/project/${id}`);
  };

  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const updateMedia = () => {
    setInnerWidth(window.innerWidth);
  };

  const useEffectOnMount = (effect: React.EffectCallback) => {
    useEffect(effect, []);
  };

  useEffectOnMount(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  // useEffect(() => {
  //   const stylesheet = document.styleSheets[0];
  //   stylesheet.insertRule('.swiper-pagination-bullet-active { border-radius: 2px !important;}', 0);
  //   stylesheet.insertRule('.swiper-pagination-bullet { border-radius: 0px !important;}', 0);
  // });

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      // className = 'border-radius: 2px';
      return '<span class="' + className + '"></span>';
    },
  };

  return (
    <div>
      <div>
        <h2 className="2xl:text-3xl xl:text-2xl lg:text-xl md:text-lg text-black/90 font-semibold mb-4">
          Top Projects
        </h2>
        <div className="mb-8">
          <Swiper
            slidesPerView={
              innerWidth > 1920
                ? 4.8
                : innerWidth > 1536
                ? 3.8
                : innerWidth > 1366
                ? 3.2
                : innerWidth >= 1140
                ? 3
                : innerWidth >= 960
                ? 2.5
                : 2
            }
            spaceBetween={
              innerWidth > 1536
                ? 40
                : innerWidth >= 1366
                ? 40
                : innerWidth >= 1140
                ? 20
                : innerWidth >= 960
                ? 25
                : 30
            }
            pagination={{
              clickable: true,
              // el: `swiper-container swiper-container-testClass`,
              // bulletClass: `swiper-pagination-bullet swiper-pagination-testClass`,
            }}
            // wrapperTag="ul"
            style={
              {
                '--swiper-pagination-color': '#700CB3',
                '--swiper-pagination-bullet-inactive-color': '#D8B4FE',
                '--swiper-pagination-bullet-inactive-opacity': '1',
                '--swiper-pagination-bullet-size': '10px',
                '--swiper-pagination-bullet-horizontal-gap': '0px',
              } as React.CSSProperties
            }
            grabCursor={true}
            freeMode={true}
            mousewheel={true}
            modules={[Pagination,FreeMode,Mousewheel]}
            className="mySwiper"
          >
            {projects
              .filter(project => Boolean(project.is_top_project))
              .map((top_project, index) =>
                index < 10 ? (
                  <SwiperSlide key={top_project.id} className="mb-12">
                    <div onClick={() => navigateToDetails(top_project?.id)} className="space-y-4">
                      <div className="2xl:w-96 xl:80 bg-white rounded-[20px] p-3">
                        <div
                          style={{ backgroundImage: `url(${top_project?.thumbnail_image})` }}
                          className="w-full h-48 rounded-xl bg-cover"
                        >
                          {top_project?.is_seal_of_trust_project ? (
                            <div className="flex justify-center items-center w-8 h-10 bg-white rounded-b-lg ml-4">
                              <TrustTag className="w-6 h-6" />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="mt-3">
                          <p className="text-sm text-slate-900 font-semibold">
                            {top_project.name ? top_project.name : '--'}
                          </p>
                          <p className="text-[10px] text-gray-500 font-normal">
                            {top_project.starting_price ? top_project.starting_price : '--'} -
                            {top_project.ending_price ? ` ${top_project.ending_price}` : '--'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ) : (
                  <></>
                ),
              )}
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export function TopProjectsMobile(props) {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 460);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 460);
  };

  const navigate = useNavigate();

  const navigateToDetails = id => {
    navigate(`/project/${id}`);
  };

  const useEffectOnMount = (effect: React.EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, []);
  };

  useEffectOnMount(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  const projects = props.projects;

  return (
    <section className="mb-6 ml-6">
      <div className="mt-8">
        <h6 className="font-semibold slate-400 mb-3">Top Projects</h6>
        <Swiper
          modules={[Autoplay, Pagination, A11y]}
          spaceBetween={isDesktop ? 40 : 50}
          slidesPerView={isDesktop ? 1.4 : 1.18}
          autoplay={true}
          grabCursor={true}
          pagination={{
            dynamicBullets: true,
            clickable: true,
            renderBullet: function (index, className) {
              return '<span class="' + className + '"></span>';
            },
          }}
          style={
            {
              '--swiper-pagination-color': '#700CB3',
              '--swiper-pagination-bullet-inactive-color': '#999999',
              '--swiper-pagination-bullet-inactive-opacity': '1',
              '--swiper-pagination-bullet-size': '10px',
              '--swiper-pagination-bullet-horizontal-gap': '2px',
            } as React.CSSProperties
          }
        >
          {projects
            .filter(project => Boolean(project.is_top_project))
            .map((top_project, index) =>
              index < 10 ? (
                <SwiperSlide className="mb-12" key={top_project.id}>
                  <div onClick={() => navigateToDetails(top_project?.id)}>
                    <div className="w-80 bg-white rounded-[20px] p-3">
                      <div
                        style={{ backgroundImage: `url(${top_project?.thumbnail_image})` }}
                        className="w-full h-48 rounded-xl bg-cover"
                      >
                        {top_project?.is_seal_of_trust_project ? (
                          <div className="flex justify-center items-center w-8 h-10 bg-white rounded-b-lg ml-4">
                            <TrustTag className="w-6 h-6" />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="mt-3">
                        <p className="text-sm text-slate-900 font-semibold">
                          {top_project.name ? top_project.name : '--'}
                        </p>
                        <p className="text-[10px] text-gray-500 font-normal">
                          {top_project.starting_price ? top_project.starting_price : '--'} -
                          {top_project.ending_price ? ` ${top_project.ending_price}` : '--'}
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ) : (
                <></>
              ),
            )}
        </Swiper>
      </div>
    </section>
  );
}
export function ProjectListCardMobile(props) {
  const project: projectServiceSchemas['CpProjectResponse'] = props.project;
  const [unitTypes, setUnitTypes] = useState('');
  const [unitConfiguration, setUnitConfiguration] = useState('');
  const [isHotSelling, setisHotSelling] = useState(false);
  const [isExclusive, setisExclusive] = useState(false);
  const [isTrusted, setisTrusted] = useState(false);
  const navigate = useNavigate();

  const useEffectOnMount = (effect: React.EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, []);
  };

  const openDetailPage = id => {
    navigate(id);
  };

  useEffectOnMount(() => {
    if (project?.is_hot_selling_project) {
      setisHotSelling(true);
    }

    if (project?.is_exclusive) {
      setisExclusive(true);
    }

    if (project?.is_seal_of_trust_project) {
      setisTrusted(true);
    }

    if (project?.project_phase?.length) {
      project?.project_phase.forEach(phase => {
        let tempArr: any[] = [];
        if (phase?.unit_group?.length) {
          phase?.unit_group.forEach(unit => {
            if (!tempArr.includes(unit?.unit_type?.name)) {
              tempArr.push(unit?.unit_type?.name);
            }
            let configurationtempArr: any[] = [];
            if (!configurationtempArr.includes(unit?.unit_configuration?.name)) {
              configurationtempArr.push(unit?.unit_configuration?.name);
            }
            setUnitConfiguration(configurationtempArr.join(', '));
          });
        }
        setUnitTypes(tempArr.join(', '));
      });
    }
  });
  const getPossessionDate = phases => {
    if (phases?.length) {
      let pDate: any = undefined;
      for (let i = 0; i < phases?.length; i++) {
        const plannedDateOfPossession = phases[i]?.planned_date_of_possession;
        if (plannedDateOfPossession) {
          pDate = pDate
            ? pDate.isBefore(moment(plannedDateOfPossession))
              ? pDate
              : moment(plannedDateOfPossession)
            : moment(plannedDateOfPossession);
        }
      }
      return pDate ? pDate.format(' MMM, YYYY') : '--';
    }
  };

  return (
    <>
      <section className="mx-6 pb-8">
        <div
          onClick={() => openDetailPage(props?.to)}
          className="w-full bg-white rounded-[20px] pt-3 pb-5 px-3"
        >
          <div
            style={{ backgroundImage: `url(${project?.thumbnail_image})` }}
            className="w-full h-52 rounded-xl bg-cover bg-center"
          >
            <div className="flex justify-between items-center mx-3">
              <div className="flex">
                {isHotSelling ? <HotSelling className="w-16 h-4" /> : ''}
                {isExclusive ? <Exclusive className="w-14 h-4 ml-1.5" /> : ''}
              </div>
              <div className="mt-4">{isTrusted ? <TrustTag className="w-9 h-9" /> : ''}</div>
            </div>
          </div>
          {project?.project_image?.length === 0 ? (
            <></>
          ) : (
            <div
              className={
                isHotSelling || isExclusive
                  ? 'flex justify-between -mt-8'
                  : isTrusted
                  ? 'flex justify-between -mt-8'
                  : 'flex justify-between -mt-8'
              }
            >
              <div></div>
              <div className="bg-black/50 backdrop-blur-sm rounded-md mb-4 mr-4 py-1 px-2.5">
                <h5 className="text-sm text-white font-medium">
                  +
                  {project?.cover_image
                    ? project?.project_image
                      ? project?.project_image?.length + 1 + ' '
                      : project?.project_image?.length + ' '
                    : ''}
                  more
                </h5>
              </div>
            </div>
          )}
          <div className="mt-3">
            <p className="text-base text-slate-900 font-semibold">{project?.name}</p>
            <p className="text-xs text-gray-500 font-normal">
              {project?.google_place_response?.results[0]?.formatted_address
                ? project?.google_place_response?.results[0]?.formatted_address
                : '--'}
            </p>
            <hr className="text-gray-500 my-3" />
            <div className="flex flex-1 mx-3">
              <div className="flex-1">
                <div className="flex items-center">
                  <StartingPrice />
                  <p className="text-xs text-black/80 font-medium ml-2">
                    {project?.starting_price ? project?.starting_price : '--'}
                  </p>
                </div>
                <div className="flex items-center mt-4">
                  <Unitype />
                  <p className="text-xs text-black/80 font-medium truncate w-32 ml-2">
                    {unitTypes ? unitTypes : '--'}
                  </p>
                </div>
              </div>
              <div className="flex-1">
                <div className="flex items-center">
                  <UniConfiguration />
                  <p className="text-xs text-black/80 font-medium ml-2">
                    {unitConfiguration ? unitConfiguration : '--'}
                  </p>
                </div>
                <div className="flex items-center mt-4">
                  <PosessionDate />
                  <div className="text-xs text-black/80 font-medium ml-2">
                    {getPossessionDate(project?.project_phase) || '--'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
