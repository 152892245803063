import cn from 'classnames';
import ReactSlider, { ReactSliderProps } from 'react-slider';
import { ReactComponent as Dot } from './../../assets/whiteDot.svg';

const RangeSlider = <T extends number | readonly number[]>(_props: ReactSliderProps<T>) => {
  const isVertical = _props.orientation === 'vertical';
  return (
    <ReactSlider
      {..._props}
      renderThumb={(props, state) => (
        <div
          {...props}
          className={cn({
            'h-8': !isVertical,
            'w-8': isVertical,
            'aspect-square rounded-full bg-primary text-xs text-white flex items-center justify-center cursor-grab':
              true,
          })}
        >
          {/* {state.valueNow} */}
          <div>
            <Dot className="w-2.8 h-2.8" />
          </div>
        </div>
      )}
      renderTrack={(props, state) => {
        const points = Array.isArray(state.value) ? state.value.length : null;
        const isMulti = points && points > 0;
        const isLast = isMulti ? state.index === points : state.index !== 0;
        const isFirst = state.index === 0;
        return (
          <div
            {...props}
            className={cn({
              'h-1/6 top-3.5': !isVertical,
              'w-1/6 left-2.5': isVertical,
              'rounded-full': true,
              'bg-purple-50': isMulti ? isFirst || isLast : isLast,
              'bg-primary': isMulti ? !isFirst || !isLast : isFirst,
            })}
          ></div>
        );
      }}
      // renderMark={props => {
      //   return (
      //     <div
      //       {...props}
      //       className={cn({
      //         'top-1/2 -translate-y-1/2': !isVertical,
      //         'left-1/2 -translate-x-1/2': isVertical,
      //         'h-1 w-1': true,
      //         'rounded-full bg-white': true,
      //       })}
      //     ></div>
      //   );
      // }}
    />
  );
};
export default RangeSlider;
