import { PayloadAction } from '@reduxjs/toolkit';
import { crmServiceSchemas } from 'types/crmServiceSchemas';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { customersEventSaga } from './saga';
import { CustomersEventState } from './types';

export const initialState: CustomersEventState = {
  loading: true,
  error: false,
  errorMsg: '',
  customersEvent: [],
  customersEventTotal: 0,
  filter_obj: {},
  customersEventPage: 0,
  customersEventSize: 50,
};

const slice = createSlice({
  name: 'customersEvent',
  initialState,
  reducers: {
    loadCustomersEvent(state, action) {
      state.error = false;
      state.customersEvent = [];
      const filter_object = action?.payload;
      state.filter_obj = filter_object;
      state.loading = true;
    },
    customersEventLoaded(
      state,
      action: PayloadAction<{
        items: crmServiceSchemas['CustomerEventResponse'][];
        total: number;
        page: number;
        size: number;
      }>,
    ) {
      const customersEvent = action.payload;

      state.customersEvent = customersEvent?.items;
      state.loading = false;

      state.customersEventTotal = customersEvent?.total;
      state.customersEventPage = customersEvent?.page;
      state.customersEventSize = customersEvent?.size;
    },
    customersEventError(state, action: PayloadAction<string>) {
      state.error = true;
      state.errorMsg = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: customersEventActions, reducer } = slice;

export const useCustomersEventSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: customersEventSaga });
  return { actions: slice.actions };
};
