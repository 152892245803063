import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { AnyAction } from 'types';
import { postRequest } from 'utils/request';
import { authActions as actions } from '.';

export function* requestAuth({ payload: { usdCode, phoneNumber, otpViaCall } }: AnyAction) {
  yield delay(500);

  try {
    const requestURL = `${process.env.REACT_APP_AUTH_API_URL}/auth/request_auth`;
    const { response } = yield call(postRequest, requestURL, {
      usd_code: usdCode,
      phone_number: phoneNumber,
      otp_via_call: otpViaCall
    });
    if (response?.data?.error) {
      yield put(actions.authenticationError(response?.data?.response?.message));
    } else {
      yield put(actions.otpSent(true));
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.authenticationError('Project not found.'));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.authenticationError('Failed to fetch project.'));
    } else {
      yield put(
        actions.authenticationError('Something went wrong while fetching project details.'),
      );
    }
  }
}

export function* authenticate({ payload: { usdCode, phoneNumber, otp } }: AnyAction) {
  yield delay(500);
  try {
    const requestURL = `${process.env.REACT_APP_AUTH_API_URL}/auth/authenticate`;
    const { data, response } = yield call(postRequest, requestURL, {
      usd_code: usdCode,
      phone_number: phoneNumber,
      otp,
    });
    if (response?.data?.error) {
      yield put(actions.authenticationError(response?.data?.response?.message));
    } else {
      yield put(actions.authenticated(data.response));
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.authenticationError('Project not found.'));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.authenticationError('Failed to fetch project.'));
    } else {
      yield put(
        actions.authenticationError('Something went wrong while fetching project details.'),
      );
    }
  }
}

export function* authSaga() {
  yield takeLatest(actions.requestAuth.type, requestAuth);
  yield takeLatest(actions.authenticate.type, authenticate);
}
