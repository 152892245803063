import { PayloadAction } from '@reduxjs/toolkit';
import { crmServiceSchemas } from 'types/crmServiceSchemas';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { teamEventSummarySaga } from './saga';
import { TeamEventSummaryState } from './types';

export const initialState: TeamEventSummaryState = {
  loading: true,
  error: false,
  errorMsg: '',
  teamEvents: {},
};

const slice = createSlice({
  name: 'teamEventSummary',
  initialState,
  reducers: {
    loadTeamEventSummary(state) {
      state.loading = true;
      state.error = false;
      state.teamEvents = {};
    },
    teamLoadedEventSummary(
      state,
      action: PayloadAction<{
        items: crmServiceSchemas['TeamSummaryResponse'];
      }>,
    ) {
      const team = action.payload;
      state.teamEvents = team?.items;
      state.loading = false;
    },
    teamError(state, action: PayloadAction<string>) {
      state.error = true;
      state.errorMsg = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: teamEventSummaryActions, reducer } = slice;

export const useTeamEventSummarySlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: teamEventSummarySaga });
  return { actions: slice.actions };
};
