import { useState } from 'react';
import { ReactComponent as CustomerFilled } from './../../assets/Customers dashbord.svg';
import { ReactComponent as Customer } from './../../assets/icon dashbord customer.svg';
import { ReactComponent as ProjectFilled } from './../../assets/project color.svg';
import { ReactComponent as Project } from './../../assets/project icon dashbord.svg';

export const Sidebar = ({ isOpen, onClose, openLogin, selectedUrl }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const urlSelected = selectedUrl;

  const [selected, setSelected] = useState('');

  const token = localStorage.getItem('userToken');
  const handleExpandToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const setSelectedUrl = data => {
    if (urlSelected) {
      urlSelected(data);
    }
  };
  return (
    <>
      <div
        id="drawer-navigation"
        className={`fixed top-0 left-0 z-40 w-64 h-screen p-4 overflow-y-auto transition-transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } bg-white dark:bg-gray-800`}
        tabIndex={-1}
        aria-labelledby="drawer-navigation-label"
      >
        <button
          type="button"
          data-drawer-hide="drawer-navigation"
          onClick={onClose}
          aria-controls="drawer-navigation"
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 absolute top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <svg
            aria-hidden="true"
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
          <span className="sr-only">Close menu</span>
        </button>
        <div className="py-4 overflow-y-auto">
          <ul className="space-y-2 font-medium">
            <li>
              <a
                key={'projects'}
                onClick={() => setSelected('projects')}
                href="/projects"
                className="flex items-center p-2 text-gray-900 rounded-lg dark:text-white hover:bg-purple-50 dark:hover:bg-purple-50"
              >
                {selected === 'projects' ? (
                  <ProjectFilled
                    className={
                      'w-10 h-10 text-primary transition duration-75 dark:text-gray-400 hover:text-primary dark:group-hover:text-white'
                    }
                  />
                ) : (
                  <Project
                    className={
                      'w-10 h-10 text-gray-500 transition duration-75 dark:text-gray-400 hover:text-primary dark:group-hover:text-white'
                    }
                  />
                )}

                <span
                  className={
                    selected === 'projects'
                      ? 'ml-3 text-primary font-medium hover:text-primary'
                      : 'ml-3 text-gray-700 font-medium hover:text-primary'
                  }
                >
                  Projects
                </span>
              </a>
            </li>
            <li>
              <button
                type="button"
                className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg group hover:bg-purple-50 dark:text-white dark:hover:bg-purple-50"
                aria-controls="dropdown-example"
                onClick={handleExpandToggle}
              >
                {isExpanded ? (
                  <CustomerFilled
                    className={
                      'flex-shrink-0 w-10 h-10 text-primary transition duration-75 dark:text-gray-400 group-hover:text-primary dark:group-hover:text-white'
                    }
                  />
                ) : (
                  <Customer
                    className={
                      'flex-shrink-0 w-10 h-10 text-gray-500  transition duration-75 dark:text-gray-400 group-hover:text-primary dark:group-hover:text-white'
                    }
                  />
                )}

                <span
                  className={
                    isExpanded
                      ? 'flex-1 ml-3 text-left whitespace-nowrap text-primary font-medium hover:text-primary'
                      : 'flex-1 ml-3 text-left whitespace-nowrap text-gray-700 font-medium hover:text-primary'
                  }
                >
                  Customers
                </span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
              {isExpanded ? (
                <ul id="dropdown-example" className="py-2 space-y-2">
                  <li>
                    <a
                      onClick={
                        token
                          ? () => {
                              setSelected('dashboard');
                              setSelectedUrl('/crm/dashboard');
                            }
                          : () => {
                              setSelected('');
                              setSelectedUrl('/crm/dashboard');
                              openLogin();
                            }
                      }
                      href={token ? '/crm/dashboard' : 'javascript:void(0)'}
                      className={
                        selected === 'dashboard'
                          ? 'flex items-center w-full p-2 text-primary font-normal transition duration-75 rounded-lg pl-11 hover:text-primary hover:bg-purple-50 dark:text-white dark:hover:bg-purple-50'
                          : 'flex items-center w-full p-2 text-gray-700 font-normal transition duration-75 rounded-lg pl-11 hover:text-primary hover:bg-purple-50 dark:text-white dark:hover:bg-purple-50'
                      }
                    >
                      Dashboard
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={
                        token
                          ? () => {
                              setSelected('mycustomers');
                              setSelectedUrl('/crm/myCustomers');
                            }
                          : () => {
                              setSelected('');
                              setSelectedUrl('/crm/myCustomers');
                              openLogin();
                            }
                      }
                      href={token ? '/crm/myCustomers' : 'javascript:void(0)'}
                      className={
                        selected === 'mycustomers'
                          ? 'flex items-center w-full p-2 text-primary font-normal transition duration-75 rounded-lg pl-11 hover:text-primary hover:bg-purple-50 dark:text-white dark:hover:bg-purple-50'
                          : 'flex items-center w-full p-2 text-gray-700 font-normal transition duration-75 rounded-lg pl-11 hover:text-primary hover:bg-purple-50 dark:text-white dark:hover:bg-purple-50'
                      }
                    >
                      My Customers
                    </a>
                  </li>
                </ul>
              ) : (
                <></>
              )}
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
