import { useChannelPartnersListSlice } from 'app/pages/MyCustomers/slices/allCpCurrentOrgslice';
import { selectChannelPartners } from 'app/pages/MyCustomers/slices/allCpCurrentOrgslice/selectors';
import { useAllUsersListSlice } from 'app/pages/MyCustomers/slices/allUsersslice';
import { selectAllUsers } from 'app/pages/MyCustomers/slices/allUsersslice/selectors';
import { useCustomerDetailSlice } from 'app/pages/MyCustomers/slices/customerDetailsSlice';
import { useCustomerHistorylice } from 'app/pages/MyCustomers/slices/customerHistorySlice';
import { selectcustomerHistory } from 'app/pages/MyCustomers/slices/customerHistorySlice/selectors';
import { useUnitConfigurationListSlice } from 'app/pages/ProjectListPage/Projects/ProjectList/slices/untiConfigurationSlice';
import { selectUnitConfigurations } from 'app/pages/ProjectListPage/Projects/ProjectList/slices/untiConfigurationSlice/selectors';
import { useUnitTypeListSlice } from 'app/pages/ProjectListPage/Projects/ProjectList/slices/untiTypeSlice';
import { selectUnitTypes } from 'app/pages/ProjectListPage/Projects/ProjectList/slices/untiTypeSlice/selectors';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ReactComponent as CategoryUnfilled } from './../../assets/crm/Category unfill.svg';
import { ReactComponent as CategoryFilled } from './../../assets/crm/categiry fill.svg';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { convertBudget } from 'utils/helper';
import { getRequest, postRequest } from 'utils/request';
import { ReactComponent as ExclamationMark } from '../../assets/crm/No event scheduled.svg';
import { ReactComponent as Arrow } from '../../assets/crm/arrow_forward.svg';
import { ReactComponent as CheckCircle } from '../../assets/crm/checkboxfill.svg';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { selectcustomerDetails } from '../MyCustomers/slices/customerDetailsSlice/selectors';
import { ReactComponent as Cross } from './../../assets/close.svg';
import { ReactComponent as Copy } from './../../assets/copy.svg';
import { ReactComponent as FollowUp } from './../../assets/crm/Follow Up.svg';
import { ReactComponent as Meeting } from './../../assets/crm/Meeting.svg';
import { ReactComponent as SiteVisit } from './../../assets/crm/Site Visit.svg';
import { ReactComponent as TransferTeammate } from './../../assets/crm/Transfer to Teammate.svg';
import { ReactComponent as ViewNotes } from './../../assets/crm/View All Notes.svg';
import { ReactComponent as History } from './../../assets/crm/View complete history.svg';
import { ReactComponent as ArrowDown } from './../../assets/crm/arrow_forward.svg';
import { ReactComponent as UncheckCircle } from './../../assets/crm/chek unfill box.svg';
import { ReactComponent as DotGreen } from './../../assets/crm/dotGreen.svg';
import { ReactComponent as SendCallCenter } from './../../assets/crm/inCallCenter.svg';
import { ReactComponent as UnCheckRadio } from './../../assets/crm/radio_button_unchecked.svg';
import { ReactComponent as CheckRadio } from './../../assets/crm/radio_check_button.svg';
import { ReactComponent as Share } from './../../assets/crm/shareBlack.svg';
import { ReactComponent as Mail } from './../../assets/gmail.svg';
import { ReactComponent as WhatsApp } from './../../assets/whatsapp.svg';

const useEffectOnMount = (effect: React.EffectCallback) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
};
export function CustomerDetails() {
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffectOnMount(() => {
    const fetchCPBusinessURL = `${process.env.REACT_APP_CP_API_URL}/cp/business/profile/`;
    getRequest(fetchCPBusinessURL).then(res => {
      setCpBusinessProfile(res?.data?.response);
    });
    dispatch(unitconfigurationsactions.loadUnitConfiguration());
    dispatch(unittypeactions.loadUnitType());
    if (id) {
      dispatch(actions.loadCustomerDetails(id));
    }
    dispatch(channelpartnersactions.loadchannelPartners({}));
    dispatch(customerhistoryactions.loadCustomerHistory(id));
  });

  const dummyProjectImg = 'https://images.indianexpress.com/2016/02/green-building759.jpg';
  const { actions: unitconfigurationsactions } = useUnitConfigurationListSlice();

  const { actions } = useCustomerDetailSlice();

  const { actions: unittypeactions } = useUnitTypeListSlice();

  const { actions: channelpartnersactions } = useChannelPartnersListSlice();

  const { actions: usersactions } = useAllUsersListSlice();

  const { actions: customerhistoryactions } = useCustomerHistorylice();

  const unitConfigurations = useSelector(selectUnitConfigurations);

  const unitTypes = useSelector(selectUnitTypes);

  const channelPartnerList = useSelector(selectChannelPartners);

  const allUserList = useSelector(selectAllUsers);

  const customerHistory = useSelector(selectcustomerHistory);
  const customerDetail = useSelector(selectcustomerDetails);
  const [customerUnitConfPrefference, setcustomerUnitConfPrefference] = useState('');
  const [customerUnitTypePrefference, setcustomerUnitTypePrefference] = useState('');
  const [customerLocalityPrefference, setcustomerLocalityPrefference] = useState('');
  const [customerLeadProjectsIds, setcustomerLeadProjectsIds] = useState('');
  const [shareProjectId, setshareProjectId] = useState('');
  const [projectShareUrl, setprojectShareUrl] = useState('');
  const [customerLeadProjects, setcustomerLeadProjects] = useState<any>([]);
  const [customerLeads, setcustomerLeads] = useState<any>([]);
  const [customerEvent, setCustomerEvent] = useState<any>([]);
  const [cpBusinessProfile, setCpBusinessProfile] = useState<any>();
  const [historyData, setHistoryData] = useState({
    FOLLOW_UP: 0,
    MEETING: 0,
    SITE_VISIT: 0,
  });

  useEffect(() => {
    setprojectShareUrl(`${process.env.REACT_APP_PROJECT_API_URL}/cp/project/${shareProjectId}`);
  }, [shareProjectId]);

  useEffect(() => {
    let arr: any = [];
    customerDetail?.customer_unit_configuration_preference?.map(id => {
      let unit_configuraion_prefference = unitConfigurations.filter(data => data?.id === id);
      if (unit_configuraion_prefference.length > 0) {
        arr.push(unit_configuraion_prefference[0]);
      }
    });
    setcustomerUnitConfPrefference(arr.map(item => item?.name).join(', '));
  }, [unitConfigurations, customerDetail]);

  useEffect(() => {
    let arr: any = [];
    customerDetail?.customer_unit_type_preference?.map(id => {
      let unit_type_prefference = unitTypes.filter(data => data?.id === id);
      if (unit_type_prefference.length > 0) {
        arr.push(unit_type_prefference[0]);
      }
    });
    setcustomerUnitTypePrefference(arr.map(item => item?.name).join(', '));
  }, [unitTypes, customerDetail]);

  useEffect(() => {
    let arr: any = [];
    let projectArr: any = [];
    let customerLeadArr: any = [];
    customerDetail?.customer_locality_preference?.map(item => {
      arr.push(item?.google_places_response?.results[0]?.formatted_address);
    });
    setcustomerLocalityPrefference(arr.join(', '));

    customerDetail?.customer_lead?.map(lead => {
      customerLeadArr.push(lead);
      projectArr.push(lead?.project_id);
    });
    setcustomerLeadProjectsIds(projectArr?.join(','));
    setcustomerLeads(customerLeadArr);
  }, [customerDetail]);

  useEffect(() => {
    const requestURL = `${process.env.REACT_APP_PROJECT_API_URL}/cp/project?project_ids=${customerLeadProjectsIds}`;
    if (requestURL && customerLeadProjectsIds) {
      getRequest(requestURL).then(res => {
        setcustomerLeadProjects(res?.data?.items);
      });
    }
  }, [customerLeadProjectsIds]);

  useEffect(() => {
    let customerEventArr: any = [];
    customerDetail?.customer_event
      ?.filter(item => item?.status === 'SCHEDULED')
      ?.map((event, index) => {
        customerEventArr.push(event);
      });
    setCustomerEvent(customerEventArr);
  }, [customerDetail]);

  useEffect(() => {
    let userIdsArr: any = [];
    channelPartnerList.map(cp => {
      userIdsArr.push(cp?.user_id);
    });
    let filter_obj: any = {
      user_ids: userIdsArr.join(','),
    };

    if (filter_obj) {
      dispatch(usersactions.loadusers(filter_obj));
    }
  }, [channelPartnerList]);

  const filterProjectData = id => {
    let selectedPrData = {};
    selectedPrData = [...customerLeadProjects]?.filter(data => data.id === id);
    return selectedPrData[0];
  };

  const teamMateTransfer = () => {
    let data = {
      transferee_cp_id: teammateSelected,
      tranferror_cp_id: localStorage.getItem('userID'),
    };
    const requestURL = `${process.env.REACT_APP_DDP_CRM_API_URL}/${customerDetail?.id}/transfer/`;
    if (requestURL && teammateSelected && customerDetail.id) {
      postRequest(requestURL, data).then(res => {
        if (res.status === 200) {
          toast.success('Customer Transferred Successfully!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        } else if (res.status !== 200) {
          toast.error('Customer Transferred Failed!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
        return res;
      });
    }
    handleTransfeTeammateModal();
  };
  const preferredPropertyStatus = {
    READY_TO_MOVE: 'Ready To Move',
    UNDER_CONSTRUCTION: 'Under Construction',
    PRE_LAUNCH: 'Pre-Launch',
  };

  const statusList = {
    NEW: 'New',
    INTERESTED: 'Interested',
    IN_CC: 'In Call Center',
    FAILED: 'Failed',
    BOOKING_DONE: 'Booking',
    NOT_INTERESTED: 'Not Interested',
  };

  const eventType = {
    FOLLOW_UP: 'Follow Up',
    MEETING: 'Meeting',
    SITE_VISIT: 'Site Visit',
  };

  const [transferTeammate, setTransferTeammate] = useState(false);

  const [sendCallCanter, setSendCallCanter] = useState(false);

  const [search, setSearch] = useState('');

  const handleTransfeTeammateModal = () => {
    setTransferTeammate(!transferTeammate);
  };

  const handleSendCallCanterModal = () => setSendCallCanter(!sendCallCanter);

  const [notes, setNotes] = useState<string>('');

  const handleChange = event => {
    setNotes(event.target.value);
  };

  const addNotes = () => {
    const requestURL = `${process.env.REACT_APP_DDP_CRM_API_URL}/customer_note/`;
    let data = { customer_id: id, note: notes };
    if (requestURL && notes) {
      postRequest(requestURL, data).then(res => {
        if (res?.status === 200) {
          toast.success('Note Added Successfully!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        } else if (res?.status !== 200) {
          toast.error('Note Added Failed!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
        return res;
      });
    }
  };

  const sendCallCanterApiCall = () => {
    const requestURL = `${process.env.REACT_APP_DDP_CRM_API_URL}/customer/${customerDetail?.id}/send_to_call_center/`;
    let data = {
      call_center_project_ids: projectSelected,
    };
    if (requestURL && data) {
      postRequest(requestURL, data).then(res => {
        const msg = res?.response?.data?.response?.message;
        if (res.status == 200) {
          toast.success('Customer Send To Call Center!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        } else if (res.status != 200) {
          toast.error(msg, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
        return res;
      });
    }
  };

  const [teammateSelected, setTeammateSelected] = useState<string | undefined>('');

  const [projectSelected, setProjectSelected] = useState<any>([]);

  const [projectLimitErr, setProjectLimitErr] = useState('');

  const handleSearch = e => {
    const lowerCase = e.target.value.toLowerCase();
    setSearch(lowerCase);
  };
  const getEventCount = eventArray => {
    if (eventArray && eventArray.length) {
      let counts = eventArray.reduce(
        (c, { scheduled: key }) => ((c[key] = (c[key] || 0) + 1), c),
        {},
      );
      return counts;
    }
    return {};
  };
  useEffect(() => {
    setHistoryData(getEventCount(customerHistory));
  }, [customerHistory]);

  const [openProjectShare, setOpenProjectShare] = useState(false);
  const handleProjectShare = id => {
    setshareProjectId(id);
    setOpenProjectShare(!openProjectShare);
  };

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div>
      <div className="py-14">
        <div className="w-full fixed top-14 flex items-center bg-white shadow-sm py-4 px-5">
          <button onClick={navigateBack}>
            <Arrow className="w-7 h-7 rotate-180" />
          </button>
          <h3 className="text-lg text-black font-medium ml-4">Customer Details</h3>
        </div>
        <div className="mt-20 mx-4">
          <div className="grid gap-y-4">
            <div className="bg-white rounded-lg shadow-sm p-3">
              <div className="flex">
                <div className="bg-primary rounded-full py-2.5 px-4">
                  <span className="text-base text-white font-medium">
                    {customerDetail?.customer_name ? (customerDetail?.customer_name).charAt(0) : ''}
                  </span>
                </div>
                <div className="ml-2.5">
                  <h5 className="text-sm text-black/80 font-semibold">
                    {customerDetail?.customer_name || '--'}
                  </h5>
                  <div className="flex items-center">
                    <h6 className="text-xs text-black font-medium">Interested</h6>
                    <p className="text-black mx-1">∙</p>
                    <h6 className="text-xs text-black font-medium">
                      {moment(
                        customerDetail?.updated_at
                          ? customerDetail.updated_at
                          : customerDetail.created_at,
                      ).diff(new Date(), 'days') < 0
                        ? `${Math.abs(
                            moment(
                              customerDetail?.updated_at
                                ? customerDetail.updated_at
                                : customerDetail.created_at,
                            ).diff(new Date(), 'days'),
                          )} days ago`
                        : 'Today'}
                    </h6>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-5">
                <div>
                  <p className="text-[10px] text-gray-700 font-medium">Budget</p>
                  <h6 className="text-xs text-black font-medium">
                    {convertBudget(customerDetail?.budget) || '--'}
                  </h6>
                </div>
                <div>
                  <p className="text-[10px] text-gray-700 font-medium">Unit Configuration:</p>
                  <h6 className="text-xs text-black font-medium">
                    {customerUnitConfPrefference || '--'}
                  </h6>
                </div>
                <div>
                  <p className="text-[10px] text-gray-700 font-medium">Property Status:</p>
                  <h6 className="text-xs text-black font-medium">
                    {preferredPropertyStatus[customerDetail?.preferred_property_status] || '--'}
                  </h6>
                </div>
                <div>
                  <p className="text-[10px] text-gray-700 font-medium">Localities</p>
                  <h6 className="text-xs text-black font-medium">
                    {customerLocalityPrefference || '--'}
                  </h6>
                </div>
                <div>
                  <p className="text-[10px] text-gray-700 font-medium">Unit Type</p>
                  <h6 className="text-xs text-black font-medium">
                    {customerUnitTypePrefference || '--'}
                  </h6>
                </div>
              </div>
            </div>
            <div className="bg-white rounded-lg shadow-sm p-3">
              <h5 className="text-sm text-black/80 font-semibold">Category</h5>
              <div className="flex mt-2">
                {customerDetail?.category === 'HOT' ? (
                  <>
                    <CategoryFilled className="w-6 h-6" />
                    <CategoryFilled className="w-6 h-6" />
                    <CategoryFilled className="w-6 h-6" />
                  </>
                ) : customerDetail?.category === 'WARM' ? (
                  <>
                    <CategoryFilled className="w-6 h-6" />
                    <CategoryFilled className="w-6 h-6" />
                    <CategoryUnfilled className="w-6 h-6" />
                  </>
                ) : customerDetail?.category === 'COLD' ? (
                  <>
                    <CategoryUnfilled className="w-6 h-6" />
                    <CategoryUnfilled className="w-6 h-6" />
                    <CategoryUnfilled className="w-6 h-6" />
                  </>
                ) : (
                  <>
                    <CategoryUnfilled className="w-6 h-6" />
                    <CategoryUnfilled className="w-6 h-6" />
                    <CategoryUnfilled className="w-6 h-6" />
                  </>
                )}
              </div>
            </div>
            <div className="bg-white rounded-lg shadow-sm p-3">
              <h5 className="text-sm text-black/80 font-semibold">Upcoming Events</h5>
              {customerEvent.map((item, index) => {
                return (
                  <>
                    <div key={item?.id} className="flex items-center my-4">
                      <div
                        className={
                          item?.event_type === 'FOLLOW_UP'
                            ? 'lex bg-indigo-50 rounded-full p-2'
                            : item?.event_type === 'SITE_VISIT'
                            ? 'flex bg-pink-50 rounded-full p-2'
                            : 'flex bg-green-50 rounded-full p-2'
                        }
                      >
                        {item?.event_type === 'FOLLOW_UP' ? (
                          <FollowUp className="w-7 h-7" />
                        ) : item?.event_type === 'SITE_VISIT' ? (
                          <SiteVisit />
                        ) : (
                          <Meeting />
                        )}
                      </div>
                      <div className="ml-3">
                        <h5 className="text-xs text-gray-500 font-medium">
                          {eventType[item?.event_type]}

                          {filterProjectData(item?.project_id) ? (
                            <span className="ml-1">
                              - {filterProjectData(item?.project_id)?.project_name}
                            </span>
                          ) : (
                            <></>
                          )}
                        </h5>
                        <div className="flex items-center mt-1.5">
                          <DotGreen />
                          <h6 className="text-xs text-black font-medium ml-1">
                            {moment
                              .utc(item.scheduled_time)
                              .utcOffset('+05:30')
                              .format('Do MMM YYYY, hh:mm A')}
                          </h6>
                        </div>
                      </div>
                    </div>
                    {customerEvent.length === index + 1 ? <></> : <hr className="my-5" />}
                  </>
                );
              })}
            </div>
            <div className="bg-white rounded-lg shadow-sm p-3">
              <h5 className="text-sm text-black/80 font-semibold">Preferred Projects</h5>
              <div className="mt-5">
                {customerLeadProjects.map((item, index) => {
                  return (
                    <>
                      <div className="flex items-center justify-between">
                        <div className="flex items-center">
                          <img
                            src={item?.thumbnail_image || dummyProjectImg}
                            className="w-20 h-20 rounded-md"
                            alt="project_img"
                          />
                          <div className="ml-3">
                            <h5 className="text-sm text-black/80 font-medium">{item?.name}</h5>
                            <h6 className="text-xs text-black/80 ont-medium mt-1">
                              {item?.address}
                            </h6>
                            <div className="flex items-center mt-2">
                              <DotGreen />
                              <h6 className="text-xs text-black/80 font-medium ml-1.5">
                                {customerLeads.map(leads => {
                                  if (leads?.project_id === item?.id) {
                                    return statusList[leads?.status];
                                  }
                                })}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <button onClick={() => handleProjectShare(item?.id)}>
                          <Share />
                        </button>
                      </div>
                    </>
                  );
                })}
                {/* share bottom sheet starts */}
                <BottomSheet open={openProjectShare}>
                  <div className="h-1/2 py-6 px-8">
                    <div className="flex justify-between">
                      <h4 className="text-base text-black font-semibold">Share this Project</h4>
                      <button className="border-none" onClick={handleProjectShare}>
                        <Cross />
                      </button>
                    </div>
                    <hr className="border border-slate-500 my-4" />
                    <h4 className="text-base text-black/80 text-center font-normal">
                      Share Project details via
                    </h4>
                    <div>
                      <div className="flex justify-between items-center border-2 border-slate-500 rounded-xl py-1.5 pr-1.5 pl-4 my-4">
                        <h6 className="text-sm text-black/80 font-medium">Whatsapp</h6>
                        <a
                          href={`whatsapp://send?text=${projectShareUrl}`}
                          data-action="share/whatsapp/share"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <button className="border border-green-100 bg-green-100 rounded-lg p-2">
                            <WhatsApp className="w-4 h-4" />
                          </button>
                        </a>
                      </div>
                      <div className="flex justify-between items-center border-2 border-slate-500 rounded-xl py-1.5 pr-1.5 pl-4 my-4">
                        <h6 className="text-sm text-black/80 font-medium">Gmail</h6>
                        <button
                          onClick={() =>
                            window.open(
                              `https://mail.google.com/mail/?view=cm&fs=1&to=user@example.com&su=Check out this Project!&body=${projectShareUrl}`,
                              '_blank',
                            )
                          }
                          className="border border-gray-200 bg-gray-200 rounded-lg p-2"
                        >
                          <Mail className="w-4 h-4" />
                        </button>
                      </div>
                    </div>
                    <h4 className="text-base text-black text-center font-semibold mb-4">Or</h4>
                    <h4 className="text-base text-black/80 text-center font-normal mb-4">
                      Copy below link to your clipboard and send it to people!
                    </h4>
                    <div className="flex justify-between items-center border-2 border-slate-500 rounded-xl py-1.5 pr-1.5 pl-4 my-4">
                      <h6 className="text-sm text-black/80 font-medium">{`${projectShareUrl}`}</h6>
                      <button
                        className="p-1.5"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${process.env.REACT_APP_PROJECT_API_URL}/cp/project/${shareProjectId}`,
                          );
                        }}
                      >
                        <Copy className="w-6 h-6" />
                      </button>
                    </div>
                  </div>
                </BottomSheet>
                {/* share bottom sheet ends */}
              </div>
            </div>
            <div className="bg-white rounded-lg shadow-sm p-3">
              <h5 className="text-sm text-black/80 font-semibold">Transfer Customer</h5>
              <div className="flex items-center justify-evenly gap-8 mt-5">
                <button
                  onClick={handleTransfeTeammateModal}
                  disabled={
                    (customerDetail?.status === 'NEW' || customerDetail?.status === 'INTERESTED') &&
                    cpBusinessProfile?.business_type === 'FIRM'
                      ? false
                      : true
                  }
                  className={
                    (customerDetail?.status === 'NEW' || customerDetail?.status === 'INTERESTED') &&
                    cpBusinessProfile?.business_type === 'FIRM'
                      ? 'px-4 mr-3 items-center'
                      : 'px-4 mr-3 items-center opacity-50'
                  }
                >
                  <TransferTeammate className="w-10 h-10 ml-8" />
                  <h6 className="text-xs text-black font-medium mt-3">Transfer to Teammate</h6>
                </button>
                <BottomSheet open={transferTeammate}>
                  <div className="p-4">
                    <div className="flex flex-1 justify-between">
                      <h4 className="text-base text-black font-medium">Transfer to teammate</h4>
                      <button onClick={handleTransfeTeammateModal}>
                        <Cross />
                      </button>
                    </div>
                    <p className="text-xs text-gray-600 font-normal">
                      Select Teammate to transfer customers:
                    </p>
                    <h6 className="text-xs text-gray-600 font-semibold mt-6 mb-4">
                      Choose teammate
                      <span className="text-red-700 font-medium">*</span>
                    </h6>
                    <div className="h-72 overflow-y-auto">
                      {allUserList.map((item, index) => {
                        return (
                          <div key={item?.id} className="flex items-center mb-6">
                            <button
                              onClick={() => {
                                setTeammateSelected(item?.id);
                              }}
                            >
                              {teammateSelected === item?.id ? <CheckRadio /> : <UnCheckRadio />}
                            </button>
                            <h5 className="text-sm text-black/80 font-normal ml-2.5">
                              {item?.name || '--'}
                            </h5>
                          </div>
                        );
                      })}
                    </div>
                    <button
                      onClick={teamMateTransfer}
                      className={
                        teammateSelected === ''
                          ? 'w-full border border-gray-500 bg-gray-500 rounded-lg p-4'
                          : 'w-full border border-purple-700 bg-primary rounded-lg p-4'
                      }
                    >
                      <h4 className="text-sm text-white font-medium">Transfer to Teammate</h4>
                    </button>
                  </div>
                </BottomSheet>
                <hr className="border border-gray-300 h-20" />
                <button
                  onClick={handleSendCallCanterModal}
                  disabled={customerDetail?.status !== 'NEW' ? true : false}
                  className={
                    customerDetail?.status === 'NEW'
                      ? 'px-4 mr-3 items-center'
                      : 'px-4 mr-3 items-center opacity-50'
                  }
                >
                  <SendCallCenter className="w-10 h-10 ml-8" />
                  <h6 className="text-xs text-black font-medium mt-3">Send to Call Centre</h6>
                </button>
                <BottomSheet open={sendCallCanter}>
                  <div className="p-4">
                    <div>
                      <div className="flex flex-1 justify-between">
                        <h4 className="text-base text-black font-medium">Send to Call Center</h4>
                        <button onClick={handleSendCallCanterModal}>
                          <Cross />
                        </button>
                      </div>
                      <p className="text-xs text-gray-600 font-normal">
                        Select Projects (Max 2) to send to Call Centre:
                      </p>
                      <h6 className="text-xs text-gray-600 font-semibold mt-6 mb-4">
                        Choose Project
                        <span className="text-red-700 font-medium">*</span>
                      </h6>
                      <div className="flex items-center border bg-gray-200 rounded-md py-3 px-5">
                        <span>
                          <SearchIcon className="w-4 h-4" />
                        </span>
                        <input
                          placeholder="Search project"
                          className="w-full outline-none text-xs text-black font-normal border-gray-200 bg-gray-200 ml-2.5"
                          onChange={handleSearch}
                        />
                      </div>
                      {projectLimitErr ? (
                        <div className="flex items-center mt-3">
                          <ExclamationMark />
                          <h6 className="text-xs text-black font-medium ml-2">
                            You have selected the maximum number of projects
                          </h6>
                        </div>
                      ) : (
                        <></>
                      )}
                      <h6 className="text-xs text-gray-600 font-medium mt-3 mb-4">
                        Recommended Projects:
                      </h6>
                      <div className="h-72 overflow-y-auto">
                        {customerLeadProjects
                          ?.filter(item =>
                            item?.name
                              ? item?.name.toLowerCase().includes(search.toLowerCase())
                              : '',
                          )
                          .map((sub_item, sub_index) => {
                            return (
                              <div key={`teammate-${sub_index}`} className="flex items-center mb-6">
                                <button
                                  onClick={() => {
                                    setProjectSelected([sub_item?.id]);
                                  }}
                                >
                                  {projectSelected === sub_item?.id ? (
                                    <CheckCircle className="w-6 h-6" />
                                  ) : (
                                    <UncheckCircle className="w-6 h-6" />
                                  )}
                                </button>
                                <h5 className="text-sm text-black/80 font-normal ml-2.5">
                                  {sub_item?.name}
                                </h5>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <button
                      onClick={sendCallCanterApiCall}
                      className="w-full border border-purple-700 bg-primary rounded-lg p-4"
                    >
                      <h4 className="text-sm text-white font-medium">Send to Call Centre</h4>
                    </button>
                  </div>
                </BottomSheet>
              </div>
            </div>
            <div className="bg-white rounded-lg shadow-sm p-3">
              <h5 className="text-sm text-black/80 font-semibold">History</h5>
              <div className="flex items-center justify-evenly mt-5">
                <div className="flex items-center">
                  <div className="flex bg-indigo-50 rounded-full p-2">
                    <FollowUp className="w-5 h-5" />
                  </div>
                  <div className="ml-2">
                    <h5 className="text-[10px] text-black/80 font-medium">Follow Up</h5>
                    <h6 className="text-base text-black font-medium ml-1">
                      {historyData?.FOLLOW_UP ? historyData?.FOLLOW_UP : 0}
                    </h6>
                  </div>
                </div>
                <hr className="border border-gray-200 h-10" />
                <div className="flex items-center">
                  <div className="flex bg-green-50 rounded-full p-2">
                    <Meeting className="w-5 h-5" />
                  </div>
                  <div className="ml-2">
                    <h5 className="text-[10px] text-black/80 font-medium">Meeting</h5>
                    <h6 className="text-base text-black font-medium ml-1">
                      {historyData?.MEETING ? historyData?.MEETING : 0}
                    </h6>
                  </div>
                </div>
                <hr className="border border-gray-200 h-10" />
                <div className="flex items-center">
                  <div className="flex bg-pink-50 rounded-full p-2">
                    <SiteVisit className="w-5 h-5" />
                  </div>
                  <div className="ml-2">
                    <h5 className="text-[10px] text-black/80 font-medium">Site Visit</h5>
                    <h6 className="text-base text-black font-medium ml-1">
                      {historyData?.SITE_VISIT ? historyData?.SITE_VISIT : 0}
                    </h6>
                  </div>
                </div>
              </div>
              <hr className="border border-gray-200 rotate-180 mt-5" />
              <div className="flex items-center mt-3.5">
                <button
                  onClick={() => {
                    navigate(`/crm/customer_history/${customerDetail?.id}`);
                  }}
                  className="flex flex-1 items-center"
                >
                  <History />
                  <h5 className="text-sm text-primary font-medium ml-2">View complete history</h5>
                </button>
                <ArrowDown />
              </div>
            </div>
            <div className="bg-white rounded-lg shadow-sm p-3.5">
              <h5 className="text-sm text-black/80 font-semibold">Add Note</h5>
              <textarea
                onChange={handleChange}
                value={notes}
                rows={4}
                className="w-full border border-purple-50 rounded-lg text-sm text-black font-medium mt-5 mb-3"
              />
              <div className="flex justify-end">
                <button onClick={addNotes} className="bg-purple-50 rounded-lg py-2 px-6 mb-5">
                  <span className="text-sm text-primary font-medium">Add Note</span>
                </button>
              </div>
              <hr className="border border-gray-200" />
              <div className="flex items-center mt-3.5">
                <button
                  onClick={() => {
                    navigate(`/crm/customer_history/${customerDetail?.id}`);
                  }}
                  className="flex flex-1 items-center"
                >
                  <ViewNotes />
                  <h5 className="text-sm text-primary font-medium ml-2">View All Notes</h5>
                </button>
                <ArrowDown className="rotate-90'" />
              </div>
            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={50000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </div>
    </div>
  );
}
