import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { projectServiceSchemas } from 'types/projectServiceSchemas';
import { request } from 'utils/request';
import { projectListActions as actions } from '.';

/**
 * Projects request/response handler
 */
export function* getProjectDetails(id) {
  yield delay(500);

  const requestURL = `${process.env.REACT_APP_PROJECT_API_URL}/cp/project/${id?.payload}/`;

  try {
    const projectDetails: projectServiceSchemas['CpProjectComplexResponse'] = yield call(
      request,
      requestURL,
    );

    if (projectDetails?.id) {
      yield put(actions.projectDetailsLoaded(projectDetails));
    } else {
      yield put(
        actions.projectDetailsError('Something went wrong while fetching project details.'),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.projectDetailsError('Project not found.'));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.projectDetailsError('Failed to fetch project.'));
    } else {
      yield put(
        actions.projectDetailsError('Something went wrong while fetching project details.'),
      );
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* projectDetailsSaga() {
  // Watches for loadRepos actions and calls getProjectDetails when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.loadProjectDetails.type, getProjectDetails);
}
