import { PayloadAction } from '@reduxjs/toolkit';
import { authServiceSchemas } from 'types/authSchemaService';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { usersListSaga } from './saga';
import { usersListState } from './types';

export const initialState: usersListState = {
  loading: true,
  error: false,
  errorMsg: '',
  users: [],
  usersTotal: 0,
  filter_obj: '',
};

const slice = createSlice({
  name: 'usersList',
  initialState,
  reducers: {
    loadusers(state, action) {
      state.loading = true;
      state.error = false;
      state.users = [];
      const filter_obj = action?.payload;
      state.filter_obj = filter_obj;
    },
    usersLoaded(
      state,
      action: PayloadAction<{
        items: authServiceSchemas['UserResponse'][];
        total: number;
      }>,
    ) {
      const users = action?.payload;
      state.users = users?.items;
      state.usersTotal = users?.total;
      state.loading = false;
    },
    usersError(state, action: PayloadAction<string>) {
      state.error = true;
      state.errorMsg = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: usersListActions, reducer } = slice;

export const useAllUsersListSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: usersListSaga });
  return { actions: slice.actions };
};
