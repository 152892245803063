import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.unitDetails || initialState;

export const selectUnitDetails = createSelector(
  [selectDomain],
  unitDetailsState => unitDetailsState.unitDetails,
);

export const selectUnitDetailsLoading = createSelector(
  [selectDomain],
  unitDetailsState => unitDetailsState.loading,
);

export const selectUnitDetailsError = createSelector(
  [selectDomain],
  unitDetailsState => unitDetailsState.error,
);

export const selectUnitDetailsErrorMsg = createSelector(
  [selectDomain],
  unitDetailsState => unitDetailsState.errorMsg,
);
