import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.customersEvent || initialState;

export const selectCustomersEvent = createSelector(
  [selectDomain],
  customersEventState => customersEventState.customersEvent,
);

export const selectCustomersEventTotal = createSelector(
  [selectDomain],
  customersEventState => customersEventState.customersEventTotal,
);

export const selectCustomersEventPage = createSelector(
  [selectDomain],
  customersEventState => customersEventState.customersEventPage,
);

export const selectCustomersEventSize = createSelector(
  [selectDomain],
  customersEventState => customersEventState.customersEventSize,
);

export const selectLoading = createSelector(
  [selectDomain],
  customersEventState => customersEventState.loading,
);

export const selectError = createSelector(
  [selectDomain],
  customersEventState => customersEventState.error,
);

export const selectErrorMsg = createSelector(
  [selectDomain],
  customersEventState => customersEventState.errorMsg,
);
