import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { projectsListResponse } from 'types/projectServiceSchemas';
import { request } from 'utils/request';
import { projectSearchActions as actions } from '.';

export function* getProjectSearch(searchInput) {
  yield delay(500);

  const requestURL = `${process.env.REACT_APP_PROJECT_API_URL}/search_project_and_developer?search_name=${searchInput.payload}`;

  try {
    const projects: projectsListResponse = yield call(request, requestURL);

    if (projects?.items?.length > 0) {
      yield put(
        actions.projectsSearchLoaded({
          items: projects?.items,
          total: projects?.total,
        }),
      );
    } else {
      yield put(actions.projectError('Something went wrong while fetching projects.'));
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.projectError('Projects not found.'));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.projectError('Failed to fetch projects.'));
    } else {
      yield put(actions.projectError('Something went wrong while fetching projects.'));
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* projectSearchSaga() {
  // Watches for loadRepos actions and calls getProjectSearch when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.loadProjectsSearch.type, getProjectSearch);
}
