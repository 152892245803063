import { CustomerDetailsCardMobile } from 'app/components/CustomerDetailsCardMobile';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { BottomSheet } from 'react-spring-bottom-sheet';
import 'react-spring-bottom-sheet/dist/style.css';
import { ToastContainer, toast } from 'react-toastify';
import { postRequest } from 'utils/request';
import { ReactComponent as Cross } from '../../assets/close.svg';
import { ReactComponent as Arrow } from '../../assets/crm/arrow_forward.svg';
import { ReactComponent as SearchIconPurple } from '../../assets/crm/searchpurple.svg';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { useChannelPartnersListSlice } from '../MyCustomers/slices/allCpCurrentOrgslice';
import { selectChannelPartners } from '../MyCustomers/slices/allCpCurrentOrgslice/selectors';
import { useAllUsersListSlice } from '../MyCustomers/slices/allUsersslice';
import { selectAllUsers } from '../MyCustomers/slices/allUsersslice/selectors';
import { useCustomersListSlice } from '../MyCustomers/slices/customerListslice';
import { selectCustomers } from '../MyCustomers/slices/customerListslice/selectors';
import { ReactComponent as UnCheckRadio } from './../../assets/crm/radio_button_unchecked.svg';
import { ReactComponent as CheckRadio } from './../../assets/crm/radio_check_button.svg';
const useEffectOnMount = (effect: React.EffectCallback) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
};
export function TransferTeammate(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { actions } = useCustomersListSlice();
  const { actions: userAction } = useAllUsersListSlice();
  const { actions: channelpartnersactions } = useChannelPartnersListSlice();

  const customerList = useSelector(selectCustomers);
  const userList = useSelector(selectAllUsers);
  const channelPartnerList = useSelector(selectChannelPartners);

  useEffectOnMount(() => {
    if (customerList.length === 0) {
      dispatch(actions.loadCustomers({ status: 'NEW' }));
    }
    if (userList.length === 0) {
      dispatch(channelpartnersactions.loadchannelPartners({}));
    }
  });

  useEffect(() => {
    let userIdsArr: any = [];
    channelPartnerList.map(cp => {
      userIdsArr.push(cp?.user_id);
    });
    let filter_obj: any = {
      user_ids: userIdsArr.join(','),
    };

    if (filter_obj) {
      dispatch(userAction.loadusers(filter_obj));
    }
  }, [channelPartnerList]);

  const [search, setSearch] = useState('');

  const [teammateSelected, setTeammateSelected] = useState<any>('');

  const [transferTeammate, setTransferTeammate] = useState(false);

  const handleTransferTeammate = () => {
    setTransferTeammate(!transferTeammate);
  };

  const handleSearch = e => {
    const lowerCase = e.target.value.toLowerCase();
    setSearch(lowerCase);
  };

  const [transferCusList, setTransferCusList] = useState<any>([]);
  const [optionSelected, setOptionSelected] = useState('');
  const [inputText, setInputText] = useState(0);

  useEffect(() => {
    let customerIdsArr = [...transferCusList];

    if (optionSelected === 'select_all') {
      customerList.map((item, index) => {
        if (item?.id) {
          if (!customerIdsArr.includes(item?.id)) {
            customerIdsArr.push(item?.id);
          }
        }
      });
      setTransferCusList(customerIdsArr);
      setInputText(customerIdsArr?.length);
    }
  }, [optionSelected]);

  const selectedCustomer = id => {
    setOptionSelected('custom_select');
    let transferListTemp = [...transferCusList];
    if (transferListTemp.includes(id)) {
      setTransferCusList([...transferListTemp.filter(ele => id != ele)]);
      setInputText(transferCusList.length - 1);
    } else {
      transferListTemp.push(id);
      setInputText(transferCusList.length + 1);
      setTransferCusList([...transferListTemp]);
    }
  };

  const bulkTeamMateTransfer = () => {
    let data = {
      customer_id: transferCusList,
      transferee_cp_id: teammateSelected,
      tranferror_cp_id: localStorage.getItem('userID'),
    };
    const requestURL = `${process.env.REACT_APP_DDP_CRM_API_URL}/customer/transfer/`;
    if (requestURL && teammateSelected) {
      postRequest(requestURL, data).then(res => {
        if (res.status === 200) {
          toast.success('Customer Transferred Successfully!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        } else if (res.status !== 200) {
          toast.error('Customer Transferred Failed!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
        return res;
      });
    }
    handleTransferTeammate();
    navigate(-1);
  };

  const clearValues = () => {
    setTransferCusList([]);
    setInputText(0);
    navigate(-1);
  };

  return (
    <div>
      <ToastContainer
        position="bottom-right"
        autoClose={50000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="py-14">
        <div className="w-full fixed top-14 flex items-center bg-white shadow-sm py-4 px-5">
          <a onClick={clearValues}>
            <Arrow className="w-7 h-7 rotate-180" />
          </a>
          <h3 className="text-lg text-black font-medium ml-4">Select customers to transfer</h3>
        </div>
        <div className="my-20 m-4">
          <div
            className={
              search.length > 0
                ? 'w-full border border-purple-700 bg-white rounded-lg p-3'
                : 'w-full border border-gray-400 bg-white rounded-lg p-3'
            }
          >
            <label className="flex items-center">
              {search.length > 0 ? <SearchIconPurple /> : <SearchIcon className="w-5 h-5" />}
              <input
                className="w-full outline-none text-sm text-black bg-white ml-2"
                type="search"
                placeholder="Search by Customer Name"
                onChange={handleSearch}
              />
            </label>
          </div>
          <div className="flex items-center justify-between my-6">
            <h5 className="text-sm text-black font-medium">{inputText} Leads Selected</h5>
            <button
              onClick={() => setOptionSelected('select_all')}
              className="border-2 border-purple-50 bg-white rounded-md py-2.5 px-5"
            >
              <h6 className="text-xs text-black font-medium">Select All</h6>
            </button>
          </div>
          {customerList
            ?.filter(item =>
              item?.customer_name
                ? item?.customer_name.toLowerCase().includes(search.toLowerCase())
                : '',
            )
            .map((sub_item, sub_index) => (
              <CustomerDetailsCardMobile
                data={sub_item}
                multiselect={true}
                selectedBar={1}
                onSelect={selectedCustomer}
                phoneNotComplete={sub_item?.phone_no?.length !== 10}
                isSelected={
                  transferCusList.filter(data => data === sub_item.id).length > 0 ? true : false
                }
                type={optionSelected}
              />
            ))}
        </div>
      </div>
      <div className="w-full fixed bottom-0 flex items-center justify-between bg-white rounded-lg shadow-xl pt-4 pb-6 px-4">
        <button onClick={clearValues} className="w-24 border border-red-700 rounded-md p-2.5">
          <h5 className="text-sm text-red-700 font-normal">Cancel</h5>
        </button>
        <button
          onClick={handleTransferTeammate}
          className="w-44 border border-purple-700 bg-primary rounded-md p-2.5"
        >
          <h5 className="text-sm text-white font-normal">Transfer to Teammate</h5>
        </button>
        <BottomSheet open={transferTeammate}>
          <div className="p-4">
            <div className="flex flex-1 justify-between">
              <h4 className="text-base text-black font-medium">Transfer to teammate</h4>
              <button onClick={handleTransferTeammate}>
                <Cross />
              </button>
            </div>
            <p className="text-xs text-gray-600 font-normal">
              Select Teammate to transfer customers:
            </p>
            <h6 className="text-xs text-gray-600 font-semibold mt-6 mb-4">
              Choose teammate
              <span className="text-red-700 font-medium">*</span>
            </h6>
            <div className="h-72 overflow-y-auto">
              {userList.map((item, index) => {
                return (
                  <div key={`teammate-${index}`} className="flex items-center mb-6">
                    <button
                      onClick={() => {
                        setTeammateSelected(item?.id);
                      }}
                    >
                      {teammateSelected === item?.id ? <CheckRadio /> : <UnCheckRadio />}
                    </button>
                    <h5 className="text-sm text-black/80 font-normal ml-2.5">{item?.name}</h5>
                  </div>
                );
              })}
            </div>
            <button
              disabled={teammateSelected === 0}
              onClick={bulkTeamMateTransfer}
              className={
                teammateSelected === 0
                  ? 'w-full border border-gray-500 bg-gray-500 rounded-lg p-4'
                  : 'w-full border border-purple-700 bg-primary rounded-lg p-4'
              }
            >
              <h4 className="text-sm text-white font-medium">Transfer to Teammate</h4>
            </button>
          </div>
        </BottomSheet>
      </div>
    </div>
  );
}
