import { PayloadAction } from '@reduxjs/toolkit';
import { projectServiceSchemas } from 'types/projectServiceSchemas';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { projectDetailsSaga } from './saga';
import { ProjectsDetailsState } from './types';

export const initialState: ProjectsDetailsState = {
  loading: true,
  error: false,
  errorMsg: '',
  projectDetails: {},
};

const slice = createSlice({
  name: 'projectDetails',
  initialState,
  reducers: {
    loadProjectDetails(state, id) {
      state.loading = true;
      state.error = false;
      state.projectDetails = {};
    },
    projectDetailsLoaded(
      state,
      action: PayloadAction<projectServiceSchemas['CpProjectComplexResponse']>,
    ) {
      const projectDetails = action.payload;
      state.projectDetails = projectDetails;
      state.loading = false;
    },
    projectDetailsError(state, action: PayloadAction<string>) {
      state.error = true;
      state.errorMsg = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: projectListActions, reducer } = slice;

export const useProjectsDetailsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: projectDetailsSaga });
  return { actions: slice.actions };
};
