import { useUnitConfigurationListSlice } from 'app/pages/ProjectListPage/Projects/ProjectList/slices/untiConfigurationSlice';
import { selectUnitConfigurations } from 'app/pages/ProjectListPage/Projects/ProjectList/slices/untiConfigurationSlice/selectors';
import moment from 'moment';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useCustomerHistorylice } from 'app/pages/MyCustomers/slices/customerHistorySlice';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { convertBudget, convertTotalPrice } from 'utils/helper';
import { ReactComponent as Sent } from './../../assets/history icon/Sent.svg';

import { getRequest } from 'utils/request';
import { ReactComponent as CategoryUnfilled } from './../../assets/crm/Category unfill.svg';
import { ReactComponent as CategoryFilled } from './../../assets/crm/categiry fill.svg';
import { ReactComponent as CheckCircle } from './../../assets/crm/checkboxfill.svg';
import { ReactComponent as UncheckCircle } from './../../assets/crm/chek unfill box.svg';
import { ReactComponent as Dot } from './../../assets/crm/dot.svg';
import { ReactComponent as DotGreen } from './../../assets/crm/dotGreen.svg';
import { ReactComponent as DotRed } from './../../assets/crm/redDot.svg';

const useEffectOnMount = (effect: React.EffectCallback) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
};

export function CustomerDetailsCardMobile(props) {
  const navigate = useNavigate();
  const customerDetails = props?.data;
  const customerBookingDetail = props?.bookingData;
  const activeTab = props?.active_tab;
  const onSelect = props?.onSelect;

  console.log(customerDetails, 'customerDetails');
  console.log(customerBookingDetail, 'customerBookingDetail');

  const { actions: customerhistoryactions } = useCustomerHistorylice();

  const [multiSelect, setMultiSelect] = useState(props?.multiselect);
  const [type, setType] = useState('');
  const [cardEventType, setCardEventType] = useState<any>([]);

  useEffect(() => {
    if (type === '') {
      setType(props?.type);
    }
  }, [props?.type]);

  useEffect(() => {
    dispatch(customerhistoryactions.loadCustomerHistory(customerDetails?.id));
  }, [customerDetails]);

  const [followUpDate, setFollowUpDate] = useState<any>({});
  const [meetingDate, setMeetingDate] = useState<any>({});
  useEffect(() => {
    setFollowUpDate(cardEventType.find(event => event.event_type === 'FOLLOW_UP'));
    setMeetingDate(cardEventType.find(event => event.event_type === 'MEETING'));
  }, [cardEventType]);

  const dispatch = useDispatch();

  useEffectOnMount(() => {
    dispatch(unitconfigurationsactions.loadUnitConfiguration());
  });

  const { actions: unitconfigurationsactions } = useUnitConfigurationListSlice();

  const unitConfigurations = useSelector(selectUnitConfigurations);

  const [customerUnitConfPrefference, setcustomerUnitConfPrefference] = useState('');

  const [customerLocalityPrefference, setcustomerLocalityPrefference] = useState('');
  const [customerLeadProjectsIds, setcustomerLeadProjectsIds] = useState('');

  const [customerLeadProjects, setcustomerLeadProjects] = useState<any>([]);

  useEffect(() => {
    let arr: any = [];
    customerDetails?.customer_unit_configuration_preference?.map(id => {
      let unit_configuraion_prefference = unitConfigurations.filter(data => data?.id === id);
      if (unit_configuraion_prefference.length > 0) {
        arr.push(unit_configuraion_prefference[0]);
      }
    });
    setcustomerUnitConfPrefference(arr.map(item => item?.name).join(', '));
  }, [unitConfigurations, customerDetails]);

  useEffect(() => {
    let arr: any = [];
    let projectArr: any = [];
    customerDetails?.customer_locality_preference?.map(item => {
      arr.push(item?.google_places_response?.results[0]?.formatted_address);
    });
    if (customerDetails?.customer_event?.length) {
      const cardEventArr: any = customerDetails?.customer_event.filter(
        event => new Date(event.scheduled_time).getDate() >= new Date().getDate(),
      );
      if (cardEventArr.length) {
        setCardEventType(
          Array.from(cardEventArr).sort(
            (a: any, b: any) =>
              moment(a.scheduled_time).valueOf() - moment(b.scheduled_time).valueOf(),
          ),
        );
      }
    }

    customerDetails?.customer_lead?.map(lead => {
      projectArr.push(lead?.project_id);
    });
    setcustomerLocalityPrefference(arr.join(', '));
    setcustomerLeadProjectsIds(projectArr?.join(','));
  }, [customerDetails]);

  useEffect(() => {
    if (customerBookingDetail?.customer_lead?.project_id) {
      setcustomerLeadProjectsIds(customerBookingDetail?.customer_lead?.project_id);
    }
  }, [customerBookingDetail]);

  useEffect(() => {
    const requestURL = `${process.env.REACT_APP_PROJECT_API_URL}/cp/project?project_ids=${customerLeadProjectsIds}`;
    if (requestURL && customerLeadProjectsIds) {
      getRequest(requestURL).then(res => {
        setcustomerLeadProjects(res?.data?.items);
      });
    }
  }, [customerLeadProjectsIds]);

  const setSelectedCustomer = data => {
    if (onSelect) {
      onSelect(data);
    }
  };

  return (
    <>
      <button
        key={customerDetails?.id}
        onClick={
          multiSelect
            ? () => {
                setSelectedCustomer(customerDetails?.id);
                setType('custom_select');
              }
            : () =>
                navigate(
                  `/crm/customer_details/${
                    customerDetails?.id || customerBookingDetail?.customer_lead?.customer_id
                  }`,
                )
        }
        disabled={props?.phoneNotComplete && props.selectedBar === 2}
        className={
          ((props.selectedBar && props?.isSelected) || type === 'select_all') &&
          !(props?.phoneNotComplete && props?.selectedBar === 2)
            ? 'w-full border-2 border-purple-700 bg-white rounded-lg shadow-sm p-3 mb-2'
            : 'w-full border bg-white rounded-lg shadow-sm p-3 mb-2'
        }
      >
        <div className="flex items-center justify-between mb-1">
          <div className="flex items-center">
            <span>
              {((props.selectedBar && props?.isSelected) || type === 'select_all') &&
              !(props?.phoneNotComplete && props?.selectedBar === 2) ? (
                <CheckCircle className="w-5 h-5 mr-3" />
              ) : multiSelect ? (
                <UncheckCircle className="w-5 h-5 mr-3" />
              ) : (
                <></>
              )}
            </span>

            <h5 className="text-base text-black font-medium">
              {customerDetails?.customer_name ||
                customerBookingDetail?.customer_lead?.customer?.customer_name}
            </h5>
          </div>
          <div className="flex">
            {customerDetails?.category === 'HOT' ||
            customerBookingDetail?.customer_lead?.customer?.category === 'HOT' ? (
              <>
                <CategoryFilled className="w-6 h-6" />
                <CategoryFilled className="w-6 h-6" />
                <CategoryFilled className="w-6 h-6" />
              </>
            ) : customerDetails?.category === 'WARM' ||
              customerBookingDetail?.customer_lead?.customer?.category === 'WARM' ? (
              <>
                <CategoryFilled className="w-6 h-6" />
                <CategoryFilled className="w-6 h-6" />
                <CategoryUnfilled className="w-6 h-6" />
              </>
            ) : customerDetails?.category === 'COLD' ||
              customerBookingDetail?.customer_lead?.customer?.category === 'COLD' ? (
              <>
                <CategoryFilled className="w-6 h-6" />
                <CategoryUnfilled className="w-6 h-6" />
                <CategoryUnfilled className="w-6 h-6" />
              </>
            ) : (
              <>
                <CategoryUnfilled className="w-6 h-6" />
                <CategoryUnfilled className="w-6 h-6" />
                <CategoryUnfilled className="w-6 h-6" />
              </>
            )}
          </div>
        </div>
        <div className="flex items-center">
          {customerDetails?.budget ? (
            <h6 className="text-xs text-left text-gray-500 font-medium truncate w-32">
              {convertBudget(customerDetails?.budget) || ''}
            </h6>
          ) : (
            <></>
          )}
          {customerUnitConfPrefference ? (
            <>
              <Dot className="mx-1" />
              <h6 className="text-xs text-left text-gray-500 font-medium truncate w-32">
                {customerUnitConfPrefference || ''}
              </h6>
            </>
          ) : (
            <></>
          )}
          {customerLocalityPrefference ? (
            <>
              <Dot className="mx-1" />
              <h6 className="text-xs text-left text-gray-500 font-medium truncate w-32">
                {customerLocalityPrefference}
              </h6>
            </>
          ) : (
            <></>
          )}
        </div>
        {customerLeadProjects[0]?.name ? (
          <div className="flex flex-1 ml-1 mt-2">
            <div className="grid grid-flow-row">
              <div className="flex text-left">
                <img
                  src={
                    customerLeadProjects[0]?.thumbnail_image
                      ? customerLeadProjects[0]?.thumbnail_image
                      : 'https://images.indianexpress.com/2016/02/green-building759.jpg'
                  }
                  className="xl:w-10 xl:h-10 xl:rounded-lg lg:w-8 lg:h-8 lg:rounded-lg w-7 h-7 rounded-md"
                  alt=""
                />
                <h5 className="text-sm text-black font-medium m-auto ml-4">
                  {customerLeadProjects[0]?.name}
                </h5>
              </div>
              {customerLeadProjects[1]?.name ? (
                <div className="flex text-left mt-2">
                  <img
                    src={
                      customerLeadProjects[1]?.thumbnail_image
                        ? customerLeadProjects[1]?.thumbnail_image
                        : 'https://images.indianexpress.com/2016/02/green-building759.jpg'
                    }
                    className="xl:w-10 xl:h-10 xl:rounded-lg lg:w-8 lg:h-8 lg:rounded-lg w-7 h-7 rounded-md"
                    alt=""
                  />
                  <h5 className="text-sm text-black font-medium m-auto ml-4">
                    {customerLeadProjects[1]?.name}
                  </h5>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <div className=" flex flex-1 "></div>
        )}

        <div className="grid gap-y-2 border bg-white rounded-lg shadow-sm p-3 mt-4">
          {customerBookingDetail?.booking_date ? (
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <DotGreen className="w-2.5 h-2.5" />
                {customerBookingDetail?.booking_date ? (
                  <h6 className="text-xs text-gray-600 font-medium ml-1.5">Booking Request Date</h6>
                ) : (
                  <></>
                )}
              </div>
              {customerBookingDetail?.booking_date ? (
                <h6 className="flex items-center justify-between text-xs">
                  {moment(customerBookingDetail?.booking_date)
                    .utcOffset('+05:30')
                    .format('Do MMM, YYYY')}
                </h6>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
          {customerBookingDetail?.agreement_value ? (
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <DotGreen className="w-2.5 h-2.5" />
                {customerBookingDetail?.agreement_value ? (
                  <h6 className="text-xs text-gray-600 font-medium ml-1.5">Total Price</h6>
                ) : (
                  <></>
                )}
              </div>
              {customerBookingDetail?.agreement_value ? (
                <h6 className="flex items-center justify-between text-xs">
                  {convertTotalPrice(customerBookingDetail?.agreement_value)}
                </h6>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
          {customerBookingDetail?.unit_number ? (
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <DotGreen className="w-2.5 h-2.5" />
                {customerBookingDetail?.unit_number ? (
                  <h6 className="text-xs text-gray-600 font-medium ml-1.5">Unit</h6>
                ) : (
                  <></>
                )}
              </div>
              {customerBookingDetail?.unit_number ? (
                <h6 className="flex items-center justify-between text-xs">
                  {customerBookingDetail?.unit_number}
                </h6>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
          {customerDetails?.created_at ? (
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <DotGreen className="w-2.5 h-2.5" />
                {customerDetails?.created_at ? (
                  <h6 className="text-xs text-gray-600 font-medium ml-1.5">Added</h6>
                ) : (
                  <></>
                )}
              </div>
              {customerDetails?.created_at ? (
                <h6 className="flex items-center justify-between text-xs">
                  {moment(customerDetails?.created_at).utcOffset('+05:30').format('Do MMM, YYYY')}
                </h6>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}

          {customerDetails?.status === 'NEW' &&
          customerDetails?.customer_event?.length < 1 &&
          activeTab === 2 ? (
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <DotRed className="w-2.5 h-2.5" />
                <h6 className="text-xs text-gray-600 font-medium ml-1.5">Event</h6>
              </div>
              <h6 className="flex items-center justify-between text-xs">No Event Scheduled</h6>
            </div>
          ) : (
            <></>
          )}

          {activeTab === 3 ? (
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="border border-green-50 bg-purple-50 rounded-full xl:p-2 lg:p-1.5 p-1">
                  <Sent className="w-2.5 h-2.5" />
                </div>
                <h6 className="text-xs text-gray-600 font-medium ml-1.5">In CC</h6>
              </div>
              <h6 className="flex items-center justify-between text-xs">
                {customerDetails?.status === 'IN_CC'
                  ? moment(
                      customerDetails?.status_updated_at
                        ? customerDetails.status_updated_at
                        : customerDetails.updated_at,
                    )
                      .utcOffset('+05:30')
                      .format('Do MMM, YYYY')
                  : ''}
              </h6>
            </div>
          ) : (
            <></>
          )}

          {activeTab === 4 ? (
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <DotGreen className="w-2.5 h-2.5" />

                <h6 className="text-xs text-gray-600 font-medium ml-1.5">Marked Intrested</h6>
              </div>

              <h6 className="flex items-center justify-between text-xs">
                {customerDetails?.status === 'INTERESTED'
                  ? moment(
                      customerDetails?.status_updated_at
                        ? customerDetails.status_updated_at
                        : customerDetails.updated_at,
                    )
                      .utcOffset('+05:30')
                      .format('Do MMM, YYYY')
                  : ''}
              </h6>
            </div>
          ) : (
            <></>
          )}

          {followUpDate?.scheduled_time ? (
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <DotGreen className="w-2.5 h-2.5" />
                {followUpDate?.scheduled_time ? (
                  <h6 className="text-xs text-gray-600 font-medium ml-1.5">
                    Follow up {(followUpDate?.status).toLowerCase()}
                  </h6>
                ) : (
                  <></>
                )}
              </div>
              {followUpDate?.scheduled_time ? (
                <h6 className="flex items-center justify-between text-xs">
                  {moment(followUpDate?.scheduled_time).utcOffset('+05:30').format('Do MMM, YYYY')}
                </h6>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}
          {meetingDate?.scheduled_time ? (
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <DotGreen className="w-2.5 h-2.5" />
                {meetingDate?.scheduled_time ? (
                  <h6 className="text-xs text-gray-600 font-medium ml-1.5">
                    Meeting {(meetingDate?.status).toLowerCase()}
                  </h6>
                ) : (
                  <></>
                )}
              </div>
              {meetingDate?.scheduled_time ? (
                <h6 className="flex items-center justify-between text-xs">
                  {moment(meetingDate?.scheduled_time).utcOffset('+05:30').format('Do MMM, YYYY')}
                </h6>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <></>
          )}

          {activeTab === 6 ? (
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <DotRed className="w-2.5 h-2.5" />

                <h6 className="text-xs text-gray-600 font-medium ml-1.5">Failed</h6>
              </div>
              <h6 className="flex items-center justify-between text-xs">
                {customerDetails?.status === 'FAILED'
                  ? moment(
                      customerDetails?.status_updated_at
                        ? customerDetails.status_updated_at
                        : customerDetails.status_updated_at,
                    )
                      .utcOffset('+05:30')
                      .format('Do MMM, YYYY')
                  : ''}
              </h6>
            </div>
          ) : (
            <></>
          )}
        </div>
        {/* <div className="grid gap-y-2 border bg-white rounded-lg shadow-sm p-3">
          {customerDetails?.customerDetails ? (
            customerDetails?.customerDetails.map((customer_item, customer_index) => {
              return (
                <div
                  key={`customerDetails-${customer_index}`}
                  className="flex items-center justify-between"
                >
                  <div className="flex items-center">
                    {customerDetails.customerDetails?.subDetails.includes('Event' || 'Failed By') ? (
                      <DotRed />
                    ) : (
                      <DotGreen className="w-2.5 h-2.5" />
                    )}
                    <h6 className="text-xs text-gray-600 font-medium ml-1.5">
                      {customerDetails.customerDetails?.subDetails}
                    </h6>
                  </div>
                  <h6 className="text-xs text-black font-medium">
                    {customerDetails.customerDetails?.subDetailsValue}
                  </h6>
                </div>
              );
            })
          ) : (
            <></>
          )}
        </div> */}
      </button>
    </>
  );
}
