import { PayloadAction } from '@reduxjs/toolkit';
import { projectServiceSchemas } from 'types/projectServiceSchemas';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { projectFilterSearchSaga } from './saga';
import { ProjectFilterSearchState } from './types';

export const initialState: ProjectFilterSearchState = {
  loading: true,
  error: false,
  errorMsg: '',
  projects: [],
  projectsTotal: 0,
  searchfilterObj: {},
};

const slice = createSlice({
  name: 'filterSearchProjectList',
  initialState,
  reducers: {
    loadProjectsFilterSearch(state, action) {
      state.loading = true;
      state.error = false;
      state.projects = [];
      state.projectsTotal = 0;
      const searchfilterObj = action.payload;
      state.searchfilterObj = searchfilterObj;
    },
    projectsFilterSearchLoaded(
      state,
      action: PayloadAction<{
        items: projectServiceSchemas['CpProjectResponse'][];
        total: number;
      }>,
    ) {
      const projects = action.payload;
      state.projects = projects?.items;
      state.projectsTotal = projects?.total;
      state.loading = false;
    },
    projectError(state, action: PayloadAction<string>) {
      state.error = true;
      state.errorMsg = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: projectFilterSearchActions, reducer } = slice;

export const useProjectFilterSearchSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: projectFilterSearchSaga });
  return { actions: slice.actions };
};
