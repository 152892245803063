import { PayloadAction } from '@reduxjs/toolkit';
import { masterServiceSchemas } from 'types/mastersServiceSchemas';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { citiesListSaga } from './saga';
import { CitiesListState } from './types';

export const initialState: CitiesListState = {
  loading: true,
  error: false,
  errorMsg: '',
  cities: [],
};

const slice = createSlice({
  name: 'cities',
  initialState,
  reducers: {
    loadCities(state) {
      state.loading = true;
      state.error = false;
      state.cities = [];
    },
    citiesLoaded(state, action: PayloadAction<masterServiceSchemas['CityResponse'][]>) {
      const cities = action.payload;
      state.cities = cities;
      state.loading = false;
    },
    citiesError(state, action: PayloadAction<string>) {
      state.error = true;
      state.errorMsg = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: citiesListActions, reducer } = slice;

export const useCitiesListSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: citiesListSaga });
  return { actions: slice.actions };
};
