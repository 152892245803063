import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { crmServiceSchemas } from 'types/crmServiceSchemas';
import { getRequest } from 'utils/request';
import { customersEventSummaryActions as actions } from '.';
/**
 * CustomersloadCustomers request/response handler
 */
export function* getCustomersEventSummary(filter_obj) {
  yield delay(500);

  var params = Object.keys(filter_obj.payload)
    .filter(function (key) {
      return filter_obj.payload[key].length ? true : !!filter_obj.payload[key];
    })
    .map(function (key) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(filter_obj.payload[key]);
    })
    .join('&');

  const requestURL = `${process.env.REACT_APP_DDP_CRM_API_URL}/customer/event_summary?${params}`;

  try {
    const { data } = yield call(getRequest, requestURL);
    const customersEventSummary: crmServiceSchemas['EventSummaryResponse'] = data?.response;
    if (customersEventSummary) {
      yield put(
        actions.customersLoadedEventSummary({
          items: customersEventSummary,
        }),
      );
    } else {
      yield put(
        actions.customersError('Something went wrong while fetching customersEventSummary.'),
      );
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.customersError('Customersload Customers not found.'));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.customersError('Failed to fetch customersEventSummary.'));
    } else {
      yield put(
        actions.customersError('Something went wrong while fetching customersEventSummary.'),
      );
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* customersEventSummarySaga() {
  // Watches for loadRepos actions and calls getCustomersEventSummary when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.loadCustomersEventSummary.type, getCustomersEventSummary);
}
