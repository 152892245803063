import { useCustomerLeadBookingSlice } from 'app/pages/CrmDashboard/slices/customerLeadBookingslice';
import { useCustomersEventSummarySlice } from 'app/pages/MyCustomers/slices/customerEventSummaryslice';
import { useCustomersListSlice } from 'app/pages/MyCustomers/slices/customerListslice';
import { useCustomersCountSlice } from 'app/pages/MyCustomers/slices/customerSummaryslice';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getRequest } from 'utils/request';
import { ReactComponent as DropDownPurple } from './../../assets/crm/dropDownPurple.svg';
import { ReactComponent as UnCheckRadio } from './../../assets/crm/radio_button_unchecked.svg';
import { ReactComponent as CheckRadio } from './../../assets/crm/radio_check_button.svg';

export function TeammateDropdown(props) {
  const users = props.users;
  const selectMember = props?.selectedMember;
  let teamMembers = [
    {
      label: 'My Team',
    },
    {
      label: 'Team Members',
      list: users,
    },
  ];
  const { actions: customerCountAction } = useCustomersCountSlice();
  const { actions: customerEventSummaryAction } = useCustomersEventSummarySlice();
  const { actions: customerLeadBookingAction } = useCustomerLeadBookingSlice();
  const { actions } = useCustomersListSlice();

  const dispatch = useDispatch();

  const [teamMembersSelected, setTeamMembersSelected] = useState('My Team');

  const [showTeam, setShowTeam] = useState(false);

  const [memberSelected, setMemberSelected] = useState('');

  useEffect(() => {
    if (selectMember) {
      selectMember(memberSelected);
    }
    dispatch(actions.loadCustomers({ mapped_cp_agent: memberSelected }));
    dispatch(customerCountAction.loadCustomersCount({ mapped_cp_agent: memberSelected }));
    dispatch(
      customerEventSummaryAction.loadCustomersEventSummary({ mapped_cp_agent: memberSelected }),
    );
    dispatch(
      customerLeadBookingAction.loadCustomerLeadBooking({ mapped_cp_agent: memberSelected }),
    );
  }, [memberSelected]);

  useEffect(() => {
    if (teamMembersSelected === 'My Team') {
      dispatch(actions.loadCustomers({}));
      dispatch(customerCountAction.loadCustomersCount({}));
      dispatch(customerEventSummaryAction.loadCustomersEventSummary({}));
      dispatch(customerLeadBookingAction.loadCustomerLeadBooking({}));
    }
  }, [teamMembersSelected]);

  const handleTeamDropdown = () => {
    setShowTeam(!showTeam);
  };
  const [teamMemberName, setTeamMemberName] = useState('');
  const getTeamMemberName = id => {
    const requestURL = `${process.env.REACT_APP_AUTH_API_URL}/user/${id}/`;
    getRequest(requestURL).then(res => {
      if (res?.data?.response?.name) {
        setTeamMemberName(res?.data?.response?.name);
      } else {
        return '';
      }
    });
  };

  return (
    <div>
      <button
        onClick={handleTeamDropdown}
        className="flex items-center justify-between bg-white rounded-lg p-3 w-32 md:w-60"
      >
        <h6 className="xl:text-xs md:text-[10px] sm:text-[9px] xs:text-[13px] text-black font-medium text-inherit">
          {memberSelected ? teamMemberName : teamMembersSelected}
        </h6>
        <DropDownPurple
          className={showTeam ? 'w-4 h-4 md:w-6 md:h-6 rotate-180' : 'w-4 h-4 md:w-6 md:h-6'}
        />
      </button>
      {showTeam ? (
        <div className="absolute z-30 w-auto shadow-sm mt-1">
          <div className="border bg-white rounded-lg py-3 px-2">
            {teamMembers?.map((item, index) => {
              return (
                <>
                  <div key={`teamMembers-${index}`} className="flex items-center mb-3">
                    <button
                      onClick={() => {
                        setTeamMembersSelected(item?.label);
                        setMemberSelected('');
                        handleTeamDropdown();
                      }}
                    >
                      {teamMembersSelected === item?.label ? <CheckRadio /> : <UnCheckRadio />}
                    </button>
                    <h5 className="lg:text-xs text-[10px] text-black font-medium ml-2">
                      {item?.label}
                    </h5>
                  </div>
                  {item?.list ? (
                    item?.list?.map((sub_item, sub_index) => {
                      return (
                        <div key={`list${sub_index}`} className="ml-3">
                          <div
                            className={
                              memberSelected === sub_item?.id
                                ? 'flex items-center bg-purple-50'
                                : 'flex items-center'
                            }
                          >
                            <hr className="rotate-180 w-[1px] h-8 border border-gray-500" />
                            <button
                              onClick={() => {
                                setMemberSelected(sub_item?.id);
                                handleTeamDropdown();
                                getTeamMemberName(sub_item?.id);
                                setTeamMembersSelected(item?.label);
                              }}
                              className="w-full"
                            >
                              <h5
                                className={
                                  memberSelected === sub_item?.id
                                    ? 'lg:text-xs text-[10px] text-purple-700 font-medium ml-5 text-justify'
                                    : 'lg:text-xs text-[10px] text-black font-medium ml-5 text-justify'
                                }
                              >
                                {sub_item?.name}
                              </h5>
                            </button>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
