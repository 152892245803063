import { CrmCalendar } from 'app/components/Calendar';
import { CustomerDetailsCard } from 'app/components/CustomerDetailsCard';
import { CustomerDetailsCardMobile } from 'app/components/CustomerDetailsCardMobile';
import { PageWrapper } from 'app/components/PageWrapper';
import { TeammateDropdown } from 'app/components/TeammateDropdown';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from 'utils/request';
import { useChannelPartnersListSlice } from '../MyCustomers/slices/allCpCurrentOrgslice';
import { selectChannelPartners } from '../MyCustomers/slices/allCpCurrentOrgslice/selectors';
import { useAllUsersListSlice } from '../MyCustomers/slices/allUsersslice';
import { selectAllUsers } from '../MyCustomers/slices/allUsersslice/selectors';
import { useCustomersEventSummarySlice } from '../MyCustomers/slices/customerEventSummaryslice';
import { selectCustomersEventSummary } from '../MyCustomers/slices/customerEventSummaryslice/selectors';
import { useCustomersCountSlice } from '../MyCustomers/slices/customerSummaryslice';
import { selectCustomersCount } from '../MyCustomers/slices/customerSummaryslice/selectors';
import { ReactComponent as Bookings } from './../../assets/crm/Bookings.svg';
import { ReactComponent as EventCompleted } from './../../assets/crm/Completed Events.svg';
import { ReactComponent as FailedPeople } from './../../assets/crm/Failed.svg';
import { ReactComponent as FollowUp } from './../../assets/crm/Follow Up.svg';
import { ReactComponent as InterestedPeople } from './../../assets/crm/Interested.svg';
import { ReactComponent as Meeting } from './../../assets/crm/Meeting.svg';
import { ReactComponent as NewPeople } from './../../assets/crm/New.svg';
import { ReactComponent as EventScheduled } from './../../assets/crm/Scheduled Events.svg';
import { ReactComponent as SiteVisit } from './../../assets/crm/Site Visit.svg';
import { ReactComponent as TotalPeople } from './../../assets/crm/Total.svg';
import { ReactComponent as BookingsNull } from './../../assets/crm/Zero state 1-01 1.svg';
import { ReactComponent as EventMissed } from './../../assets/crm/event_busy.svg';
import { ReactComponent as EventDone } from './../../assets/crm/event_done.svg';
import { ReactComponent as InCallCente } from './../../assets/crm/inCallCenter.svg';
import { ReactComponent as PeopleRed } from './../../assets/crm/peopleRed.svg';
import { ReactComponent as SiteVisitScheduled } from './../../assets/crm/site visit scheduled.svg';
import { useCustomerLeadBookingSlice } from './slices/customerLeadBookingslice';
import {
  selectCustomerLeadBooking,
  selectCustomerLeadBookingTotal,
} from './slices/customerLeadBookingslice/selectors';
import { useCustomersEventSlice } from './slices/customersEventslice';
import { selectCustomersEvent } from './slices/customersEventslice/selectors';
import { useTeamEventSummarySlice } from './slices/teamSummarySummaryslice';
import { selectTeamEventSummary } from './slices/teamSummarySummaryslice/selectors';
export function CrmDashboard() {
  const dispatch = useDispatch();

  const useEffectOnMount = (effect: React.EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, []);
  };
  const dateToday = moment().format('YYYY-MM-DD');
  const tomorrowsDate = moment().add(1, 'days').format('YYYY-MM-DD');
  const userId = localStorage.getItem('userID');

  useEffect(() => {
    const fetchProfileURL = `${process.env.REACT_APP_AUTH_API_URL}/user/fetch_profile/`;
    getRequest(fetchProfileURL).then(res => {
      setUserProfile(res?.data?.response);
    });
  }, [userId]);

  useEffectOnMount(() => {
    dispatch(
      customerseventactions.loadCustomersEvent({ start_date: dateToday, end_date: tomorrowsDate }),
    );
    dispatch(customerEventSummaryAction.loadCustomersEventSummary({}));
    dispatch(customerLeadBookingAction.loadCustomerLeadBooking({}));
    dispatch(customerCountAction.loadCustomersCount({}));
    dispatch(teamEventSummaryAction.loadTeamEventSummary());
    dispatch(channelpartnersactions.loadchannelPartners({}));

    const requestURL = `${process.env.REACT_APP_DDP_CRM_API_URL}/customer/customer_summary`;
    getRequest(requestURL).then(res => {
      setCustomerAllSummary(res?.data?.response);
    });
  });

  const { actions: customerCountAction } = useCustomersCountSlice();
  const { actions: customerEventSummaryAction } = useCustomersEventSummarySlice();
  const { actions: customerLeadBookingAction } = useCustomerLeadBookingSlice();
  const { actions: teamEventSummaryAction } = useTeamEventSummarySlice();
  const { actions: usersactions } = useAllUsersListSlice();
  const { actions: channelpartnersactions } = useChannelPartnersListSlice();
  const { actions: customerseventactions } = useCustomersEventSlice();

  const customerCount = useSelector(selectCustomersCount);
  const customerEventSummary = useSelector(selectCustomersEventSummary);
  const customersEvent = useSelector(selectCustomersEvent);
  const teamEventSummary = useSelector(selectTeamEventSummary);
  const allUserList = useSelector(selectAllUsers);
  const channelPartnerList = useSelector(selectChannelPartners);
  const customerLeadBooking = useSelector(selectCustomerLeadBooking);
  const customerLeadBookingTotal = useSelector(selectCustomerLeadBookingTotal);
  const [projectName, setProjectName] = useState<any>('');
  const [projectThumbnail, setProjectThumbnail] = useState<any>('');
  const [userProfile, setUserProfile] = useState<any>();
  const [customerAllSummary, setCustomerAllSummary] = useState<any>();

  useEffect(() => {
    let userIdsArr: any = [];
    channelPartnerList.map(cp => {
      userIdsArr.push(cp?.user_id);
    });
    let filter_obj: any = {
      user_ids: userIdsArr.join(','),
    };

    if (filter_obj) {
      dispatch(usersactions.loadusers(filter_obj));
    }
  }, [channelPartnerList]);

  const getProjectName = id => {
    const requestURL = `${process.env.REACT_APP_PROJECT_API_URL}/cp/project?project_ids=${id}`;
    getRequest(requestURL).then(res => {
      if (res) {
        setProjectThumbnail(res[0]?.thumbnail_image);
        setProjectName(res[0]?.name);
      }
    });
  };

  const customerSummaryAll = [
    {
      value: `${customerAllSummary?.TOTAL || 0}`,
      label: 'Total',
    },
    {
      value: `${customerAllSummary?.NEW || 0}`,
      label: 'New',
    },
    {
      value: `${customerAllSummary?.INTERESTED || 0}`,
      label: 'Interested',
    },
    {
      value: `${customerAllSummary?.IN_CC || 0}`,
      label: 'In call centre',
    },
    {
      value: `${customerAllSummary?.BOOKING_DONE || 0}`,
      label: 'Bookings',
    },
    {
      value: `${customerAllSummary?.FAILED || 0}`,
      label: 'Failed',
    },
  ];

  const customerSummary = [
    {
      value: `${customerCount?.TOTAL || 0}`,
      label: 'Total',
    },
    {
      value: `${customerCount?.NEW || 0}`,
      label: 'New',
    },
    {
      value: `${customerCount?.INTERESTED || 0}`,
      label: 'Interested',
    },
    {
      value: `${customerCount?.IN_CC || 0}`,
      label: 'In call centre',
    },
    {
      value: `${customerCount?.BOOKING_DONE || 0}`,
      label: 'Bookings',
    },
    {
      value: `${customerCount?.FAILED || 0}`,
      label: 'Failed',
    },
  ];

  const teamActivityMeet = [
    {
      value: `${teamEventSummary?.SITE_VISITS || 0}`,
      title: 'Site Visit',
    },
    {
      value: `${teamEventSummary?.MEETINGS || 0}`,
      title: 'Meetings',
    },
    {
      value: `${teamEventSummary?.FOLLOW_UPS || 0}`,
      title: 'Follow Ups',
    },
    {
      value: `${teamEventSummary?.NO_EVENTS || 0}`,
      title: 'No Events',
    },
  ];

  const [isDesktop, setDesktop] = useState(window.innerWidth > 560);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 560);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  });

  return (
    <PageWrapper>
      <div className="-mt-12 md:-mt-4">
        <h2 className="xl:text-2xl md:text-xl xs:text-base text-primary font-semibold">
          Welcome back {userProfile?.name}!
        </h2>
        <h3 className="xl:text-xl md:text-base xs:text-sm text-black mt-1.5 mb-4 font-medium md:mt-6 md:font-semibold">
          Today's schedule
        </h3>
      </div>

      <div className="border rounded-lg py-0 px-0 md:py-10 md:px-[12px] lg:px-[16px] 2xl:px-[30px] md:mb-7 md:bg-white md:shadow-sm xs:bg-inherit xs:shadow-none">
        <div className="grid lg:grid-cols-3 md:grid-cols-2 2xl:gap-8 md:gap-4 grid-cols-1 gap-4">
          {/* schedule events card starts */}
          {Array.from(customersEvent).filter(event => event.status !== 'CANCELLED').length ? (
            <div className="space-y-3 lg:col-span-2">
              {Array.from(customersEvent)
                .filter(event => event.status !== 'CANCELLED')
                ?.sort(
                  (a: any, b: any) =>
                    moment(a.scheduled_time).valueOf() - moment(b.scheduled_time).valueOf(),
                )
                ?.map((item, index) => {
                  // eslint-disable-next-line no-lone-blocks
                  {
                    item?.project_id ? getProjectName(item?.project_id) : <></>;
                  }
                  return (
                    <div
                      key={`scheduleEvents-${index}`}
                      className="border border-gray-300 rounded-lg p-3 xs:bg-white"
                    >
                      <div className="flex justify-between">
                        <div className="flex items-center">
                          {item?.event_type === 'SITE_VISIT' ? (
                            <SiteVisit />
                          ) : item?.event_type === 'MEETING' ? (
                            <Meeting />
                          ) : (
                            <FollowUp />
                          )}
                          <h6 className="text-xs text-black font-medium ml-2">
                            {item?.event_type === 'SITE_VISIT'
                              ? 'Site Visit'
                              : item?.event_type === 'MEETING'
                              ? 'Meeting'
                              : item?.event_type === 'FOLLOW_UP'
                              ? 'Follow Up'
                              : ''}
                          </h6>
                        </div>
                        {item?.scheduled_time ? (
                          <h6 className="text-xs text-black font-medium">
                            {moment.utc(item?.scheduled_time).utcOffset('+05:30').format('hh:mm A')}
                          </h6>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div
                        className={
                          item?.event_type === 'SITE_VISIT'
                            ? 'flex items-center justify-between mt-2'
                            : 'flex items-center justify-between mt-5'
                        }
                      >
                        <div>
                          {item?.event_type === 'SITE_VISIT' ? (
                            <div className="flex items-center">
                              <img
                                src={
                                  projectThumbnail ||
                                  'https://images.indianexpress.com/2016/02/green-building759.jpg'
                                }
                                className="w-12 h-12 rounded-lg mr-2"
                                alt=""
                              />
                              <div>
                                <h6 className="text-xs text-black font-medium">{projectName}</h6>
                                <h6 className="text-[10px] text-black font-medium mt-1">
                                  {item?.customer_name}
                                </h6>
                              </div>
                            </div>
                          ) : (
                            <div className="flex items-center hustify-between">
                              <h6 className="text-sm text-black font-medium">
                                {item?.customer_name}
                              </h6>
                            </div>
                          )}
                        </div>
                        <div>
                          {item?.status === 'SCHEDULED' ? (
                            <SiteVisitScheduled className="w-8 h-8 mr-1" />
                          ) : (
                            <EventDone className="w-8 h-8 mr-1" />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          ) : (
            <div className=" space-y-3 lg:col-span-2">
              <div className="flex justify-center bg-white rounded-lg shadow-sm py-16 px-[30px]">
                <div>
                  <div className="flex justify-center mb-2">
                    <BookingsNull className="" />
                  </div>
                  <div className="text-center mt-8">
                    <h3 className="md:text-lg text-base text-black font-bold">
                      No Events Scheduled
                    </h3>
                    <h4 className="md:text-base text-xs text-black font-normal mt-0.5">
                      You have not scheduled any events for today
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* schedule events card ends */}
          {/* calendar card starts */}
          <div className="w-full">
            <CrmCalendar />
          </div>
          {/* calendar card ends */}
        </div>
      </div>
      <div className="flex items-center mt-8 md:mt-0">
        <h3 className="xl:text-xl md:text-base md:font-semibold md:mt-6 mb-4 xs:text-sm text-black font-medium mt-4 mb-5">
          Booking Requests
        </h3>
        <div className="border rounded-full bg-blue-200 py-1 px-2 ml-2">
          <p className="text-xs text-black font-medium">{customerLeadBookingTotal}</p>
        </div>
      </div>
      {customerLeadBooking.length ? (
        <div>
          {isDesktop
            ? customerLeadBooking?.map(item => (
                <CustomerDetailsCard key={`web-book-${item?.id}`} bookingData={item} />
              ))
            : customerLeadBooking?.map(item => (
                <CustomerDetailsCardMobile key={`mob-book-${item?.id}`} bookingData={item} />
              ))}
        </div>
      ) : (
        <div className="flex justify-center border bg-white rounded-lg shadow-sm py-10 px-[30px] mb-2">
          <div>
            <div className="flex justify-center">
              <BookingsNull className="" />
            </div>
            <div className="text-center mt-8">
              <h3 className="md:text-lg text-base text-black font-bold">No bookings requested</h3>
              <h4 className="md:text-base text-xs text-black font-normal mt-0.5">
                You have not made any Booking Requests so far
              </h4>
            </div>
          </div>
        </div>
      )}
      <h3 className="xl:text-xl md:text-base md:font-semibold md:mt-6 mb-4 xs:text-sm text-black font-medium mt-4 mb-5">
        Customer Summary
      </h3>
      <div className="grid grid-cols-2 gap-y-4 gap-x-6 lg:grid-cols-3 lg:gap-y-4 lg:gap-x-12">
        {customerSummaryAll.map((item, index) => {
          return (
            <div className="flex border border-gray-300 bg-white rounded-lg py-4 px-5">
              {item?.label === 'Total' ? (
                <TotalPeople className="w-4 h-4 md:w-6 h-6 xl:w-8 h-8" />
              ) : item?.label === 'New' ? (
                <NewPeople className="w-4 h-4 md:w-6 h-6 xl:w-8 h-8" />
              ) : item?.label === 'Interested' ? (
                <InterestedPeople className="w-4 h-4 md:w-6 h-6 xl:w-8 h-8" />
              ) : item?.label === 'In call centre' ? (
                <InCallCente className="w-4 h-4 md:w-6 h-6 xl:w-8 h-8" />
              ) : item?.label === 'Bookings' ? (
                <Bookings className="w-4 h-4 md:w-6 h-6 xl:w-8 h-8" />
              ) : (
                <FailedPeople className="w-4 h-4 md:w-6 h-6 xl:w-8 h-8" />
              )}
              <div className="ml-3">
                <h2 className="xl:text-2xl lg:text-xl xs:text-lg text-black font-semibold">
                  {item?.value}
                </h2>
                <h4 className="xl:text-base lg:text-sm md:text-xs xs:text-[11px] text-gray-600 font-normal">
                  {item?.label}
                </h4>
              </div>
            </div>
          );
        })}
      </div>
      <h3 className="xl:text-xl md:text-base md:font-semibold md:mt-6 mb-4 xs:text-sm text-black font-medium mt-4 mb-5">
        {/* Customer Stats  */}
        Team activity
      </h3>

      {/* Single Customer View */}

      {/* <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-y-4 gap-x-12">
        {customerStats.map((item, index) => {
          return (
            <div className="grid grid-cols-4 border border-gray-300 bg-white rounded-lg py-4 px-5">
              <div className="flex">
                {item?.event_title === 'Scheduled Events' ? (
                  <EventScheduled className="w-7 h-7" />
                ) : item?.event_title === 'Missed Events' ? (
                  <EventMissed className="w-7 h-7" />
                ) : item?.event_title === 'Completed Events' ? (
                  <EventCompleted className="w-7 h-7" />
                ) : (
                  <TotalPeople className="w-8 h-5" />
                )}
                <div className="ml-2">
                  <h4
                    className={
                      item?.event_title === 'Scheduled Events'
                        ? 'xl:text-base lg:text-sm md:text-xs text-primary font-medium'
                        : item?.event_title === 'Missed Events'
                        ? 'xl:text-base lg:text-sm md:text-xs text-red-700 font-medium'
                        : item?.event_title === 'Completed Events'
                        ? 'xl:text-base lg:text-sm md:text-xs text-green-700 font-medium'
                        : 'xl:text-base lg:text-sm md:text-xs text-indigo-800 font-medium'
                    }
                  >
                    {item?.event_title}
                  </h4>
                  <h6 className="text-sm text-black font-normal">(Till Date)</h6>
                </div>
              </div>
              <div className="flex">
                <div>
                  <FollowUp />
                </div>
                <div className="ml-2.5">
                  <h6 className="text-[11px] text-black font-medium">
                    {item?.event_title === 'Customer Conversion' ? 'Received' : 'Follow Up'}
                  </h6>
                  <h4 className="text-lg text-black font-medium mt-1.5">50</h4>
                </div>
              </div>
              <div className="flex">
                <div>
                  <Meeting />
                </div>
                <div className="ml-2.5">
                  <h6 className="text-[11px] text-black font-medium">
                    {item?.event_title === 'Customer Conversion' ? 'Interested' : 'Meeting'}
                  </h6>
                  <h4 className="text-lg text-black font-medium mt-1.5">50</h4>
                </div>
              </div>
              <div className="flex">
                <div>
                  <SiteVisit />
                </div>
                <div className="ml-2.5">
                  <h6 className="text-[11px] text-black font-medium">
                    {' '}
                    {item?.event_title === 'Customer Conversion' ? 'Failed' : 'Site Visit'}
                  </h6>
                  <h4 className="text-lg text-black font-medium mt-1.5">50</h4>
                </div>
              </div>
            </div>
          );
        })}
      </div> */}

      {/* Single Customer View Ends*/}

      <div className="border bg-white rounded-lg shadow-sm p-6 mb-2">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 lg:gap-8">
          {teamActivityMeet.map((item, index) => {
            return (
              <div
                key={`teamMeet${index}`}
                className={
                  item?.title === 'No Events'
                    ? 'flex border bg-red-100 rounded-lg p-3'
                    : 'flex border bg-blue-100 rounded-lg p-3'
                }
              >
                {item?.title === 'No Events' ? (
                  <PeopleRed className="xl:w-4 h-4 xl:mt-1.5 md:w-3 md:h-3 md:mt-1" />
                ) : (
                  <TotalPeople className="xl:w-4 h-4 xl:mt-1.5 md:w-3 md:h-3 md:mt-1" />
                )}
                <div className="xs:flex xs:gap-x-4 ml-3">
                  <h2
                    className={
                      item?.title === 'No Events'
                        ? 'xl:text-xl md:text-base text-red-800 font-medium'
                        : 'xl:text-xl md:text-base text-indigo-800 font-medium'
                    }
                  >
                    {item?.value}
                  </h2>
                  <h6
                    className={
                      item?.title === 'No Events'
                        ? 'xl:text-xs md:text-[10px] text-red-800 font-medium mt-2 xs:mt-0'
                        : 'xl:text-xs md:text-[10px] text-indigo-800 font-medium mt-2 xs:mt-0'
                    }
                  >
                    {item?.title}
                  </h6>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex items-center justify-between mt-8">
          <div>
            <h5 className="xl:text-xl md:text-base md:font-semibold xs:text-sm text-black font-medium">
              Teammates summary
            </h5>
          </div>
          <div className="border border-gray-300 rounded-md">
            <TeammateDropdown users={allUserList} />
          </div>
        </div>
        <hr className="my-2" />
        <h5 className="xl:text-base md:text-sm xs:text-xs text-gray-600 font-semibold mt-6 mb-2">
          Teammate Summary
        </h5>
        <div>
          <div className="grid grid-cols-2 gap-y-4 gap-x-6 lg:grid-cols-3 lg:gap-y-4 lg:gap-x-12">
            {customerSummary.map((item, index) => {
              return (
                <div className="flex border border-gray-300 bg-white rounded-lg py-2 px-2.5 md:py-4 md:px-5">
                  {item?.label === 'Total' ? (
                    <TotalPeople className="w-4 h-4 md:w-6 h-6 xl:w-8 h-8" />
                  ) : item?.label === 'New' ? (
                    <NewPeople className="w-4 h-4 md:w-6 h-6 xl:w-8 h-8" />
                  ) : item?.label === 'Interested' ? (
                    <InterestedPeople className="w-4 h-4 md:w-6 h-6 xl:w-8 h-8" />
                  ) : item?.label === 'In call centre' ? (
                    <InCallCente className="w-4 h-4 md:w-6 h-6 xl:w-8 h-8" />
                  ) : item?.label === 'Bookings' ? (
                    <Bookings className="w-4 h-4 md:w-6 h-6 xl:w-8 h-8" />
                  ) : (
                    <FailedPeople className="w-4 h-4 md:w-6 h-6 xl:w-8 h-8" />
                  )}
                  <div className="ml-3">
                    <h2 className="xl:text-2xl lg:text-xl xs:text-lg text-black font-semibold">
                      {item?.value}
                    </h2>
                    <h4 className="xl:text-base lg:text-sm md:text-xs xs:text-[11px] text-gray-600 font-normal">
                      {item?.label}
                    </h4>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <h5 className="xl:text-base md:text-sm xs:text-xs text-gray-600 font-semibold mt-6 mb-2">
          Customer Stats
        </h5>
        <div className="grid xl:grid-cols-2 sm:grid-cols-1 gap-y-4 gap-x-12">
          {Object.entries(customerEventSummary).map((item, index) => {
            return (
              <div className="border border-gray-300 bg-white rounded-lg py-3 px-2 md:py-4 md:px-5">
                <div className="flex">
                  {item[0] === 'scheduled_events' ? (
                    <EventScheduled className="md:w-7 h-7" />
                  ) : item[0] === 'missed_events' ? (
                    <EventMissed className="md:w-7 h-7" />
                  ) : item[0] === 'completed_events' ? (
                    <EventCompleted className="md:w-7 h-7" />
                  ) : (
                    <TotalPeople className="w-6 h-3 md:w-8 h-5" />
                  )}
                  <div className="flex items-center ml-2">
                    <h4
                      className={
                        item[0] === 'scheduled_events'
                          ? 'xl:text-base md:text-sm xs:text-xs text-primary font-medium'
                          : item[0] === 'missed_events'
                          ? 'xl:text-base md:text-sm xs:text-xs text-red-700 font-medium'
                          : item[0] === 'completed_events'
                          ? 'xl:text-base md:text-sm xs:text-xs text-green-700 font-medium'
                          : 'xl:text-base md:text-sm xs:text-xs text-indigo-800 font-medium'
                      }
                    >
                      {item[0] === 'scheduled_events'
                        ? 'Scheduled Events'
                        : item[0] === 'missed_events'
                        ? 'Missed Events'
                        : item[0] === 'completed_events'
                        ? 'Completed Events'
                        : 'Customer Conversion'}
                    </h4>
                    <h6 className="md:text-sm text-[10px] text-black font-normal ml-2">
                      (Till Date)
                    </h6>
                  </div>
                </div>
                <hr className="mt-2 mb-3" />
                <div className="flex justify-between">
                  <div className="flex">
                    <div>
                      <FollowUp />
                    </div>
                    <div className="ml-2.5">
                      <h6 className="text-[11px] text-black font-medium">
                        {item[0] === 'customer_conversion' ? 'Received' : 'Follow Up'}
                      </h6>
                      <h4 className="text-lg text-black text-center font-medium mt-1.5">
                        {item[1]?.FOLLOW_UP || item[1]?.RECEIVED || 0}
                      </h4>
                    </div>
                  </div>
                  <div className="flex">
                    <div>
                      <Meeting />
                    </div>
                    <div className="ml-2.5">
                      <h6 className="text-[11px] text-black font-medium">
                        {item[0] === 'customer_conversion' ? 'Interested' : 'Meeting'}
                      </h6>
                      <h4 className="text-lg text-black text-center font-medium mt-1.5">
                        {item[1]?.MEETING || item[1]?.INTERESTED || 0}
                      </h4>
                    </div>
                  </div>
                  <div className="flex">
                    <div>
                      <SiteVisit />
                    </div>
                    <div className="ml-2.5">
                      <h6 className="text-[11px] text-black font-medium">
                        {item[0] === 'customer_conversion' ? 'Failed' : 'Site Visit'}
                      </h6>
                      <h4 className="text-lg text-black text-center font-medium mt-1.5">
                        {item[1]?.SITE_VISIT || item[1]?.FAILED || 0}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </PageWrapper>
  );
}
