import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.teamEventSummary || initialState;

export const selectTeamEventSummary = createSelector(
  [selectDomain],
  teamEventSummaryState => teamEventSummaryState.teamEvents,
);

export const selectLoadingEventSummary = createSelector(
  [selectDomain],
  teamEventSummaryState => teamEventSummaryState.loading,
);

export const selectError = createSelector(
  [selectDomain],
  teamEventSummaryState => teamEventSummaryState.error,
);

export const selectErrorMsg = createSelector(
  [selectDomain],
  teamEventSummaryState => teamEventSummaryState.errorMsg,
);
