import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { amenitiesListResponse } from 'types/projectServiceSchemas';
import { request } from 'utils/request';
import { amenitiesListActions as actions } from '.';

/**
 * amenities request/response handler
 */
export function* getamenitiesList() {
  yield delay(500);

  const requestURL = `${process.env.REACT_APP_PROJECT_API_URL}/amenities_master/`;
  try {
    const amenities: amenitiesListResponse = yield call(request, requestURL);

    if (amenities?.items?.length > 0) {
      yield put(
        actions.amenitiesLoaded({
          items: amenities?.items,
          total: amenities?.total,
        }),
      );
    } else {
      yield put(actions.amenitiesError('Something went wrong while fetching amenities.'));
    }
  } catch (err: any) {
    if (err.response?.status === 404) {
      yield put(actions.amenitiesError('amenities not found.'));
    } else if (err.message === 'Failed to fetch') {
      yield put(actions.amenitiesError('Failed to fetch amenities.'));
    } else {
      yield put(actions.amenitiesError('Something went wrong while fetching amenities.'));
    }
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* amenitiesListSaga() {
  // Watches for loadRepos actions and calls getamenitiesList when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.loadAmenities.type, getamenitiesList);
}
