import { PayloadAction } from '@reduxjs/toolkit';
import { crmServiceSchemas } from 'types/crmServiceSchemas';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { customersListSaga } from './saga';
import { CustomersListState } from './types';

export const initialState: CustomersListState = {
  loading: true,
  error: false,
  errorMsg: '',
  customers: [],
  customersTotal: 0,
  filter_obj: {},
  customersPage: 0,
  customersSize: 50,
};

const slice = createSlice({
  name: 'customersList',
  initialState,
  reducers: {
    loadCustomers(state, action) {
      state.error = false;
      const filter_object = action?.payload;
      state.filter_obj = filter_object;
      if (!filter_object?.page || (filter_object?.page && filter_object.page === 1)) {
        state.customers = [];
        state.loading = true;
      } else {
        state.loading = true;
      }
    },
    customersLoaded(
      state,
      action: PayloadAction<{
        items: crmServiceSchemas['CustomerListResponse'][];
        total: number;
        page: number;
        size: number;
      }>,
    ) {
      const customers = action.payload;
      if (customers?.page === 1) {
        state.customers = customers?.items;
        state.loading = false;
      } else {
        state.customers = [...state.customers, ...customers?.items];
        state.loading = false;
      }
      state.customersTotal = customers?.total;
      state.customersPage = customers?.page;
      state.customersSize = customers?.size;
    },
    customersError(state, action: PayloadAction<string>) {
      state.error = true;
      state.errorMsg = action.payload;
      state.loading = false;
    },
  },
});

export const { actions: customersListActions, reducer } = slice;

export const useCustomersListSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: customersListSaga });
  return { actions: slice.actions };
};
