import { Dialog } from '@material-tailwind/react';
import { CustomerDetailsCard } from 'app/components/CustomerDetailsCard';
import { CustomerDetailsCardMobile } from 'app/components/CustomerDetailsCardMobile';
import { BlackLoadingIndicator } from 'app/components/LoadingIndicator';
import { TeammateDropdown } from 'app/components/TeammateDropdown';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { DonutChart } from 'react-circle-chart';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { getRequest, postRequest } from 'utils/request';
import { ReactComponent as BulkUploadWhite } from '../../assets/crm/Bulkupload white.svg';
import { ReactComponent as People } from '../../assets/crm/Total.svg';
import { ReactComponent as BulkUpload } from '../../assets/crm/bulk upload.svg';
import { ReactComponent as Upload } from '../../assets/crm/bulk uplod bottam shheet icon.svg';
import { ReactComponent as TransferCallCenterWhite } from '../../assets/crm/sendtocallcenterwhite.svg';
import { ReactComponent as TransferTeamMateWhite } from '../../assets/crm/tarnsfertoteammatewhite.svg';
import { ReactComponent as TransferCallCenter } from '../../assets/crm/transferCallCenter.svg';
import { ReactComponent as TransferTeamMate } from '../../assets/crm/transferTeammate.svg';
import { useUnitConfigurationListSlice } from '../ProjectListPage/Projects/ProjectList/slices/untiConfigurationSlice';
import { ReactComponent as Cross } from './../../assets/close.svg';
import { ReactComponent as ExclamationMark } from './../../assets/crm/No event scheduled.svg';
import { ReactComponent as CheckCircle } from './../../assets/crm/checkboxfill.svg';
import { ReactComponent as UncheckCircle } from './../../assets/crm/chek unfill box.svg';
import { ReactComponent as PeopleRed } from './../../assets/crm/peopleRed.svg';
import { ReactComponent as UnCheckRadio } from './../../assets/crm/radio_button_unchecked.svg';
import { ReactComponent as CheckRadio } from './../../assets/crm/radio_check_button.svg';
import { ReactComponent as Search } from './../../assets/search.svg';
// import SampleFile from './../../assets/SampleFile.';
import { MobileFilters } from './components/MobileFilters';
import { WebFilters } from './components/WebFilters';
import { useChannelPartnersListSlice } from './slices/allCpCurrentOrgslice';
import { selectChannelPartners } from './slices/allCpCurrentOrgslice/selectors';
import { useAllUsersListSlice } from './slices/allUsersslice';
import { selectAllUsers } from './slices/allUsersslice/selectors';
import { useCallCenterInfoSlice } from './slices/callCenterInfoslice';
import { selectCallCenterInfo } from './slices/callCenterInfoslice/selectors';
import { useCustomersListSlice } from './slices/customerListslice';
import {
  selectCustomers,
  selectCustomersPage,
  selectCustomersSize,
  selectCustomersTotal,
  selectLoading,
} from './slices/customerListslice/selectors';
import { useCustomersCountSlice } from './slices/customerSummaryslice';
import { selectCustomersCount } from './slices/customerSummaryslice/selectors';

export function MyCustomers() {
  const { actions } = useCustomersListSlice();
  const { actions: customerCountAction } = useCustomersCountSlice();
  const { actions: callCenterInfoAction } = useCallCenterInfoSlice();
  const { actions: channelpartnersactions } = useChannelPartnersListSlice();
  const { actions: usersactions } = useAllUsersListSlice();

  const customerCount = useSelector(selectCustomersCount);
  const customersLoading = useSelector(selectLoading);
  const CustomerDetails = useSelector(selectCustomers);
  const customerTotal = useSelector(selectCustomersTotal);
  const callCenterInfoDetails = useSelector(selectCallCenterInfo);
  const dispatch = useDispatch();
  const [filterObject,setFilterObject] = useState({});
  const [activeStatus, setActiveStatus] = useState('');
  const activeStatusRef = useRef<string>();
  activeStatusRef.current = activeStatus;

  const channelPartnerList = useSelector(selectChannelPartners);
  const allUserList = useSelector(selectAllUsers);

  const useEffectOnMount = (effect: React.EffectCallback) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(effect, []);
  };

  useEffectOnMount(() => {
    dispatch(actions.loadCustomers({}));
    dispatch(customerCountAction.loadCustomersCount({}));
    dispatch(callCenterInfoAction.loadCallCenterInfo());
    dispatch(channelpartnersactions.loadchannelPartners({}));
    const fetchCPBusinessURL = `${process.env.REACT_APP_CP_API_URL}/cp/business/profile/`;
    getRequest(fetchCPBusinessURL).then(res => {
      setCpBusinessProfile(res?.data?.response);
    });
  });

  useEffect(() => {
    let userIdsArr: any = [];
    channelPartnerList.map(cp => {
      userIdsArr.push(cp?.user_id);
    });
    let filter_obj: any = {
      user_ids: userIdsArr.join(','),
    };

    if (filter_obj) {
      dispatch(usersactions.loadusers(filter_obj));
    }
  }, [channelPartnerList]);

  const [appliedCustomerFilters, setAppliedCustomerFilters] = useState<any>([]);
  const [activeTab, setActiveTab] = useState(1);

  const [customerSearch, setCustomerSearch] = useState<[{ type: string; value: string }]>([
    {
      type: '',
      value: '',
    },
  ]);

  const Tabs = [
    {
      value: 1,
      header: 'All customers',
      label: `All customers (${customerCount?.TOTAL || 0})`,
      status: '',
    },
    {
      value: 2,
      header: 'New Customers',
      label: `New (${customerCount?.NEW || 0})`,
      status: 'NEW',
    },
    {
      value: 3,
      header: 'In Call centre',
      label: `Call centre (${customerCount?.IN_CC || 0})`,
      status: 'IN_CC',
    },
    {
      value: 4,
      header: 'Interested',
      label: `Interested (${customerCount?.INTERESTED || 0})`,
      status: 'INTERESTED',
    },
    {
      value: 5,
      header: 'Booking',
      label: `Booking (${customerCount?.BOOKING_DONE || 0})`,
      status: 'BOOKING_DONE',
    },
    {
      value: 6,
      header: 'Failed',
      label: `Failed (${customerCount?.FAILED || 0})`,
      status: 'FAILED',
    },
  ];
  const [selectedMember, setSelectedMember] = useState('');

  const selectMember = id => {
    setSelectedMember(id);
  };

  const onTabChange = status => setActiveStatus(status);

  useEffect(() => {
    applyFilters(appliedCustomerFilters);
  }, [customerSearch]);

  useEffect(() => {
    const newFilterObject = {...filterObject,status:activeStatus};
    setFilterObject(newFilterObject)
    dispatch(actions.loadCustomers(newFilterObject));
  }, [activeStatus]);

  useEffect(() => {
    const newFilterObject = {...filterObject,mapped_cp_agent: selectedMember};
    setFilterObject(newFilterObject)
    dispatch(actions.loadCustomers(newFilterObject));
    dispatch(customerCountAction.loadCustomersCount(newFilterObject));
  }, [selectedMember]);

  const applyFilters = filters => {
    const appliedFilters = filters.filter(
      item => item.option.filter(option => option.selected === true).length > 0,
    );
    let filterObject = { status: activeStatus, mapped_cp_agent: selectedMember };

    customerSearch.map(search => {
      if (Object.entries(search || {}).length) {
        if (search?.type === 'Name') {
          filterObject['customer_name'] = search?.value;
        } else if (search?.type === 'Project Name') {
          filterObject['project_name'] = search?.value;
        }
      }
    });
    if (appliedFilters.length) {
      const EventStatusEnum = {
        Missed: 'MISSED',
        Planned: 'PLANNED',
        Completed: 'COMPLETED',
      };
      const CategoryFilterEnum = {
        Hot: 'HOT',
        Warm: 'WARM',
        Cold: 'COLD',
      };
      const EventTypeEnum = {
        'Follow Up': 'FOLLOW_UP',
        Meeting: 'MEETING',
        'Site Visit': 'SITE_VISIT',
      };

      
        const categoryFilterLabel = 'Category';
        const categoryFilterApplied = appliedFilters.filter(
          item => item.label === categoryFilterLabel,
        ).length;

        if (categoryFilterApplied) {
          let selectedCategory: any[] = [];
          const categoryFilter = appliedFilters
            .filter(item => item.label === categoryFilterLabel)[0]
            .option.filter(selectedOption => selectedOption.selected === true);

          [...categoryFilter].forEach(ele => {
            selectedCategory.push(CategoryFilterEnum[ele.subLabel]);
          });
          filterObject['category'] = selectedCategory.join(',');
        }
      
        const lastEventStatusFilterLabel = 'Event Status';
        const upcomingEventDateTypeFilterLabel = 'Event Date';
        const customerAssignedFilterLabel = 'Customer Created';

        const lastEventStatusFilterApplied = appliedFilters.filter(
          item => item.label === lastEventStatusFilterLabel,
        ).length;
        const upcomingEventDateTypeFilterApplied = appliedFilters.filter(
          item => item.label === upcomingEventDateTypeFilterLabel,
        ).length;
        const customerAssignedFilterApplied = appliedFilters.filter(
          item => item.label === customerAssignedFilterLabel,
        ).length;

        let today = moment(new Date()).format('YYYY-MM-DD');

        if (lastEventStatusFilterApplied) {
          const lastEventFilter = appliedFilters
            .filter(item => item.label === lastEventStatusFilterLabel)[0]
            .option.filter(selectedOption => selectedOption.selected === true)[0];

          if (lastEventFilter.subLabel !== 'Completed') {
            filterObject['missed_event_date'] = today;
          }
          filterObject['last_event_status'] = EventStatusEnum[lastEventFilter.subLabel];
        }

        if (upcomingEventDateTypeFilterApplied) {
          const upcomingEventDateType = appliedFilters
            .filter(item => item.label === upcomingEventDateTypeFilterLabel)[0]
            .option.filter(selectedOption => selectedOption.selected === true)[0];

          if (upcomingEventDateType.subLabel === 'No Events') {
            filterObject['no_upcoming_events'] = true;
          } else {
            filterObject['upcoming_event_start_date'] = today;
            let numberofDays = '';
            switch (upcomingEventDateType.subLabel) {
              case 'Today':
                numberofDays = moment().add(1, 'days').format('YYYY-MM-DD');
                break;

              case 'This Week':
                numberofDays = moment().day(7).format('YYYY-MM-DD');
                break;

              case 'This Month':
                numberofDays = moment().endOf('month').format('YYYY-MM-DD');
                break;

              default:
                break;
            }
            filterObject['upcoming_event_end_date'] = numberofDays;
          }
        }

        if (categoryFilterApplied) {
          let selectedCategory: any[] = [];
          const categoryFilter = appliedFilters
            .filter(item => item.label === categoryFilterLabel)[0]
            .option.filter(selectedOption => selectedOption.selected === true);

          [...categoryFilter].forEach(ele => {
            selectedCategory.push(CategoryFilterEnum[ele.subLabel]);
          });
          filterObject['category'] = selectedCategory.join(',');
        }

        if (customerAssignedFilterApplied) {
          const assignedFilter = appliedFilters
            .filter(item => item.label === customerAssignedFilterLabel)[0]
            .option.filter(selectedOption => selectedOption.selected === true)[0];

          if (assignedFilter.subLabel !== 'All Time') {
            filterObject['customer_assigned_end_date'] = today;
            switch (assignedFilter.subLabel) {
              case 'Today':
                filterObject['customer_assigned_start_date'] = today;
                break;

              case 'Last 7 Days':
                filterObject['customer_assigned_start_date'] = moment()
                  .add(-7, 'days')
                  .format('YYYY-MM-DD');
                break;

              case 'Last 30 Days':
                filterObject['customer_assigned_start_date'] = moment()
                  .add(-30, 'days')
                  .format('YYYY-MM-DD');
                break;

              default:
                break;
            }
          }
        }
      
        const CCStatusEnum = {
          'Not Yet Called': 'NotYetCalled',
          'Not Interested': 'CustomerNotInterested',
          'Call Attempted Unsuccessfully': 'CallAttemptedUnsuccessfully',
        };

        const ccStatusFilterLabel = 'CC - Call Status';

        const ccStatusFilterApplied = appliedFilters.filter(
          item => item.label === ccStatusFilterLabel,
        ).length;

        if (ccStatusFilterApplied) {
          const ccStatusFilter = appliedFilters
            .filter(item => item.label === ccStatusFilterLabel)[0]
            .option.filter(selectedOption => selectedOption.selected === true)[0];

          filterObject['call_center_status'] = CCStatusEnum[ccStatusFilter.subLabel];
        }
      
        const upcomingEventFilterLabel = 'Upcoming Event';
        const customersThatHaveFilterLabel = 'Customers that have';
        const customersInterestedInFilterLabel = 'Customers interested in';
        const customersMovedToInterestedFilterLabel = 'Customers moved to interested';
        const customersMarkedInterestedByFilterLabel = 'Customers marked interested by';

        const upcomingEventFilterApplied = appliedFilters.filter(
          item => item.label === upcomingEventFilterLabel,
        ).length;
        const customersThatHaveFilterApplied = appliedFilters.filter(
          item => item.label === customersThatHaveFilterLabel,
        ).length;
        const customersInterestedInFilterApplied = appliedFilters.filter(
          item => item.label === customersInterestedInFilterLabel,
        ).length;
        const customersMovedToInterestedFilterApplied = appliedFilters.filter(
          item => item.label === customersMovedToInterestedFilterLabel,
        ).length;
        const customersMarkedInterestedByFilterApplied = appliedFilters.filter(
          item => item.label === customersMarkedInterestedByFilterLabel,
        ).length;

        if (lastEventStatusFilterApplied) {
          const lastEventFilter = appliedFilters
            .filter(item => item.label === lastEventStatusFilterLabel)[0]
            .option.filter(selectedOption => selectedOption.selected === true)[0];

          if (lastEventFilter.subLabel !== 'Completed') {
            filterObject['missed_event_date'] = today;
          }
          filterObject['last_event_status'] = EventStatusEnum[lastEventFilter.subLabel];
        }

        if (categoryFilterApplied) {
          let selectedCategory: any[] = [];
          const categoryFilter = appliedFilters
            .filter(item => item.label === categoryFilterLabel)[0]
            .option.filter(selectedOption => selectedOption.selected === true);

          [...categoryFilter].forEach(ele => {
            selectedCategory.push(CategoryFilterEnum[ele.subLabel]);
          });
          filterObject['category'] = selectedCategory.join(',');
        }

        if (upcomingEventFilterApplied) {
          const upcomingEvent = appliedFilters
            .filter(item => item.label === upcomingEventFilterLabel)[0]
            .option.filter(selectedOption => selectedOption.selected === true)[0];

          filterObject['customer_event_type'] = EventTypeEnum[upcomingEvent.subLabel];
          filterObject['upcoming_event_start_date'] = today;
        }

        if (customersThatHaveFilterApplied) {
          const customersThatHave = appliedFilters
            .filter(item => item.label === customersThatHaveFilterLabel)[0]
            .option.filter(selectedOption => selectedOption.selected === true)[0];

          if (customersThatHave.subLabel === 'No Upcoming Events') {
            filterObject['no_upcoming_events'] = true;
          } else {
            filterObject['last_event_status'] = 'COMPLETED';
            switch (customersThatHave.subLabel) {
              case 'Site Visit Completed':
                filterObject['customer_event_type'] = 'SITE_VISIT';
                break;

              case 'Meeting Completed':
                filterObject['customer_event_type'] = 'MEETING';
                break;

              default:
                break;
            }
          }
        }

        if (customersInterestedInFilterApplied) {
          const customersInterestedIn = appliedFilters
            .filter(item => item.label === customersInterestedInFilterLabel)[0]
            .option.filter(selectedOption => selectedOption.selected === true)[0];

          switch (customersInterestedIn.subLabel) {
            case 'Single Project':
              filterObject['number_of_projects_interested_in'] = '1,1';
              break;

            case '2-4 Projects':
              filterObject['number_of_projects_interested_in'] = '2,4';
              break;

            case '5-7 Projects':
              filterObject['number_of_projects_interested_in'] = '5,7';
              break;

            case '7+ Projects':
              filterObject['number_of_projects_interested_in'] = '8,10';
              break;

            default:
              filterObject['number_of_projects_interested_in'] = '0,10';
              break;
          }
        }

        if (customersMovedToInterestedFilterApplied) {
          const customersMovedToInterested = appliedFilters
            .filter(item => item.label === customersMovedToInterestedFilterLabel)[0]
            .option.filter(selectedOption => selectedOption.selected === true)[0];

          if (customersMovedToInterested.subLabel !== 'All Time') {
            filterObject['customer_moved_to_interested_end_date'] = today;
            switch (customersMovedToInterested.subLabel) {
              case 'Today':
                filterObject['customer_moved_to_interested_start_date'] = today;
                break;

              case 'Last 7 Days':
                filterObject['customer_moved_to_interested_start_date'] = moment()
                  .add(-7, 'days')
                  .format('YYYY-MM-DD');
                break;

              case 'Last 30 Days':
                filterObject['customer_moved_to_interested_start_date'] = moment()
                  .add(-30, 'days')
                  .format('YYYY-MM-DD');
                break;

              default:
                break;
            }
          }
        }

        if (customersMarkedInterestedByFilterApplied) {
          const customersMarkedInterestedBy = appliedFilters
            .filter(item => item.label === customersMarkedInterestedByFilterLabel)[0]
            .option.filter(selectedOption => selectedOption.selected === true)[0];

          if (customersMarkedInterestedBy.subLabel === 'You') {
            filterObject['customer_moved_to_interested_by'] = 'CHANNEL_PARTNER';
          } else if (customersMarkedInterestedBy.subLabel === 'Call Centre') {
            filterObject['customer_moved_to_interested_by'] = 'CALL_CENTER';
          }
        }

        const bookingDoneOnFilterLabel = 'Booking Done on';
        const unitConfigurationFilterLabel = 'Unit Configuration';

        const bookingDoneOnFilterApplied = appliedFilters.filter(
          item => item.label === bookingDoneOnFilterLabel,
        ).length;
        const unitConfigurationFilterApplied = appliedFilters.filter(
          item => item.label === unitConfigurationFilterLabel,
        ).length;

        if (upcomingEventFilterApplied) {
          const upcomingEvent = appliedFilters
            .filter(item => item.label === upcomingEventFilterLabel)[0]
            .option.filter(selectedOption => selectedOption.selected === true)[0];

          filterObject['customer_event_type'] = EventTypeEnum[upcomingEvent.subLabel];
          filterObject['upcoming_event_start_date'] = today;
        }

        if (bookingDoneOnFilterApplied) {
          const bookingDoneOn = appliedFilters
            .filter(item => item.label === bookingDoneOnFilterLabel)[0]
            .option.filter(selectedOption => selectedOption.selected === true)[0];

          filterObject['customer_lead_booking_done_end_date'] = moment()
            .add(1, 'days')
            .format('YYYY-MM-DD');

          switch (bookingDoneOn.subLabel) {
            case 'Today':
              filterObject['customer_lead_booking_done_start_date'] = today;
              break;

            case 'Yesterday':
              filterObject['customer_lead_booking_done_start_date'] = moment()
                .add(-1, 'days')
                .format('YYYY-MM-DD');
              break;

            case 'Last 7 Days':
              filterObject['customer_lead_booking_done_start_date'] = moment()
                .add(-7, 'days')
                .format('YYYY-MM-DD');
              break;

            case 'Last 30 Days':
              filterObject['customer_lead_booking_done_start_date'] = moment()
                .add(-30, 'days')
                .format('YYYY-MM-DD');
              break;

            default:
              break;
          }
        }

        if (unitConfigurationFilterApplied) {
          let selectedUnitConfigurations: any[] = [];
          const unitConfigurationFilter = appliedFilters
            .filter(item => item.label === unitConfigurationFilterLabel)[0]
            .option.filter(selectedOption => selectedOption.selected === true);

          [...unitConfigurationFilter].forEach(ele => {
            selectedUnitConfigurations.push(ele.id);
          });

          filterObject['unit_configuration'] = selectedUnitConfigurations.join(',');
        }
      
        const lastEventTypeFilterLabel = 'Event Type';
        const failureMarkedOnFilterLabel = 'Marked Failed on';

        const lastEventTypeFilterApplied = appliedFilters.filter(
          item => item.label === lastEventTypeFilterLabel,
        ).length;
        
        const failureMarkedOnFilterApplied = appliedFilters.filter(
          item => item.label === failureMarkedOnFilterLabel,
        ).length;

        

        if (lastEventTypeFilterApplied) {
          const lastEventType = appliedFilters
            .filter(item => item.label === lastEventTypeFilterLabel)[0]
            .option.filter(selectedOption => selectedOption.selected === true)[0];

          filterObject['customer_event_type'] = EventTypeEnum[lastEventType.subLabel];
          filterObject['upcoming_event_end_date'] = today;
        }

        if (lastEventStatusFilterApplied) {
          const lastEventStatus = appliedFilters
            .filter(item => item.label === lastEventStatusFilterLabel)[0]
            .option.filter(selectedOption => selectedOption.selected === true)[0];

          if (lastEventStatus.subLabel === 'Missed') {
            filterObject['missed_event_date'] = today;
          } else if (lastEventStatus.subLabel === 'Planned') {
            filterObject['missed_event_date'] = moment().add(-7000, 'days').format('YYYY-MM-DD');
          }

          filterObject['last_event_status'] = EventStatusEnum[lastEventStatus.subLabel];
        }

        if (failureMarkedOnFilterApplied) {
          const failureMarkedOn = appliedFilters
            .filter(item => item.label === failureMarkedOnFilterLabel)[0]
            .option.filter(selectedOption => selectedOption.selected === true)[0];

          if (failureMarkedOn.subLabel !== 'All Time') {
            filterObject['customer_moved_to_failed_end_date'] = moment()
              .add(1, 'days')
              .format('YYYY-MM-DD');

            switch (failureMarkedOn.subLabel) {
              case 'Today':
                filterObject['customer_moved_to_failed_start_date'] = today;
                break;

              case 'Last 7 Days':
                filterObject['customer_moved_to_failed_start_date'] = moment()
                  .add(-7, 'days')
                  .format('YYYY-MM-DD');
                break;

              case 'Last 30 Days':
                filterObject['customer_moved_to_failed_start_date'] = moment()
                  .add(-30, 'days')
                  .format('YYYY-MM-DD');
                break;

              default:
                break;
            }
          }
        }
      }
    setFilterObject(filterObject);
    dispatch(actions.loadCustomers(filterObject));
    dispatch(customerCountAction.loadCustomersCount(filterObject));
  };

  const [totalCC, setTotalCC] = useState(0);
  const [remainingLimit, setRemainingLimit] = useState(0);
  useEffect(() => {
    if (callCenterInfoDetails?.total_customer_in_cc) {
      setTotalCC(
        (callCenterInfoDetails?.total_customer_in_cc / (callCenterInfoDetails?.total_limit || 1)) *
          100,
      );
    }
    if (callCenterInfoDetails?.remaining_limit) {
      setRemainingLimit(
        (callCenterInfoDetails?.remaining_limit / (callCenterInfoDetails?.total_limit || 1)) * 100,
      );
    }
  }, [callCenterInfoDetails]);

  const donutData = [
    { value: totalCC || 0, label: 'Total In CC' },
    { value: remainingLimit || 0, label: 'Available Limit' },
  ];

  const [transferTeammate, setTransferTeammate] = useState(false);

  const [sendCallCanter, setSendCallCanter] = useState(false);

  const [bulkUpload, setBulkUpload] = useState(false);

  const [bulkUploadList, setBulkUploadList] = useState(false);

  const [bulkListSelected, setBulkListSelected] = useState('');
  const [search, setSearch] = useState('');

  const [teammateSelected, setTeammateSelected] = useState<string | undefined>('');

  const [showSelectBar, setShowSelectBar] = useState(false);

  const [selectBarValue, setSelectBarValue] = useState(0);

  const [activeTabName, setActiveTabName] = useState('All Customers');

  const [recommendedProjects, setRecommendedProjects] = useState<any>([]);

  const [selectedProjects, setSelectedProjects] = useState<any>([]);

  const [maxProjectmsg, setMaxProjectmsg] = useState(false);

  const [searchedProject, setSearchedProject] = useState<any>([]);

  const [controller, setController] = useState<any>();

  const [cpBusinessProfile, setCpBusinessProfile] = useState<any>();
  const handleTransferTeammateSelectBar = () => {
    setSelectBarValue(1);
  };
  const { actions: unitconfigurationsactions } = useUnitConfigurationListSlice();

  useEffect(() => {
    if (activeTab === 5) {
      dispatch(unitconfigurationsactions.loadUnitConfiguration());
    }
    resetValues();
  }, [activeTab]);

  useEffect(() => {
    if (selectBarValue === 1 || selectBarValue === 2) {
      setShowSelectBar(true);
    } else {
      setShowSelectBar(false);
    }
    if (selectBarValue === 2) {
      const requestUrl = `${process.env.REACT_APP_PROJECT_API_URL}/cp/project?is_top_project=true&page=1&size=10`;
      getRequest(requestUrl).then(res => {
        setRecommendedProjects(res?.data?.items);
      });
    }
  }, [selectBarValue]);

  const handleTransfeTeammateModal = () => {
    setTransferTeammate(!transferTeammate);
  };

  const handleTransfeTeammate = () => {
    setTransferTeammate(!transferTeammate);
    setShowSelectBar(!showSelectBar);
    setSelectBarValue(0);
  };

  const handleSendCallCenterSelectBar = () => {
    setSelectBarValue(2);
  };

  const handleSendCallCanterModal = () => {
    setSendCallCanter(!sendCallCanter);
  };

  const handleCallCenter = () => {
    setSendCallCanter(!sendCallCanter);
    setShowSelectBar(!showSelectBar);
    setSelectBarValue(0);
  };

  const handleBulkUploadModal = () => {
    setBulkUpload(!bulkUpload);
  };

  const [file, setFile] = useState<File>();

  const handleFileChange = e => {
    if (e.target.files) {
      setFile(e.target.files[0]);
    }
  };

  const [isDesktop, setDesktop] = useState(window.innerWidth > 720);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 720);
  };

  const customersPage = useSelector(selectCustomersPage);
  const customersSize = useSelector(selectCustomersSize);
  const customersTotal = useSelector(selectCustomersTotal);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [customersPage]);

  const loadMoreCustomers = (customersPage, customersSize) => {
    dispatch(
      actions.loadCustomers({
        status: activeStatusRef.current,
        page: customersPage + 1,
        size: customersSize,
      }),
    );
  };

  const handleScroll = () => {
    let userScrollHeight = window.innerHeight + window.scrollY;
    const fullPageHeight = document.documentElement.scrollHeight;

    if (userScrollHeight >= fullPageHeight / (customersPage + 1)) {
      const numPages = customersTotal / customersSize;
      if (customersPage <= numPages) {
        loadMoreCustomers(customersPage, customersSize);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('resize', updateMedia);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const bulkTeamMateTransfer = () => {
    let data = {
      customer_id: transferCusList,
      transferee_cp_id: teammateSelected,
      tranferror_cp_id: localStorage.getItem('userID'),
    };
    const requestURL = `${process.env.REACT_APP_DDP_CRM_API_URL}/customer/transfer/`;
    if (requestURL && teammateSelected) {
      postRequest(requestURL, data).then(res => {
        if (res.status === 200) {
          toast.success('Customer Transferred Successfully!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        } else if (res.status !== 200) {
          toast.error('Customer Transferred Failed!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
        return res;
      });
    }
    handleTransfeTeammate();
    setSelectBarValue(0);
    setTransferCusList([]);
    setTeammateSelected('');
    setOptionSelected('');
  };

  const bulkUploadApiCall = () => {
    var formData = new FormData();
    if (file) {
      formData.append('uploaded_file', file);
    }
    const cpUserId = localStorage.getItem('userID');
    const requestURL = `${process.env.REACT_APP_DDP_CRM_API_URL}/customer/bulk_upload/${cpUserId}`;
    if (requestURL && cpUserId) {
      postRequest(requestURL, formData).then(res => {
        if (res.status === 200) {
          toast.success('Customers Uploaded Successfully!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          applyFilters(appliedCustomerFilters);
        } else if (res.status !== 200) {
          toast.error('Customers Uploaded Failed!', {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
        return res;
      });
    }
    handleBulkUploadModal();
    setSelectBarValue(0);
  };

  const [optionSelected, setOptionSelected] = useState('');

  const handleBulkTransfer = type => {
    setOptionSelected(type);
  };
  const [inputText, setInputText] = useState(0);

  let inputHandler = e => {
    if (e.target.value > customerTotal) {
      setInputText(customerTotal);
    } else {
      const text = e.target.value.replace(/[^0-9]/g, '');
      setInputText(text);
    }
  };

  useEffect(() => {
    let customerIdsArr = [...transferCusList];
    if (optionSelected === 'select_all') {
      CustomerDetails.map((item, index) => {
        if (
          item?.id &&
          (selectBarValue === 1 || (selectBarValue === 2 && item?.phone_no?.length === 10))
        ) {
          if (!customerIdsArr.includes(item?.id)) {
            customerIdsArr.push(item?.id);
          }
        }
      });
      setTransferCusList(customerIdsArr);
      setInputText(customerIdsArr?.length);
    }
  }, [optionSelected]);

  useEffect(() => {
    let selectedCustomerArr: any = [];
    const unselectableCustomers = CustomerDetails.filter(
      item => item?.phone_no?.length !== 10,
    ).length;
    if (inputText !== transferCusList?.length) {
      CustomerDetails.map((item, index) => {
        if (
          selectedCustomerArr.length < inputText &&
          (selectBarValue === 1 || (selectBarValue === 2 && item?.phone_no?.length === 10))
        ) {
          selectedCustomerArr.push(item?.id);
        }
      });
      setTransferCusList(selectedCustomerArr);
    }
  }, [inputText]);

  const [transferCusList, setTransferCusList] = useState<any>([]);

  const selectedCustomer = id => {
    setOptionSelected('custom_select');
    let transferListTemp = [...transferCusList];
    if (transferListTemp.includes(id)) {
      setTransferCusList([...transferListTemp.filter(ele => id !== ele)]);
      setInputText(transferCusList.length - 1);
    } else {
      transferListTemp.push(id);
      setInputText(transferCusList.length + 1);
      setTransferCusList([...transferListTemp]);
    }
  };

  const bulkSendCallCanterApiCall = () => {
    const requestURL = `${process.env.REACT_APP_DDP_CRM_API_URL}/customer/send_to_call_center/`;
    let data = {
      customer_ids: transferCusList,
      call_center_project_ids: selectedProjects,
    };
    if (requestURL && data) {
      postRequest(requestURL, data).then(res => {
        const msg = res?.data?.response?.message;
        if (res.status === 200) {
          toast.success(msg, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        } else if (res.status !== 200) {
          toast.error(msg, {
            position: 'bottom-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
        }
        return res;
      });
    }
    handleCallCenter();
    setSelectBarValue(0);
    setTransferCusList([]);
    setOptionSelected('');
    setSelectedProjects([]);
  };

  const handleSearch = e => {
    if (controller) {
      controller.abort();
    }
    const localController = new AbortController();
    setController(localController);

    const lowerCase = e.target.value.toLowerCase();
    setSearch(lowerCase);
    if (!lowerCase) {
      setSearchedProject([]);
    } else {
      let requestUrl = `${process.env.REACT_APP_PROJECT_API_URL}/cp/project?project_name=${lowerCase}`;
      getRequest(requestUrl, localController.signal).then(res => {
        setSearchedProject([]);
        setSearchedProject(res?.data?.items);
      });
    }
  };

  const selectProject = id => {
    if (selectedProjects.includes(id)) {
      var selectproj = [...selectedProjects];
      var index = selectproj.indexOf(id);
      if (index > -1) {
        selectproj.splice(index, 1);
      }
      setSelectedProjects(selectproj);
    } else {
      var projects = searchedProject?.length ?[...searchedProject] :[...recommendedProjects];
      var selectproj = [...selectedProjects];
      var elementPos = projects
        .map(function (x) {
          return x?.id;
        })
        .indexOf(id);
      selectproj.push(projects[elementPos].id);
      setSelectedProjects([...selectproj]);
    }
  };

  useEffect(() => {
    if (selectedProjects.length >= 2) {
      setMaxProjectmsg(true);
    } else {
      setMaxProjectmsg(false);
    }
  }, [selectedProjects]);

  const [tick, setTick] = useState<any>([]);
  const tickProjects = id => {
    let tickListTemp = [...tick];
    if (tickListTemp.includes(id)) {
      setTick([...tickListTemp.filter(ele => id !== ele)]);
    } else {
      setTick([...tick, id]);
    }
  };

  const resetValues = () => {
    setSelectedProjects([]);
    setSelectBarValue(0);
    setTransferCusList([]);
    setTeammateSelected('');
    setOptionSelected('');
    setTransferTeammate(false);
    setShowSelectBar(false);
    setSendCallCanter(false);
    setShowSelectBar(false);
    setInputText(0);
    setTick([]);
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={50000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div>
        <div style={{ width: '90%', paddingTop: '8rem', margin: 'auto' }}>
          {isDesktop ? (
            <WebFilters
              status={activeStatus}
              setCustomerSearch={setCustomerSearch}
              setAppliedCustomerFilters={setAppliedCustomerFilters}
              applyFilters={applyFilters}
            />
          ) : (
            <MobileFilters
              status={activeStatus}
              setCustomerSearch={setCustomerSearch}
              setAppliedCustomerFilters={setAppliedCustomerFilters}
              applyFilters={applyFilters}
            />
          )}
        </div>
        <div style={{ width: '90%', margin: 'auto' }}>
          <div className="flex items-center justify-between my-4 md:my-0">
            <h2 className="text-xl text-black font-medium">{activeTabName}</h2>
            <div className="flex flex-wrap gap-x-4 items-center">
              {cpBusinessProfile?.business_type === 'FIRM' ? (
                <button
                  onClick={handleTransferTeammateSelectBar}
                  className={
                    selectBarValue === 1
                      ? 'md:block hidden border border-purple-300 bg-primary rounded-full p-3.5'
                      : 'md:block hidden border border-gray-300 bg-white rounded-full p-3.5'
                  }
                >
                  {selectBarValue === 1 ? <TransferTeamMateWhite /> : <TransferTeamMate />}
                </button>
              ) : (
                <></>
              )}
              {/* Transfer to Teammate Modal Starts */}
              <Dialog
                open={transferTeammate}
                handler={handleTransfeTeammateModal}
                className="w-96 max-w-96 min-w-44 py-5 px-6 rounded-md"
              >
                <div>
                  <div className="flex flex-1 justify-between">
                    <h4 className="text-base text-black font-medium">Transfer to teammate</h4>
                    <button onClick={handleTransfeTeammateModal}>
                      <Cross />
                    </button>
                  </div>
                  <p className="text-xs text-gray-600 font-normal">
                    Select Teammate to transfer customers:
                  </p>
                  <h6 className="text-xs text-gray-600 font-semibold mt-6 mb-4">
                    Choose teammate
                    <span className="text-red-700 font-medium">*</span>
                  </h6>
                  <div className="h-72 overflow-y-auto">
                    {allUserList.map((item, index) => {
                      return (
                        <div key={`teammate-${index}`} className="flex items-center mb-6">
                          <button
                            onClick={() => {
                              setTeammateSelected(item?.id);
                            }}
                          >
                            {teammateSelected === item?.id ? <CheckRadio /> : <UnCheckRadio />}
                          </button>
                          <h5 className="text-sm text-black/80 font-normal ml-2.5">{item?.name}</h5>
                        </div>
                      );
                    })}
                  </div>
                  <button
                    onClick={bulkTeamMateTransfer}
                    className="w-full border border-purple-700 bg-primary rounded-lg p-4"
                  >
                    <h4 className="text-sm text-white font-medium">Transfer to Teammate</h4>
                  </button>
                </div>
              </Dialog>
              {/* Transfer to Teammate Modal Ends */}
              
              <button
                onClick={() => setBulkUploadList(!bulkUploadList)}
                className={
                  bulkUploadList
                    ? 'md:block hidden border border-purple-300 bg-primary rounded-full py-3 px-3.5'
                    : 'md:block hidden border border-gray-300 bg-white rounded-full py-3 px-3.5'
                }
              >
                {bulkUploadList ? <BulkUploadWhite /> : <BulkUpload />}
              </button>
              
              {bulkUploadList ? (
                <div className="absolute z-30 w-48 bg-white rounded-sm shadow-lg pt-3 pb-3 mt-28 ml-24">
                  <div className={bulkListSelected ? 'flex items-center' : 'flex items-center'}>
                    <button
                      onClick={() => {
                        handleBulkUploadModal();
                        setBulkListSelected('upload');
                        setBulkUploadList(!bulkUploadList);
                      }}
                    >
                      <h5 className="lg:text-sm text-[10px] text-black font-base ml-2">
                        Bulk Upload
                      </h5>
                    </button>
                  </div>
                  <div className="flex items-center mt-4">
                    <a
                      onClick={() => {
                        setBulkListSelected('download');
                        setBulkUploadList(!bulkUploadList);
                      }}
                      href={require('./../../assets/SampleFile.csv')}
                      download="SampleBulkUpload"
                    >
                      <h5 className="lg:text-sm text-[10px] text-black font-base ml-2">
                        Download Sample File
                      </h5>
                    </a>
                  </div>
                </div>
              ) : (
                <></>
              )}
              {/* Bulk Upload Modal Starts */}
              <Dialog
                open={bulkUpload}
                handler={handleBulkUploadModal}
                className="w-96 max-w-96 min-w-44 py-5 px-6 rounded-md"
              >
                <div>
                  <div className="flex flex-1 justify-between">
                    <h4 className="text-base text-black font-medium">Bulk Upload Customers</h4>
                    <button
                      onClick={() => {
                        handleBulkUploadModal();
                        setSelectBarValue(0);
                        setFile(undefined);
                      }}
                    >
                      <Cross />
                    </button>
                  </div>
                  <div className="flex flex-col items-center mt-10">
                    <DragAndDrop setFile={setFile} />
                    <label className="flex flex-col items-center cursor-pointer">
                      {!file ? (
                        <>
                          <h5 className="text-sm text-black font-normal mt-5">
                            Drag & drop file here to upload
                          </h5>
                          <p className="text-sm text-gray-500 font-normal my-3">or</p>
                        </>
                      ) : (
                        <div className="w-80 flex items-center justify-between border border-gray-200 bg-gray-200 text-xs text-black font-medium py-2 px-3 my-6">
                          {file && `${file.name} - ${file.type}`}
                          {/* <button>
              <Cross />
            </button> */}
                        </div>
                      )}
                      <input
                        type="file"
                        size={100}
                        accept=".pdf, .xls, .xlsx, .csv"
                        onChange={handleFileChange}
                        className="hidden cursor-pointer"
                      />
                      {!file ? (
                        <div className="flex gap-x-2">
                          <div className="border border-purple-700 bg-primary rounded-md py-2.5 px-4">
                            <h4 className="text-sm text-white font-medium">Browse File</h4>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}

                      {file ? (
                        <button
                          onClick={bulkUploadApiCall}
                          className="border border-purple-700 bg-primary rounded-md py-2.5 px-4"
                        >
                          <h4 className="text-sm text-white font-medium">Upload File</h4>
                        </button>
                      ) : (
                        <></>
                      )}
                    </label>
                  </div>
                </div>
              </Dialog>
              {/* Bulk Upload Modal Ends */}
              {activeStatus === 'NEW' ? (
                <button
                  onClick={handleSendCallCenterSelectBar}
                  className={
                    selectBarValue === 2
                      ? 'md:block hidden border border-purple-300 bg-primary rounded-full p-3.5'
                      : 'md:block hidden border border-gray-300 bg-white rounded-full p-3.5'
                  }
                >
                  {selectBarValue === 2 ? <TransferCallCenterWhite /> : <TransferCallCenter />}
                </button>
              ) : (
                <></>
              )}
              {/* Send to Call Center Starts */}
              <Dialog
                open={sendCallCanter}
                handler={handleSendCallCanterModal}
                className="w-96 max-w-96 min-w-44 py-5 px-6 rounded-sm"
              >
                <div>
                  <div className="flex flex-1 justify-between">
                    <h4 className="text-base text-black font-medium">Send to Call Center</h4>
                    <button onClick={handleSendCallCanterModal}>
                      <Cross />
                    </button>
                  </div>
                  <p className="text-xs text-gray-600 font-normal">
                    Select Projects (Max 2) to send to Call Centre:
                  </p>
                  <h6 className="text-xs text-gray-600 font-semibold mt-6 mb-4">
                    Choose Project
                    <span className="text-red-700 font-medium">*</span>
                  </h6>
                  <div className="flex items-center border bg-gray-200 rounded-md py-3 px-5">
                    <span>
                      <Search className="w-4 h-4" />
                    </span>
                    <input
                      placeholder="Search project"
                      className="w-full text-xs text-black font-normal border-gray-200 bg-gray-200 ml-2.5"
                      onChange={handleSearch}
                      value={search}
                    />
                  </div>
                  {maxProjectmsg ? (
                    <div className="flex items-center mt-3">
                      <ExclamationMark />
                      <h6 className="text-xs text-black font-medium ml-2">
                        You have selected the maximum number of projects
                      </h6>
                    </div>
                  ) : (
                    <></>
                  )}
                  <h6 className="text-xs text-gray-600 font-medium mt-3 mb-4">
                    Recommended Projects:
                  </h6>
                  <div className="h-72 overflow-y-auto">
                    {searchedProject?.length
                      ? searchedProject?.map((sub_item, sub_index) => {
                          return (
                            <div key={`teammate-${sub_index}`} className="flex items-center mb-6">
                              <button
                                onClick={() => {
                                  selectProject(sub_item?.id);
                                  tickProjects(sub_item?.id);
                                }}
                              >
                                {tick.includes(sub_item?.id) ? (
                                  <CheckCircle className="w-6 h-6" />
                                ) : (
                                  <UncheckCircle className="w-6 h-6" />
                                )}
                              </button>
                              <h5 className="text-sm text-black/80 font-normal ml-2.5">
                                {sub_item?.name}
                              </h5>
                            </div>
                          );
                        })
                      : recommendedProjects?.map((sub_item, sub_index) => {
                          return (
                            <div key={`teammate-${sub_index}`} className="flex items-center mb-6">
                              <button
                                onClick={() => {
                                  selectProject(sub_item?.id);
                                  tickProjects(sub_item?.id);
                                }}
                              >
                                {tick.includes(sub_item?.id) ? (
                                  <CheckCircle className="w-6 h-6" />
                                ) : (
                                  <UncheckCircle className="w-6 h-6" />
                                )}
                              </button>
                              <h5 className="text-sm text-black/80 font-normal ml-2.5">
                                {sub_item?.name}
                              </h5>
                            </div>
                          );
                        })}
                  </div>
                </div>
                <button
                  onClick={bulkSendCallCanterApiCall}
                  className="w-full border border-purple-700 bg-primary rounded-lg p-4"
                >
                  <h4 className="text-sm text-white font-medium">Send to Call Center</h4>
                </button>
              </Dialog>
              {/* Send to Call Center Modal Ends */}
              <div>
                <TeammateDropdown users={allUserList} selectedMember={selectMember} />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 mb-96">
          <div className="w-[90%] mx-auto ">
            <div className="flex items-center border bg-white rounded-md py-4 px-7 mb-6 xs:overflow-x-auto">
              {Tabs.map((item, index) => {
                return (
                  <button
                    className="flex-1 xs:flex-none xs:mr-6"
                    key={`Tabs-${item?.value}`}
                    onClick={() => {
                      setActiveTab(item?.value);
                      setActiveTabName(item?.header);
                      onTabChange(item?.status);
                    }}
                    disabled={activeTab === item?.value}
                  >
                    <h6
                      className={
                        item?.value === activeTab
                          ? 'text-xs text-primary font-semibold'
                          : 'text-xs text-black font-semibold'
                      }
                    >
                      {item?.label}
                    </h6>
                  </button>
                );
              })}
            </div>
          </div>
          <div style={{ width: '90%', margin: 'auto' }}>
            {activeTab === 3 ? (
              <div>
                <div className="md:flex grid gap-x-4 xl:gap-x-8">
                  <div className="md:w-1/3 w-full items-center border rounded-md mb-6 py-3 px-3 bg-transparent md:bg-white xl:py-4 xl:pl-6 xl:pr-[60px]">
                    <h5 className="text-sm text-black font-semibold mb-4">Summary</h5>
                    <div className="flex items-center rounded-md bg-white p-3 md:bg-transparent md:p-0">
                      <div className="">
                        <DonutChart
                          items={donutData}
                          roundedCaps={false}
                          size={106}
                          trackWidth="sm"
                          trackColor="#835c01"
                          showTotal={false}
                          tooltipFontSize="8px"
                        />
                        {/* <span>
                        <CallCenterPurple className="md:w-6 md:h-6 lg:w-8 lg:h-8" />
                      </span> */}
                      </div>
                      <div className="ml-5">
                        <div>
                          <h6 className="xl:text-xs text-[10px] text-black font-medium">
                            Total Customers in Call Centre:
                          </h6>
                          <h2 className="xl:text-xl text-lg text-blue-700 font-semibold">
                            {callCenterInfoDetails?.total_customer_in_cc}
                          </h2>
                        </div>
                        <div className="mt-4">
                          <h6 className="xl:text-xs text-[10px] text-black font-medium">
                            Available limit for upload:
                          </h6>
                          <h2 className="xl:text-xl text-lg text-yellow-700 font-semibold">
                            {callCenterInfoDetails?.remaining_limit}
                          </h2>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="md:w-2/3 w-full flex flex-col justify-between rounded-md mb-6 py-3 px-3 bg-transparent md:bg-white  xl:py-4 xl:px-6">
                    <div className="flex items-center justify-between">
                      <h5 className="text-sm text-black font-semibold">Call Centre Conversions</h5>
                      <div className="w-32 flex items-center justify-center rounded-md py-2.5 px-3 bg-white md:bg-gray-200">
                        <p className="text-[10px] text-black justify-center font-medium">
                          Last 7 Days
                        </p>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mt-4 xl:gap-8">
                      <div className="xl:flex md:block flex bg-indigo-50 rounded-md p-3">
                        <People className="w-4 h-4 mt-1.5" />
                        <div className="xl:ml-4 xl:mt-0 md:ml-0 md:mt-4 ml-4 ">
                          <h2 className="text-xl text-indigo-700 font-medium">
                            {callCenterInfoDetails?.total_customer_in_cc}
                          </h2>
                          <h6 className="text-xs text-indigo-700 font-medium mt-2">
                            Total customers
                          </h6>
                        </div>
                      </div>
                      <div className="xl:flex md:block flex bg-indigo-50 rounded-md p-3">
                        <People className="w-4 h-4 mt-1.5" />
                        <div className="xl:ml-4  md:ml-0 md:mt-4 ml-4 xl:mt-0">
                          <h2 className="text-xl text-indigo-700 font-medium">
                            {callCenterInfoDetails?.customer_moved_to_interested}
                          </h2>
                          <h6 className="text-xs text-indigo-700 font-medium mt-2">Interested</h6>
                        </div>
                      </div>
                      <div className="xl:flex md:block flex bg-red-50 rounded-md p-3">
                        <PeopleRed className="w-4 h-4 mt-1.5" />
                        <div className="xl:ml-4 xl:mt-0 md:ml-0 md:mt-4 ml-4 ">
                          <h2 className="text-xl text-red-700 font-medium">
                            {callCenterInfoDetails.not_interseted_customers}
                          </h2>
                          <h6 className="text-xs text-red-700 font-medium mt-2">Not interested</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {isDesktop
                  ? CustomerDetails?.map(item => (
                      <CustomerDetailsCard
                        key={`web-cc-${item?.id}`}
                        data={item}
                        active_tab={activeTab}
                        selectedBar={selectBarValue}
                        onSelect={selectedCustomer}
                        type={optionSelected}
                      />
                    ))
                  : CustomerDetails?.map(item => (
                      <CustomerDetailsCardMobile
                        key={`mob-cc-${item?.id}`}
                        data={item}
                        active_tab={activeTab}
                      />
                    ))}
              </div>
            ) : isDesktop ? (
              CustomerDetails?.map(item => (
                <CustomerDetailsCard
                  key={`web-interested-${item?.id}`}
                  data={item}
                  active_tab={activeTab}
                  selectedBar={selectBarValue}
                  onSelect={selectedCustomer}
                  phoneNotComplete={item?.phone_no?.length !== 10}
                  isSelected={
                    transferCusList.filter(data => data === item.id).length > 0 ? true : false
                  }
                  type={optionSelected}
                  cpBusiness={cpBusinessProfile?.business_type}
                />
              ))
            ) : (
              CustomerDetails?.map(item => (
                <CustomerDetailsCardMobile
                  key={`mob-interested-${item?.id}`}
                  data={item}
                  active_tab={activeTab}
                  selectedBar={selectBarValue}
                  onSelect={selectedCustomer}
                  phoneNotComplete={item?.phone_no?.length !== 10}
                  isSelected={
                    transferCusList.filter(data => data === item.id).length > 0 ? true : false
                  }
                  type={optionSelected}
                />
              ))
            )}
          </div>
        </div>
      </div>
      {customersLoading ? <BlackLoadingIndicator /> : <></>}
      {showSelectBar ? (
        <div className="w-full fixed bottom-0 flex items-center justify-between border rounded-md bg-white py-5 px-8">
          <div className="flex items-center">
            <div key={'select_all'} className="flex items-center">
              <button
                onClick={() => {
                  handleBulkTransfer('select_all');
                }}
              >
                {optionSelected === 'select_all' ? <CheckRadio /> : <UnCheckRadio />}
              </button>
              <h4 className="text-base text-black font-normal ml-1.5">Select All</h4>
            </div>
            <div className="flex items-center ml-10">
              <div key={'custom_select'} className="flex items-center">
                <button
                  onClick={() => {
                    handleBulkTransfer('custom_select');
                  }}
                >
                  {optionSelected === 'custom_select' ? <CheckRadio /> : <UnCheckRadio />}
                </button>
                <h5 className="text-base text-black font-normal ml-1.5">Custom Select</h5>
              </div>
              <div className="flex items-center ml-3">
                <input
                  pattern="^-?[0-9]\d*\.?\d*$"
                  className="w-[102px] border border-gray-300 bg-white rounded-l-md py-2 px-3 text-base text-black font-normal"
                  onChange={inputHandler}
                  value={inputText}
                  disabled={optionSelected !== 'custom_select' ? true : false}
                ></input>
                <div className="w-[78px] border border-gray-300 bg-gray-200 rounded-r-md py-2 px-3">
                  <h4 className="text-base text-gray-500 font-normal">{customersTotal}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center gap-x-7">
            <button onClick={resetValues} className="text-base text-primary font-medium">
              Cancel
            </button>
            <div>
              {selectBarValue === 1 ? (
                <button
                  onClick={handleTransfeTeammateModal}
                  className="bg-primary rounded-sm text-base text-white font-medium py-3 px-5"
                >
                  Transfer to teammate
                </button>
              ) : (
                <button
                  onClick={handleSendCallCanterModal}
                  className="bg-primary rounded-sm text-base text-white font-medium py-3 px-5"
                >
                  Send to Call Centre
                </button>
              )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

function DragAndDrop(props) {
  const setFile = props?.setFile;
  const [dragging, setDragging] = useState(false);

  // Define the event listeners for the drop zone
  function handleDragEnter(event) {
    event.preventDefault();
    setDragging(true);
  }

  function handleDragLeave(event) {
    event.preventDefault();
    setDragging(false);
  }

  function handleDragOver(event) {
    event.preventDefault();
  }

  function handleDrop(event) {
    event.preventDefault();
    setDragging(false);
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      handleFiles(files);
    }
  }

  // Define the function to handle the dropped files
  function handleFiles(files) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (setFile && file) {
        setFile(file);
      }
      // Do something with the file, e.g. upload it to a server or display it on the page
    }
  }

  return (
    <Upload
      className="flex flex-col w-full h-10 items-center"
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    />
  );
}

export default DragAndDrop;
