import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

// First select the relevant part from the state
const selectDomain = (state: RootState) => state.callCenterInfo || initialState;

export const selectCallCenterInfo = createSelector(
  [selectDomain],
  callCenterInfoState => callCenterInfoState.callCenterInfo,
);

export const selectLoadingEventSummary = createSelector(
  [selectDomain],
  callCenterInfoState => callCenterInfoState.loading,
);

export const selectError = createSelector(
  [selectDomain],
  callCenterInfoState => callCenterInfoState.error,
);

export const selectErrorMsg = createSelector(
  [selectDomain],
  callCenterInfoState => callCenterInfoState.errorMsg,
);
